import { useField } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FastCheckboxField as CallerSameAsOwnerField } from '../../shared/formFields/CheckboxField';
import { FastEmailInputField as EmailInputField } from '../../shared/formFields/EmailInputField';
import { FastInputField as InputField } from '../../shared/formFields/InputField';
import { FastPhoneNumberField as PhoneNumberField } from '../../shared/formFields/PhoneNumberField';
import FormGroup from '../../shared/utilityComponents/FormGroup';
import { nameof } from '../caseRegistrationFunctions';
import { LocationForm } from './LocationForm';

function CallerInfoFormGroupComp() {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tCaller = useTranslation('translation', { keyPrefix: 'callerForm' }).t;
  const [field] = useField(nameof('callerSameAsOwner'));
  const { value: checked } = field;

  return (
    <FormGroup label={tCaller('callerInfo')}>
      <CallerSameAsOwnerField name={nameof('callerSameAsOwner')} label={tCaller('callerIsOwner')} />

      {!checked ? (
        <>
          <InputField label={`${tShared('name')}*`} name={nameof('callerName')} />
          <PhoneNumberField
            label={`${tShared('telephoneNumber')}*`}
            name={nameof('callerPhone')}
            search
            mapping="caller"
          />
          <LocationForm mapping="caller" />
          <EmailInputField label={tShared('email')} name={nameof('callerEmail')} />
        </>
      ) : null}
    </FormGroup>
  );
}

export const CallerInfoFormGroup = memo(CallerInfoFormGroupComp);
