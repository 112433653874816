import { useParams } from 'react-router-dom';
import { CaseRegistrationData } from './CaseRegistrationData';
import { CaseRegistrationStoreContextProvider } from './CaseRegistrationStore';

export const CaseRegistrationPage = () => {
  const { visCaseId } = useParams();

  return (
    <CaseRegistrationStoreContextProvider key={visCaseId}>
      <CaseRegistrationData visCaseId={visCaseId} />
    </CaseRegistrationStoreContextProvider>
  );
};
