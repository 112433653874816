import { useSnackbarRef } from '@@shared/hooks/useSnackbarRef';
import { useStyletron } from 'baseui';
import { DeleteAlt } from 'baseui/icon';
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE } from 'baseui/modal';
import { Form, Formik, FormikProps } from 'formik';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { CancelCaseCommand } from '../../api/api.generated';
import { useCancellationBasesQuery } from '../../api/queries/useCaseMasterDataQueries';
import { useCancelCaseMutation } from '../../api/queries/useCaseQueries';
import { Button } from '../../shared/basewebComponentOverrides/Button';
import SelectField from '../../shared/formFields/SelectField';
import TextareaField from '../../shared/formFields/TextareaField';

interface CancelCaseForm {
  reasonId: string;
  cancelComment: string;
}

interface CancelCaseModalProps {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
  caseId?: string;
  userId: string;
  title: string;
  successMessage: string;
  failedMessage: string;
}

function CancelCaseModalComp(props: CancelCaseModalProps) {
  const { setIsOpen, isOpen, caseId, userId } = props;
  const { title, successMessage, failedMessage } = props;

  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;

  const [css] = useStyletron();

  const values: CancelCaseForm = { reasonId: '', cancelComment: '' };

  const cancelCase = useCancelCaseMutation();
  const { data: cancellationCodes = [] } = useCancellationBasesQuery();

  const enqueue = useSnackbarRef();

  function handleCancellation(values: CancelCaseForm) {
    cancelCase.mutate({
      caseId: caseId,
      userId: userId,
      reasonId: values.reasonId,
      description: values.cancelComment,
    } as CancelCaseCommand);
  }

  function close() {
    setIsOpen(false);
  }

  function handleValidation(values: CancelCaseForm) {
    const errors: Partial<CancelCaseForm> = {};

    if (!values.cancelComment.trim()) {
      errors.cancelComment = 'Missing comment';
    }

    if (values.reasonId === '') {
      errors.reasonId = 'Missing reasonId';
    }

    return errors;
  }

  const showSnackBar = useCallback(
    (success: boolean) =>
      enqueue({
        message: success ? successMessage : failedMessage,
        startEnhancer: ({ size }) => (success ? <FaCheckCircle size={size} /> : <DeleteAlt size={size} />),
      }),
    [enqueue, failedMessage, successMessage]
  );

  useEffect(() => {
    if (cancelCase.isSuccess && isOpen) {
      setIsOpen(false);
      cancelCase.reset();
      showSnackBar(true);
    }
  }, [cancelCase, cancelCase.isSuccess, isOpen, setIsOpen, showSnackBar]);

  useEffect(() => {
    if (cancelCase.isError && isOpen) {
      setIsOpen(false);
      showSnackBar(false);
    }
  }, [cancelCase.isError, isOpen, setIsOpen, showSnackBar]);

  return (
    <Modal
      isOpen={isOpen}
      animate
      autoFocus
      onClose={close}
      overrides={{
        Dialog: {
          style: {
            width: '40vw',
          },
        },
      }}
      role={ROLE.dialog}
      closeable
    >
      <Formik initialValues={values} onSubmit={handleCancellation} validate={handleValidation} validateOnBlur={false}>
        {(formikProps: FormikProps<CancelCaseForm>) => {
          return (
            <Form>
              <ModalHeader>{title}</ModalHeader>
              <ModalBody>
                <SelectField options={cancellationCodes} label={`${tShared('chooseReason')}*`} name="reasonId" />
                <TextareaField name="cancelComment" label={`${tShared('description')}*`} />
              </ModalBody>
              <ModalFooter className={css({ display: 'flex', justifyContent: 'flex-end' })}>
                <Button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  kind="secondary"
                  $wideButton
                  className={css({ marginRight: '1em' })}
                >
                  {tShared('cancel')}
                </Button>

                <Button type="submit" disabled={!formikProps.isValid} $wideButton isLoading={cancelCase.isLoading}>
                  {tShared('register')}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export const CancelCaseModal = memo(CancelCaseModalComp);
