import { styled, Theme } from 'baseui';
import { LinkProps as BaseUiLinkProps, StyledLink } from 'baseui/link';
import { Link, LinkProps } from 'react-router-dom';
import { StyleObject } from 'styletron-standard';
import { expandPadding } from '@@styles/styleUtils';

// Combines <StyledLink /> from 'baseui' and <Link /> from 'react-router-dom'.
export const RouterLink = (props: LinkProps & BaseUiLinkProps & { $style?: StyleObject }) => (
  <StyledLink $as={Link} {...props} />
);

export const StyledRouterMenuLink = styled(RouterLink, ({ $theme }: { $theme: Theme }) => ({
  textDecoration: 'none !important',
  color: 'unset !important',
  display: 'flex',
  alignItems: 'center',
  ...expandPadding([$theme.sizing.scale400, $theme.sizing.scale800]),
  width: '100%',
  lineHeight: '2em',
}));

export const StyledIncognitoLink = styled(RouterLink, () => ({
  textDecoration: 'none !important',
  color: 'unset !important',
  ':hover': {
    textDecoration: 'underline !important',
  },
}));
