import { useStyletron } from 'baseui';
import { Spinner as BaseUiSpinner } from 'baseui/spinner';

export const SpinnerOverlay = () => {
  const [css, theme] = useStyletron();

  return (
    <div className={css({ display: 'grid', placeItems: 'center', height: '100vh' })}>
      <BaseUiSpinner
        $color={theme.colors.primary}
        $size={theme.sizing.scale1200}
        $borderWidth={theme.sizing.scale200}
      />
    </div>
  );
};

export const MiniSpinner = () => {
  const [, theme] = useStyletron();

  return (
    <BaseUiSpinner $color={theme.colors.primary} $size={theme.sizing.scale600} $borderWidth={theme.sizing.scale0} />
  );
};
