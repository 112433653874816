import { UseMutationResult } from '@tanstack/react-query';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { KIND } from 'baseui/button';
import { DeleteAlt } from 'baseui/icon';
import { Cell, Grid } from 'baseui/layout-grid';
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE } from 'baseui/modal';
import { ALIGN, Radio, RadioGroup } from 'baseui/radio';
import { OnChangeParams, Option } from 'baseui/select';
import { LabelMedium, LabelSmall } from 'baseui/typography';
import { Form, Formik, useFormikContext } from 'formik';
import { memo, UIEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAddCircle, MdCheckCircle, MdOutlineInfo } from 'react-icons/md';
import { useStore } from 'zustand';
import { useCaseRegistrationStoreContext } from '@@caseRegistration/CaseRegistrationStore';
import { ActorDetailsDrawer } from '@@core/drawers/ActorDetailsDrawer';
import { useSnackbarRef } from '@@shared/hooks/useSnackbarRef';
import {
  CaseAction,
  CaseActionDto,
  ComboboxItem,
  CreateCaseActionPaymentGuaranteeCommand,
  CreatePaymentGuaranteeCommand,
  PaymentGuaranteeResult,
} from '../../api/api.generated';
import { useRentalCarProviderQuery } from '../../api/queries/useActorQueries';
import {
  useAdditionalServiceToCaseHandlerMutation,
  useCreateCaseActionAdditionalServiceWithPaymentGuarantee,
  useCreatePaymentGuaranteeMutation,
} from '../../api/queries/useCaseActionQueries';
import { useServicesQuery } from '../../api/queries/useCaseMasterDataQueries';
import { useNearbyRentalCarProvidersWithDistanceQuery } from '../../api/queries/useRetailerQueries';
import { Button } from '../../shared/basewebComponentOverrides/Button';
import { MiniSpinner } from '../../shared/basewebComponentOverrides/Spinner';
import CheckboxField from '../../shared/formFields/CheckboxField';
import { EmailInputField } from '../../shared/formFields/EmailInputField';
import { FastInputField as InputField } from '../../shared/formFields/InputField';
import { FastSelectField as SelectField } from '../../shared/formFields/SelectField';
import { FastTextareaField as TextareaField } from '../../shared/formFields/TextareaField';
import { Banner } from '../../shared/info/Banner';
import { RentalCarServiceCode } from '../constants';

enum ChooseRentalActorType {
  List = 'List',
  Input = 'Input',
}

interface ServiceComponentProps {
  addAdditionalService: UseMutationResult<CaseAction, unknown, CaseActionDto, unknown>;
  createPaymentGuarantee: UseMutationResult<PaymentGuaranteeResult, unknown, CreatePaymentGuaranteeCommand, unknown>;
  caseId: string | undefined;
}

function ServiceComponentComp({ addAdditionalService, caseId, createPaymentGuarantee }: ServiceComponentProps) {
  const tServices = useTranslation('translation', { keyPrefix: 'serviceComponent' }).t;

  const [css, theme] = useStyletron();
  const enqueue = useSnackbarRef();

  const paymentGuaranteeModalScrollRef = useRef<HTMLDivElement>(null);

  const { values, setFieldValue, errors } = useFormikContext<ServiceComponentFormFields>();

  const {
    rentalCarProviderId,
    rentalCarProviderName,
    rentalCarSendPaymentGuarantee,
    rentalCarProviderEmail,
    rentalCarProviderMessage,
    selectedService,
    serviceMessage,
  } = values;

  const rentalCarProviderEmailError = errors['rentalCarProviderEmail'];

  const store = useCaseRegistrationStoreContext();
  const incidentLocationLat = useStore(store, (s) => s.incidentAddressLatitude);
  const incidentLocationLon = useStore(store, (s) => s.incidentAddressLongitude);

  const [value, setValue] = useState<string>(
    incidentLocationLon && incidentLocationLat ? '' : ChooseRentalActorType.Input
  );
  const [sortedServices, setSortedServices] = useState<ComboboxItem[]>();
  const [validProvider, setValidProvider] = useState(true);
  const [displayPaymentGuaranteeSummary, setDisplayPaymentGuaranteeSummary] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const [paymentGuaranteeData, setPaymentGuaranteeData] = useState<PaymentGuaranteeResult>({});
  const [carProvidersQueryEnabled, setCarProvidersQueryEnabled] = useState(false);
  const [showRentalDetailsForId, setShowRentalDetailsForId] = useState<string>();

  const addAdditionalServiceWithPaymentGuarantee = useCreateCaseActionAdditionalServiceWithPaymentGuarantee();

  const { data: services } = useServicesQuery();

  const { data: nearbyRentalCarProviders, isInitialLoading: nearbyCarProvidersIsLoading } =
    useNearbyRentalCarProvidersWithDistanceQuery(
      incidentLocationLat ?? '',
      incidentLocationLon ?? '',
      carProvidersQueryEnabled
    );

  const {
    data: rentalCarProvider,
    isFetching: rentalCarProviderIsLoading,
    isFetched: rentalCarProviderIsFetched,
  } = useRentalCarProviderQuery(rentalCarProviderId);
  function handleCarProvidersSelectOpen() {
    setCarProvidersQueryEnabled(true);
  }

  function setProvider(provider: Option | undefined | null) {
    if (provider?.name) {
      setFieldValue('rentalCarProviderName', provider.name);
      setValidProvider(true);
    }
  }

  useEffect(() => {
    if (services) {
      setSortedServices([
        ...services.filter(
          (s) => s.id === RentalCarServiceCode || s.id === 'OV' || s.id === 'TAX' || s.id === 'HR' || s.id === 'GOW'
        ),
      ]);
    }
  }, [services]);

  const addAdditionalServiceIsSuccess = addAdditionalService.isSuccess;
  const addAdditionalServiceWithPaymentGuaranteeIsSuccess = addAdditionalServiceWithPaymentGuarantee.isSuccess;
  const addAdditionalServiceReset = addAdditionalService.reset;
  const addAdditionalServiceWithPaymentGuaranteeReset = addAdditionalServiceWithPaymentGuarantee.reset;

  useEffect(() => {
    if (addAdditionalServiceIsSuccess || addAdditionalServiceWithPaymentGuaranteeIsSuccess) {
      setFieldValue('selectedService', '');
      setFieldValue('serviceMessage', '');
      setFieldValue('rentalCarProviderName', '');
      setFieldValue('rentalCarProviderId', '');

      setValue('');

      enqueue({
        message: tServices('servicesSuccess'),
        startEnhancer: ({ size }) => <MdCheckCircle size={size} />,
      });
    }

    if (addAdditionalServiceWithPaymentGuaranteeIsSuccess) {
      setFieldValue('rentalCarProviderEmail', '');
      setFieldValue('rentalCarProviderMessage', '');
      setFieldValue('rentalCarSendPaymentGuarantee', false);

      addAdditionalServiceWithPaymentGuaranteeReset();
      enqueue({
        message: tServices('paymentGuaranteeSent'),
        startEnhancer: ({ size }) => <MdCheckCircle size={size} />,
      });
    } else if (addAdditionalServiceIsSuccess) addAdditionalServiceReset();
  }, [
    addAdditionalServiceIsSuccess,
    addAdditionalServiceWithPaymentGuaranteeIsSuccess,
    enqueue,
    tServices,
    setFieldValue,
    addAdditionalServiceReset,
    addAdditionalServiceWithPaymentGuaranteeReset,
  ]);

  useEffect(() => {
    if (addAdditionalService.error || addAdditionalServiceWithPaymentGuarantee.error) {
      enqueue({
        message: tServices('serviceFailed'),
        startEnhancer: ({ size }) => <DeleteAlt size={size} />,
      });
    }
  }, [addAdditionalService.error, addAdditionalServiceWithPaymentGuarantee.error, enqueue, tServices]);

  useEffect(() => {
    setFieldValue('rentalCarProviderName', rentalCarProvider ? rentalCarProvider.name : '');
  }, [rentalCarProvider, setFieldValue]);

  function isAddServiceDisabled() {
    if (selectedService === RentalCarServiceCode) {
      if (rentalCarSendPaymentGuarantee) {
        return (
          !rentalCarProviderEmail ||
          !!rentalCarProviderEmailError ||
          !(serviceMessage && rentalCarProviderId && validProvider)
        );
      } else if (value === ChooseRentalActorType.Input) {
        return !(serviceMessage && rentalCarProviderId && validProvider);
      } else {
        return !(serviceMessage && rentalCarProviderId);
      }
    } else {
      return !(serviceMessage && selectedService);
    }
  }

  function handleSubmitWithGuarantee() {
    handleClosePaymentGuarantee();
    const body: CreateCaseActionPaymentGuaranteeCommand = {
      message: rentalCarProviderMessage,
      caseId: caseId,
      service: selectedService,
      description: serviceMessage,
      distributedToId: selectedService === RentalCarServiceCode ? parseInt(rentalCarProviderId ?? '') : undefined,
      distributedToName: selectedService === RentalCarServiceCode ? rentalCarProviderName : undefined,
      recipient: rentalCarProviderEmail,
    };
    addAdditionalServiceWithPaymentGuarantee.mutate(body);
  }

  function handleClosePaymentGuarantee() {
    setDisplayPaymentGuaranteeSummary(false);
    setScrolledToBottom(false);
  }

  useEffect(() => {
    if (paymentGuaranteeModalScrollRef.current) {
      const div = paymentGuaranteeModalScrollRef.current;

      if (div) {
        const canScroll = div.scrollHeight > div.clientHeight;

        if (!canScroll) setScrolledToBottom(true);
      }
    }
  }, [createPaymentGuarantee.data]);

  useEffect(() => {
    if (createPaymentGuarantee.isSuccess) setPaymentGuaranteeData(createPaymentGuarantee.data);
  }, [createPaymentGuarantee.isSuccess, createPaymentGuarantee.data]);

  function handleScroll(e: UIEvent<HTMLDivElement>) {
    const marginOfError = 5;

    const isScrolledToBottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight <= marginOfError;

    if (isScrolledToBottom) setScrolledToBottom(true);
  }

  const getLabel = ({ option }: Option) => {
    return option?.distance?.isAccurate ? (
      <>{`${option?.name} (${option?.distance?.displayValue})`}</>
    ) : (
      <>{`${option?.name}`}</>
    );
  };

  const showRentalCarDetailsButton = !rentalCarProviderIsLoading && rentalCarProvider?.jeId && rentalCarProvider?.name;

  return (
    <>
      {!caseId ? <Banner kind="info" text={tServices('disabledMessage')} closeable /> : null}

      <Grid gridMargins={[0]}>
        <Cell span={9}>
          <LabelMedium marginTop="24px" marginBottom="12px">
            {tServices('additionalServices')}
          </LabelMedium>
          <Grid gridMargins={[0]}>
            <Cell span={8}>
              <SelectField
                disabled={!caseId}
                options={sortedServices}
                name="selectedService"
                label={`${tServices('additionalService')}*`}
              />
              {selectedService === RentalCarServiceCode ? (
                <>
                  <LabelSmall>{`${tServices('chooseActor')}*`}</LabelSmall>
                  <RadioGroup
                    value={value}
                    onChange={(e) => {
                      setValue(e.currentTarget.value);
                      setFieldValue('rentalCarProviderId', '');
                      setFieldValue('rentalCarProviderName', '');
                    }}
                    align={ALIGN.vertical}
                  >
                    <Radio value={ChooseRentalActorType.List} disabled={!(incidentLocationLon && incidentLocationLat)}>
                      {tServices('nearbyRentalCars')}
                    </Radio>
                    <Radio value={ChooseRentalActorType.Input}>{tServices('addActorNr')}</Radio>
                  </RadioGroup>
                  {value === ChooseRentalActorType.List ? (
                    <Block display="flex" alignItems="end">
                      <Block flex={1}>
                        <SelectField
                          options={nearbyRentalCarProviders ?? []}
                          onOpen={handleCarProvidersSelectOpen}
                          name="rentalCarProviderId"
                          getOptionLabel={getLabel}
                          isLoading={nearbyCarProvidersIsLoading}
                          label={`${tServices('actor')}*`}
                          onChange={(params: OnChangeParams) => setProvider(params.option)}
                        />
                      </Block>
                      {showRentalCarDetailsButton ? (
                        <Block flex={0} paddingLeft="scale200">
                          <Block marginBottom="scale600">
                            <Button
                              type="button"
                              kind={KIND.secondary}
                              onClick={() => setShowRentalDetailsForId(rentalCarProviderId)}
                            >
                              <MdOutlineInfo />
                            </Button>
                          </Block>
                        </Block>
                      ) : null}
                    </Block>
                  ) : (
                    <>
                      <Block display="flex" alignItems="end">
                        <Block flex={1}>
                          <InputField
                            name="rentalCarProviderId"
                            debounceMs={250}
                            label={`${tServices('actorNr')}*`}
                            endEnhancer={rentalCarProviderIsLoading && <MiniSpinner />}
                            deps={[rentalCarProviderIsLoading, rentalCarProviderIsFetched, rentalCarProviderName]}
                            caption={
                              rentalCarProviderIsFetched && !rentalCarProviderName
                                ? tServices('invalidActorMessage')
                                : ''
                            }
                          />
                        </Block>
                        {showRentalCarDetailsButton ? (
                          <Block flex={0} paddingLeft="scale200">
                            <Block marginBottom="scale600">
                              <Button
                                type="button"
                                kind={KIND.secondary}
                                onClick={() => setShowRentalDetailsForId(rentalCarProviderId)}
                              >
                                <MdOutlineInfo />
                              </Button>
                            </Block>
                          </Block>
                        ) : null}
                      </Block>

                      <InputField name="rentalCarProviderName" label={`${tServices('actorName')}*`} readOnly />
                    </>
                  )}
                </>
              ) : null}
            </Cell>
            <Cell span={4} />
          </Grid>

          <TextareaField
            disabled={!caseId}
            name={'serviceMessage'}
            label={`${tServices('messageDescription')}*`}
            debounceMs={0}
          />

          {selectedService === RentalCarServiceCode ? (
            <CheckboxField name={'rentalCarSendPaymentGuarantee'} label={tServices('rentalCarSendPaymentGuarantee')} />
          ) : null}

          {rentalCarSendPaymentGuarantee ? (
            <>
              <Grid gridMargins={[0]}>
                <Cell span={8}>
                  <EmailInputField name="rentalCarProviderEmail" label={`${tServices('rentalCarProviderEmail')}*`} />
                </Cell>
              </Grid>
              <TextareaField
                name={'rentalCarProviderMessage'}
                label={`${tServices('rentalCarProviderMessage')}`}
                debounceMs={0}
                maxLength={1000}
              />
            </>
          ) : null}

          <Button
            type="submit"
            isLoading={addAdditionalService.isLoading}
            kind={KIND.secondary}
            disabled={isAddServiceDisabled()}
            startEnhancer={() => <MdAddCircle style={{ marginTop: '2px' }} size={18} />}
            onClick={() => setDisplayPaymentGuaranteeSummary(rentalCarSendPaymentGuarantee)}
          >
            {tServices('addService')}
          </Button>
        </Cell>
        <Cell span={3} />
      </Grid>

      <Modal
        isOpen={displayPaymentGuaranteeSummary}
        size={'auto'}
        closeable
        onClose={handleClosePaymentGuarantee}
        animate
        autoFocus
        role={ROLE.dialog}
      >
        <ModalHeader>{tServices('summaryPaymentGuarantee')}</ModalHeader>
        <ModalBody>
          <div
            style={{
              background: theme.colors.white,
              overflowY: 'auto',
              maxHeight: '70vh',
              maxWidth: '80vh',
              minWidth: '70vh',
              minHeight: '60vh',
              paddingLeft: '10px',
            }}
            onScroll={handleScroll}
            ref={paymentGuaranteeModalScrollRef}
            dangerouslySetInnerHTML={{ __html: paymentGuaranteeData.templateText ?? '' }}
          />
        </ModalBody>
        <ModalFooter className={css({ display: 'flex', justifyContent: 'flex-end' })}>
          <Button
            type="button"
            onClick={handleClosePaymentGuarantee}
            kind={KIND.secondary}
            $wideButton
            className={css({ marginRight: '1em' })}
          >
            {tServices('goBack')}
          </Button>
          <Button onClick={() => handleSubmitWithGuarantee()} type="button" $wideButton disabled={!scrolledToBottom}>
            {tServices('complete')}
          </Button>
        </ModalFooter>
      </Modal>
      <ActorDetailsDrawer
        actorId={showRentalDetailsForId}
        isOpen={!!showRentalDetailsForId}
        setIsOpen={(isOpen) => !isOpen && setShowRentalDetailsForId(undefined)}
      />
    </>
  );
}

interface ServiceComponentFormFields {
  selectedService: string;
  serviceMessage: string;
  rentalCarProviderName: string;
  rentalCarProviderId: string;
  rentalCarSendPaymentGuarantee: boolean;
  rentalCarProviderEmail: string;
  rentalCarProviderMessage: string;
}

const initialValues: ServiceComponentFormFields = {
  selectedService: '',
  serviceMessage: '',
  rentalCarProviderId: '',
  rentalCarProviderName: '',
  rentalCarSendPaymentGuarantee: false,
  rentalCarProviderEmail: '',
  rentalCarProviderMessage: '',
};

const ServiceComponentWithItsOwnFormComp = () => {
  const store = useCaseRegistrationStoreContext();
  const caseId = useStore(store, (s) => s.caseId);

  const addAdditionalService = useAdditionalServiceToCaseHandlerMutation();
  const createPaymentGuarantee = useCreatePaymentGuaranteeMutation();

  function handleSubmit(values: ServiceComponentFormFields) {
    if (values.rentalCarSendPaymentGuarantee) {
      const body: CreatePaymentGuaranteeCommand = {
        serviceCode: values.selectedService,
        caseId: caseId,
        message: values.rentalCarProviderMessage,
      };
      createPaymentGuarantee.mutate(body);
    } else {
      const body: CaseActionDto = {
        caseId: caseId,
        description: values.serviceMessage,
        service: values.selectedService,
        distributedToId:
          values.selectedService === RentalCarServiceCode ? parseInt(values.rentalCarProviderId ?? '') : undefined,
        distributedToName: values.selectedService === RentalCarServiceCode ? values.rentalCarProviderName : undefined,
      };

      addAdditionalService.mutate(body);
    }
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
      {() => (
        <Form>
          <ServiceComponentComp
            addAdditionalService={addAdditionalService}
            caseId={caseId}
            createPaymentGuarantee={createPaymentGuarantee}
          />
        </Form>
      )}
    </Formik>
  );
};

export const ServiceComponent = memo(ServiceComponentWithItsOwnFormComp);
