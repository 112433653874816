export class DateTimeFormatGroup {
  public readonly dateFormatter: Intl.DateTimeFormat;
  public readonly timeFormatter: Intl.DateTimeFormat;
  public readonly dateTimeFormatter: Intl.DateTimeFormat;

  constructor(locale: string, timeZone: string | undefined) {
    timeZone = timeZone ?? 'UTC';
    const timeZoneName = timeZone === 'UTC' ? 'short' : undefined;

    this.dateFormatter = new Intl.DateTimeFormat(locale, { timeZone: timeZone, timeZoneName: timeZoneName });

    this.timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: timeZone,
      timeZoneName: timeZoneName,
    });

    this.dateTimeFormatter = new Intl.DateTimeFormat(locale, {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: timeZone,
      timeZoneName: timeZoneName,
    });
  }
}

const dateTimeFormatContainer: Record<string, DateTimeFormatGroup> = {};

export function getDateTimeFormatterGroup(locale: string, timeZone: string | undefined) {
  const key = `${locale}_${timeZone}`;

  if (!dateTimeFormatContainer[key]) {
    dateTimeFormatContainer[key] = new DateTimeFormatGroup(locale, timeZone);
  }

  return dateTimeFormatContainer[key];
}

export function getDateFormatter(locale: string, timeZone: string | undefined) {
  return getDateTimeFormatterGroup(locale, timeZone).dateFormatter;
}

export function getTimeFormatter(locale: string, timeZone: string | undefined) {
  return getDateTimeFormatterGroup(locale, timeZone).timeFormatter;
}

export function getDateTimeFormatter(locale: string, timeZone: string | undefined) {
  return getDateTimeFormatterGroup(locale, timeZone).dateTimeFormatter;
}
