import { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useUsersQuery } from '@@api/queries/useUserQueries';
import { SpinnerOverlay } from '@@shared/basewebComponentOverrides/Spinner';
import { useUser } from '@@shared/hooks/useUser';
import { UserDetails } from './UserDetails';

export const UserDetailsPage = () => {
  const { userId } = useParams();

  const { data: currentUser, isLoading: currentUserisLoading, isFetched: currentUserisFetched } = useUser();
  const { data: users, isLoading: usersIsLoading, isFetched: usersIsFetched } = useUsersQuery();

  const user = useMemo(() => {
    return users ? users.find((u) => u.visUserId === userId) : undefined;
  }, [users, userId]);

  const isLoading = currentUserisLoading || usersIsLoading;
  const isFetched = currentUserisFetched && usersIsFetched;

  if (isLoading || !isFetched) return <SpinnerOverlay />;

  if (currentUser && user && currentUser.id === user.id) {
    return <Navigate to="/profile" replace />;
  }

  return <UserDetails user={user} mode="details" />;
};
