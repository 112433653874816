import { useStyletron } from 'baseui';
import { ListItem } from 'baseui/list';
import { PopoverProps, StatefulPopover } from 'baseui/popover';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { grays } from '@@styles/appTheme';
import { expandMargin, expandPadding } from '@@styles/styleUtils';
import { StyledRouterMenuLink } from './RouterLink';

export interface NavItem {
  id: string | number;
  label: string;
  url: string;
  icon?: JSX.Element;
}

interface Props extends Omit<PopoverProps, 'content' | 'isOpen'> {
  menuOptions: NavItem[];
  children: ReactNode;
}

export const PopoverNavMenu = (props: Props) => {
  const { menuOptions, children } = props;

  const [css, theme] = useStyletron();

  const tNavigation = useTranslation('translation', { keyPrefix: 'navigation' }).t;

  return (
    <StatefulPopover
      autoFocus={false}
      content={({ close }) => (
        <ul
          className={css({
            minWidth: '10em',
            padding: 0,
            margin: 0,
          })}
        >
          {menuOptions.map((item) => (
            <ListItem
              key={item.id}
              overrides={{
                Content: {
                  style: {
                    ...expandMargin(0),
                    ...expandPadding(0),
                    display: 'flex',
                    alignItems: 'center',
                    ':hover': {
                      backgroundColor: grays.gray100,
                    },
                  },
                },
              }}
            >
              <StyledRouterMenuLink to={item.url} onClick={() => close()}>
                {item.icon}
                <span className={css({ paddingLeft: theme.sizing.scale400 })}>{tNavigation(item.label)}</span>
              </StyledRouterMenuLink>
            </ListItem>
          ))}
        </ul>
      )}
    >
      <div>{children}</div>
    </StatefulPopover>
  );
};
