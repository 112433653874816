import React from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { appInsights } from '../../config/appInsights';
import { ApiException } from '../api.generated';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      if (ApiException.isApiException(error)) {
        if (error.status === 401 || error.status === 403 || error.status === 522) {
          appInsights.trackException({ exception: error as ApiException });
        }
      }
    },
  }),
});

interface ReactQueryProviderProps {
  children: React.ReactNode;
}

const ReactQueryProviderComponent = ({ children }: ReactQueryProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export const ReactQueryProvider = React.memo(ReactQueryProviderComponent);
