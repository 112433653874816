import React from 'react';
import { LabelSmall, ParagraphSmall } from 'baseui/typography';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  heading: string;
  paragraphs: (string | undefined)[];
}

const InfoBlock = ({ paragraphs, heading, ...props }: Props) => {
  return (
    <div {...props}>
          <LabelSmall overrides={{
              Block: {
                  style: () => ({
                      fontWeight: 300
                  }),
              },
          }} >{heading}</LabelSmall>
      {paragraphs.map(
        (p, i) =>
           p && (
                  <ParagraphSmall margin={[0]} overrides={{
                      Block: {
                          style: () => ({
                              fontWeight: 400 
                          }),
                      },
                  }} key={`paragraph-${i}`}>
              {p}
                  </ParagraphSmall>
          )
      )}
    </div>
  );
};

export default InfoBlock;
