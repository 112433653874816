import { useStyletron } from 'baseui';
import { KIND } from 'baseui/button';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { orderBy } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineAddCircleOutline } from 'react-icons/md';
import { UserDto } from '@@api/api.generated';
import {
  useAdminActivateUserMutation,
  useAdminDeactivateUserMutation,
  useAdminUpdateUserMutation,
} from '@@api/queries/useUserAdminQueries';
import { useUsersQuery } from '@@api/queries/useUserQueries';
import { Button } from '@@shared/basewebComponentOverrides/Button';
import { useSnackbarRef } from '@@shared/hooks/useSnackbarRef';
import DebouncedInput from '@@shared/inputs/DebouncedInput';
import { AlertDialog } from '@@shared/modals/AlertDialog';
import { Pagination } from '@@shared/utilityComponents/Pagination';
import { CreateUserModal } from './CreateUserModal';
import { UsersTable } from './UsersTable';

export const PAGE_SIZE = 15;

export const UserListPage = () => {
  const [css, theme] = useStyletron();

  const enqueue = useSnackbarRef();

  const [filterQuery, setFilterQuery] = useState('');
  const [includeInactive, setIncludeInactive] = useState(false);
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);

  const [confirmDeactivate, setConfirmDeactivate] = useState<{ isOpen: boolean; user: UserDto | undefined }>({
    isOpen: false,
    user: undefined,
  });
  const [confirmActivate, setConfirmActivate] = useState<{ isOpen: boolean; user: UserDto | undefined }>({
    isOpen: false,
    user: undefined,
  });

  const [createUserModalIsOpen, setCreateUserModalIsOpen] = useState(false);

  const { data: users, isLoading } = useUsersQuery();
  const { mutate: updateUser, isLoading: userUpdateIsLoading } = useAdminUpdateUserMutation();
  const { mutate: deactivateUser, isLoading: userDeactivateIsLoading } = useAdminDeactivateUserMutation();
  const { mutate: activateUser, isLoading: userActivateIsLoading } = useAdminActivateUserMutation();

  const operationsOngoing = userUpdateIsLoading || userDeactivateIsLoading || userActivateIsLoading;

  const filteredUsers = useMemo(() => {
    if (!users) return [];

    return users.filter((u) => {
      if (!u.active && !includeInactive) return false;

      const fullName = u.firstName || u.lastName ? `${u.firstName} ${u.lastName}`.trim() : '';

      if (fullName && fullName.toLowerCase().includes(filterQuery.toLowerCase())) return true;

      if (u.email && u.email.toLowerCase().includes(filterQuery.toLowerCase())) return true;

      if (u.visUserId && u.visUserId?.toLowerCase().includes(filterQuery.toLowerCase())) return true;

      return false;
    });
  }, [users, filterQuery, includeInactive]);

  const sortedUsers = useMemo(() => {
    let iteratees: (keyof UserDto)[];

    if (sortColumn === 'name') iteratees = ['firstName', 'lastName'];
    else iteratees = [sortColumn as keyof UserDto];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const iterateesSortDirections = iteratees.map((_) => sortOrder.toLowerCase() as 'asc' | 'desc');

    return orderBy(filteredUsers, iteratees, iterateesSortDirections);
  }, [filteredUsers, sortColumn, sortOrder]);

  const safePage = useMemo(() => {
    const pageCount = Math.max(1, Math.ceil(sortedUsers.length / PAGE_SIZE));

    const safePage = Math.min(currentPage, pageCount);

    return safePage;
  }, [sortedUsers, currentPage]);

  const paginatedUsers = useMemo(() => {
    const startIndex = (safePage - 1) * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, sortedUsers.length);

    const result = sortedUsers.slice(startIndex, endIndex);

    return result;
  }, [sortedUsers, safePage]);

  const tUsers = useTranslation('translation', { keyPrefix: 'users' }).t;

  const handleSort = (newSortColumn: string) => {
    if (newSortColumn === sortColumn) {
      setSortOrder((dir) => (dir === 'ASC' ? 'DESC' : 'ASC'));
    } else {
      setSortColumn(newSortColumn);
    }
  };

  const showSuccessToast = (messageKey: string) => {
    enqueue({ message: tUsers(messageKey) });
  };

  const handleUpdateRole = (userId: string, roleId: number): void => {
    updateUser(
      { userId: userId, updateCommand: { roleId: roleId } },
      { onSuccess: () => showSuccessToast('roleUpdated') }
    );
  };

  const handleUpdateCallCenter = (userId: string, callCenter: string): void => {
    updateUser(
      { userId: userId, updateCommand: { callCenterCountryCode: callCenter } },
      { onSuccess: () => showSuccessToast('callCenterUpdated') }
    );
  };

  const handleUpdateLanguage = (userId: string, language: string): void => {
    updateUser(
      { userId: userId, updateCommand: { languageCode: language } },
      { onSuccess: () => showSuccessToast('languageUpdated') }
    );
  };

  const handleToggleUserActive = (user: UserDto): void => {
    if (user.active) setConfirmDeactivate({ isOpen: true, user: user });
    else setConfirmActivate({ isOpen: true, user: user });
  };

  const handleDeactivateConfirmClose = (buttonIndex: number | undefined) => {
    const confirmed = buttonIndex === 0;

    if (!confirmed) {
      setConfirmDeactivate({ isOpen: false, user: undefined });
      return;
    }

    const selectedUser = confirmDeactivate.user;

    if (!selectedUser?.id) return;

    deactivateUser(selectedUser.id, {
      onSuccess: () => {
        showSuccessToast('userDeactivated');
      },
      onSettled: () => {
        setConfirmDeactivate({ isOpen: false, user: undefined });
      },
    });
  };

  const handleActivateConfirmClose = (buttonIndex: number | undefined) => {
    const confirmed = buttonIndex === 0;

    if (!confirmed) {
      setConfirmActivate({ isOpen: false, user: undefined });
      return;
    }

    const selectedUser = confirmActivate.user;

    if (!selectedUser?.id) return;

    activateUser(selectedUser.id, {
      onSuccess: () => {
        showSuccessToast('userActivated');
      },
      onSettled: () => {
        setConfirmActivate({ isOpen: false, user: undefined });
      },
    });
  };

  return (
    <>
      <div
        className={css({
          display: 'grid',
          height: '100%',
          gridTemplateRows: 'min-content auto min-content',
          paddingTop: theme.sizing.scale800,
          paddingBottom: theme.sizing.scale800,
          rowGap: '1em',
        })}
      >
        <div className={css({ gridRow: '1 / 2', display: 'grid', gridTemplateColumns: '1fr 1fr' })}>
          <div className={css({ gridColumn: '1 / 2' })}>
            <div className={css({ display: 'flex', alignItems: 'center', gap: '1em' })}>
              <DebouncedInput
                overrides={{ Root: { style: { maxWidth: '20em' } } }}
                debounceMs={250}
                value={filterQuery}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setFilterQuery(e.target.value)
                }
                placeholder={tUsers('searchPlaceholder')}
                clearable
              />
              <Checkbox
                checked={includeInactive}
                onChange={(e) => {
                  setIncludeInactive(e.currentTarget.checked);
                }}
                checkmarkType={STYLE_TYPE.toggle_round}
              >
                {tUsers('includeInactive')}
              </Checkbox>
            </div>
          </div>
          <div
            className={css({
              gridColumn: '2 / 3',
              display: 'flex',
              justifyContent: 'end',
              gap: '1em',
              alignItems: 'flex-start',
            })}
          >
            <Button
              type="button"
              kind={KIND.secondary}
              $wideButton
              startEnhancer={
                <MdOutlineAddCircleOutline size="1.5em" style={{ marginTop: '-0.5em', marginBottom: '-0.5em' }} />
              }
              onClick={() => setCreateUserModalIsOpen(true)}
            >
              {tUsers('addUser')}
            </Button>
          </div>
        </div>
        <div className={css({ gridRow: '2 / 3', overflow: 'auto', minHeight: 0 })}>
          <UsersTable
            users={paginatedUsers}
            dataIsLoading={isLoading}
            userUpdateIsLoading={operationsOngoing}
            onSort={handleSort}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            onUpdateRole={handleUpdateRole}
            onUpdateCallCenter={handleUpdateCallCenter}
            onUpdateLanguage={handleUpdateLanguage}
            onToggleUserActive={handleToggleUserActive}
          />
        </div>
        <div
          className={css({
            gridRow: '3 / 4',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: theme.sizing.scale800,
          })}
        >
          <Pagination
            pageSize={PAGE_SIZE}
            totalCount={sortedUsers.length}
            currentPage={safePage}
            onPageChange={(p) => setCurrentPage(p)}
          />
        </div>
      </div>

      <AlertDialog
        isOpen={confirmDeactivate.isOpen}
        onClose={handleDeactivateConfirmClose}
        type="confirm"
        isLoading={userDeactivateIsLoading}
        header={tUsers('deactivateUser')}
        body={tUsers('confirmDeactivateUserMessage')}
      />

      <AlertDialog
        isOpen={confirmActivate.isOpen}
        onClose={handleActivateConfirmClose}
        type="confirm"
        isLoading={userActivateIsLoading}
        header={tUsers('activateUser')}
        body={tUsers('confirmActivateUserMessage')}
      />

      <CreateUserModal
        isOpen={createUserModalIsOpen}
        onClose={() => {
          setCreateUserModalIsOpen(false);
        }}
      />
    </>
  );
};
