import { Block } from 'baseui/block';
import { useFormikContext } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInsuranceCompaniesQuery } from '../../api/queries/useActorQueries';
import { useInsuranceStatusCodesQuery, useInsuranceTypesQuery } from '../../api/queries/useCaseMasterDataQueries';
import { isFieldRequired, nameof } from '../../caseRegistration/caseRegistrationFunctions';
import { CaseRegistrationFormFields } from '../../caseRegistration/caseRegistrationModels';
import { FastInputField as InputField } from '../../shared/formFields/InputField';
import { FastSelectField as SelectField } from '../../shared/formFields/SelectField';
import { FastTextareaField as TextareaField } from '../../shared/formFields/TextareaField';
import FormGroup from '../../shared/utilityComponents/FormGroup';

function InsuranceFormGroupComp() {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tInsurance = useTranslation('translation', { keyPrefix: 'insuranceForm' }).t;

  const { data: insuranceTypes = [] } = useInsuranceTypesQuery();
  const { data: insuranceCompanies = [] } = useInsuranceCompaniesQuery();
  const { data: insuranceStatusCodes = [] } = useInsuranceStatusCodesQuery();

  const formik = useFormikContext<CaseRegistrationFormFields>();
  const requiredFieldsAgreement = formik.values.agreementInfo?.caseFields;

  return (
    <FormGroup label={tInsurance('insurance')}>
      <SelectField
        label={
          isFieldRequired('insuranceCompany', requiredFieldsAgreement)
            ? `${tInsurance('insuranceCompany')}*`
            : tInsurance('insuranceCompany')
        }
        options={insuranceCompanies}
        name={nameof('insuranceCompany')}
        creatable
        bindToObject
      />
      <SelectField label={tInsurance('insuranceType')} options={insuranceTypes} name={nameof('insuranceType')} />
      <Block display="flex">
        <Block paddingRight="scale100">
          <InputField label={tInsurance('amount')} name={nameof('insuranceDeductible')} />
        </Block>
        <Block paddingLeft="scale100">
          <InputField label={tInsurance('refNr')} name={nameof('insuranceReference')} />
        </Block>
      </Block>
      <SelectField
        label={tInsurance('insuranceStatus')}
        options={insuranceStatusCodes}
        name={nameof('insuranceStatusCode')}
      />
      <TextareaField label={tShared('additionalInfo')} name={nameof('insuranceReadOnlyInfo')} disabled />
    </FormGroup>
  );
}
export const InsuranceFormGroup = memo(InsuranceFormGroupComp);
