import { useStyletron } from 'baseui';
import { FormControl } from 'baseui/form-control';
import { DeleteAlt } from 'baseui/icon';
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE } from 'baseui/modal';
import { Radio, RadioGroup } from 'baseui/radio';
import { Textarea } from 'baseui/textarea';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';
import { useSnackbarRef } from '@@shared/hooks/useSnackbarRef';
import { SendCaseToVikingCommand } from '../../api/api.generated';
import { useGetDeadlineQuery } from '../../api/queries/useCaseMasterDataQueries';
import { useSendToVikingMutation } from '../../api/queries/useCaseQueries';
import { Button } from '../../shared/basewebComponentOverrides/Button';
import { MiniSpinner } from '../../shared/basewebComponentOverrides/Spinner';
import { useUser } from '../../shared/hooks/useUser';

interface RegisterCaseModalProps {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
  caseId: string | undefined;
  userId: string;
}

function RegisterCaseModalComp(props: RegisterCaseModalProps) {
  const { setIsOpen, isOpen, caseId, userId } = props;

  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tCaseReg = useTranslation('translation', { keyPrefix: 'caseRegistration' }).t;

  const [description, setDescription] = useState('');
  const [deadline, setDeadline] = useState('');

  const sendToViking = useSendToVikingMutation();
  const user = useUser();
  const caseDeadlines = useGetDeadlineQuery();

  const enqueue = useSnackbarRef();

  const [css] = useStyletron();

  function handleRegistration() {
    sendToViking.mutate({
      userId: userId,
      caseId: caseId,
      serviceType: 'As',
      description: description,
      channel: 'T',
      degreeOfUrgency: deadline,
    } as SendCaseToVikingCommand);
  }

  function handleClose() {
    setIsOpen(false);
  }

  const showSnackBar = useCallback(
    (success: boolean) =>
      enqueue({
        message: success ? tCaseReg('successReg') : tCaseReg('failedReg'),
        startEnhancer: ({ size }) => (success ? <FaCheckCircle size={size} /> : <DeleteAlt size={size} />),
      }),
    [enqueue, tCaseReg]
  );

  useEffect(() => {
    if (sendToViking.isSuccess && isOpen) {
      setIsOpen(false);
      setDescription('');
      setDeadline('');
      showSnackBar(true);
      sendToViking.reset();
    }
  }, [isOpen, sendToViking, sendToViking.isSuccess, setIsOpen, showSnackBar]);

  useEffect(() => {
    if (sendToViking.error && isOpen) {
      setIsOpen(false);
      showSnackBar(false);
    }
  }, [isOpen, sendToViking.error, setIsOpen, showSnackBar]);

  return (
    <Modal
      onClose={() => handleClose()}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      overrides={{
        Dialog: {
          style: {
            width: '40vw',
          },
        },
      }}
      role={ROLE.dialog}
    >
      <ModalHeader>
        {caseDeadlines.error ? tCaseReg('deadlineError') : `${tShared('register')} ${tShared('case').toLowerCase()}`}
      </ModalHeader>
      <ModalBody>
        {(user.isLoading || caseDeadlines.isLoading) && <MiniSpinner />}
        {!!caseDeadlines.error && (
          <Button
            type="button"
            size={'compact'}
            onClick={() => caseDeadlines.refetch()}
            startEnhancer={() => <MdRefresh />}
          >
            {tCaseReg('deadlineRefetch')}
          </Button>
        )}
        {user.isSuccess && user.data && caseDeadlines.isSuccess && caseDeadlines.data && (
          <>
            <FormControl label={tShared('description')}>
              <Textarea
                name={'description'}
                value={description}
                onChange={(e) => setDescription(e.currentTarget.value)}
                clearOnEscape
              />
            </FormControl>
            <FormControl label={tCaseReg('deadline')}>
              <RadioGroup value={deadline} onChange={(value) => setDeadline(value.currentTarget.value)}>
                {caseDeadlines.data.map((deadline) => {
                  return (
                    <Radio key={`deadline-${deadline.name}`} value={deadline.name}>
                      {deadline.name}
                    </Radio>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </>
        )}
      </ModalBody>
      <ModalFooter className={css({ display: 'flex', justifyContent: 'flex-end' })}>
        <Button
          type="button"
          onClick={() => handleClose()}
          kind="secondary"
          $wideButton
          className={css({ marginRight: '1em' })}
        >
          {tShared('cancel')}
        </Button>
        <Button
          disabled={deadline === ''}
          onClick={() => handleRegistration()}
          type="button"
          $wideButton
          isLoading={sendToViking.isLoading}
        >
          {tShared('register')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
export const RegisterCaseModal = memo(RegisterCaseModalComp);
