import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { AppConfig } from './appConfig';
import { memo, ReactNode } from 'react';

const browserHistory = createBrowserHistory();
export const reactPlugin = new ReactPlugin();
export let appInsights: ApplicationInsights;

export function configureApplicationInsights(appConfig: AppConfig) {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: appConfig.applicationInsightId,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
      enableDebug: true,
    },
  });
  appInsights.loadAppInsights();
}

const AppInsightsContextProvider = ({ children }: { children: ReactNode }) => {
  return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
};

export default memo(AppInsightsContextProvider);
