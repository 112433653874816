import { ButtonProps } from 'baseui/button';
import { Link } from 'react-router-dom';
import { Button } from '../basewebComponentOverrides/Button';

function ButtonLink(props: Omit<ButtonProps, 'href'> & { to: string }) {
  return <Button {...props} href={props.to} type="button" $as={Link} />;
}

export function ButtonExternalLink(props: ButtonProps & { href: string }) {
  return <Button {...props} type="button" $as="a" target="_blank" />;
}

export default ButtonLink;
