import { useRef } from 'react';
import { DurationT, SnackbarElementPropsT, useSnackbar } from 'baseui/snackbar';

type EnqueueFn = (elementProps: SnackbarElementPropsT, duration?: DurationT) => unknown;

/** Wrapper over useSnackbar's enqueue function that's safe to use as an effect dep */
const useSnackbarRef = (): EnqueueFn => {
  const { enqueue } = useSnackbar();

  const enqueueRef = useRef(enqueue);

  enqueueRef.current = enqueue;

  const memoizedEnqueueRef = useRef<EnqueueFn>((...args) => enqueueRef.current(...args));

  return memoizedEnqueueRef.current;
};

export { useSnackbarRef };
