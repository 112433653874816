import { Button, KIND } from 'baseui/button';
import { DeleteAlt } from 'baseui/icon';
import { Cell, Grid } from 'baseui/layout-grid';
import { OnChangeParams } from 'baseui/select';
import { LabelMedium } from 'baseui/typography';
import { Form, Formik, FormikProps, useField } from 'formik';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheckCircle, MdSms } from 'react-icons/md';
import { useStore } from 'zustand';
import { useCaseRegistrationStoreContext } from '@@caseRegistration/CaseRegistrationStore';
import { useSnackbarRef } from '@@shared/hooks/useSnackbarRef';
import { SMS } from '../../api/api.generated';
import { useSmsTemplates } from '../../api/queries/useCaseMasterDataQueries';
import { useSendFreeTextSms } from '../../api/queries/useCommunicationClient';
import PhoneNumberField from '../../shared/formFields/PhoneNumberField';
import SelectField from '../../shared/formFields/SelectField';
import TextareaField from '../../shared/formFields/TextareaField';

interface SmsComponentProps {
  isLoading: boolean;
}

function SmsComponentComp({ isLoading }: SmsComponentProps) {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tCommunication = useTranslation('translation', { keyPrefix: 'communicationComponent' }).t;
  const store = useCaseRegistrationStoreContext();

  const [smsPhoneNumberField] = useField('smsPhoneNumber');
  const [smsMessage, , smsMessageField] = useField('smsMessage');
  const [disabled, setDisabled] = useState(true);

  const ownerName = useStore(store, (s) => s.ownerName);
  const callerName = useStore(store, (s) => s.callerName);
  const callerIsOwner = useStore(store, (s) => s.callerSameAsOwner);
  const visCaseId = useStore(store, (s) => s.visCaseId);
  const visCaseCallcenterCountry = useStore(store, (s) => s.callCenterCountry);
  const { data: smsTemplates = [] } = useSmsTemplates(visCaseCallcenterCountry);

  useEffect(() => {
    if (smsPhoneNumberField.value && smsMessage.value) {
      setDisabled(false);
    }
  }, [smsPhoneNumberField.value, smsMessage.value]);

  function smsTemplateChanged(params: OnChangeParams) {
    smsMessageField.setValue(populateSmsTemplate(params.option?.description));
  }

  function populateSmsTemplate(template: string) {
    return template
      ?.replace('#Oppdrag.Oppdrag', visCaseId ?? '')
      ?.replace('#mottaker', (callerIsOwner ? ownerName : callerName) ?? '');
  }
  return (
    <Grid gridMargins={[0]}>
      <Cell span={9}>
        <LabelMedium marginTop="24px">{tCommunication('sendSms')}</LabelMedium>
        <Grid gridMargins={[0]}>
          <Cell span={9}>
            <PhoneNumberField label={`${tShared('telephoneNumber')}*`} name="smsPhoneNumber" />
          </Cell>
          <Cell span={3} />
        </Grid>

        <SelectField
          options={smsTemplates}
          name="smsTemplates"
          label={`${tCommunication('messageTemplate')}`}
          onChange={smsTemplateChanged}
        />

        <TextareaField name="smsMessage" label={`${tCommunication('messageText')}*`} />

        <Button
          type="submit"
          kind={KIND.secondary}
          disabled={disabled}
          startEnhancer={() => <MdSms style={{ marginTop: '2px' }} size={18} />}
          isLoading={isLoading}
        >
          {tCommunication('send')}
        </Button>
      </Cell>
      <Cell span={3} />
    </Grid>
  );
}

interface SmsComponentFormFields {
  smsPhoneNumber: string;
  smsMessage: string;
}

const initialValues: SmsComponentFormFields = {
  smsPhoneNumber: '',
  smsMessage: '',
};

const SmsComponentWithItsOwnForm = () => {
  const enqueue = useSnackbarRef();

  const store = useCaseRegistrationStoreContext();
  const caseId = useStore(store, (s) => s.caseId);
  const visCaseId = useStore(store, (s) => s.visCaseId);

  const sendFreeText = useSendFreeTextSms();

  const tCommunication = useTranslation('translation', { keyPrefix: 'communicationComponent' }).t;

  const formRef = useRef<FormikProps<SmsComponentFormFields> | null>(null);

  const showSnackBar = useCallback(
    (success: boolean) => {
      enqueue({
        message: success ? tCommunication('smsSuccess') : tCommunication('smsFailed'),
        startEnhancer: ({ size }) => (success ? <MdCheckCircle size={size} /> : <DeleteAlt size={size} />),
      });
    },
    [enqueue, tCommunication]
  );

  useEffect(() => {
    if (sendFreeText.isSuccess) {
      formRef?.current?.resetForm();
      showSnackBar(true);
    }
  }, [sendFreeText.isSuccess, showSnackBar]);

  useEffect(() => {
    if (sendFreeText.error) {
      showSnackBar(false);
    }
  }, [sendFreeText.error, showSnackBar]);

  function handleSubmit(values: SmsComponentFormFields) {
    const smsBody: SMS = {
      visCaseId: visCaseId ? parseInt(visCaseId) : 0,
      caseId: caseId,
      messageText: values.smsMessage,
      phoneNumber: values.smsPhoneNumber,
    };

    sendFreeText.mutate(smsBody);
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} innerRef={formRef}>
      {() => (
        <Form>
          <SmsComponentComp isLoading={sendFreeText.isLoading} />
        </Form>
      )}
    </Formik>
  );
};

export const SmsComponent = memo(SmsComponentWithItsOwnForm);
