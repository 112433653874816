import { useStyletron } from 'baseui';
import { ExtendedBreakpoints, Layout, SPLIT_LAYOUT_BREAKPOINT } from '@@styles/appTheme';
import { useMediaQuery } from './useMediaQuery';

export type QueryType = 'min' | 'max';

export const useLayoutQuery = (layout: Layout = SPLIT_LAYOUT_BREAKPOINT, queryType: QueryType = 'min'): boolean => {
  const [, theme] = useStyletron();

  const breakpoints = theme.breakpoints as ExtendedBreakpoints;

  const breakpointValue =
    layout === 'xxl'
      ? breakpoints.extraExtraLarge
      : layout === 'xl'
      ? breakpoints.extraLarge
      : layout === 'lg'
      ? breakpoints.large
      : layout === 'md'
      ? breakpoints.medium
      : layout === 'sm'
      ? breakpoints.small
      : breakpoints.extraSmall;

  const mediaQueryBuilder = queryType === 'min' ? getMinMediaQuery : getMaxMediaQuery;

  const matchesLayout = useMediaQuery(mediaQueryBuilder(breakpointValue));

  return matchesLayout;
};

const getMinMediaQuery = (size: number): string => `screen and (min-width: ${size}px)`;
const getMaxMediaQuery = (size: number): string => `screen and (max-width: ${size - 0.02}px)`;
