import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { hoursToMilliseconds } from 'date-fns/esm';
import { UpdateProfileCommand } from '@@api/api.generated';
import { useApiClient } from '../apiClientContext';
import { getInvalidateQueriesMutationOption, USERS_QUERY_KEY } from './queryUtils';

export const useUserQuery = (noRetry?: boolean) => {
  const { userClient } = useApiClient();

  return useQuery(['UserProfile'], () => userClient.getUserProfile(), {
    retry: noRetry ? false : 3,
    cacheTime: hoursToMilliseconds(1),
  });
};

export const useUsersQuery = () => {
  const { userClient } = useApiClient();

  return useQuery([USERS_QUERY_KEY], () => userClient.getUsers());
};

export const useRolesQuery = () => {
  const { userClient } = useApiClient();

  return useQuery(['Roles'], () => userClient.getRoles());
};

export const useUpdateUserMutation = () => {
  const { userClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), ['UserProfile', USERS_QUERY_KEY]);

  return useMutation((updateCommand: UpdateProfileCommand) => userClient.updateUserProfile(updateCommand), options);
};
