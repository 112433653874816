import { Block } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { LabelMedium } from 'baseui/typography';
import { Form, Formik, FormikHelpers, FormikProps, FormikState } from 'formik';
import { Key, memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowRight, FaCheckCircle, FaSave, FaTrash } from 'react-icons/fa';
import useDebouncy from 'use-debouncy/lib/fn';
import { useStore } from 'zustand';
import { useContainerQuery } from '@@shared/hooks/useContainerQuery';
import { useIsMounted } from '@@shared/hooks/useIsMounted';
import { useLayoutQuery } from '@@shared/hooks/useLayoutQuery';
import { expandPadding } from '@@styles/styleUtils';
import { OverflowTabContent } from '../shared/basewebComponentOverrides/OverflowTabContent';
import { isPhoneNumberValid } from '../shared/functions/general';
import { CaseFormStatus } from '../shared/types';
import PopoverButton from '../shared/utilityComponents/PopoverButton';
import { useCaseRegistrationStoreContext } from './CaseRegistrationStore';
import { AgreementField } from './caseFormFields/AgreementField';
import { CallerInfoFormGroup } from './caseFormGroups/CallerInfoFormGroup';
import { ContactPersonFormGroup } from './caseFormGroups/ContactPersonFormGroup';
import { IncidentAddressFormGroup } from './caseFormGroups/IncidentAddressFormGroup';
import { IncidentReasonFormGroup } from './caseFormGroups/IncidentReasonFormGroup';
import { InsuranceFormGroup } from './caseFormGroups/InsuranceFormGroup';
import { NewVehicleInfoFormGroup } from './caseFormGroups/NewVehicleInfoFormGroup';
import { OwnerFormGroup } from './caseFormGroups/OwnerFormGroup';
import { RequesterFormGroup } from './caseFormGroups/RequesterFormGroup';
import { ServiceInformationFormGroup } from './caseFormGroups/ServiceInformationFormGroup';
import { StartInfoFormGroup } from './caseFormGroups/StartInfoFormGroup';
import { TransportFormGroup } from './caseFormGroups/TransportFormGroup';
import { VehicleInformationFormGroup } from './caseFormGroups/VehicleInformationFormGroup';
import { WaitingFormGroup } from './caseFormGroups/WaitingFormGroup';
import { CaseRegistrationFormFields } from './caseRegistrationModels';
import { tabOverrides } from './styles';
import { CaseStatusTag } from './utilityComponents/CaseStatusTag';

interface Props {
  initialValues: CaseRegistrationFormFields;
  onSubmit: (values: CaseRegistrationFormFields) => void;
  mode: string;
  isLoading: boolean;
  onCancelClick: () => void;
  onFixedItClick: () => void;
  onLeasingClick: () => void;
  onRegisterClick: (values: CaseRegistrationFormFields) => void;
}

const CaseRegistrationFormComp = (props: Props) => {
  const { initialValues, onSubmit, mode, isLoading, onCancelClick, onFixedItClick, onRegisterClick, onLeasingClick } =
    props;

  const tCaseReg = useTranslation('translation', { keyPrefix: 'caseRegistration' }).t;
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;

  const store = useCaseRegistrationStoreContext();
  const updateValuesAndHelpers = useStore(store, (s) => s.updateValuesAndHelpers);

  const [activeTab, setActiveTab] = useState<Key>(0);

  const isMounted = useIsMounted();

  // TODO: consider replacing the debounce function with a throttle function
  // for possibly better responsiveness
  const debouncedUpdateValuesAndHelpers = useDebouncy(
    (
      formikState: FormikState<CaseRegistrationFormFields>,
      formikHelpers: FormikHelpers<CaseRegistrationFormFields>
    ) => {
      isMounted.current && updateValuesAndHelpers && updateValuesAndHelpers(formikState, formikHelpers);
    },
    import.meta.env.DEV ? 0 : 250 // makes it easier to spot issues in dev
  );

  const isBigLayout = useLayoutQuery();

  const blockContainer = useRef<HTMLDivElement | null>(null);

  const wideEnoughFor3 = useContainerQuery(blockContainer.current, 800, isBigLayout);
  const wideEnoughFor2 = useContainerQuery(blockContainer.current, 600, isBigLayout);

  const columnCount = wideEnoughFor3 ? 3 : wideEnoughFor2 ? 2 : 1;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnBlur={false}>
      {(formik: FormikProps<CaseRegistrationFormFields>) => {
        {
          debouncedUpdateValuesAndHelpers(
            formik as FormikState<CaseRegistrationFormFields>,
            formik as FormikHelpers<CaseRegistrationFormFields>
          );
        }

        return (
          <Form style={{ height: '100%', width: '100%' }} noValidate>
            <Block
              ref={blockContainer}
              display="flex"
              flexDirection={'column'}
              {...expandPadding(isBigLayout ? ['scale600', 'scale600', 'scale600', '0'] : '0px')}
              height={'100%'}
            >
              <Block flex={'0'}>
                <Block display="flex" justifyContent="space-between" alignItems="center" marginBottom="12px">
                  <span>
                    {formik.values?.visCaseId ? (
                      <LabelMedium display="inline">
                        {tShared('visCaseId')} {formik.values.visCaseId}
                      </LabelMedium>
                    ) : null}
                  </span>
                  <span>
                    <CaseStatusTag mode={mode} />
                  </span>
                </Block>
              </Block>

              <Block flex="1">
                <Tabs
                  activeKey={activeTab}
                  onChange={({ activeKey }) => setActiveTab(activeKey)}
                  activateOnFocus
                  renderAll
                  overrides={{
                    Root: { style: { display: 'flex', flexDirection: 'column', width: '100%', height: '100%' } },
                  }}
                >
                  <Tab title={tCaseReg('vehicleAndOwner')} artwork={FaCheckCircle} overrides={tabOverrides}>
                    <OverflowTabContent>
                      <FlexGrid flexGridColumnCount={columnCount} flexGridColumnGap="scale800">
                        <FlexGridItem>
                          <StartInfoFormGroup />
                          <VehicleInformationFormGroup />
                        </FlexGridItem>
                        <FlexGridItem>
                          <OwnerFormGroup onClick={onLeasingClick} />
                        </FlexGridItem>
                        <FlexGridItem>
                          <CallerInfoFormGroup />
                        </FlexGridItem>
                      </FlexGrid>
                    </OverflowTabContent>
                  </Tab>
                  <Tab title={tCaseReg('incidentAndLocation')} artwork={FaCheckCircle} overrides={tabOverrides}>
                    <OverflowTabContent>
                      <FlexGrid flexGridColumnCount={columnCount} flexGridColumnGap="scale800">
                        <FlexGridItem>
                          <IncidentReasonFormGroup />
                          <IncidentAddressFormGroup />
                        </FlexGridItem>
                        <FlexGridItem>
                          <RequesterFormGroup />
                        </FlexGridItem>
                        <FlexGridItem>
                          <WaitingFormGroup />
                        </FlexGridItem>
                      </FlexGrid>
                    </OverflowTabContent>
                  </Tab>
                  <Tab title={tCaseReg('agreementAndCoverage')} artwork={FaCheckCircle} overrides={tabOverrides}>
                    <OverflowTabContent>
                      <FlexGrid flexGridColumnCount={columnCount} flexGridColumnGap="scale800">
                        <FlexGridItem>
                          <AgreementField submitCount={formik.submitCount} />
                          <NewVehicleInfoFormGroup />
                        </FlexGridItem>
                        <FlexGridItem>
                          <ServiceInformationFormGroup />
                        </FlexGridItem>
                        <FlexGridItem>
                          <InsuranceFormGroup />
                        </FlexGridItem>
                      </FlexGrid>
                    </OverflowTabContent>
                  </Tab>
                  <Tab title={tCaseReg('transportAndWorkshop')} artwork={FaCheckCircle} overrides={tabOverrides}>
                    <OverflowTabContent>
                      <FlexGrid flexGridColumnCount={columnCount} flexGridColumnGap="scale800">
                        <FlexGridItem>
                          <TransportFormGroup />
                        </FlexGridItem>
                        <FlexGridItem>
                          <ContactPersonFormGroup />
                        </FlexGridItem>
                      </FlexGrid>
                    </OverflowTabContent>
                  </Tab>
                </Tabs>
              </Block>

              <Block flexDirection="row" display="flex">
                <Block>
                  <PopoverButton
                    disabled={
                      !formik.values.caseOriginCode ||
                      (!formik.values.vehicleRegistrationNumber &&
                        !isPhoneNumberValid(formik.values.ownerPhone) &&
                        !isPhoneNumberValid(formik.values.callerPhone) &&
                        !isPhoneNumberValid(formik.values.requesterPhone))
                    }
                    content={tCaseReg('saveButtonPopover')}
                    type={'submit'}
                    kind="secondary"
                    isLoading={isLoading}
                    startEnhancer={() => <FaSave />}
                  >
                    {tShared('save')}
                  </PopoverButton>
                </Block>

                <Block display="flex" flex={1} justifyContent="flex-end">
                  <PopoverButton
                    disabled={
                      mode === CaseFormStatus.Sent ||
                      mode === CaseFormStatus.Closed ||
                      mode === CaseFormStatus.Cancelled ||
                      !formik.values.caseId ||
                      !formik.values.caseOriginCode ||
                      (!formik.values.vehicleRegistrationNumber &&
                        !isPhoneNumberValid(formik.values.ownerPhone) &&
                        !isPhoneNumberValid(formik.values.callerPhone) &&
                        !isPhoneNumberValid(formik.values.requesterPhone))
                    }
                    onClick={onCancelClick}
                    content={
                      mode === CaseFormStatus.Create
                        ? `${tCaseReg('cancelButtonPopover')} + ${tCaseReg('saveCase')}`
                        : mode === CaseFormStatus.Sent ||
                          mode === CaseFormStatus.Closed ||
                          mode === CaseFormStatus.Cancelled
                        ? tCaseReg('cancelButtonPopoverStatus')
                        : tCaseReg('cancelButtonPopover')
                    }
                    type={'submit'}
                    kind="secondary"
                    isLoading={isLoading}
                    startEnhancer={() => <FaTrash />}
                  >
                    {tCaseReg('cancelCase')}
                  </PopoverButton>
                  <PopoverButton
                    disabled={
                      !formik.values.caseId ||
                      !formik.values.caseOriginCode ||
                      !formik.values.agreement?.id ||
                      (!formik.values.vehicleRegistrationNumber &&
                        !isPhoneNumberValid(formik.values.ownerPhone) &&
                        !isPhoneNumberValid(formik.values.callerPhone) &&
                        !isPhoneNumberValid(formik.values.requesterPhone))
                    }
                    onClick={onFixedItClick}
                    content={
                      mode === CaseFormStatus.Create
                        ? `${tCaseReg('infocallButtonPopover')} + ${tCaseReg('saveCase')}`
                        : tCaseReg('infocallButtonPopover')
                    }
                    type={'submit'}
                    kind="secondary"
                    isLoading={isLoading}
                    size="default"
                    overrides={{
                      BaseButton: {
                        style: ({ $theme }) => ({
                          marginLeft: $theme.sizing.scale200,
                        }),
                      },
                    }}
                  >
                    {tCaseReg('fixedIt')}
                  </PopoverButton>
                  <PopoverButton
                    disabled={
                      mode === CaseFormStatus.Sent ||
                      !formik.values.caseId ||
                      !formik.values.caseOriginCode ||
                      !formik.values.agreement?.id ||
                      !formik.values.incidentTypeCode ||
                      !formik.values.incidentAddressStreet ||
                      !formik.values.vehicleRegistrationNumber ||
                      (!isPhoneNumberValid(formik.values.ownerPhone) &&
                        !isPhoneNumberValid(formik.values.callerPhone) &&
                        !isPhoneNumberValid(formik.values.requesterPhone))
                    }
                    onClick={() => onRegisterClick(formik.values)}
                    content={
                      mode === CaseFormStatus.Create
                        ? `${tCaseReg('registerButtonPopover')} + ${tCaseReg('saveCase')} `
                        : tCaseReg('registerButtonPopover')
                    }
                    type={'submit'}
                    kind="secondary"
                    isLoading={isLoading}
                    size="default"
                    startEnhancer={() => <FaArrowRight />}
                    overrides={{
                      BaseButton: {
                        style: ({ $theme }) => ({
                          marginLeft: $theme.sizing.scale200,
                        }),
                      },
                    }}
                  >
                    {tCaseReg('registerCase')}
                  </PopoverButton>
                </Block>
              </Block>
            </Block>
          </Form>
        );
      }}
    </Formik>
  );
};

export const CaseRegistrationForm = memo(CaseRegistrationFormComp);
