import { withStyle } from 'baseui';
import { BlockProps } from 'baseui/block';
import { StyledLabel as StyledCheckedboxLabel } from 'baseui/checkbox';
import { HeadingXLarge, LabelLarge, ParagraphLarge, ParagraphMedium } from 'baseui/typography';
import { forwardRef } from 'react';

export const CheckboxLabel = withStyle(StyledCheckedboxLabel, () => ({
  fontWeight: 300,
}));

export const PageTitle = forwardRef<HTMLHeadingElement, BlockProps<'h1'>>((props: BlockProps<'h1'>, ref) => (
  <LabelLarge {...props} marginBottom="scale800" marginTop="0" ref={ref} as="h1" />
));
PageTitle.displayName = 'PageTitle ';

export const TextMedium = forwardRef((props: BlockProps, ref) => (
  <ParagraphMedium {...props} marginBottom="0" marginTop="0" ref={ref} />
));
TextMedium.displayName = 'TextMedium';

export const TextLarge = forwardRef((props: BlockProps, ref) => (
  <ParagraphLarge {...props} marginBottom="0" marginTop="0" ref={ref} />
));
TextLarge.displayName = 'TextLarge';

export const TextXLarge = forwardRef<HTMLParagraphElement, BlockProps<'p'>>((props: BlockProps<'p'>, ref) => (
  <HeadingXLarge {...props} marginBottom="0" marginTop="0" ref={ref} as="p" />
));
TextXLarge.displayName = 'TextXLarge';
