import { useField } from 'formik';
import { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AgreementDto } from '../../api/api.generated';
import { useAgreementQuery, useAgreementsQuery } from '../../api/queries/useAgreementQueries';
import { FastSelectField as SelectField } from '../../shared/formFields/SelectField';
import FormGroup from '../../shared/utilityComponents/FormGroup';
import { nameof } from '../caseRegistrationFunctions';
import { CaseRegistrationFormFields } from '../caseRegistrationModels';

interface agreementFieldProps {
  submitCount?: number;
}

function AgreementFieldComp(props: agreementFieldProps) {
  const { submitCount } = props;

  const { t } = useTranslation('translation', { keyPrefix: 'shared' });
  const [agreementField] = useField<CaseRegistrationFormFields['agreement']>(nameof('agreement'));
  const { value: agreement } = agreementField;
  const { data: agreementOptions = [] } = useAgreementsQuery();
  const { data: newAgreementObject = {} as AgreementDto } = useAgreementQuery(agreement?.id);

  const [, , helpers] = useField(nameof('agreementInfo'));

  const helpersRef = useRef(helpers);

  const agreementId = agreement?.id;

  useEffect(() => {
    if (newAgreementObject && newAgreementObject.id && agreementId) {
      helpersRef.current?.setValue(newAgreementObject);
    }
  }, [newAgreementObject, agreementId, submitCount]);

  return (
    <FormGroup label={t('agreement')}>
      <SelectField label={`${t('agreement')}*`} options={agreementOptions} name={nameof('agreement')} bindToObject />
    </FormGroup>
  );
}
export const AgreementField = memo(AgreementFieldComp);
