import { useStyletron } from 'baseui';
import { ParagraphSmall } from 'baseui/typography';
import { useFormikContext } from 'formik';

interface CustomErrorMessageProps {
  name: string;
}

export function CustomErrorMessage(props: CustomErrorMessageProps) {
  const ctx = useFormikContext<Record<string, unknown>>();

  const [css, theme] = useStyletron();

  const error = ctx.errors[props.name];

  if (!(ctx.submitCount > 0 && !!error)) return null;

  return <ParagraphSmall className={css({ color: theme.colors.negative })}>{error}</ParagraphSmall>;
}
