import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SMS } from '../api.generated';
import { useApiClient } from '../apiClientContext';
import { CASE_ACTION_QUERY_KEY, getInvalidateQueriesMutationOption } from './queryUtils';

export const useSendFreeTextSms = () => {
  const { communicationClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_ACTION_QUERY_KEY]);

  return useMutation((body: SMS) => communicationClient.sendFreeTextSms(body), options);
};

export const useSendGeoLinkSms = () => {
  const { communicationClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_ACTION_QUERY_KEY]);

  return useMutation((body: SMS) => communicationClient.sendGeoLinkSms(body), options);
};
