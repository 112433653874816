import { memo, ReactElement, ReactNode } from 'react';
import { useLayoutQuery } from '@@shared/hooks/useLayoutQuery';
import { SPLIT_LAYOUT_BREAKPOINT } from '@@styles/appTheme';
import { SplitLayout } from './SplitLayout';
import { TabsLayout } from './TabsLayout';

export interface TwoHalvesLayoutProps {
  children: [ReactElement<NamedChildElement>, ReactElement<NamedChildElement>];
}

export interface NamedChildElement {
  name: string;
  children?: ReactNode;
}

export const LayoutItemComp = (props: NamedChildElement) => {
  return <>{props.children}</>;
};

export const TwoHalvesLayoutComp = (props: TwoHalvesLayoutProps) => {
  const isOverBreakpoint = useLayoutQuery(SPLIT_LAYOUT_BREAKPOINT);

  return isOverBreakpoint ? <SplitLayout {...props} /> : <TabsLayout {...props} />;
};

export const TwoHalvesLayout = memo(TwoHalvesLayoutComp);
export const LayoutItem = memo(LayoutItemComp);
