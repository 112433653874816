import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { DEFAULT_MAP_COORDS } from '../../map/Map';
import { isNumeric } from '../../shared/functions/general';
import { LocationDto } from '../api.generated';
import { useApiClient } from '../apiClientContext';

export const useNearbyRetailersWithDistanceQuery = (latitude: string, longitude: string, enabled: boolean) => {
  const { retailerClient } = useApiClient();
  const tTransport = useTranslation('translation', { keyPrefix: 'transportForm' }).t;

  return useQuery<LocationDto[]>(
    ['NearbyRetailersWithDistance', latitude, longitude],
    async () => {
      try {
        return await retailerClient.getNearByRetailers(parseFloat(latitude), parseFloat(longitude), true);
      } catch (error) {
        console.error(tTransport('workshopsError'), error);
        throw error;
      }
    },
    {
      enabled:
        enabled &&
        isNumeric(latitude) &&
        isNumeric(longitude) &&
        parseFloat(latitude) !== DEFAULT_MAP_COORDS.lat &&
        parseFloat(longitude) !== DEFAULT_MAP_COORDS.lng,
      onError: (error) => {
        console.error('useNearbyRetailersWithDistanceQuery failed', error);
      },
    }
  );
};

export const useNearbyRentalCarProvidersWithDistanceQuery = (latitude: string, longitude: string, enabled: boolean) => {
  const { retailerClient } = useApiClient();

  return useQuery<LocationDto[]>(
    ['NearbyRentalCarProvidersWithDistance', latitude, longitude],
    () => retailerClient.getNearbyRentalCarProviders(parseFloat(latitude), parseFloat(longitude), true),
    {
      enabled:
        enabled &&
        isNumeric(latitude) &&
        isNumeric(longitude) &&
        parseFloat(latitude) !== DEFAULT_MAP_COORDS.lat &&
        parseFloat(longitude) !== DEFAULT_MAP_COORDS.lng,
    }
  );
};

export const useNearbyRetailersQuery = (latitude: string, longitude: string) => {
  const { retailerClient } = useApiClient();

  return useQuery<LocationDto[]>(
    ['NearbyRetailers', latitude, longitude],
    () => retailerClient.getNearByRetailers(parseFloat(latitude), parseFloat(longitude), false),
    {
      enabled:
        isNumeric(latitude) &&
        isNumeric(longitude) &&
        parseFloat(latitude) !== DEFAULT_MAP_COORDS.lat &&
        parseFloat(longitude) !== DEFAULT_MAP_COORDS.lng,
    }
  );
};

export const useNearbyRentalCarProvidersQuery = (latitude: string, longitude: string) => {
  const { retailerClient } = useApiClient();

  return useQuery<LocationDto[]>(
    ['NearbyRentalCarProviders', latitude, longitude],
    () => retailerClient.getNearbyRentalCarProviders(parseFloat(latitude), parseFloat(longitude), false),
    {
      enabled:
        isNumeric(latitude) &&
        isNumeric(longitude) &&
        parseFloat(latitude) !== DEFAULT_MAP_COORDS.lat &&
        parseFloat(longitude) !== DEFAULT_MAP_COORDS.lng,
    }
  );
};

export const useRentalCarProvidersQuery = () => {
  const { retailerClient } = useApiClient();

  return useQuery(['RentalCarProviders'], () => retailerClient.getRentalCarProviders2());
};
