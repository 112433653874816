/* eslint-disable @typescript-eslint/consistent-type-assertions */

/* Baseweb should make partial classes available for overrides, but it does not */
import { Primitives, createLightTheme } from 'baseui';
import { Colors } from 'baseui/styles';
import { Borders, Breakpoints, Theme } from 'baseui/theme';
import { Client } from 'styletron-engine-atomic';

export interface ExtendedBreakpoints extends Breakpoints {
  extraSmall: number;
  extraLarge: number;
  extraExtraLarge: number;
}

export type Layout = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

// Bootstrap 5 breakpoints
export const LAYOUT_BREAKPOINTS: Record<Layout, number> = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const SPLIT_LAYOUT_BREAKPOINT: Layout = 'xxl';

export const grays = {
  gray50: '#f0f0f0',
  gray100: '#EEEEEE',
  gray200: '#E2E2E2',
  gray400: '#AFAFAF',
};

const primitives: Partial<Primitives> = {
  primary: '#CF2B32',
  primary50: '#feebee',
  primary100: '#fdccd3',
  primary200: '#ec999b',
  primary300: '#e17175',
  primary400: '#eb5054',
  primary500: '#f03e3b',
  primary600: '#e13539',
  primary700: '#cf2b33',
  negative: '#CF2B32',
  warning: '#F8C40E',
  positive: '#05944F',
  accent: '#05944F',
  primaryFontFamily: `'Merriweather Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
};

const overrides: Partial<Theme> = {
  colors: {
    buttonPrimaryFill: '#313131',
    buttonPrimaryText: '#ffffff',
    buttonPrimaryHover: '#d5c6c4',
    buttonSecondaryFill: '#F0E8E6',
    buttonSecondaryText: '#313131',
    buttonSecondaryHover: '#d5c6c4',
    buttonTertiaryFill: 'transparent',
    buttonTertiaryText: '#313131',
    buttonTertiaryHover: grays.gray100,
    backgroundPrimary: '#FAF6F5',
    backgroundSecondary: '#FFFFFF',
    backgroundInversePrimary: '#313131',
    borderAccentLight: '#AFAFAF',
    inputFill: '#FFFFFF',
    inputFillDisabled: grays.gray100,
    inputTextDisabled: '#313131',
    inputBorder: '#E2E2E2',
    notificationInfoBackground: '#FFFFFF',
  } as Colors,
  borders: {
    buttonBorderRadius: '4px',
    inputBorderRadius: '0',
    useRoundedCorners: true,
  } as Borders,
  breakpoints: {
    extraSmall: LAYOUT_BREAKPOINTS.xs,
    small: LAYOUT_BREAKPOINTS.sm,
    medium: LAYOUT_BREAKPOINTS.md,
    large: LAYOUT_BREAKPOINTS.lg,
    extraLarge: LAYOUT_BREAKPOINTS.xl,
    extraExtraLarge: LAYOUT_BREAKPOINTS.xxl,
  } as Breakpoints,
};

const appTheme = createLightTheme(primitives, overrides);

const styletronEngine = new Client();

export { appTheme, styletronEngine };
