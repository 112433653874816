import { useRentalCarProviderQuery } from '@@api/queries/useActorQueries';
import { Block } from 'baseui/block';
import { Drawer } from 'baseui/drawer';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Spinner } from 'baseui/spinner';
import { HeadingSmall, LabelSmall, ParagraphMedium } from 'baseui/typography';
import { memo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';

interface ActorDetailsDrawerProps {
  actorId?: string;
  isFetching?: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

function ActorDetailsDrawerComp(props: ActorDetailsDrawerProps) {
  const { setIsOpen, isOpen, actorId } = props;
  const { data: rentalCarProvider, isFetching } = useRentalCarProviderQuery(actorId);
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tCaseReg = useTranslation('translation', { keyPrefix: 'caseRegistration' }).t;
  const [css] = useStyletron();

  const labelSmall = css({
    fontWeight: 300,
  });

  const flexBlock = css({
    marginBottom: '32px',
    display: 'flex',
    flexDirection: 'column',
  });

  const contactInfoBlock = css({
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  });

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      anchor={'right'}
      animate
      overrides={{
        DrawerBody: {
          style: { marginBottom: '0px', marginLeft: '0px', marginRight: '0px', marginTop: '0px', padding: '32px' },
        },
      }}
    >
      <Block>
        {isFetching ? (
          <Spinner />
        ) : (
          <>
            <HeadingSmall marginTop={'16px'} marginBottom={'16px'}>
              {rentalCarProvider?.name}
            </HeadingSmall>
            <Block className={flexBlock}>
              <Block>{rentalCarProvider?.adress}</Block>
              <Block>
                {rentalCarProvider?.postalCode} {rentalCarProvider?.postalPlace}
              </Block>
            </Block>
            <Block className={flexBlock}>
              <ParagraphMedium>{tShared('contactInfo')}</ParagraphMedium>
              <Block className={contactInfoBlock}>
                <LabelSmall className={labelSmall}>{tCaseReg('actorNr')}</LabelSmall>
                <Block>{rentalCarProvider?.jeId}</Block>
              </Block>
              <Block className={contactInfoBlock}>
                <LabelSmall className={labelSmall}>{tShared('telephoneNumber')}</LabelSmall>
                <Block>{rentalCarProvider?.mainPhone}</Block>
              </Block>
            </Block>
            <Block className={flexBlock}>
              <ParagraphMedium>{tCaseReg('emailAddress')}</ParagraphMedium>
              <FlexGrid flexGridColumnCount={2} flexGridColumnGap="32px" flexGridRowGap="16px">
                {rentalCarProvider?.emailAddress
                  ? rentalCarProvider.emailAddress
                      .sort((a, b) => (a.sort || 0) - (b.sort || 0))
                      .map((email, index) => (
                        <Fragment key={`${index} - ${email.address}`}>
                          <FlexGridItem overrides={{ Block: { style: { wordBreak: 'break-word' } } }}>
                            {email.address}
                          </FlexGridItem>
                          <FlexGridItem overrides={{ Block: { style: { width: '80px' } } }}>
                            <LabelSmall className={labelSmall}>{email.note}</LabelSmall>
                          </FlexGridItem>
                        </Fragment>
                      ))
                  : null}
              </FlexGrid>
            </Block>

            <ParagraphMedium>{tCaseReg('comment')}</ParagraphMedium>
            <div style={{ whiteSpace: 'pre-wrap' }}>{rentalCarProvider?.comment}</div>
          </>
        )}
      </Block>
    </Drawer>
  );
}

export const ActorDetailsDrawer = memo(ActorDetailsDrawerComp);
