import { Button, KIND } from 'baseui/button';
import { DeleteAlt } from 'baseui/icon';
import { Cell, Grid } from 'baseui/layout-grid';
import { LabelMedium } from 'baseui/typography';
import { Form, Formik, FormikProps, useField } from 'formik';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdArticle, MdCheckCircle } from 'react-icons/md';
import { useStore } from 'zustand';
import { useCaseRegistrationStoreContext } from '@@caseRegistration/CaseRegistrationStore';
import { CaseActionDto, ComboboxItem } from '../../api/api.generated';
import {
  useSendComplainToCaseHandlerMutation,
  useSendDeviationToCaseHandlerMutation,
  useSendExternalNoteMutation,
  useSendNoteToCaseHandlerMutation,
  useSendNoteToDispatchMutation,
} from '../../api/queries/useCaseActionQueries';
import { useServicesQuery } from '../../api/queries/useCaseMasterDataQueries';
import SelectField from '../../shared/formFields/SelectField';
import TextareaField from '../../shared/formFields/TextareaField';
import { useSnackbarRef } from '@@shared/hooks/useSnackbarRef';

enum NoteType {
  DISPATCH = 'dispatchNote',
  EXTERNAL = 'externalNote',
  CASEHANDLER = 'noteToCaseHandler',
  DEVIATION = 'deviation',
  COMPLAIN = 'complainReceived',
}

interface NoteComponentProps {
  isLoading: boolean;
}

function NoteComponentComp({ isLoading }: NoteComponentProps) {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tCommunication = useTranslation('translation', { keyPrefix: 'communicationComponent' }).t;

  const [noteMessage] = useField('noteMessage');
  const [noteType] = useField('noteType');
  const [noteService] = useField('noteService');
  const [disabled, setDisabled] = useState(true);
  const [sortedServices, setSortedServices] = useState<ComboboxItem[]>();

  const { data: services = [] } = useServicesQuery();

  const messageType: ComboboxItem[] = [
    {
      id: NoteType.DISPATCH,
      name: tCommunication('dispatchNote'),
    },
    {
      id: NoteType.EXTERNAL,
      name: tCommunication('externalNote'),
    },
    {
      id: NoteType.CASEHANDLER,
      name: tCommunication('noteToCaseHandler'),
    },
    {
      id: NoteType.DEVIATION,
      name: tCommunication('deviation'),
    },
    {
      id: NoteType.COMPLAIN,
      name: tCommunication('complainReceived'),
    },
  ];

  useEffect(() => {
    setSortedServices([
      ...services.filter(
        (s) =>
          s.id === 'AS' ||
          s.id === 'HR' ||
          s.id === 'LE' ||
          s.id === 'OV' ||
          s.id === 'DIV' ||
          s.id === 'TAX' ||
          s.id === 'GOW'
      ),
    ]);
  }, [services]);

  useEffect(() => {
    if (noteType.value === NoteType.DISPATCH && !!noteMessage.value) {
      setDisabled(false);
    } else if (!!noteType.value && !!noteMessage.value && !!noteService.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    //TODO add check for deviation and complain
  }, [noteMessage.value, noteType.value, noteService.value]);

  return (
    <Grid gridMargins={[0]}>
      <Cell span={9}>
        <LabelMedium marginTop="24px" marginBottom="12px">
          {tCommunication('note')}
        </LabelMedium>
        <Grid gridMargins={[0]}>
          <Cell span={5}>
            <SelectField options={messageType} name="noteType" label={`${tCommunication('messageType')}*`} />
            {noteType.value === NoteType.DISPATCH ? null : (
              <SelectField
                options={sortedServices}
                name="noteService"
                label={`${tCommunication('relatedToService')}*`}
              />
            )}
          </Cell>
          <Cell span={4} />
        </Grid>

        <TextareaField name="noteMessage" label={`${tShared('description')}*`} />

        <Button
          type="submit"
          kind={KIND.secondary}
          disabled={disabled}
          startEnhancer={() => <MdArticle style={{ marginTop: '2px' }} size={18} />}
          isLoading={isLoading}
        >
          {tCommunication('addNote')}
        </Button>
      </Cell>
      <Cell span={3} />
    </Grid>
  );
}

interface NoteComponentFormFields {
  noteMessage: string;
  noteService: string;
  noteType: string;
}

const initialValues: NoteComponentFormFields = {
  noteMessage: '',
  noteService: '',
  noteType: '',
};

const NoteComponentWithItsOwnForm = () => {
  const store = useCaseRegistrationStoreContext();
  const caseId = useStore(store, (s) => s.caseId);

  const enqueue = useSnackbarRef();

  const sendNoteToDispatch = useSendNoteToDispatchMutation();
  const sendExternalNote = useSendExternalNoteMutation();
  const sendNoteToCaseHandler = useSendNoteToCaseHandlerMutation();
  const sendComplainToCaseHandler = useSendComplainToCaseHandlerMutation();
  const sendDeviationToCaseHandler = useSendDeviationToCaseHandlerMutation();

  const tCommunication = useTranslation('translation', { keyPrefix: 'communicationComponent' }).t;

  const formRef = useRef<FormikProps<NoteComponentFormFields> | null>(null);

  const showSnackBar = useCallback(
    (success: boolean) => {
      enqueue({
        message: success ? tCommunication('noteSuccess') : tCommunication('noteFailed'),
        startEnhancer: ({ size }) => (success ? <MdCheckCircle size={size} /> : <DeleteAlt size={size} />),
      });
    },
    [enqueue, tCommunication]
  );

  const clearValues = useCallback(
    (success: boolean) => {
      if (success) {
        formRef?.current?.resetForm();
        showSnackBar(true);
      } else {
        showSnackBar(false);
      }
    },
    [showSnackBar]
  );

  useEffect(() => {
    //Reset values after success so that the snackbar doesn´t show up when some other note is success

    if (sendNoteToDispatch.isSuccess) {
      sendNoteToDispatch.reset();
      clearValues(true);
    } else if (sendExternalNote.isSuccess) {
      sendExternalNote.reset();
      clearValues(true);
    } else if (sendNoteToCaseHandler.isSuccess) {
      sendNoteToCaseHandler.reset();
      clearValues(true);
    } else if (sendComplainToCaseHandler.isSuccess) {
      sendComplainToCaseHandler.reset();
      clearValues(true);
    } else if (sendDeviationToCaseHandler.isSuccess) {
      sendDeviationToCaseHandler.reset();
      clearValues(true);
    }
  }, [
    sendNoteToDispatch.isSuccess,
    sendExternalNote.isSuccess,
    sendNoteToCaseHandler.isSuccess,
    sendComplainToCaseHandler.isSuccess,
    sendDeviationToCaseHandler.isSuccess,
    sendNoteToDispatch,
    sendExternalNote,
    sendNoteToCaseHandler,
    sendComplainToCaseHandler,
    sendDeviationToCaseHandler,
    clearValues,
  ]);

  useEffect(() => {
    if (sendNoteToDispatch.error) {
      sendNoteToDispatch.reset();
      clearValues(false);
    } else if (sendExternalNote.error) {
      sendExternalNote.reset();
      clearValues(false);
    } else if (sendNoteToCaseHandler.error) {
      sendNoteToCaseHandler.reset();
      clearValues(false);
    } else if (sendDeviationToCaseHandler.error) {
      sendDeviationToCaseHandler.reset();
      clearValues(false);
    } else if (sendComplainToCaseHandler.error) {
      sendComplainToCaseHandler.reset();
      clearValues(false);
    }

    //TODO add check for deviation and complain
  }, [
    sendNoteToDispatch.error,
    sendExternalNote.error,
    sendNoteToCaseHandler.error,
    sendDeviationToCaseHandler.error,
    sendComplainToCaseHandler.error,
    sendNoteToDispatch,
    sendExternalNote,
    sendNoteToCaseHandler,
    sendDeviationToCaseHandler,
    sendComplainToCaseHandler,
    clearValues,
  ]);

  function handleSubmit(values: NoteComponentFormFields) {
    const body: CaseActionDto = {
      caseId: caseId,
      description: values.noteMessage,
      service: values.noteType === NoteType.DISPATCH ? 'DIV' : values.noteService,
    };

    switch (values.noteType) {
      case NoteType.DISPATCH:
        sendNoteToDispatch.mutate(body);
        break;
      case NoteType.EXTERNAL:
        sendExternalNote.mutate(body);
        break;
      case NoteType.CASEHANDLER:
        sendNoteToCaseHandler.mutate(body);
        break;
      case NoteType.DEVIATION:
        sendDeviationToCaseHandler.mutate(body);
        break;
      case NoteType.COMPLAIN:
        sendComplainToCaseHandler.mutate(body);
        break;
    }
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} innerRef={formRef}>
      {() => (
        <Form>
          <NoteComponentComp
            isLoading={
              sendNoteToCaseHandler.isLoading ||
              sendNoteToDispatch.isLoading ||
              sendExternalNote.isLoading ||
              sendDeviationToCaseHandler.isLoading ||
              sendComplainToCaseHandler.isLoading
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export const NoteComponent = memo(NoteComponentWithItsOwnForm);
