import { useMemo } from 'react';
import { FaSignOutAlt, FaUserCircle } from 'react-icons/fa';
import { Dashboard } from '@@core/pages/Dashboard';
import { LogOut } from '@@core/pages/LogOut';
import { NotFound } from '@@core/pages/NotFound';
import { equalsIgnoreCase } from '@@shared/functions/general';
import { useUser } from '@@shared/hooks/useUser';
import { UserDetailsPage } from '@@users/UserDetailsPage';
import { UserListPage } from '@@users/UserListPage';
import { UserProfilePage } from '@@users/UserProfilePage';
import { CaseRegistrationPage } from './caseRegistration/CaseRegistrationPage';
import { CaseSearchPage } from './caseSearch/CaseSearchPage';

export const useAppRoutes = (): AppRoute[] => {
  const { data: user, role: userRole } = useUser();

  const userRoleName = userRole.name;

  const appRoutes = useMemo(() => {
    if (!user) return [];

    return routes.map((r) => {
      const hasAccess = !r.requiredRole || equalsIgnoreCase(userRoleName, r.requiredRole);

      const appRoute: AppRoute = { ...r, userHasAccess: hasAccess };

      return appRoute;
    });
  }, [user, userRoleName]);

  return appRoutes;
};

export interface AppRoute extends IRoute {
  userHasAccess: boolean;
}

export interface IRoute {
  path: string;
  label: string;
  component: JSX.Element;
  icon?: JSX.Element;
  menu?: 'main' | 'user';
  requiredRole?: string;
}

export const routes: IRoute[] = [
  {
    path: '/',
    label: 'dashboard',
    component: <Dashboard />,
    menu: 'main',
  },
  {
    path: '/case-search',
    label: 'caseSearch',
    component: <CaseSearchPage />,
    menu: 'main',
  },
  {
    path: '/case/:visCaseId',
    label: 'caseReg',
    component: <CaseRegistrationPage />,
  },
  {
    path: '/new-case',
    label: 'newCase',
    component: <CaseRegistrationPage />,
    menu: 'main',
  },
  {
    path: '/users',
    label: 'users',
    component: <UserListPage />,
    menu: 'main',
    requiredRole: 'admin',
  },
  {
    path: '/user/:userId',
    label: 'userDetails',
    component: <UserDetailsPage />,
    requiredRole: 'admin',
  },
  {
    label: 'profil',
    path: '/profile',
    menu: 'user',
    component: <UserProfilePage />,
    icon: <FaUserCircle />,
  },
  {
    label: 'logout',
    path: '/logout',
    menu: 'user',
    component: <LogOut />,
    icon: <FaSignOutAlt />,
  },
  {
    label: 'notFound',
    path: '*',
    component: <NotFound />,
  },
];
