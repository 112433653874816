import { ReactNode } from 'react';
import { appConfig } from '@@config/appConfig';
import { useLayoutQuery } from '@@shared/hooks/useLayoutQuery';

export const BreakpointHelper = () => {
  const isSm = useLayoutQuery('sm');
  const isMd = useLayoutQuery('md');
  const isLg = useLayoutQuery('lg');
  const isXl = useLayoutQuery('xl');
  const isXxl = useLayoutQuery('xxl');

  const minLayout = isXxl ? 'xxl' : isXl ? 'xl' : isLg ? 'lg' : isMd ? 'md' : isSm ? 'sm' : 'xs';

  return <span style={{ position: 'fixed', bottom: '1em', right: '1em' }}>{minLayout}</span>;
};

export const DebugOnly = (props: { localOnly?: boolean; children: ReactNode }) => {
  const { localOnly = true, children } = props;

  if (localOnly && !import.meta.env.DEV) return null;

  const isDebug = ['local', 'dev', 'qa'].includes(appConfig.environment.toLowerCase());

  if (!isDebug) return null;

  return <>{children}</>;
};
