import React from 'react';
import { LabelXSmall, ParagraphXSmall } from 'baseui/typography';

interface InfoRowProps {
  label: string;
  info?: string | number;
}

function InfoRow({ label, info }: InfoRowProps) {
  return (
    <div style={{ flexDirection: 'column', display: 'flex', paddingBottom: '5px' }}>
      <ParagraphXSmall marginBottom={0} marginTop={0}>
        {label}
      </ParagraphXSmall>
      <LabelXSmall>{info}</LabelXSmall>
    </div>
  );
}

export default InfoRow;
