import { useMsal } from '@azure/msal-react';
import { appConfig } from '../../config/appConfig';

export const LogOut = () => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount() || instance.getAllAccounts()[0];

  instance.logoutRedirect({ postLogoutRedirectUri: `${window.location.origin}${appConfig.publicUrl}`, account });

  return <>Buh bye</>;
};
