import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { EqualityComparator } from 'fast-equals';
import { createCustomEqual } from 'fast-equals';
import { useEffect, useRef } from 'react';

const deepCompareEqualsForMaps = createCustomEqual({
  createInternalComparator:
    (deepEqual: EqualityComparator<unknown>) => (a: google.maps.LatLngLiteral, b: google.maps.LatLngLiteral, state) => {
      if (isLatLngLiteral(a) || isLatLngLiteral(b)) {
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
      }

      // TODO extend to other types

      // use fast-equals for other objects
      return deepEqual(a, b, state);
    },
});

function useDeepCompareMemoize(value: unknown) {
  const ref = useRef<unknown>();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffectForMaps(callback: React.EffectCallback, dependencies: unknown[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
