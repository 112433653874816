import { Block } from 'baseui/block';
import { ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@@api/api.generated';

type VolvoApi = 'Consumer' | 'Vehicle' | 'SoftProduct';

interface EviVolvoErrorsProps {
  errors?: ErrorMessage[];
  children: ReactNode;
  showForApi: VolvoApi;
  showMessageFromVolvoMessage?: boolean;
}

const EviVolvoErrorsComp = ({ children, errors, showForApi, showMessageFromVolvoMessage }: EviVolvoErrorsProps) => {
  const tEvi = useTranslation('translation', { keyPrefix: 'evi' }).t;

  const errorsForApi = errors?.filter((e) => e.api === showForApi);
  const hasErrors = errorsForApi?.length;

  if (!hasErrors) {
    return <>{children}</>;
  }

  return (
    <>
      {!!showMessageFromVolvoMessage && <Block marginBottom={'16px'}>{tEvi('volvoErrorMessage')}</Block>}
      <Block style={{ fontStyle: 'italic' }}>
        {errorsForApi.map((e, i) => (
          <Block marginBottom={'16px'} key={`error-${i}`}>
            <span>{e.error}</span>
          </Block>
        ))}
      </Block>
    </>
  );
};
export const EviVolvoErrors = memo(EviVolvoErrorsComp);
