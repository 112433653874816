import { InputProps } from 'baseui/input';
import React, { useCallback, useEffect, useState } from 'react';
import useDebouncy from 'use-debouncy/lib/fn';
import { Input } from '../basewebComponentOverrides/Input';

export interface DebouncedInputProps extends InputProps {
  debounceMs?: number;
  upperCase?: boolean;
}

const DebouncedInput: React.FC<DebouncedInputProps> = (props: DebouncedInputProps) => {
  const { debounceMs, onChange, value, upperCase, ...restOfProps } = props;

  const [localValue, setLocalValue] = useState<string | number>('');

  const debouncedHandleChange = useDebouncy((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event);
    }
  }, debounceMs ?? 0);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.currentTarget.value;

      setLocalValue(upperCase ? newValue.toUpperCase() : newValue);

      debouncedHandleChange(event);
    },
    [debouncedHandleChange, upperCase]
  );

  useEffect(() => {
    const toMaybeUpperCase = (input: string | number) => (upperCase ? (input + '').toUpperCase() : input);

    setLocalValue(toMaybeUpperCase(value || ''));
  }, [value, upperCase]);

  return <Input value={localValue} onChange={handleChange} {...restOfProps} />;
};

export default DebouncedInput;
