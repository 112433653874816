import { Block } from 'baseui/block';
import { FieldValidator, useField } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useFuelsQuery,
  useVehicleBrandsQuery,
  useVehicleModelsQuery,
} from '../../api/queries/useCaseMasterDataQueries';
import { FastInputField as InputField } from '../../shared/formFields/InputField';
import { FastSelectField as SelectField } from '../../shared/formFields/SelectField';
import { isNumeric } from '../../shared/functions/general';
import FormGroup from '../../shared/utilityComponents/FormGroup';
import { nameof } from '../caseRegistrationFunctions';
import { CaseRegistrationFormFields } from '../caseRegistrationModels';

function VehicleInformationFormGroupComp() {
  const tVehicle = useTranslation('translation', { keyPrefix: 'vehicleInfoForm' }).t;

  const [field] = useField<CaseRegistrationFormFields['vehicleBrand']>(nameof('vehicleBrand'));
  const [, , modelHelper] = useField<CaseRegistrationFormFields['vehicleModel']>(nameof('vehicleModel'));

  const { data: vehicleBrands = [] } = useVehicleBrandsQuery();
  const { data: models = [] } = useVehicleModelsQuery(field.value?.id);
  const { data: fuels = [] } = useFuelsQuery();

  const handleChange = () => {
    modelHelper.setValue(undefined);
  };

  const validateYear: FieldValidator = (value: string) => {
    if (!value?.trim()) return;

    if (isNumeric(value) && value.length === 4) return;

    return tVehicle('validYear') as string;
  };

  return (
    <FormGroup label={tVehicle('vehicleInfo')}>
      <SelectField
        options={vehicleBrands}
        label={`${tVehicle('brand')}*`}
        name={'vehicleBrand'}
        bindToObject
        onChange={handleChange}
      />
      <SelectField options={models} label={`${tVehicle('model')}*`} name={nameof('vehicleModel')} bindToObject />
      <InputField label={`${tVehicle('makeModel')}`} name={nameof('vehicleMakeModel')} />
      <Block display="flex">
        <Block flex="0 0 5em">
          <InputField label={`${tVehicle('yearModel')}*`} name={nameof('vehicleModelYear')} validate={validateYear} />
        </Block>
        <Block flex="1" paddingLeft="scale200">
          <SelectField label={`${tVehicle('fuel')}*`} options={fuels} name={nameof('vehicleFuelType')} />
        </Block>
      </Block>
      <InputField label={`${tVehicle('vehicleVIN')}`} name={nameof('vehicleChassis')} />
    </FormGroup>
  );
}

export const VehicleInformationFormGroup = memo(VehicleInformationFormGroupComp);
