import { Avatar } from 'baseui/avatar';
import { reduce } from 'lodash-es';

interface Props {
  name: string;
  size?: string;
}

export const InitialsAvatar = ({ name, size }: Props) => {
  const nameAsChars = Array.from((name ?? '').toUpperCase()).map((_, i) => name.charCodeAt(i));

  const nameHash = reduce(nameAsChars, (pre: number, curr: number) => pre + curr, 1);

  const bgColor = colorPalette[nameHash % colorPalette.length];

  const safeSize = size || '3em';

  return (
    <Avatar
      name={name}
      size={safeSize}
      overrides={{
        Root: { style: { backgroundColor: bgColor } },
        Initials: { style: { fontSize: `calc(${safeSize} / 2)` } },
      }}
    />
  );
};

const colorPalette: string[] = [
  '#FFB300',
  '#1E88E5',
  '#546E7A',
  '#6D4C41',
  '#00ACC1',
  '#F4511E',
  '#5E35B1',
  '#43A047',
  '#757575',
  '#3949AB',
  '#039BE5',
  '#7CB342',
  '#C0CA33',
  '#FB8C00',
  '#D81B60',
  '#8E24AA',
  '#E53935',
  '#00897B',
  '#FDD835',
];
