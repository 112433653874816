import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import usePlacesAutocomplete, { Suggestion, getLatLng } from 'use-places-autocomplete';
import {
  geocodeAddressPlaceId,
  geocodeAddressText,
  getStreetAddressFromGeocodeResult,
  getPostalCodeFromGeocodeResult,
  isPlacePointOfInterest,
  getPostalAreaFromGeocodeResult,
} from '@@map/placesFunctions';
import { SearchInput, SearchItem } from './SearchInput';

export interface ResolvedAddress {
  street?: string;
  postalCode?: string;
  city?: string;
  latitude?: number;
  longitude?: number;
  poiDescription?: string;
}

interface Props {
  value: string;
  country: string;
  showMissingCoordsWarning?: boolean;
  onAddressResolved: (address: ResolvedAddress | undefined) => void;
  onInputClear: () => void;
}

const AddressSearchComp = (props: Props) => {
  const { value: valueProp, country, showMissingCoordsWarning, onAddressResolved, onInputClear } = props;

  const {
    suggestions: { data, loading },
    value,
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: [country],
      },
    },
    cacheKey: `country=${country}`,
    debounce: 250,
  });

  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;

  useEffect(() => {
    setValue(valueProp, false);
  }, [valueProp, setValue]);

  useEffect(() => {
    if (country) {
      clearSuggestions();
    }
  }, [country, clearSuggestions]);

  const handleInputChange = (value: string) => {
    setValue(value);
  };

  const resolveAddressWithIdSelect = async (address: SearchItem): Promise<ResolvedAddress | undefined> => {
    const geocodedAddress = await geocodeAddressPlaceId(address.id);

    if (!geocodedAddress) {
      console.error("Couldn't geocode placeId: " + address.id);
      return;
    }

    const street = getStreetAddressFromGeocodeResult(geocodedAddress);

    const postalCode = await getPostalCodeFromGeocodeResult(geocodedAddress);

    const city = getPostalAreaFromGeocodeResult(geocodedAddress);

    const { lat, lng } = getLatLng(geocodedAddress);

    const isPoi = await isPlacePointOfInterest(address.id);

    const resolvedAddress: ResolvedAddress = {
      street: street,
      postalCode: postalCode,
      city: city,
      latitude: lat,
      longitude: lng,
      poiDescription: isPoi ? address.label : undefined,
    };

    return resolvedAddress;
  };

  const resolveTextOnlyAddressSelect = async (address: SearchItem): Promise<ResolvedAddress | undefined> => {
    const text = address.label;

    const geocodedAddress = await geocodeAddressText(text, country);

    if (!geocodedAddress) {
      return {
        street: text,
      };
    }

    const street = await getStreetAddressFromGeocodeResult(geocodedAddress);

    const postalCode = await getPostalCodeFromGeocodeResult(geocodedAddress);

    const city = getPostalAreaFromGeocodeResult(geocodedAddress);

    const { lat, lng } = getLatLng(geocodedAddress);

    const resolvedAddress: ResolvedAddress = {
      street: street,
      postalCode: postalCode,
      city: city,
      latitude: lat,
      longitude: lng,
    };

    return resolvedAddress;
  };

  const handleItemSelect = async (address: SearchItem) => {
    if (!address) return;

    let resolved: ResolvedAddress | undefined;

    if (address.id) {
      resolved = await resolveAddressWithIdSelect(address);
    } else if (address.label) {
      resolved = await resolveTextOnlyAddressSelect(address);
    }

    clearSuggestions();

    if (value !== (resolved?.street ?? '')) {
      setValue(resolved?.street ?? '', false);
    }

    onAddressResolved(resolved);
  };

  return (
    <SearchInput
      onItemSelect={handleItemSelect}
      onInputChange={handleInputChange}
      onInputClear={onInputClear}
      value={value}
      options={buildOptions(data)}
      loading={loading}
      caption={showMissingCoordsWarning ? tShared('addressMissingCoordinates') : undefined}
      label={tShared('address')}
      placeholder={tShared('address')}
    />
  );
};

export const AddressSearch = memo(AddressSearchComp);

const buildOptions = (data: Suggestion[]): SearchItem[] => {
  return data.map((suggestion) => {
    const {
      place_id,
      structured_formatting: { main_text, secondary_text },
      description,
    } = suggestion;

    return { label: main_text, id: place_id, name: secondary_text, description: description };
  });
};
