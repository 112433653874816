import { Block } from 'baseui/block';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'zustand';
import { useCaseRegistrationStoreContext } from '@@caseRegistration/CaseRegistrationStore';
import { useZonedDateUtils } from '@@shared/hooks/useZonedDateUtils';
import { ActorsEnum } from '@@shared/types';
import InfoBlock from '@@shared/utilityComponents/InfoBlock';
import { useStyletron } from 'baseui';

const CaseSummaryComp = () => {
  const tCaseReg = useTranslation('translation', { keyPrefix: 'caseRegistration' }).t;
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;

  const { formatDateTime } = useZonedDateUtils();

  const [css] = useStyletron();

  const store = useCaseRegistrationStoreContext();

  const incidentTime = useStore(store, (s) => s.incidentTime);
  const incidentAddressStreet = useStore(store, (s) => s.incidentAddressStreet);
  const incidentAddressPostalCode = useStore(store, (s) => s.incidentAddressPostalCode);
  const incidentAddressCity = useStore(store, (s) => s.incidentAddressCity);

  const vehicleRegistrationNumber = useStore(store, (s) => s.vehicleRegistrationNumber);
  const vehicleBrand = useStore(store, (s) => s.vehicleBrand);
  const vehicleModel = useStore(store, (s) => s.vehicleModel);
  const agreement = useStore(store, (s) => s.agreement);

  const requesterName = useStore(store, (s) => s.requesterName);
  const requesterPhone = useStore(store, (s) => s.requesterPhone);
  const ownerName = useStore(store, (s) => s.ownerName);
  const ownerPhone = useStore(store, (s) => s.ownerPhone);
  const callerName = useStore(store, (s) => s.callerName);
  const callerPhone = useStore(store, (s) => s.callerPhone);
  const contactPerson = useStore(store, (s) => s.contactPerson);

  const [contactName, contactPhone] =
    contactPerson === ActorsEnum.owner
      ? [ownerName, ownerPhone]
      : contactPerson === ActorsEnum.caller
      ? [callerName, callerPhone]
      : contactPerson === ActorsEnum.requester
      ? [requesterName, requesterPhone]
      : [];

  return (
    <Block
      minHeight="6em"
      padding="scale600"
      backgroundColor="backgroundTertiary"
      display="flex"
      justifyContent="space-between"
      flexWrap
      className={css({ gap: '2em' })}
    >
      <InfoBlock heading={tCaseReg('incidentTime')} paragraphs={[formatDateTime(incidentTime)]} />
      <InfoBlock heading={tCaseReg('regNr')} paragraphs={[vehicleRegistrationNumber]} />
      <InfoBlock heading={tShared('vehicle')} paragraphs={[vehicleBrand?.name, vehicleModel?.name]} />
      <InfoBlock heading={tShared('agreement')} paragraphs={[agreement?.name]} />
      <InfoBlock heading={tShared('contactInfo')} paragraphs={[contactName, contactPhone]} />
      <InfoBlock
        heading={tCaseReg('carLocation')}
        paragraphs={[incidentAddressStreet, `${incidentAddressPostalCode ?? ''} ${incidentAddressCity ?? ''}`]}
      />
    </Block>
  );
};

export const CaseSummary = memo(CaseSummaryComp);
