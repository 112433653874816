import { cloneDeep } from 'lodash-es';
import { ActorTypeEnum, CaseActorDto, CaseDto, CaseFieldDto } from '../api/api.generated';
import { getCurrentDate, parseMaybeJsonDate } from '../shared/functions/dateUtils';
import { getDialCodeForCountry, isNilOrEmptyString, isNumeric } from '../shared/functions/general';
import { ActorsEnum, CaseFormStatus, CaseStatusEnum, TransportToType, Waiting } from '../shared/types';
import { CaseRegistrationFormFields } from './caseRegistrationModels';

export function nameof(fieldName: keyof CaseRegistrationFormFields): string {
  return fieldName;
}

export function asKeyOf(obj: unknown, key: string): keyof CaseRegistrationFormFields {
  //if (!Object.getOwnPropertyNames(obj).includes(key)) throw `Invalid key: ${key}`;

  return key as keyof CaseRegistrationFormFields;
}

export function createInitialValues(caseDto: CaseDto, callCenterCountry: string): CaseRegistrationFormFields {
  const phoneNumberPrefix = getDialCodeForCountry(callCenterCountry);

  const { actors, incident, vehicle, insurance, agreement, workshop } = caseDto;

  const owner = actors && actors.find((a: CaseActorDto) => a.actorType === ActorTypeEnum.Owner);
  const caller = actors && actors.find((a: CaseActorDto) => a.actorType === ActorTypeEnum.Caller);
  const requester = actors && actors.find((a: CaseActorDto) => a.actorType === ActorTypeEnum.Driver);

  const formFields: CaseRegistrationFormFields = {
    caseId: caseDto.id ?? '',
    caseOriginCode: caseDto.caseOriginCode ?? '',
    contactPerson: owner?.contactPerson
      ? ActorsEnum.owner
      : requester?.contactPerson
      ? ActorsEnum.requester
      : caller?.contactPerson
      ? ActorsEnum.caller
      : '',
    visCaseId: caseDto.visCaseId?.toString() ?? '',

    ownerName: owner?.actor?.name ?? '',
    ownerEmail: owner?.actor?.email ?? '',
    ownerPhone: owner?.actor?.phoneNumber ?? phoneNumberPrefix,
    ownerAddressCity: owner?.actor?.location?.postalArea ?? '',
    ownerAddressCountry: owner?.actor?.location?.countryCode ?? callCenterCountry,
    ownerAddressPostalCode: owner?.actor?.location?.postalCode ?? '',
    ownerAddressStreet: owner?.actor?.location?.street ?? '',
    ownerAddressId: owner?.actor?.location?.id ?? '',
    ownerAddressLatitude: owner?.actor?.location?.latitude?.toString() ?? '',
    ownerAddressLongitude: owner?.actor?.location?.longitude?.toString() ?? '',
    ownerId: owner?.actor?.id ?? '',

    requesterName: requester?.actor?.name ?? '',
    requesterEmail: requester?.actor?.email ?? '',
    requesterPhone: requester?.actor?.phoneNumber ?? phoneNumberPrefix,
    requesterAddressCity: requester?.actor?.location?.postalArea ?? '',
    requesterAddressCountry: requester?.actor?.location?.countryCode ?? callCenterCountry,
    requesterAddressPostalCode: requester?.actor?.location?.postalCode ?? '',
    requesterAddressStreet: requester?.actor?.location?.street ?? '',
    requesterAddressId: requester?.actor?.location?.id ?? '',
    requesterAddressLatitude: requester?.actor?.location?.latitude?.toString() ?? '',
    requesterAddressLongitude: requester?.actor?.location?.longitude?.toString() ?? '',
    requesterId: requester?.actor?.id ?? '',
    requesterIs: requester?.driver
      ? ActorsEnum.requester
      : caller?.driver
      ? ActorsEnum.caller
      : owner?.driver
      ? ActorsEnum.owner
      : '',

    callerName: caller?.actor?.name ?? '',
    callerEmail: caller?.actor?.email ?? '',
    callerPhone: caller?.actor?.phoneNumber ?? phoneNumberPrefix,
    callerAddressCity: caller?.actor?.location?.postalArea ?? '',
    callerAddressCountry: caller?.actor?.location?.countryCode ?? callCenterCountry,
    callerAddressPostalCode: caller?.actor?.location?.postalCode ?? '',
    callerAddressStreet: caller?.actor?.location?.street ?? '',
    callerAddressId: caller?.actor?.location?.id ?? '',
    callerSameAsOwner: owner?.actor?.name || caller?.actor?.name ? caller?.actor?.name === owner?.actor?.name : false,
    callerAddressLatitude: caller?.actor?.location?.latitude?.toString() ?? '',
    callerAddressLongitude: caller?.actor?.location?.longitude?.toString() ?? '',
    callerId: caller?.actor?.id ?? '',

    incidentTime: incident?.time ? parseMaybeJsonDate(incident?.time) : getCurrentDate(),
    incidentAddressCity: incident?.location?.postalArea ?? '',
    incidentAddressCountry: incident?.location?.countryCode ?? callCenterCountry,
    incidentAddressPostalCode: incident?.location?.postalCode ?? '',
    incidentAddressStreet: incident?.location?.street ?? '',
    incidentAddressId: incident?.location?.id ?? '',
    incidentAddressComment: incident?.location?.description ?? '',
    incidentDescription: incident?.description ?? '',
    incidentTypeCode: incident?.incidentTypeCode ?? '',
    incidentDetailTypeCode: incident?.incidentDetailTypeCode ?? '',
    incidentWaitingInfo: incident?.waitingInfo ?? '',
    incidentWaitingInfoDescription: incident?.waitingInfoDescription ?? '',
    incidentKeyPlacementCode: incident?.keyPlacementCode ?? '',
    incidentWaitingField: incident?.keyPlacementCode ? Waiting.notWaiting : Waiting.waiting,

    incidentAddressLatitude: incident?.location?.latitude?.toString() ?? '',
    incidentAddressLongitude: incident?.location?.longitude?.toString() ?? '',

    vehicleRegistrationNumber: vehicle?.registrationNumber ?? '',
    vehicleOriginalDealerJe: '',
    vehicleOriginalDealerName: vehicle?.originalDealerName ?? '',
    vehicleFirstRegistrationDate: parseMaybeJsonDate(vehicle?.firstRegistrationDate),
    vehicleLastServiceLocationJe: '',
    vehicleLastServiceLocationName: vehicle?.lastServiceLocationName ?? '',
    vehicleLastServiceDate: parseMaybeJsonDate(vehicle?.lastServiceDate),
    vehicleServiceWarrantyValidTo: parseMaybeJsonDate(vehicle?.lastServiceValidUntilDate),
    vehicleServiceWarrantyMaximumTotalKm: vehicle?.lastServiceValidUntilKm?.toString() ?? '',
    vehicleCurrentKmSum: vehicle?.currentKmSum?.toString() ?? '',
    vehicleModelYear: vehicle?.modelYear?.toString() ?? '',
    vehicleFuelType: vehicle?.fuelType ?? '',
    vehicleBrand: vehicle?.brand,
    vehicleModel: vehicle?.model,
    vehicleAllowedWeight: vehicle?.maximumAllowableWeight?.toString() ?? '',
    vehicleChassis: vehicle?.vin ?? '',
    vehicleDriveTrain: vehicle?.driveTrain ?? '',
    vehicleWeight: vehicle?.weight?.toString() ?? '',
    vehicleLastServiceKmSum: vehicle?.lastServiceKmSum?.toString() ?? '',
    vehicleLastRegistrationDate: parseMaybeJsonDate(vehicle?.lastRegistrationDate),
    vehicleTypeCode: vehicle?.vehicleTypeCode ?? '',
    vehicleIsUsedCommercially: false,
    vehicleMakeModel: vehicle?.makeModel ?? '',

    workshop: workshop?.visId ? { id: workshop.visId ?? '', name: workshop.name ?? '' } : undefined,
    workshopOther: workshop?.visId ? '' : workshop?.name ?? '',
    workshopDescription: workshop?.description ?? '',
    workshopAddressId: workshop?.transportTo?.id ?? '',
    workshopAddressStreet: workshop?.transportTo?.street ?? '',
    workshopAddressCountry: workshop?.transportTo?.countryCode ?? callCenterCountry,
    workshopAddressCity: workshop?.transportTo?.postalArea ?? '',
    workshopAddressPostalCode: workshop?.transportTo?.postalCode ?? '',
    workshopAddressComment: workshop?.transportTo?.description ?? '',
    workshopAddressLatitude: workshop?.transportTo?.latitude?.toString() ?? '',
    workshopAddressLongitude: workshop?.transportTo?.longitude?.toString() ?? '',
    transportTo: workshop?.visId ? TransportToType.workshop : TransportToType.other,

    insuranceDeductible: insurance?.deductible?.toString() ?? '',
    insuranceReference: insurance?.reference ?? '',
    insuranceStatusCode: insurance?.statusCode ?? '',
    insuranceReadOnlyInfo: insurance?.readOnlyInfo ?? '',
    insuranceCompany: insurance?.insuranceCompany,
    insuranceType: insurance?.type ?? '',

    agreement: agreement,
    agreementInfo: {
      id: undefined,
      name: undefined,
      important: undefined,
      details: undefined,
      caseFields: [],
    },
    leasingTaker: caseDto.leasingTaker ?? '',

    callCenterCountry: caseDto.callCenterCountry ?? callCenterCountry,
  };

  return formFields;
}

export function getUpdatedModel(caseObject: CaseDto | undefined, values: CaseRegistrationFormFields): CaseDto {
  const updatedModel = cloneDeep(caseObject ?? {});

  const ownerActorDto: CaseActorDto = {
    actor: {
      id: emptyStringToUndefined(values.ownerId),
      email: emptyStringToUndefined(values.ownerEmail),
      location: {
        id: emptyStringToUndefined(values.ownerAddressId),
        countryCode: emptyStringToUndefined(values.ownerAddressCountry),
        postalArea: emptyStringToUndefined(values.ownerAddressCity),
        postalCode: emptyStringToUndefined(values.ownerAddressPostalCode),
        street: emptyStringToUndefined(values.ownerAddressStreet),
      },
      name: emptyStringToUndefined(values.ownerName),
      phoneNumber: emptyStringToUndefined(values.ownerPhone),
    },
    actorType: ActorTypeEnum.Owner,
    contactPerson: values.contactPerson === ActorsEnum.owner,
    driver: values.requesterIs === ActorsEnum.owner,
  };

  const callerActorDto: CaseActorDto = {
    actor: {
      id: emptyStringToUndefined(values.callerId),
      email: emptyStringToUndefined(values.callerSameAsOwner ? values.ownerEmail : values.callerEmail),
      location: {
        id: emptyStringToUndefined(values.callerAddressId),
        countryCode: emptyStringToUndefined(
          values.callerSameAsOwner ? values.ownerAddressCountry : values.callerAddressCountry
        ),
        postalArea: emptyStringToUndefined(
          values.callerSameAsOwner ? values.ownerAddressCity : values.callerAddressCity
        ),
        postalCode: emptyStringToUndefined(
          values.callerSameAsOwner ? values.ownerAddressPostalCode : values.callerAddressPostalCode
        ),
        street: emptyStringToUndefined(
          values.callerSameAsOwner ? values.ownerAddressStreet : values.callerAddressStreet
        ),
      },
      name: emptyStringToUndefined(values.callerSameAsOwner ? values.ownerName : values.callerName),
      phoneNumber: emptyStringToUndefined(values.callerSameAsOwner ? values.ownerPhone : values.callerPhone),
    },
    actorType: ActorTypeEnum.Caller,
    contactPerson: values.contactPerson === ActorsEnum.caller,
    driver: values.requesterIs === ActorsEnum.caller,
  };

  const requesterActorDto: CaseActorDto = {
    actor: {
      id: emptyStringToUndefined(values.requesterId),
      email: emptyStringToUndefined(
        values.requesterIs === ActorsEnum.owner
          ? values.ownerEmail
          : values.requesterIs === ActorsEnum.caller
          ? values.callerEmail
          : values.requesterEmail
      ),
      location: {
        id: emptyStringToUndefined(values.requesterAddressId),
        countryCode: emptyStringToUndefined(
          values.requesterIs === ActorsEnum.owner
            ? values.ownerAddressCountry
            : values.requesterIs === ActorsEnum.caller
            ? values.callerAddressCountry
            : values.requesterAddressCountry
        ),
        postalArea: emptyStringToUndefined(
          values.requesterIs === ActorsEnum.owner
            ? values.ownerAddressCity
            : values.requesterIs === ActorsEnum.caller
            ? values.callerAddressCity
            : values.requesterAddressCity
        ),
        postalCode: emptyStringToUndefined(
          values.requesterIs === ActorsEnum.owner
            ? values.ownerAddressPostalCode
            : values.requesterIs === ActorsEnum.caller
            ? values.callerAddressPostalCode
            : values.requesterAddressPostalCode
        ),
        street: emptyStringToUndefined(
          values.requesterIs === ActorsEnum.owner
            ? values.ownerAddressStreet
            : values.requesterIs === ActorsEnum.caller
            ? values.callerAddressStreet
            : values.requesterAddressStreet
        ),
      },
      name: emptyStringToUndefined(
        values.requesterIs === ActorsEnum.owner
          ? values.ownerName
          : values.requesterIs === ActorsEnum.caller
          ? values.callerName
          : values.requesterName
      ),
      phoneNumber: emptyStringToUndefined(
        values.requesterIs === ActorsEnum.owner
          ? values.ownerPhone
          : values.requesterIs === ActorsEnum.caller
          ? values.callerPhone
          : values.requesterPhone
      ),
    },
    actorType: ActorTypeEnum.Driver,
    contactPerson: values.contactPerson === ActorsEnum.requester,
    driver: values.requesterIs === ActorsEnum.requester,
  };

  updatedModel.actors = [ownerActorDto, callerActorDto, requesterActorDto];

  updatedModel.agreement = values.agreement;
  updatedModel.caseOriginCode = emptyStringToUndefined(values.caseOriginCode);

  updatedModel.incident = {
    description: emptyStringToUndefined(values.incidentDescription),
    incidentDetailTypeCode: emptyStringToUndefined(values.incidentDetailTypeCode),
    incidentTypeCode: emptyStringToUndefined(values.incidentTypeCode),
    location: {
      id: emptyStringToUndefined(values.incidentAddressId),
      countryCode: emptyStringToUndefined(values.incidentAddressCountry),
      description: emptyStringToUndefined(values.incidentAddressComment),
      latitude: emptyFloatToUndefined(values.incidentAddressLatitude),
      longitude: emptyFloatToUndefined(values.incidentAddressLongitude),
      postalArea: emptyStringToUndefined(values.incidentAddressCity),
      postalCode: emptyStringToUndefined(values.incidentAddressPostalCode),
      street: emptyStringToUndefined(values.incidentAddressStreet),
    },
    time: values.incidentTime,
    waitingInfo: emptyStringToUndefined(values.incidentWaitingInfo),
    waitingInfoDescription: emptyStringToUndefined(values.incidentWaitingInfoDescription),
    keyPlacementCode: emptyStringToUndefined(values.incidentKeyPlacementCode),
  };

  updatedModel.insurance = {
    id: caseObject?.insurance?.id,
    insuranceCompany: values.insuranceCompany,
    reference: emptyStringToUndefined(values.insuranceReference),
    type: emptyStringToUndefined(values.insuranceType),
    deductible: emptyNumberToUndefined(values.insuranceDeductible),
    statusCode: emptyStringToUndefined(values.insuranceStatusCode),
    readOnlyInfo: emptyStringToUndefined(values.insuranceReadOnlyInfo),
  };

  updatedModel.statusId = caseObject?.statusId;

  updatedModel.vehicle = {
    vin: emptyStringToUndefined(values.vehicleChassis),
    registrationNumber: emptyStringToUndefined(values.vehicleRegistrationNumber),
    vehicleTypeCode: emptyStringToUndefined(values.vehicleTypeCode),
    weight: emptyNumberToUndefined(values.vehicleWeight),
    maximumAllowableWeight: emptyNumberToUndefined(values.vehicleAllowedWeight),
    brand: values.vehicleBrand,
    model: values.vehicleModel,
    modelYear: emptyNumberToUndefined(values.vehicleModelYear),
    driveTrain: emptyStringToUndefined(values.vehicleDriveTrain),
    fuelType: emptyStringToUndefined(values.vehicleFuelType),
    isUsedCommercially: values.vehicleIsUsedCommercially,
    currentKmSum: emptyNumberToUndefined(values.vehicleCurrentKmSum),
    lastServiceKmSum: emptyNumberToUndefined(values.vehicleLastServiceKmSum),
    lastServiceDate: parseMaybeJsonDate(values.vehicleLastServiceDate),
    firstRegistrationDate: parseMaybeJsonDate(values.vehicleFirstRegistrationDate),
    lastRegistrationDate: parseMaybeJsonDate(values.vehicleLastRegistrationDate),
    originalDealerName: emptyStringToUndefined(values.vehicleOriginalDealerName),
    lastServiceValidUntilKm: emptyNumberToUndefined(values.vehicleServiceWarrantyMaximumTotalKm),
    lastServiceValidUntilDate: parseMaybeJsonDate(values.vehicleServiceWarrantyValidTo),
    lastServiceLocationVisId: emptyNumberToUndefined(values.vehicleLastServiceLocationJe),
    lastServiceLocationName: emptyStringToUndefined(values.vehicleLastServiceLocationName),
    makeModel: values.vehicleMakeModel,
  };

  updatedModel.workshop = {
    name:
      values.transportTo === TransportToType.workshop
        ? emptyStringToUndefined(values.workshop?.name ?? '')
        : values.workshopOther,
    description: emptyStringToUndefined(values.workshopDescription),
    visId: emptyStringToUndefined(values.workshop?.id ?? ''),
    transportTo: {
      id: emptyStringToUndefined(values.workshopAddressId),
      street: emptyStringToUndefined(values.workshopAddressStreet),
      postalArea: emptyStringToUndefined(values.workshopAddressCity),
      postalCode: emptyStringToUndefined(values.workshopAddressPostalCode),
      countryCode: emptyStringToUndefined(values.workshopAddressCountry),
      description: emptyStringToUndefined(values.workshopDescription),
      latitude: emptyFloatToUndefined(values.workshopAddressLatitude),
      longitude: emptyFloatToUndefined(values.workshopAddressLongitude),
    },
  };
  updatedModel.leasingTaker = emptyStringToUndefined(values.leasingTaker);

  return updatedModel;
}

export function missingRequiredFields(formFields: Partial<CaseRegistrationFormFields>): string[] {
  const missingFields: string[] = [];

  Object.entries(formFields).forEach(([key, value]) => {
    if (requiredFields.find((e) => e === key) && isNilOrEmptyString(value)) {
      if (key.includes('caller')) {
        if (!formFields.callerSameAsOwner) {
          missingFields.push(key);
        }
      } else if (key.includes('requester')) {
        if (formFields.requesterIs === ActorsEnum.requester) {
          missingFields.push(key);
        }
      } else if (key.includes('incidentWaitingInfo')) {
        if (formFields.incidentWaitingField === Waiting.waiting) {
          missingFields.push(key);
        }
      } else if (key.includes('incidentKeyPlacementCode')) {
        if (formFields.incidentWaitingField === Waiting.notWaiting) {
          missingFields.push(key);
        }
      } else {
        missingFields.push(key);
      }
    }
  });
  if (formFields.agreement?.name === undefined || formFields.agreement?.name === null) {
    missingFields.push('agreement');
  }
  if (formFields.agreementInfo?.caseFields) {
    formFields.agreementInfo?.caseFields.forEach((value: CaseFieldDto) => {
      if (value.requiredLevel === 'I' || value.requiredLevel === 'O') {
        const mappedValue = agreementRequiredMapping(value.caseFieldCode);
        if (mappedValue !== '' && isNilOrEmptyString(formFields[mappedValue as keyof CaseRegistrationFormFields])) {
          missingFields.push(mappedValue);
        }
      }
    });
  }

  return missingFields;
}

const requiredFields = [
  'ownerName',
  'ownerAddressCity',
  'ownerAddressStreet',
  'callerName',
  'callerPhone', //Ikke obligatorisk hvis eier er innringer
  'callerAddressCity',
  'callerAddressStreet',
  'requesterName', //Hvis havarist er annen obligatorisk
  'requesterPhone', //Hvis havarist er annen obligatorisk
  'incidentTime',
  'incidentWaitingKeyInfo',
  'incidentWaitingInfo',
  'incidentKeyPlacementCode',
  'incidentAddressCity',
  'incidentAddressCountry',
  'incidentAddressPostalCode',
  'vehicleModelYear',
  'vehicleFuelType',
  'vehicleBrand',
  'vehicleModel',
  'workshopName',
];

function agreementRequiredMapping(toMap?: string): string {
  switch (toMap) {
    case 'ForsteRegDato':
      return 'vehicleFirstRegistrationDate';
    case 'JEForsikringSelskap':
      return 'insuranceCompany';
    case 'KmStand':
      return 'vehicleCurrentKmSum';
    case 'SisteServiceForhandlerNavn':
      return 'vehicleLastServiceLocationName';
    case 'SisteServiceGyldigTilDato':
      return 'vehicleServiceWarrantyValidTo';
    case 'TransportTilNavn':
      return 'workshopName';
    default:
      return '';
  }
}

export function isFieldRequired(field: string, caseFields?: CaseFieldDto[]): boolean {
  const caseField = caseFields?.find(
    (value: CaseFieldDto) =>
      (value.requiredLevel === 'I' || value.requiredLevel === 'O') &&
      agreementRequiredMapping(value.caseFieldCode) === field
  );
  return !!caseField?.caseFieldCode;
}

export function getCaseFormStatus(statusId?: number, visCaseId?: number, completedAssistance?: boolean): string {
  if (!visCaseId) return CaseFormStatus.Create;

  if (completedAssistance) return CaseFormStatus.Closed;

  switch (statusId) {
    case CaseStatusEnum.Cancelled:
      return CaseFormStatus.Cancelled;
    case CaseStatusEnum.Transferred:
      return CaseFormStatus.Sent;
    case CaseStatusEnum.Closed:
      return CaseFormStatus.Closed;
    case CaseStatusEnum.Active:
    default:
      return CaseFormStatus.Update;
  }
}

function emptyStringToUndefined(input: string): string | undefined {
  return input.trim() === '' ? undefined : input;
}

function emptyNumberToUndefined(input: string): number | undefined {
  return isNumeric(input) ? parseInt(input) : undefined;
}

function emptyFloatToUndefined(input: string): number | undefined {
  return isNumeric(input) ? parseFloat(input) : undefined;
}
