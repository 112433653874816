import { useStyletron } from 'baseui';
import { memo, useEffect, useRef } from 'react';
import { TwoHalvesLayoutProps } from './TwoHalvesLayout';

const minDistanceToEdge = 600;
const snapToMiddleDistance = 25;
const draggableWidth = 10;
const ghostWidth = 4;
const lineWidth = 2;
const defaultSplitPercentage = 50;

export const SplitLayoutComp = ({ children }: TwoHalvesLayoutProps) => {
  const [css] = useStyletron();

  const isBeingDraggedRef = useRef(false);
  const draggableOffsetXRef = useRef(0);

  const draggableRef = useRef<HTMLDivElement | null>(null);
  const ghostRef = useRef<HTMLDivElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const leftChild = useRef<HTMLDivElement | null>(null);
  const rightChild = useRef<HTMLDivElement | null>(null);

  const isMounted = useRef(true);

  function endDrag() {
    const container = containerRef.current as HTMLDivElement;

    setIsBeingDragged(false);

    const draggableOffsetXMiddle = draggableOffsetXRef.current + draggableWidth / 2;

    const newGhostOffsetX = draggableOffsetXMiddle - ghostWidth / 2;

    if (ghostRef.current) {
      ghostRef.current.style.left = `${newGhostOffsetX}px`;
    }

    const newSplit = Math.round((newGhostOffsetX / container.clientWidth) * 100);

    setSplit(newSplit);
  }

  function setIsBeingDragged(enable: boolean) {
    isBeingDraggedRef.current = enable;
    if (overlayRef.current) {
      overlayRef.current.style.display = enable ? 'block' : 'none';
    }
    if (ghostRef.current) {
      ghostRef.current.style.visibility = enable ? 'visible' : 'hidden';
    }
  }

  function setSplit(split: number) {
    if (leftChild.current) {
      leftChild.current.style.flexBasis = `${split}%`;
    }

    if (rightChild.current) {
      rightChild.current.style.flexBasis = `${100 - split}%`;
    }
  }

  useEffect(() => {
    isMounted.current = true;

    const eventCtrl = new AbortController();
    const eventOptions = { signal: eventCtrl.signal };

    const draggable = draggableRef.current as HTMLDivElement;
    const container = containerRef.current as HTMLDivElement;

    const init = () => {
      const middleX = container.clientWidth / 2;
      const draggableOffsetX = middleX - draggableWidth / 2;
      const ghostOffsetX = middleX - ghostWidth / 2;

      draggableOffsetXRef.current = draggableOffsetX;

      if (draggableRef.current) {
        draggableRef.current.style.left = `${draggableOffsetX}px`;
      }

      if (ghostRef.current) {
        ghostRef.current.style.left = `${ghostOffsetX}px`;
      }

      setSplit(defaultSplitPercentage);
    };

    init();

    draggable.addEventListener(
      'mousedown',
      (e: MouseEvent) => {
        e.preventDefault();
        isBeingDraggedRef.current = true;
        setIsBeingDragged(true);
      },
      eventOptions
    );

    window.addEventListener(
      'mouseup',
      () => {
        if (isBeingDraggedRef.current) {
          endDrag();
        }
      },
      eventOptions
    );

    container.addEventListener(
      'mouseenter',
      (ev) => {
        if (ev.buttons !== 1 && isBeingDraggedRef.current) {
          endDrag();
        }
      },
      eventOptions
    );

    window.addEventListener(
      'mousemove',
      (ev) => {
        if (!isBeingDraggedRef.current) return;

        const middleX = container.clientWidth / 2;

        const containerBounds = container.getBoundingClientRect();

        const cursorX = Math.max(
          Math.min(containerBounds.right - minDistanceToEdge, ev.clientX),
          containerBounds.left + minDistanceToEdge
        );

        let newX = cursorX - containerBounds.left;

        const distanceToMiddle = Math.abs(middleX - newX);

        if (distanceToMiddle < snapToMiddleDistance) {
          newX = middleX;
        }

        const draggableOffsetX = newX - draggableWidth / 2;

        draggableOffsetXRef.current = draggableOffsetX;

        if (draggableRef.current) {
          draggableRef.current.style.left = `${draggableOffsetX}px`;
        }
      },
      eventOptions
    );

    window.addEventListener('resize', () => init(), eventOptions);

    return () => {
      isMounted.current = false;
      eventCtrl.abort();
    };
    // all deps are stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rootStyles = css({
    height: '100%',
    width: '100%',
    display: 'flex',
    position: 'relative',
  });

  const overlayStyles = css({
    height: '100%',
    position: 'absolute',
    width: '100%',
    display: 'none',
  });

  const draggableStyles = css({
    width: `${draggableWidth}px`,
    position: 'absolute',
    cursor: 'w-resize !important',
    height: '100%',
    padding: `1rem ${(draggableWidth - lineWidth) / 2}px`,
  });

  const ghostStyles = css({
    width: `${ghostWidth}px`,
    position: 'absolute',
    height: '100%',
    padding: `1rem ${lineWidth / 2}px`,
    visibility: 'hidden',
  });

  const splitterStyles = css({
    pointerEvents: 'none',
    width: `${lineWidth}px`,
    height: '100%',
    backgroundColor: '#555',
  });

  return (
    <div className={rootStyles} ref={containerRef}>
      <div ref={leftChild}>{children[0]}</div>
      <div ref={rightChild}>{children[1]}</div>

      <div className={draggableStyles} ref={draggableRef} id="draggableRef">
        <div className={splitterStyles} />
      </div>

      <div className={ghostStyles} ref={ghostRef}>
        <div className={splitterStyles} />
      </div>

      <div ref={overlayRef} className={overlayStyles} />
    </div>
  );
};

export const SplitLayout = memo(SplitLayoutComp);
