import { Radio } from 'baseui/radio';
import { Option } from 'baseui/select';
import { useFormikContext } from 'formik';
import { toString } from 'lodash-es';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNearbyRetailersWithDistanceQuery } from '../../api/queries/useRetailerQueries';
import { FastInputField as InputField } from '../../shared/formFields/InputField';
import { FastRadioGroupField as RadioGroupField } from '../../shared/formFields/RadioGroupField';
import { FastSelectField as SelectField } from '../../shared/formFields/SelectField';
import { FastTextareaField as TextareaField } from '../../shared/formFields/TextareaField';
import { useUser } from '../../shared/hooks/useUser';
import { TransportToType } from '../../shared/types';
import FormGroup from '../../shared/utilityComponents/FormGroup';
import { isFieldRequired, nameof } from '../caseRegistrationFunctions';
import { CaseRegistrationFormFields } from '../caseRegistrationModels';
import { LocationForm } from './LocationForm';

function TransportFormGroupComp() {
  const tTransport = useTranslation('translation', { keyPrefix: 'transportForm' }).t;

  const { callCenterCountry } = useUser();

  const { values, setValues } = useFormikContext<CaseRegistrationFormFields>();

  const { transportTo, incidentAddressLatitude, incidentAddressLongitude, workshop } = values;

  const requiredFieldsAgreement = values.agreementInfo?.caseFields;

  const [retailersQueryEnabled, setRetailersQueryEnabled] = useState(false);

  const {
    data: workshops,
    error: workshopsError,
    isFetching,
  } = useNearbyRetailersWithDistanceQuery(incidentAddressLatitude, incidentAddressLongitude, retailersQueryEnabled);

  // Changed via event flags:
  const [transportTypeChanged, setTransportTypeChanged] = useState(false);
  const [workshopChanged, setWorkshopChanged] = useState(false);

  function handleTransportToChange() {
    setTransportTypeChanged(true);
  }

  function handleWorkshopChange() {
    setWorkshopChanged(true);
  }

  function handleWorkshopSelectOpen() {
    setRetailersQueryEnabled(true);
  }

  // TODO: Consider saving addresses in different fields in order to not
  // always have to clear the address fields when changing transport type
  useEffect(() => {
    if (transportTypeChanged) {
      setTransportTypeChanged(false);

      const newWorkshopValues: Partial<CaseRegistrationFormFields> = {
        workshop: undefined,
        workshopOther: '',
        workshopAddressStreet: '',
        workshopAddressPostalCode: '',
        workshopAddressCity: '',
        workshopAddressCountry: callCenterCountry,
        workshopAddressLatitude: '',
        workshopAddressLongitude: '',
      };

      setValues((v) => ({ ...v, ...newWorkshopValues }));
    }
  }, [transportTypeChanged, transportTo, callCenterCountry, setValues]);

  useEffect(() => {
    if (workshopChanged) {
      setWorkshopChanged(false);

      const newWorkshopValues: Partial<CaseRegistrationFormFields> = {
        workshopOther: '',
        workshopAddressStreet: toString(workshop?.address?.address),
        workshopAddressPostalCode: toString(workshop?.address?.postalCode),
        workshopAddressCity: toString(workshop?.address?.postalPlace),
        workshopAddressCountry: toString(workshop?.address?.country),
        workshopAddressLatitude: toString(workshop?.address?.latitude),
        workshopAddressLongitude: toString(workshop?.address?.longitude),
      };

      setValues((v) => ({ ...v, ...newWorkshopValues }));
    }
  }, [workshopChanged, workshop, setValues]);

  const getLabel = ({ option }: Option) => {
    return option?.distance?.isAccurate ? (
      <>{`${option?.name} (${option?.distance?.displayValue})`}</>
    ) : (
      <>{`${option?.name}`}</>
    );
  };

  return (
    <FormGroup label={tTransport('workshop')}>
      <RadioGroupField
        label={tTransport('chooseTransport')}
        name={nameof('transportTo')}
        onChange={handleTransportToChange}
        deps={[incidentAddressLatitude, incidentAddressLongitude]}
      >
        <Radio value={TransportToType.workshop} disabled={!incidentAddressLatitude || !incidentAddressLongitude}>
          {tTransport('volvoWorkshop')}
        </Radio>
        <Radio value={TransportToType.other}>{tTransport('otherAddress')}</Radio>
      </RadioGroupField>

      {transportTo === TransportToType.workshop ? (
        <>
          <SelectField
            label={
              isFieldRequired('workshopName', requiredFieldsAgreement)
                ? `${tTransport('workshop')}*`
                : tTransport('workshop')
            }
            name={nameof('workshop')}
            bindToObject
            options={workshopsError ? [{ name: tTransport('workshopsError'), disabled: true }] : workshops ?? []}
            getOptionLabel={getLabel}
            onChange={handleWorkshopChange}
            onOpen={handleWorkshopSelectOpen}
            isLoading={isFetching}
          />
          <LocationForm mapping="workshop" readOnly />
          <TextareaField name={nameof('workshopDescription')} label={tTransport('description')} />
        </>
      ) : (
        <>
          <InputField
            name={nameof('workshopOther')}
            label={
              isFieldRequired('workshopName', requiredFieldsAgreement)
                ? `${tTransport('actorName')}*`
                : tTransport('actorName')
            }
          />
          <LocationForm mapping="workshop" allowSearch />
          <TextareaField name={nameof('workshopDescription')} label={tTransport('description')} />
        </>
      )}
    </FormGroup>
  );
}

export const TransportFormGroup = memo(TransportFormGroupComp);
