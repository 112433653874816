import { ensureIsDate } from '@@shared/functions/dateUtils';
import { CaseSearchResultDto } from '../api/api.generated';
import { CASE_STATUS_TO_TAG_KEY } from '../shared/constants';
import { joinToString } from '../shared/functions/general';
import { CaseSearchResultItem } from './caseSearch.models';

export function mapCaseSearchDtoToItem(items: CaseSearchResultDto[] | undefined): CaseSearchResultItem[] {
  if (!items) return [];

  return items.map((item) => {
    const resultItem: CaseSearchResultItem = {
      visCaseId: item.visCaseId,
      registrationNumber: item.registrationNumber,
      stateOfCase: CASE_STATUS_TO_TAG_KEY[item.stateOfCase ?? ''],
      incidentDate: ensureIsDate(item.incidentDateTime),
      incidentCityAndCountry: joinToString([item.incidentPostalArea, item.incidentCountry]),
      incidentReason: item.incidentType,
      makeAndModel: joinToString([item.vehicleBrand, item.vehicledModel], '/'),
      owner: item.owner,
      workshop: item.processingLocation?.trim(),
      callCenterCountry: item.callCenterCountry,
    };

    return resultItem;
  });
}
