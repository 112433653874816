import { IPublicClientApplication } from '@azure/msal-browser';
import { appConfig } from '../config/appConfig';

export interface IApiConfig {
  getAuthorizationToken(): Promise<string>;
}

export const getApiConfig = (msalInstance: IPublicClientApplication): IApiConfig => {
  const getAuthorizationToken = async () => {
    const account = msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
    try {
      const apiToken = await msalInstance.acquireTokenSilent({
        account,
        scopes: [appConfig.apiAuthConfig],
        forceRefresh: false,
        redirectUri: `${window.location.origin}${appConfig.publicUrl}`,
      });
      return `Bearer ${apiToken.accessToken}`;
    } catch (error) {
      await msalInstance.acquireTokenRedirect({
        account,
        scopes: [appConfig.apiAuthConfig],
        redirectUri: `${window.location.origin}${appConfig.publicUrl}`,
      });
      throw error;
    }
  };
  return { getAuthorizationToken };
};
