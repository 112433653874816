import { ALIGN, Radio, RadioGroup } from 'baseui/radio';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Banner } from '../../shared/info/Banner';
import { NoteComponent } from './NoteComponent';
import { SmsComponent } from './SmsComponent';

enum communicationEnum {
  NOTE = '0',
  SMS = '1',
}

interface CommunicationProps {
  caseId: string;
}

function CommunicationComponentComp({ caseId }: CommunicationProps) {
  const [value, setValue] = useState<string>();
  const tCommunication = useTranslation('translation', { keyPrefix: 'communicationComponent' }).t;

  return (
    <>
      {!caseId ? <Banner kind="info" text={tCommunication('disabledMessage')} closeable /> : null}

      <RadioGroup
        disabled={!caseId}
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        align={ALIGN.horizontal}
      >
        <Radio value={communicationEnum.NOTE}>{tCommunication('note')}</Radio>
        <Radio value={communicationEnum.SMS}>SMS</Radio>
      </RadioGroup>
      {value === communicationEnum.NOTE ? <NoteComponent /> : value === communicationEnum.SMS ? <SmsComponent /> : null}
    </>
  );
}

export const CommunicationComponent = memo(CommunicationComponentComp);
