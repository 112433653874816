import { DatePicker, DatepickerProps } from 'baseui/datepicker';
import { BaseInput } from 'baseui/input';
import { FC, forwardRef } from 'react';
import { useForwardedRef } from '@@shared/hooks/useForwardRef';

// https://github.com/uber/baseweb/issues/3755#issuecomment-868966102
const DatePickerNoMask: FC<DatepickerProps> = (props: DatepickerProps) => {
  const { overrides } = props;

  const noMaskInputOverrides = overrides ?? {};

  noMaskInputOverrides.Input = {
    props: {
      overrides: {
        Input: {
          component: ForwardedInput,
        },
      },
    },
  };

  return <DatePicker {...props} overrides={noMaskInputOverrides} />;
};

export default DatePickerNoMask;

// https://stackoverflow.com/questions/73015696/whats-the-difference-between-reacts-forwardedref-and-refobject
const ForwardedInput = forwardRef<HTMLInputElement | HTMLTextAreaElement>(function ForwardedInput(props, ref) {
  const innerRef = useForwardedRef<HTMLInputElement | HTMLTextAreaElement>(ref);

  return <BaseInput {...props} inputRef={innerRef} />;
});
