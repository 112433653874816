import { useQuery } from '@tanstack/react-query';
import { CaseCoverageStatusRequest, ComboboxItem } from '../api.generated';
import { useApiClient } from '../apiClientContext';

export const useAgreementsQuery = () => {
  const { agreementClient } = useApiClient();

  return useQuery(['Agreements'], async () => {
    return await agreementClient.agreementAll();
  });
};

export const useAgreementQuery = (agreementCodeId: ComboboxItem['id']) => {
  const { agreementClient } = useApiClient();
  return useQuery(
    ['Agreement', agreementCodeId ?? ''],
    async () => {
      return await agreementClient.agreement(agreementCodeId ?? '');
    },
    { enabled: !!agreementCodeId }
  );
};

export const useCalculateCaseCoverageStatus = (statusRequest: CaseCoverageStatusRequest) => {
  const { agreementClient } = useApiClient();

  return useQuery(['CalculateCaseCoverageStatus', statusRequest ?? {}], async () => {
    return await agreementClient.calculateCaseCoverageStatus(statusRequest ?? undefined);
  });
};
