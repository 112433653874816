import { useMsal } from '@azure/msal-react';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { KIND } from 'baseui/button';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ROLE, SIZE } from 'baseui/modal';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { useStore } from 'zustand';
import { useCaseRegistrationStoreContext } from '@@caseRegistration/CaseRegistrationStore';
import { AddressDto, GeolocationReceived } from '../../api/api.generated';
import { appConfig } from '../../config/appConfig';
import { useSnackbarRef } from '../../shared/hooks/useSnackbarRef';
import { CaseRegistrationFormFields } from '../caseRegistrationModels';

const GeoLinkListenerComp = () => {
  const [confirmAddressModalOpen, setConfirmAddressModalOpen] = useState(false);
  const [updateIncidentLocation, setUpdateIncidentLocation] = useState(false);
  const [newIncidentAddress, setNewIncidentAddress] = useState<AddressDto | undefined>(undefined);

  const connectionRef = useRef<HubConnection | null>(null);

  const { instance } = useMsal();
  const enqueue = useSnackbarRef();

  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tRequester = useTranslation('translation', { keyPrefix: 'requesterForm' }).t;

  const store = useCaseRegistrationStoreContext();

  const visCaseId = useStore(store, (s) => s.visCaseId);
  const incidentAddressCity = useStore(store, (s) => s.incidentAddressCity);
  const incidentAddressComment = useStore(store, (s) => s.incidentAddressComment);
  const incidentAddressPostalCode = useStore(store, (s) => s.incidentAddressPostalCode);
  const incidentAddressStreet = useStore(store, (s) => s.incidentAddressStreet);

  const setFormikValues = useStore(store, (s) => s.formikHelpers?.setValues);

  const isIncidentAddressEmpty = !(
    !!incidentAddressCity ||
    !!incidentAddressComment ||
    !!incidentAddressPostalCode ||
    !!incidentAddressStreet
  );

  const handleNewGeolocation = useCallback(
    (message: GeolocationReceived) => {
      if (message.oppdragId && message.oppdragId.toString() === visCaseId) {
        setNewIncidentAddress(message.addressDto);

        if (isIncidentAddressEmpty) {
          setUpdateIncidentLocation(true);
        } else {
          setConfirmAddressModalOpen(true);
        }
      }
    },
    [visCaseId, isIncidentAddressEmpty]
  );

  useEffect(() => {
    const getToken = async () => {
      const account = instance.getActiveAccount() || instance.getAllAccounts()[0];
      const tokenResponse = await instance.acquireTokenSilent({
        account,
        scopes: [appConfig.apiAuthConfig],
        forceRefresh: false,
        redirectUri: `${window.location.origin}${appConfig.publicUrl}`,
      });

      return tokenResponse.accessToken;
    };

    const connection = connectionRef.current;

    if (!connection) {
      getToken().then((accessToken) => {
        const newConnection = new HubConnectionBuilder()
          .withUrl(`${appConfig.apiBaseURL}/hubs/case`, {
            accessTokenFactory: async () => getToken(),
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .configureLogging(LogLevel.Warning)
          .withAutomaticReconnect()
          .build();

        newConnection.start().catch((e: unknown) => {
          // It was probably just stopped during effect cleanup
          if (e instanceof Error && (e as Error).message === 'The connection was stopped during negotiation.') {
            return;
          }

          enqueue({
            message: `${tShared('error')}: ${e}`,
            startEnhancer: () => <MdError size={22} />,
          });
        });

        connectionRef.current = newConnection;
      });
    }

    return () => {
      const connection = connectionRef.current;
      if (connection) {
        connection
          .stop()
          .catch((e: unknown) => {
            enqueue({
              message: `${tShared('error')}: ${e}`,
              startEnhancer: () => <MdError size={22} />,
            });
          })
          .finally(() => {
            connectionRef.current = null;
          });
      }
    };
  }, [enqueue, instance, tShared]);

  useEffect(() => {
    const connection = connectionRef.current;

    if (connection) {
      connection.on('GeolocationReceived', (message: GeolocationReceived) => handleNewGeolocation(message));
    }

    return () => {
      if (connection) {
        connection.off('GeolocationReceived');
      }
    };
  }, [handleNewGeolocation]);

  useEffect(() => {
    if (newIncidentAddress && updateIncidentLocation) {
      const newFieldValues: Partial<CaseRegistrationFormFields> = {};

      if (newIncidentAddress.address) newFieldValues.incidentAddressStreet = newIncidentAddress.address;
      if (newIncidentAddress.postalCode) newFieldValues.incidentAddressPostalCode = newIncidentAddress.postalCode;
      if (newIncidentAddress.postalPlace) newFieldValues.incidentAddressCity = newIncidentAddress.postalPlace;
      if (newIncidentAddress.country) newFieldValues.incidentAddressCountry = newIncidentAddress.country;
      if (newIncidentAddress.latitude) newFieldValues.incidentAddressLatitude = newIncidentAddress.latitude.toString();
      if (newIncidentAddress.longitude)
        newFieldValues.incidentAddressLongitude = newIncidentAddress.longitude.toString();

      setUpdateIncidentLocation(false);
      setNewIncidentAddress(undefined);
      setConfirmAddressModalOpen(false);

      setFormikValues && setFormikValues((v) => ({ ...v, ...newFieldValues }));

      enqueue({
        message: tRequester('gotGeolocationAndSaved'),
        startEnhancer: () => <MdCheckCircle size={22} />,
      });
    }
  }, [updateIncidentLocation, newIncidentAddress, enqueue, tRequester, setFormikValues]);

  return (
    <Modal
      onClose={() => setConfirmAddressModalOpen(false)}
      closeable
      isOpen={confirmAddressModalOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>{tRequester('geoLinkModalHeader')}</ModalHeader>
      <ModalBody>{tRequester('geoLinkModalBody')}</ModalBody>
      <ModalFooter>
        <ModalButton kind={KIND.secondary} onClick={() => setConfirmAddressModalOpen(false)}>
          {tShared('cancel')}
        </ModalButton>
        <ModalButton onClick={() => setUpdateIncidentLocation(true)}>
          {tRequester('replaceAddressConfirmationButton')}
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export const GeoLinkListener = memo(GeoLinkListenerComp);
