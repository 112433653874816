import { FormControl } from 'baseui/form-control';
import { FastField, Field, FieldProps, FieldValidator } from 'formik';
import { FakeFormikEvent } from '../types';
import { DatepickerProps } from 'baseui/datepicker';
import { customShouldComponentUpdate } from './fastFormFieldFunctions';
import DatePickerExtra from '../inputs/DatePickerExtra';

type SafeDatePickerProps = Omit<DatepickerProps, 'value' | 'onChange'>;

interface CommonDateFieldProps {
  label: string;
  noFuture?: boolean;
}

interface DateFormControlProps extends CommonDateFieldProps, SafeDatePickerProps {
  fieldProps: FieldProps;
}

export interface DateFieldProps extends CommonDateFieldProps, SafeDatePickerProps {
  name: string;
  validate?: FieldValidator;
}

function DateFormControl(props: DateFormControlProps) {
  const { fieldProps, label, disabled, noFuture, ...restOfProps } = props;

  const { field, meta } = fieldProps;

  const showError = meta.touched && !!meta.error;

  return (
    <FormControl label={label} disabled={disabled} error={showError && meta.error}>
      <DatePickerExtra
        value={field.value}
        onChange={({ date }) => {
          const newValue = Array.isArray(date) ? date[0] : date;
          field.onChange(new FakeFormikEvent(field.name, newValue));
        }}
        useZonedTime
        maxDate={noFuture ? new Date() : undefined}
        formatString="dd.MM.yyyy"
        placeholder="dd.mm.yyyy"
        disabled={disabled}
        {...restOfProps}
      />
    </FormControl>
  );
}

export function FastDateField(props: DateFieldProps) {
  const { name, validate, ...restOfProps } = props;

  const depProps = { disabled: props.disabled, required: props.required };

  return (
    <FastField name={name} validate={validate} shouldUpdate={customShouldComponentUpdate} {...depProps}>
      {(fieldProps: FieldProps) => <DateFormControl {...restOfProps} fieldProps={fieldProps} />}
    </FastField>
  );
}

export default function DateField(props: DateFieldProps) {
  const { name, validate, ...restOfProps } = props;

  return (
    <Field name={name} validate={validate}>
      {(fieldProps: FieldProps) => <DateFormControl {...restOfProps} fieldProps={fieldProps} />}
    </Field>
  );
}
