import { toString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { ApiException } from '@@api/api.generated';

export const ApiErrorMessage = ({ error }: { error: unknown }) => {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tError = useTranslation('translation', { keyPrefix: 'error' }).t;

  const errorMessage = ApiException.isApiException(error)
    ? toString((error as ApiException).response)
    : tShared('unknownError');

  const splits = errorMessage.split('\n');

  if (splits.length === 1) {
    return <>{errorMessage}</>;
  }

  return (
    <div>
      <span>{tError('errorPreText')}</span>
      <ul style={{ padding: 0 }}>
        {splits.map((s) => (
          <li key={s}>{s}</li>
        ))}
      </ul>
    </div>
  );
};
