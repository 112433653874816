import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { AppConfig } from '../config/appConfig';

let msalInstance: PublicClientApplication;

export function configureMsal(appConfig: AppConfig) {
  const { authConfig } = appConfig;

  const msalConfig: Configuration = {
    auth: {
      clientId: authConfig.clientId,
      authority: authConfig.authority,
    },
    cache: {
      cacheLocation: authConfig.cacheLocation,
    },
  };

  msalInstance = new PublicClientApplication(msalConfig);
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProviderComponent = ({ children }: AuthProviderProps) => {
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

export default React.memo(AuthProviderComponent);
