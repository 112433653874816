import { FormControl } from 'baseui/form-control';
import { FastField, Field, FieldInputProps, FieldProps, FieldValidator } from 'formik';
import DebouncedTextarea, { DebouncedTextareaProps } from '../inputs/DebouncedTextarea';
import { ChangeEvent, useRef, KeyboardEvent, KeyboardEventHandler, useState, useEffect } from 'react';
import { customShouldComponentUpdate } from './fastFormFieldFunctions';
import { FakeBlurEvent, FakeFormikEvent } from '../types';
import { toString } from 'lodash-es';

type SafeTextareaProps = Omit<DebouncedTextareaProps, 'value' | 'error' | 'onChange' | 'onBlur' | 'onKeyPress'>;

interface CommonTextareaFieldProps {
  label: string;
  onChange?: (v: string | undefined) => void;
  onBlur?: () => void;
  onKeyPress?: KeyboardEventHandler;
}

interface TextareaFormControlProps extends CommonTextareaFieldProps, SafeTextareaProps {
  fieldProps: FieldProps;
}

export interface TextareaFieldProps extends CommonTextareaFieldProps, SafeTextareaProps {
  name: string;
  validate?: FieldValidator;
}

export interface TextareaFastFieldProps extends TextareaFieldProps {
  deps?: unknown[];
}

function TextareaFormControl(props: TextareaFormControlProps) {
  const { fieldProps, label, disabled, maxLength, onChange, onBlur, onKeyPress, ...restOfProps } = props;

  const { field, meta } = fieldProps;

  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const commitOnChange = props.debounceMs !== null && props.debounceMs !== undefined;

  const showError = meta.touched && !!meta.error;
  const fieldValueLength = toString(field.value).length;

  const [counter, setCounter] = useState(fieldValueLength);

  useEffect(() => {
    setCounter(fieldValueLength);
  }, [fieldValueLength]);

  function smartCommitValue(inputValue: string | undefined, field: FieldInputProps<unknown>): void {
    if (inputValue !== field.value) {
      field.onChange(new FakeFormikEvent(field.name, inputValue));

      if (onChange) onChange(inputValue);
    }
  }

  return (
    <FormControl
      label={label}
      disabled={disabled}
      error={showError && meta.error}
      caption={maxLength ? counter + '/' + maxLength : undefined}
    >
      <DebouncedTextarea
        inputRef={inputRef}
        value={field.value}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          if (commitOnChange) {
            const currentTextareaValue = e.target.value;

            smartCommitValue(currentTextareaValue, field);
          }
          setCounter(e.target.value.length);
        }}
        onBlur={() => {
          if (!commitOnChange) {
            const currentTextareaValue = inputRef?.current?.value;

            smartCommitValue(currentTextareaValue, field);
          }

          setTimeout(() => {
            field.onBlur(new FakeBlurEvent(field.name));
          });

          if (onBlur) onBlur();
        }}
        onKeyPress={(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            const currentTextareaValue = inputRef?.current?.value;

            smartCommitValue(currentTextareaValue, field);
          }

          if (onKeyPress) onKeyPress(e);
        }}
        error={showError}
        clearOnEscape
        autoComplete="remove"
        disabled={disabled}
        maxLength={maxLength}
        {...restOfProps}
      />
    </FormControl>
  );
}

export function FastTextareaField(props: TextareaFastFieldProps) {
  const { name, validate, deps, ...restOfProps } = props;

  const fieldDeps = [props.disabled, props.required, ...(deps ?? [])];

  return (
    <FastField name={name} validate={validate} shouldUpdate={customShouldComponentUpdate} deps={fieldDeps}>
      {(fieldProps: FieldProps) => <TextareaFormControl {...restOfProps} fieldProps={fieldProps} />}
    </FastField>
  );
}

export default function TextareaField(props: TextareaFieldProps) {
  const { name, validate, ...restOfProps } = props;

  return (
    <Field name={name} validate={validate}>
      {(fieldProps: FieldProps) => <TextareaFormControl {...restOfProps} fieldProps={fieldProps} />}
    </Field>
  );
}
