import { useStyletron, withStyle } from 'baseui';
import { Block } from 'baseui/block';
import { StyledBodyCell } from 'baseui/table-grid';
import { PLACEMENT, StatefulTooltip } from 'baseui/tooltip';
import { useTranslation } from 'react-i18next';
import { FaAt, FaChevronDown, FaChevronUp, FaDatabase, FaEnvelope, FaFax, FaNewspaper } from 'react-icons/fa';
import { MdCheckCircle, MdPhone, MdRadio, MdRadioButtonUnchecked, MdSms, MdSupportAgent, MdWeb } from 'react-icons/md';
import { grays } from '@@styles/appTheme';
import { strip } from '../shared/functions/general';
import { useZonedDateUtils } from '../shared/hooks/useZonedDateUtils';
import HistoryService from './HistoryService';
import { ExtendedCaseAction } from './types';

interface HistoryRowProps {
  row: ExtendedCaseAction | undefined;
  onExpandRow: VoidFunction;
  expanded: boolean;
  rowIndex: number;
}

const HistoryRow = ({ row, expanded, onExpandRow, rowIndex }: HistoryRowProps) => {
  const [css] = useStyletron();

  const { formatDateTime } = useZonedDateUtils();

  const { t } = useTranslation('translation', { keyPrefix: 'caseHistory' });

  if (!row) return null;

  const backgroundColor = rowIndex % 2 ? grays.gray50 : 'unset';

  const CenteredBodyCell = withStyle(StyledBodyCell, {
    display: 'flex',
    alignItems: 'top',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    whiteSpace: !expanded ? 'nowrap' : 'pre-wrap',
    overflowY: !expanded ? 'hidden' : 'auto',
    overflowX: 'hidden',
    maxHeight: '160px',
    backgroundColor: backgroundColor,
  });

  const BodyCellLongtext = withStyle(CenteredBodyCell, {
    overflowX: !expanded ? 'hidden' : 'auto',
  });

  return (
    <div role="row" className={css({ display: 'contents' })} onClick={onExpandRow}>
      <CenteredBodyCell>{formatDateTime(row.registeredDateTime)}</CenteredBodyCell>
      <CenteredBodyCell>{row.distributedBy}</CenteredBodyCell>
      <CenteredBodyCell>{row.visLoggTypeName}</CenteredBodyCell>
      <StatefulTooltip
        content={() => row.source && <Block padding={'5px'}>{getSource(row.source)}</Block>}
        placement={PLACEMENT.top}
        showArrow
      >
        <CenteredBodyCell>{mapSource(row.source as Sources)}</CenteredBodyCell>
      </StatefulTooltip>
      <CenteredBodyCell>{row.responsible}</CenteredBodyCell>

      <BodyCellLongtext>{strip(row.description)}</BodyCellLongtext>

      <CenteredBodyCell>{row.visLoggServiceName}</CenteredBodyCell>

      <StatefulTooltip
        content={() => <Block padding={'5px'}>{row.completedDateTime ? t('done') : t('notDone')}</Block>}
        placement={PLACEMENT.top}
        showArrow
      >
        <CenteredBodyCell>
          {row.completedDateTime ? <MdCheckCircle fontSize={20} /> : <MdRadioButtonUnchecked fontSize={20} />}
        </CenteredBodyCell>
      </StatefulTooltip>
      <CenteredBodyCell>{expanded ? <FaChevronUp /> : <FaChevronDown />}</CenteredBodyCell>
      {expanded && <HistoryService service={row} backgroundColor={backgroundColor} />}
    </div>
  );
};

enum Sources {
  API = 'A',
  BREV = 'B',
  EMAIL = 'E',
  FAX = 'F',
  INTERN_REG = 'I',
  RADIO = 'R',
  SMS = 'S',
  TELEFON = 'T',
  'VIS' = 'V',
  WEB = 'W',
}

const mapSource = (source: Sources): JSX.Element => {
  switch (source) {
    case Sources.API:
      return <FaDatabase fontSize={20} />;
    case Sources.BREV:
      return <FaEnvelope fontSize={20} />;
    case Sources.EMAIL:
      return <FaAt fontSize={20} />;
    case Sources.FAX:
      return <FaFax fontSize={20} />;
    case Sources.INTERN_REG:
      return <FaNewspaper fontSize={20} />;
    case Sources.RADIO:
      return <MdRadio fontSize={20} />;
    case Sources.SMS:
      return <MdSms fontSize={20} />;
    case Sources.TELEFON:
      return <MdPhone fontSize={20} />;
    case Sources.VIS:
      return <MdSupportAgent fontSize={20} />;
    case Sources.WEB:
      return <MdWeb fontSize={20} />;
  }
};

const getSource = (value: string | undefined) => {
  return Object.entries(Sources).find(([, val]) => val === value)?.[0];
};

export default HistoryRow;
