import { expandBorderStyles as bwExpandBorderStyles } from 'baseui/styles';
import { Properties } from 'styletron-standard';

export function expandBorderStyles(borderShortHand: string) {
  if (!borderShortHand) return undefined;

  const parts = borderShortHand.split(' ');

  if (parts.length === 3) {
    const [borderWidth, borderStyle, borderColor] = parts;

    const borderLeftStyle: Properties['borderLeftStyle'] = borderStyle as unknown as Properties['borderLeftStyle'];

    return bwExpandBorderStyles({
      borderWidth,
      borderStyle: borderLeftStyle,
      borderColor,
    });
  } else {
    const border = parts[0];

    const borderLeftStyle: Properties['borderLeftStyle'] = border as unknown as Properties['borderLeftStyle'];

    return bwExpandBorderStyles({
      borderWidth: undefined,
      borderStyle: borderLeftStyle,
      borderColor: undefined,
    });
  }
}

export function expandBorderRadius(borderRadius: string | number): {
  borderTopLeftRadius: string | undefined;
  borderTopRightRadius: string | undefined;
  borderBottomLeftRadius: string | undefined;
  borderBottomRightRadius: string | undefined;
} {
  const borderRadiusValue = borderRadius.toString();

  return {
    borderTopLeftRadius: borderRadiusValue,
    borderTopRightRadius: borderRadiusValue,
    borderBottomLeftRadius: borderRadiusValue,
    borderBottomRightRadius: borderRadiusValue,
  };
}

export function expandMargin(margin: string | number | string[] | number[]): {
  marginTop: string | undefined;
  marginRight: string | undefined;
  marginBottom: string | undefined;
  marginLeft: string | undefined;
} {
  const marginAsArray = (Array.isArray(margin) ? margin : [margin]).map((x) => x.toString());

  switch (marginAsArray.length) {
    case 1:
      return {
        marginTop: marginAsArray[0],
        marginRight: marginAsArray[0],
        marginBottom: marginAsArray[0],
        marginLeft: marginAsArray[0],
      };
    case 2:
      return {
        marginTop: marginAsArray[0],
        marginRight: marginAsArray[1],
        marginBottom: marginAsArray[0],
        marginLeft: marginAsArray[1],
      };
    case 3:
      return {
        marginTop: marginAsArray[0],
        marginRight: marginAsArray[1],
        marginBottom: marginAsArray[0],
        marginLeft: marginAsArray[2],
      };
    case 4:
      return {
        marginTop: marginAsArray[0],
        marginRight: marginAsArray[1],
        marginBottom: marginAsArray[2],
        marginLeft: marginAsArray[3],
      };
    default:
      return {
        marginTop: undefined,
        marginRight: undefined,
        marginBottom: undefined,
        marginLeft: undefined,
      };
  }
}

export function expandPadding(padding: string | number | string[] | number[]): {
  paddingTop: string | undefined;
  paddingRight: string | undefined;
  paddingBottom: string | undefined;
  paddingLeft: string | undefined;
} {
  const paddingAsArray = (Array.isArray(padding) ? padding : [padding]).map((x) => x.toString());

  switch (paddingAsArray.length) {
    case 1:
      return {
        paddingTop: paddingAsArray[0],
        paddingRight: paddingAsArray[0],
        paddingBottom: paddingAsArray[0],
        paddingLeft: paddingAsArray[0],
      };
    case 2:
      return {
        paddingTop: paddingAsArray[0],
        paddingRight: paddingAsArray[1],
        paddingBottom: paddingAsArray[0],
        paddingLeft: paddingAsArray[1],
      };
    case 3:
      return {
        paddingTop: paddingAsArray[0],
        paddingRight: paddingAsArray[1],
        paddingBottom: paddingAsArray[0],
        paddingLeft: paddingAsArray[2],
      };
    case 4:
      return {
        paddingTop: paddingAsArray[0],
        paddingRight: paddingAsArray[1],
        paddingBottom: paddingAsArray[2],
        paddingLeft: paddingAsArray[3],
      };
    default:
      return {
        paddingTop: undefined,
        paddingRight: undefined,
        paddingBottom: undefined,
        paddingLeft: undefined,
      };
  }
}
