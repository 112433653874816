import { mergeOverrides, Theme, withStyle } from 'baseui';
import { Input as BaseUIInput, InputOverrides, InputProps, StyledInput, StyledRoot } from 'baseui/input';
import { expandBorderStyles } from '../../styles/styleUtils';

const AppStyledRoot = withStyle(StyledRoot, (props: InputProps & { $theme: Theme }) => {
  let styles = {};

  if (props.disabled) {
    styles = { ...styles, ...expandBorderStyles(`2px solid ${props.$theme.colors.inputBorder}`) };
  }

  if (props.readOnly) {
    styles = { ...styles, backgroundColor: props.$theme.colors.inputFillDisabled };
  }

  return styles;
});

const AppStyledInput = withStyle(StyledInput, (props: InputProps & { $theme: Theme }) => {
  return {
    backgroundColor: props.readOnly ? props.$theme.colors.inputFillDisabled : undefined,
  };
});

export const Input = (props: InputProps & Record<string, unknown>): JSX.Element => {
  const { overrides, ...rest } = props;

  const inputOverrides: InputOverrides = {
    Root: {
      component: AppStyledRoot,
      props: { readOnly: props.readOnly, disabled: rest.disabled },
      style: { backgroundColor: 'white' }, // Bg color is visible when using a start/end enhancer
    },
    Input: {
      component: AppStyledInput,
      props: { readOnly: props.readOnly },
    },
    StartEnhancer: {
      style: { backgroundColor: 'transparent' },
    },
    EndEnhancer: {
      style: { backgroundColor: 'transparent' },
    },
  };

  const mergedOverrides: InputOverrides = mergeOverrides({ ...inputOverrides }, { ...overrides });

  return <BaseUIInput {...rest} overrides={mergedOverrides} />;
};
