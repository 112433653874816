import { useStyletron } from 'baseui';
import { FormControl } from 'baseui/form-control';
import { StatefulMenu } from 'baseui/menu';
import { PLACEMENT, Popover } from 'baseui/popover';
import { Option } from 'baseui/select';
import { ChangeEvent, useEffect, useRef, useState, FocusEvent, memo } from 'react';
import { MdSearch } from 'react-icons/md';
import { Input } from '@@shared/basewebComponentOverrides/Input';
import { MiniSpinner } from '@@shared/basewebComponentOverrides/Spinner';

export interface SearchItem {
  label: string;
  id: string;
  name: string;
  description: string;
}

interface Props {
  value: string;
  options: SearchItem[];
  caption?: string;
  loading?: boolean;
  label?: string;
  placeholder?: string;
  onItemSelect: (value: SearchItem) => void;
  onInputChange: (value: string) => void;
  onInputClear: () => void;
}

const SearchInputComp = (props: Props) => {
  const { options, value: valueProp, caption, loading, label, placeholder } = props;
  const { onItemSelect, onInputChange, onInputClear } = props;

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const originalValueRef = useRef<string | null>(null);

  const [value, setValue] = useState(valueProp);

  const [css, theme] = useStyletron();

  useEffect(() => {
    setDropdownIsOpen(options.length > 0);
  }, [options]);

  useEffect(() => {
    setValue(valueProp);

    // if the value changed externally, update the saved original value
    // to prevent re-triggering search on blur
    if (inputRef.current && inputRef.current.value !== valueProp) {
      originalValueRef.current = valueProp;
    }
  }, [valueProp]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;

    setValue(value);
    onInputChange(value);
  };

  const handleItemSelect = ({ item }: { item: SearchItem }) => {
    onItemSelect(item);

    setDropdownIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && inputRef.current === event.target) return;

    setDropdownIsOpen(false);
  };

  const handleInputFocus = () => {
    originalValueRef.current = value;

    if (options.length > 0) setDropdownIsOpen(true);
  };

  const handleInputBlur = (e: FocusEvent<Element, Element>) => {
    const focusedElementRole = e?.relatedTarget?.attributes.getNamedItem('role')?.value;

    // If the dropdown menu got focused
    if (focusedElementRole === 'listbox') {
      return;
    }

    if (value !== originalValueRef.current) {
      if (!value?.length) {
        onInputClear();
      } else {
        const textOnlyItem: SearchItem = { label: value, id: '', name: '', description: '' };

        onItemSelect(textOnlyItem);
      }
    }

    setDropdownIsOpen(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (value === originalValueRef.current) return;

      const textOnlyItem: SearchItem = { label: value, id: '', name: '', description: '' };

      onItemSelect(textOnlyItem);

      setDropdownIsOpen(false);

      // Update the original value to prevent re-triggering search on blur
      originalValueRef.current = value;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'ArrowDown') {
      menuRef.current && menuRef.current.focus();
    }
  };

  const suggestionsMenu = (
    <StatefulMenu
      rootRef={menuRef}
      items={options}
      onItemSelect={handleItemSelect}
      overrides={{
        Option: {
          props: {
            getItemLabel: (item: Option) => (
              <>
                <strong>{item?.label}&nbsp;</strong>
                {item?.name}
              </>
            ),
          },
        },
      }}
    />
  );

  return (
    <>
      <FormControl label={label ? `${label}*` : undefined} caption={caption}>
        <Popover
          isOpen={dropdownIsOpen}
          onClickOutside={handleClickOutside}
          content={suggestionsMenu}
          autoFocus={false}
          placement={PLACEMENT.bottomLeft}
          focusLock={false}
          returnFocus={false}
        >
          <div>
            <Input
              overrides={{
                Root: {
                  style: {
                    paddingLeft: 0,
                    position: 'relative',
                  },
                },
              }}
              inputRef={inputRef}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              type="text"
              value={value}
              onChange={handleInputChange}
              placeholder={placeholder}
              startEnhancer={() => <MdSearch className={css({ position: 'absolute', left: theme.sizing.scale400 })} />}
              endEnhancer={() => (loading ? <MiniSpinner /> : null)}
              onKeyPress={handleKeyPress}
              onKeyDown={handleKeyDown}
            />
          </div>
        </Popover>
      </FormControl>
    </>
  );
};

export const SearchInput = memo(SearchInputComp);
