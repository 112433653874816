import { ProgressBar } from 'baseui/progress-bar';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { useTranslation } from 'react-i18next';
import { CaseSearchOrderKey, OrderDirectionEnum } from '../api/api.generated';
import { COUNTRY_ALPHA3_TO_IANA_TIME_ZONE_MAP } from '../shared/constants';
import { getDateTimeFormatter } from '../shared/functions/dateTimeFormatContainer';
import { parseMaybeJsonDate } from '../shared/functions/dateUtils';
import { useUser } from '../shared/hooks/useUser';
import { RouterLink } from '../shared/utilityComponents/RouterLink';
import { CaseSearchResultItem } from './caseSearch.models';

interface Props {
  caseSearchResults?: CaseSearchResultItem[];
  isLoading?: boolean;
  loadingMessage?: React.ReactNode | (() => React.ReactNode);
  emptyMessage?: React.ReactNode | (() => React.ReactNode);
  orderColumn?: CaseSearchOrderKey;
  onOrderColumnChange?: (orderColumn: CaseSearchOrderKey) => void;
  orderDirection?: OrderDirectionEnum;
  onOrderDirectionChange?: (orderDirection: OrderDirectionEnum) => void;
  clientSideSorting?: boolean;
}

export const CaseSearchResults = (props: Props) => {
  const { caseSearchResults, isLoading, emptyMessage, loadingMessage, clientSideSorting } = props;
  const {
    orderColumn: sortColumn,
    onOrderColumnChange: onSortColumnChange,
    orderDirection: sortDirection,
    onOrderDirectionChange: onSortDirectionChange,
  } = props;

  const { languageCode } = useUser();

  const tSearchResults = useTranslation('translation', { keyPrefix: 'searchResults' }).t;
  const tCaseReg = useTranslation('translation', { keyPrefix: 'caseRegistration' }).t;

  function handleSort(columnId: string) {
    const column = columnId as CaseSearchOrderKey;

    if (!column) return;

    if (column === sortColumn) {
      onSortDirectionChange &&
        onSortDirectionChange(
          sortDirection === OrderDirectionEnum.Ascending ? OrderDirectionEnum.Descending : OrderDirectionEnum.Ascending
        );
    } else {
      onSortColumnChange && onSortColumnChange(column);
    }
  }

  return (
    <TableBuilder
      data={caseSearchResults || []}
      sortColumn={sortColumn?.toString()}
      sortOrder={sortDirection === OrderDirectionEnum.Ascending ? 'ASC' : 'DESC'}
      onSort={handleSort}
      isLoading={isLoading}
      emptyMessage={emptyMessage ?? tSearchResults('noResults')}
      loadingMessage={loadingMessage ?? <ProgressBar infinite />}
      overrides={{ Root: { style: { height: '100%' } } }}
    >
      <TableBuilderColumn id="visCaseId" header={tSearchResults('visCaseId')} sortable>
        {(row: CaseSearchResultItem) => <RouterLink to={`/case/${row.visCaseId}`}>{row.visCaseId}</RouterLink>}
      </TableBuilderColumn>
      <TableBuilderColumn id="registrationNumber" header={tSearchResults('vehicleRegistration')} sortable>
        {(row: CaseSearchResultItem) => row.registrationNumber}
      </TableBuilderColumn>
      <TableBuilderColumn id="stateOfCase" header={tSearchResults('stateOfCase')} sortable={clientSideSorting}>
        {(row: CaseSearchResultItem) => tCaseReg(row.stateOfCase ?? '')}
      </TableBuilderColumn>
      <TableBuilderColumn id="incidentDate" header={tSearchResults('incidentDateTime')} sortable>
        {(row: CaseSearchResultItem) =>
          getDateTimeFormatter(languageCode, COUNTRY_ALPHA3_TO_IANA_TIME_ZONE_MAP[row.callCenterCountry ?? '']).format(
            parseMaybeJsonDate(row.incidentDate)
          )
        }
      </TableBuilderColumn>
      <TableBuilderColumn id="incidentCityAndCountry" header={tSearchResults('incidentCountry')} sortable>
        {(row: CaseSearchResultItem) => row.incidentCityAndCountry}
      </TableBuilderColumn>
      <TableBuilderColumn id="incidentReason" header={tSearchResults('incidentType')} sortable>
        {(row: CaseSearchResultItem) => row.incidentReason}
      </TableBuilderColumn>
      <TableBuilderColumn id="makeAndModel" header={tSearchResults('vehicleBrandAndModel')} sortable>
        {(row: CaseSearchResultItem) => row.makeAndModel}
      </TableBuilderColumn>
      <TableBuilderColumn id="owner" header={tSearchResults('owner')} sortable>
        {(row: CaseSearchResultItem) => row.owner}
      </TableBuilderColumn>
      <TableBuilderColumn id="workshop" header={tSearchResults('workshop')} sortable>
        {(row: CaseSearchResultItem) => row.workshop}
      </TableBuilderColumn>
    </TableBuilder>
  );
};
