import { Block } from 'baseui/block';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { useStyletron } from 'baseui/styles';
import { StyledTable, StyledHeadCell } from 'baseui/table-grid';
import { HeadingMedium, LabelMedium } from 'baseui/typography';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CaseAction, ComboboxItem } from '@@api/api.generated';
import { useGroupsQuery, useLoggTypesQuery, useServicesQuery } from '@@api/queries/useCaseMasterDataQueries';
import HistoryRow from './HistoryRow';
import { ExtendedCaseAction } from './types';

export enum HistoryType {
  services = 'services',
  logs = 'logs',
}

interface CaseHistoryTableProps {
  heading: string;
  type: HistoryType;
  data: CaseAction[] | undefined;
  getTypesData: boolean;
  showExpandAllButton?: boolean;
}

function CaseHistoryTableComp({ data, heading, type, getTypesData, showExpandAllButton }: CaseHistoryTableProps) {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tCaseHistory = useTranslation('translation', { keyPrefix: 'caseHistory' }).t;

  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const onExpandChange = (rowId?: string) => {
    if (!rowId) {
      return;
    }
    setExpandedRows({ ...expandedRows, [rowId]: !expandedRows[rowId] });
  };

  const isAllExpanded =
    Object.keys(expandedRows).length === data?.length && Object.values(expandedRows).every((r) => r);

  const updateExpandStateForAllRows = () => {
    if (!data) {
      return;
    }
    const updatedRows = data.reduce<Record<string, boolean>>((previous, current) => {
      if (!current.id) {
        return previous;
      }
      return {
        ...previous,
        [current.id]: !isAllExpanded,
      };
    }, {});
    setExpandedRows(updatedRows);
  };

  const serviceTypes = useServicesQuery(getTypesData);
  const logTypes = useLoggTypesQuery();
  const groupTypes = useGroupsQuery(getTypesData);
  const [css] = useStyletron();

  return (
    <>
      <Block display={'flex'} justifyContent={'space-between'} padding={'16px 0px 8px 16px'}>
        <LabelMedium margin={'15px 0'}>{heading}</LabelMedium>
        {showExpandAllButton ? (
          <Block display={'flex'} alignItems={'center'}>
            <Checkbox
              checked={isAllExpanded}
              onChange={updateExpandStateForAllRows}
              checkmarkType={STYLE_TYPE.toggle_round}
              overrides={{ Label: { style: { alignItems: 'center' } } }}
            >
              {isAllExpanded ? tCaseHistory('collapseAll') : tCaseHistory('expandAll')}
            </Checkbox>
          </Block>
        ) : null}
      </Block>
      <StyledTable
        role="grid"
        $gridTemplateColumns="minmax(135px, auto) auto auto auto auto auto auto auto auto"
        $style={{ height: 'auto' }}
      >
        <div role="row" className={css({ display: 'contents' })}>
          <StyledHeadCell>{tCaseHistory('registeredTime')}</StyledHeadCell>
          <StyledHeadCell>{tCaseHistory('id')}</StyledHeadCell>
          <StyledHeadCell>{tCaseHistory('type')}</StyledHeadCell>
          <StyledHeadCell>{tCaseHistory('per')}</StyledHeadCell>
          <StyledHeadCell>{tCaseHistory('responsible')}</StyledHeadCell>
          <StyledHeadCell>{tCaseHistory('text')}</StyledHeadCell>
          <StyledHeadCell>{tCaseHistory('service')}</StyledHeadCell>

          <StyledHeadCell className={css({ gridColumn: 'span 2 /auto' })}>{tCaseHistory('done')}</StyledHeadCell>
        </div>
        {serviceTypes.isLoading ? (
          <p>{tShared('loading')}</p>
        ) : (
          <>
            {data && data.length > 0 ? (
              data.map((row, rowIndex) => {
                return (
                  <HistoryRow
                    row={mapHistory(row, logTypes.data, serviceTypes.data, groupTypes.data)}
                    key={`row-${row.id}`}
                    onExpandRow={() => onExpandChange(row.id)}
                    expanded={row?.id ? expandedRows[row?.id] : false}
                    rowIndex={rowIndex}
                  />
                );
              })
            ) : (
              <div className={css({ textAlign: 'center', gridColumn: 'span 9' })}>
                <HeadingMedium color={'#AFAFAF'}>
                  {tCaseHistory('empty')} {type === 'services' ? tCaseHistory('services') : tCaseHistory('logTypes')}
                </HeadingMedium>
              </div>
            )}
          </>
        )}
      </StyledTable>
    </>
  );
}

const mapHistory = (
  row: CaseAction,
  logTypes: ComboboxItem[] | undefined,
  serviceTypes: ComboboxItem[] | undefined,
  groupTypes: ComboboxItem[] | undefined
): ExtendedCaseAction | undefined => {
  if (logTypes && serviceTypes && groupTypes) {
    return {
      ...row,
      visLoggTypeName: logTypes.filter((d) => d.id === row.visLoggTypeCode).map((d) => d.name)[0],
      visLoggServiceName: serviceTypes.filter((d) => d.id === row.visLoggServiceCode).map((d) => d.name)[0],
      responsible: groupTypes.filter((d) => d.id === row.responsible).map((d) => d.name)[0] ?? row.responsible,
    };
  }
};
export const CaseHistoryTable = memo(CaseHistoryTableComp);
