export type Concrete<Type> = {
  [Property in keyof Type]-?: Type[Property];
};

export class FakeFormikEvent<T = unknown> {
  target: { name: string; value: T };

  constructor(fieldName: string, value: T) {
    this.target = {
      name: fieldName,
      value: value,
    };
  }
}

export class FakeBlurEvent {
  target: { name: string };

  constructor(fieldName: string) {
    this.target = {
      name: fieldName,
    };
  }
}

export enum CaseStatusEnum {
  Active = 0,
  Cancelled = 10,
  Transferred = 20,
  Closed = 99,
}

export type FormErrors<T = unknown> = Partial<Record<keyof T, string>> & Record<string, string>;

export enum Country {
  Norway = 'NOR',
  Sweden = 'SWE',
  Denmark = 'DNK',
  Finland = 'FIN',
}

export enum ActorsEnum {
  owner = 'owner',
  caller = 'caller',
  requester = 'requester',
}

export enum Waiting {
  waiting = 'waiting',
  notWaiting = 'notWaiting',
}

export enum CaseFormStatus {
  Create = 'CREATE',
  Update = 'UPDATE',
  Sent = 'SENT',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
}

export enum TransportToType {
  workshop = 'workshop',
  other = 'other',
}

export interface DateTimeFormatOptions {
  localeMatcher?: 'best fit' | 'lookup' | undefined;
  weekday?: 'long' | 'short' | 'narrow' | undefined;
  era?: 'long' | 'short' | 'narrow' | undefined;
  year?: 'numeric' | '2-digit' | undefined;
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined;
  day?: 'numeric' | '2-digit' | undefined;
  hour?: 'numeric' | '2-digit' | undefined;
  minute?: 'numeric' | '2-digit' | undefined;
  second?: 'numeric' | '2-digit' | undefined;
  timeZoneName?: 'long' | 'short' | undefined;
  formatMatcher?: 'best fit' | 'basic' | undefined;
  hour12?: boolean | undefined;
  timeZone?: string | undefined;
}
