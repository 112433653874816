import { parseJSON, setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns/esm';
import { DateTimeFormatOptions } from '../types';
import utcToZonedTime from 'date-fns-tz/esm/utcToZonedTime';
import zonedTimeToUtc from 'date-fns-tz/esm/zonedTimeToUtc';

export function formatDate(
  date: Date | null | undefined,
  options: DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  },
  locales: string | string[] = 'no'
): string {
  if (date) {
    return new Intl.DateTimeFormat(locales, options).format(new Date(date));
  }

  return '';
}

export function ensureIsDate(date: Date | undefined): Date | undefined {
  return date ? new Date(date) : undefined;
}

export function parseMaybeJsonDate(date: Date | undefined): Date | undefined {
  return date && typeof date === 'string' ? parseJSON(date) : date;
}

export function convertUtcToZonedTime(input: Date, timeZone: string) {
  return utcToZonedTime(input, timeZone);
}

export function convertZonedTimeToUtc(input: Date, timeZone: string) {
  return zonedTimeToUtc(input, timeZone);
}

export enum TimePrecisionEnum {
  'date' = 0,
  'hour' = 1,
  'minute' = 2,
  'second' = 3,
  'millisecond' = 4,
}

export function getCurrentDate(precision: TimePrecisionEnum = TimePrecisionEnum.minute): Date {
  const currentDate = new Date();

  let outDate = currentDate;

  if (precision === TimePrecisionEnum.date) {
    outDate = setHours(outDate, 0);
  }

  if (precision <= TimePrecisionEnum.hour) {
    outDate = setMinutes(outDate, 0);
  }

  if (precision <= TimePrecisionEnum.minute) {
    outDate = setSeconds(outDate, 0);
  }

  if (precision <= TimePrecisionEnum.second) {
    outDate = setMilliseconds(outDate, 0);
  }

  return outDate;
}

export function getCurrentDateInTimeZone(timeZone: string, precision?: TimePrecisionEnum): Date {
  const currentDate = getCurrentDate(precision);

  return utcToZonedTime(currentDate, timeZone);
}
