import { Radio } from 'baseui/radio';
import { useFormikContext } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FastRadioGroupField as RadioGroupField } from '../../shared/formFields/RadioGroupField';
import { isPhoneNumberValid } from '../../shared/functions/general';
import { ActorsEnum } from '../../shared/types';
import FormGroup from '../../shared/utilityComponents/FormGroup';
import { nameof } from '../caseRegistrationFunctions';
import { CaseRegistrationFormFields } from '../caseRegistrationModels';

function ContactPersonFormGroupComp() {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tContact = useTranslation('translation', { keyPrefix: 'contactForm' }).t;
  const formik = useFormikContext<CaseRegistrationFormFields>();

  const requesterPhone = formik.values.requesterPhone;
  const ownerPhone = formik.values.ownerPhone;
  const callerPhone = formik.values.callerPhone;

  return (
    <FormGroup label={tContact('contactPerson')}>
      <RadioGroupField
        label={`${tContact('useContactInfo')}*`}
        name={nameof('contactPerson')}
        deps={[requesterPhone, ownerPhone, callerPhone]}
      >
        <Radio disabled={!isPhoneNumberValid(requesterPhone)} value={ActorsEnum.requester}>
          {tShared('requester')}
        </Radio>
        <Radio disabled={!isPhoneNumberValid(ownerPhone)} value={ActorsEnum.owner}>
          {tShared('owner')}
        </Radio>
        <Radio disabled={!isPhoneNumberValid(callerPhone)} value={ActorsEnum.caller}>
          {tShared('caller')}
        </Radio>
      </RadioGroupField>
    </FormGroup>
  );
}
export const ContactPersonFormGroup = memo(ContactPersonFormGroupComp);
