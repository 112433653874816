import { getDetails, getGeocode } from 'use-places-autocomplete';
import { GGeocoderRequest, GGeocoderResult, GPlaceResult, LatLngLiteral } from './types';

export const geocodeAddressPlaceId = async (placeId: string) => {
  const request = {
    placeId: placeId,
  };

  try {
    const geocodeResults = await getGeocode(request);

    return geocodeResults[0];
  } catch {
    /* empty */
  }
};

export const geocodeAddressText = async (text: string, countryCode?: string) => {
  let request: GGeocoderRequest | undefined;

  const inputAsLatLng = parseLatLng(text);

  if (inputAsLatLng) {
    request = {
      location: inputAsLatLng,
      region: countryCode,
    };
  } else {
    request = {
      address: text,
      region: countryCode,
    };
  }

  try {
    const geocodeResults = await getGeocode(request);

    return geocodeResults[0];
  } catch {
    /* empty */
  }
};

export const geocodeAddressWithPostalAreaAndCode = async (
  text: string,
  postalCode: string,
  postalArea: string,
  countryCode: string
) => {
  const addressQuery = `${text}, ${postalArea} ${postalCode}`;

  const request = {
    address: addressQuery,
    region: countryCode,
  };

  try {
    const geocodeResults = await getGeocode(request);

    return geocodeResults;
  } catch {
    /* empty */
  }
};

export const getStreetAddressFromGeocodeResult = (geocodeResult: GGeocoderResult) => {
  const addressParts = geocodeResult.formatted_address.split(',');

  return addressParts.length > 0 ? addressParts[0] : undefined;
};

export const getPostalAreaFromGeocodeResult = (geocodeResult: GGeocoderResult) => {
  const postalAreaTypes = ['postal_town', 'locality', 'administrative_area_level_1', 'administrative_area_level_2'];

  const postalArea = geocodeResult.address_components.find((address) => postalAreaTypes.includes(address.types[0]));

  if (postalArea) {
    return postalArea.long_name;
  }
};

export const findGeocodeResultMatchingPostalCodeAndStreet = (
  geocodeResults: GGeocoderResult[],
  postalCode: string,
  address: string
) => {
  const match = geocodeResults.find((r) => {
    const postalCodeComponent = r.address_components.find((address) => address.types[0] === 'postal_code');

    const postalCodeMatch = postalCode === postalCodeComponent?.long_name;

    const addressFromResult = getStreetAddressFromGeocodeResult(r);

    if (!addressFromResult) {
      return false;
    }

    const addressMatch = address.toLowerCase().includes(addressFromResult.toLowerCase());

    return addressMatch && postalCodeMatch;
  });

  return match;
};

export const getPostalCodeFromGeocodeResult = async (geocodeResult: GGeocoderResult) => {
  const postalCode = geocodeResult.address_components.find((address) => address.types[0] === 'postal_code');

  if (postalCode) {
    return postalCode.long_name;
  }

  const isStreetWithoutNumber =
    geocodeResult.types.includes('route') && !geocodeResult.types.includes('street_address');

  if (isStreetWithoutNumber) {
    // Attempt to guess the postal code by searching for a place
    // with the same street name and the number 1 appended;
    const formattedAddress = geocodeResult.formatted_address;
    const addressWithNumber = formattedAddress.replace(',', ' 1,');

    const countryCode = geocodeResult.address_components.find((address) => address.types[0] === 'country')?.short_name;

    const geocodedAddress = await geocodeAddressText(addressWithNumber, countryCode);

    if (geocodedAddress) {
      const zipCode = geocodedAddress.address_components.find((address) => address.types[0] === 'postal_code');

      if (zipCode) {
        return zipCode.long_name;
      }
    }
  }
};

export const isPlacePointOfInterest = async (placeId: string) => {
  const request = {
    placeId: placeId,
    fields: ['formatted_address', 'types'],
  };

  let details = await getDetails(request);

  if (!details) return false;

  details = details as GPlaceResult;

  return details.types?.includes('point_of_interest');
};

export const resolveAddressWithNewPostalCode = async (
  street: string,
  newPostalCode: string,
  postalArea: string,
  countryCode: string
) => {
  const geocodedAddressList = await geocodeAddressWithPostalAreaAndCode(street, countryCode, postalArea, newPostalCode);

  if (!geocodedAddressList) return;

  const matchingResult = findGeocodeResultMatchingPostalCodeAndStreet(geocodedAddressList, newPostalCode, street);

  return matchingResult;
};

const parseLatLng = (text: string): LatLngLiteral | undefined => {
  const trimmedText = (text ?? '').trim();

  if (!trimmedText.length) return;

  // 60.780533, 10.63723. separator must be at least one of either "," or " "
  // tests: https://regex101.com/r/9ClG3A/1
  // TODO add proper tests
  const latLngInputRegex = /^(-?\d+(?:\.\d+))(?:(?:\s*,\s*)|\s+)(-?\d+(?:\.\d+))$/i;

  // N 60° 46' 49.92", E 10° 38' 14.03"
  const dmsInputRegex =
    /^([NS])\s*(\d{1,3})°?\s*(\d{1,2})'?\s*(\d{1,2}(\.\d{1,2})?)"?,\s*([EW])\s*(\d{1,3})°?\s*(\d{1,2})'?\s*(\d{1,2}(\.\d{1,2})?)"?$/i;

  const latLngMatch = trimmedText.match(latLngInputRegex);

  if (latLngMatch) {
    const lat = parseFloat(latLngMatch[1]);
    const lng = parseFloat(latLngMatch[2]);

    return { lat, lng };
  }

  const dmsMatch = trimmedText.match(dmsInputRegex);

  if (dmsMatch) {
    const latDir = dmsMatch[1];
    const latDeg = dmsMatch[2];
    const latMin = dmsMatch[3];
    const latSec = dmsMatch[4];

    const lngDir = dmsMatch[6];
    const lngDeg = dmsMatch[7];
    const lngMin = dmsMatch[8];
    const lngSec = dmsMatch[9];

    const lat = parseDms(latDir, latDeg, latMin, latSec);
    const lng = parseDms(lngDir, lngDeg, lngMin, lngSec);

    return { lat, lng };
  }
};

const parseDms = (dir: string, deg: string, min: string, sec: string) => {
  let degrees = parseFloat(deg),
    minutes = parseFloat(min),
    seconds = parseFloat(sec);

  if (dir === 'S' || dir === 'W') {
    degrees = -degrees;
    minutes = -minutes;
    seconds = -seconds;
  }

  return degrees + minutes / 60 + seconds / 3600;
};
