import { useMutation } from '@tanstack/react-query';
import { useApiClient } from '../apiClientContext';

export interface PhoneLookUpInput {
  phoneNumber: string | undefined;
  countryCode: string | undefined;
  callCenterCode: string | undefined;
}

export const usePhoneLookUpPerson = () => {
  const { phoneLookUpClient } = useApiClient();

  return useMutation((input: PhoneLookUpInput) =>
    phoneLookUpClient.phoneLookupPersonRequest(input.phoneNumber, input.countryCode, input.callCenterCode)
  );
};

export const usePhoneLookUpCompany = () => {
  const { phoneLookUpClient } = useApiClient();

  return useMutation((input: PhoneLookUpInput) =>
    phoneLookUpClient.phoneLookupCompanyRequest(input.phoneNumber, input.countryCode, input.callCenterCode)
  );
};
