import { styled } from 'baseui';

export const OverflowTabContent = styled('div', () => ({
  position: 'absolute',
  overflowY: 'auto',
  overflowX: 'hidden',
  top: '0',
  bottom: '10px',
  left: '0',
  right: '0',
  padding: '16px',
}));
