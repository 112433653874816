import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { HeadingMedium, LabelMedium } from 'baseui/typography';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SokDekningRequest } from '../api/api.generated';
import { useDataSourcesQuery, useDekningsSokMutation } from '../api/queries/useExternalVehicleInfoQueries';
import { EviResultTable } from '../evi/EviResultTable';
import { EviSearch, Database } from '../evi/EviSearch';

export const VOLVO_DB_ID = 'Volvo';
export const MOTORV_FIN_DB_ID = 'MotorvFin';

interface Props {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
}

function EviModalComp({ setIsOpen, isOpen }: Props) {
  const { data: sources = [] } = useDataSourcesQuery();

  const [selectedDbIds, setSelectedDbIds] = useState<string[]>([VOLVO_DB_ID]);

  const tEvi = useTranslation('translation', { keyPrefix: 'evi' }).t;

  const [css, theme] = useStyletron();

  const searchDekning = useDekningsSokMutation();

  const databases = useMemo(() => {
    if (!sources?.length) return [];

    let databases = sources.map((s) => {
      const db: Database = {
        id: s.id ?? '',
        name: s.name ?? '',
        selected: !!s.id && selectedDbIds.indexOf(s.id) > -1,
      };
      return db;
    });

    const volvoDb = databases.find((db) => db.id === VOLVO_DB_ID);

    // Move Volvo to the top of the list
    if (volvoDb) {
      databases = [volvoDb, ...databases.filter((d) => d !== volvoDb)];
    }

    return databases;
  }, [sources, selectedDbIds]);

  function handleToggleDatabase(dbId: string) {
    const isSelected = selectedDbIds.indexOf(dbId) > -1;

    if (isSelected) {
      setSelectedDbIds(selectedDbIds.filter((db) => db !== dbId));
    } else {
      setSelectedDbIds([...selectedDbIds, dbId]);
    }
  }

  function handleClearResults() {
    searchDekning.reset();
    setSelectedDbIds([VOLVO_DB_ID]);
  }

  return (
    <Modal
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
      overrides={{
        Dialog: {
          style: {
            height: `calc(100vh - ${theme.sizing.scale1600})`,
            width: `calc(100vw - ${theme.sizing.scale1600})`,
            display: 'flex',
            flexDirection: 'column',
          },
        },
      }}
    >
      <ModalHeader className={css({ flex: '0' })}>
        <HeadingMedium marginTop="0" marginBottom="scale400">
          {tEvi('searchCoverage')}
        </HeadingMedium>
      </ModalHeader>
      <ModalBody
        className={css({
          paddingLeft: theme.sizing.scale200,
          paddingRight: theme.sizing.scale200,
          display: 'flex',
          flex: '1',
        })}
      >
        <Block
          flex={0}
          paddingRight="scale1200"
          overrides={{
            Block: {
              style: {
                borderRight: `1px solid ${theme.colors.tickBorder}`,
              },
            },
          }}
        >
          <>
            <LabelMedium paddingBottom="28px">{tEvi('searchBasis')}</LabelMedium>
            <EviSearch
              onSearch={(request: SokDekningRequest) => {
                searchDekning.mutate(request);
              }}
              databases={databases}
              onToggleDatabase={handleToggleDatabase}
              clearResults={handleClearResults}
            />
          </>
        </Block>
        <Block flex={1} paddingLeft="scale1200">
          <EviResultTable
            searchResults={searchDekning.data?.dekninger ?? []}
            isLoading={searchDekning.isLoading}
            setIsOpen={(value: boolean) => setIsOpen(value)}
          />
        </Block>
      </ModalBody>
    </Modal>
  );
}

export const EviModal = memo(EviModalComp);
