import { FieldValidator } from 'formik';
import { useTranslation } from 'react-i18next';
import InputField, { FastInputField, InputFieldProps } from './InputField';

export function FastEmailInputField(props: InputFieldProps) {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;

  const emailValidator: FieldValidator = (value: string) => {
    if (!value?.trim()) return;

    if (value.match(/.+@\w+\.\w/)) return;

    return tShared('validEmail') as string;
  };

  return <FastInputField {...props} validate={emailValidator} />;
}

export function EmailInputField(props: InputFieldProps) {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;

  const emailValidator: FieldValidator = (value: string) => {
    if (!value?.trim()) return;

    if (value.match(/.+@\w+\.\w/)) return;

    return tShared('validEmail') as string;
  };

  return <InputField {...props} validate={emailValidator} />;
}
