import { useSnackbarRef } from '@@shared/hooks/useSnackbarRef';
import { useStyletron } from 'baseui';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { DeleteAlt } from 'baseui/icon';
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE } from 'baseui/modal';
import { Radio, RadioGroup } from 'baseui/radio';
import { Form, Formik, FormikProps } from 'formik';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { FixedItCommand, FixedItType } from '../../api/api.generated';
import { useInfoCallCodesQuery } from '../../api/queries/useCaseMasterDataQueries';
import { useFixedItMutation } from '../../api/queries/useCaseQueries';
import { Button } from '../../shared/basewebComponentOverrides/Button';
import SelectField from '../../shared/formFields/SelectField';
import TextareaField from '../../shared/formFields/TextareaField';

interface FixedItForm {
  infoCallReasonId: string;
  infoCallComment: string;
  phoneFixComment: string;
  solution: string; // FixedItType from API
}

interface FixedItModalProps {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
  caseId?: string;
  userId?: string;
  successPhoneFixMessage: string;
  failedPhoneFixMessage: string;
  successInfoCallMessage: string;
  failedInfoCallMessage: string;
}

function FixedItModalComp(props: FixedItModalProps) {
  const { setIsOpen, isOpen, caseId, userId } = props;
  const { successInfoCallMessage, successPhoneFixMessage, failedInfoCallMessage, failedPhoneFixMessage } = props;

  const tCaseReg = useTranslation('translation', { keyPrefix: 'caseRegistration' }).t;
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;

  const [css, theme] = useStyletron();

  const values: FixedItForm = {
    infoCallReasonId: '',
    infoCallComment: '',
    phoneFixComment: '',
    solution: '',
  };

  const fixedIt = useFixedItMutation();
  const { data: infoCallCodes = [] } = useInfoCallCodesQuery();
  //Need to add a option that is updated when registered to send correct option back to snackbar
  const [option, setOption] = useState('');

  const enqueue = useSnackbarRef();

  //Needs to be updated to correct end point when this is implemented backend
  function handleFixedIt(values: FixedItForm) {
    fixedIt.mutate({
      caseId: caseId,
      userId: userId,
      description: values.solution === FixedItType.InfoCall ? values.infoCallComment : values.phoneFixComment,
      type: values.solution,
      reasonId: values.solution === FixedItType.InfoCall ? values.infoCallReasonId : undefined,
    } as FixedItCommand);

    setOption(values.solution);
  }

  function handleValidation(values: FixedItForm) {
    const errors: Partial<FixedItForm> = {};

    if (values.solution === FixedItType.PhoneFix.toString() && values.phoneFixComment === '') {
      errors.phoneFixComment = 'Missing comment';
    } else if (values.solution === FixedItType.InfoCall.toString()) {
      if (values.infoCallReasonId === 'A' && values.infoCallComment === '') {
        errors.infoCallComment = 'Missing comment';
      }
      if (!values.infoCallReasonId) {
        errors.infoCallReasonId = 'Missing id';
      }
    }

    return errors;
  }

  const showSnackBar = useCallback(
    (solution: string, success: boolean) => {
      enqueue({
        message:
          solution === FixedItType.PhoneFix.toString() && success
            ? successPhoneFixMessage
            : solution === FixedItType.PhoneFix.toString() && !success
            ? failedPhoneFixMessage
            : solution === FixedItType.InfoCall.toString() && success
            ? successInfoCallMessage
            : failedInfoCallMessage,

        startEnhancer: ({ size }) => (success ? <FaCheckCircle size={size} /> : <DeleteAlt size={size} />),
      });
    },
    [enqueue, failedInfoCallMessage, failedPhoneFixMessage, successInfoCallMessage, successPhoneFixMessage]
  );

  useEffect(() => {
    if (fixedIt.isSuccess && isOpen) {
      setIsOpen(false);
      fixedIt.reset();
      showSnackBar(option, true);
    }
  }, [fixedIt, fixedIt.isSuccess, isOpen, option, setIsOpen, showSnackBar]);

  useEffect(() => {
    if (fixedIt.isError && isOpen) {
      setIsOpen(false);
      showSnackBar(option, false);
    }
  }, [fixedIt.isError, isOpen, option, setIsOpen, showSnackBar]);

  return (
    <Modal
      isOpen={isOpen}
      animate
      autoFocus
      overrides={{
        Dialog: {
          style: {
            width: '40vw',
          },
        },
      }}
      role={ROLE.dialog}
      closeable
    >
      <Formik initialValues={values} onSubmit={handleFixedIt} validate={handleValidation} validateOnBlur={false}>
        {(formikProps: FormikProps<FixedItForm>) => {
          return (
            <Form>
              <ModalHeader> {tCaseReg('fixedIt')}</ModalHeader>
              <ModalBody
                className={css({
                  minHeight: '270px',
                  paddingLeft: theme.sizing.scale200,
                  paddingRight: theme.sizing.scale200,
                })}
              >
                <FlexGrid flexGridColumnCount={2}>
                  <FlexGridItem flex={1}>
                    <RadioGroup value={formikProps.values.solution} onChange={formikProps.handleChange} name="solution">
                      <Radio value={FixedItType.PhoneFix.toString()}>Phonefix</Radio>
                      <Radio value={FixedItType.InfoCall.toString()}>InfoCall</Radio>
                    </RadioGroup>
                  </FlexGridItem>
                  <FlexGridItem>
                    {formikProps.values.solution === FixedItType.InfoCall.toString() ? (
                      <>
                        <SelectField
                          options={infoCallCodes}
                          label={`${tShared('chooseReason')}*`}
                          name="infoCallReasonId"
                        />
                        {formikProps.values.infoCallReasonId === 'A' ? (
                          <TextareaField name="infoCallComment" label={`${tShared('description')}*`} />
                        ) : null}
                      </>
                    ) : formikProps.values.solution === FixedItType.PhoneFix.toString() ? (
                      <TextareaField label={`${tShared('description')}*`} name="phoneFixComment" />
                    ) : null}
                  </FlexGridItem>
                </FlexGrid>
              </ModalBody>
              <ModalFooter className={css({ display: 'flex', justifyContent: 'flex-end' })}>
                <Button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  kind="secondary"
                  $wideButton
                  className={css({ marginRight: '1em' })}
                >
                  {tShared('cancel')}
                </Button>
                <Button disabled={!formikProps.isValid} type="submit" $wideButton isLoading={fixedIt.isLoading}>
                  {tShared('register')}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export const FixedItModal = memo(FixedItModalComp);
