import { SpinnerOverlay } from '@@shared/basewebComponentOverrides/Spinner';
import { useUser } from '@@shared/hooks/useUser';
import { UserDetails } from './UserDetails';

export const UserProfilePage = () => {
  const { data: user, isLoading, isFetched } = useUser();

  if (isLoading || !isFetched) return <SpinnerOverlay />;

  return <UserDetails user={user} mode="profile" />;
};
