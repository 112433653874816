export const V_LANGUAGE_CODE_TO_ISO1: Record<string, string> = {
  N: 'nb',
  DK: 'da',
  S: 'sv',
  FI: 'fi',
  E: 'en',
};

export const ISO1_LANGUAGE_CODE_TO_V: Record<string, string> = {
  nb: 'N',
  da: 'DK',
  sv: 'S',
  fi: 'FI',
  en: 'E',
};

export const COUNTRY_ALPHA3_TO_IANA_TIME_ZONE_MAP: Record<string, string> = {
  SWE: 'Europe/Stockholm',
  FIN: 'Europe/Helsinki',
  DNK: 'Europe/Copenhagen',
  NOR: 'Europe/Oslo',
};

export const CASE_FORM_STATUS_TO_TAG_KEY: Record<string, string> = {
  CREATE: 'active',
  UPDATE: 'active',
  SENT: 'sent',
  CANCELLED: 'cancelled',
  CLOSED: 'closed',
  CLOSED_ASSISTANCE: 'closed',
  _: 'unknown',
};

export const CASE_STATUS_TO_TAG_KEY: Record<string, string> = {
  Active: 'active',
  Cancelled: 'cancelled',
  Transferred: 'sent',
  Closed: 'closed',
};

export const LANGUAGE_LIST: string[] = ['nb', 'da', 'sv', 'fi', 'en'];
export const CALL_CENTER_LIST: string[] = ['NOR', 'SWE', 'DNK', 'FIN'];

export const MAX_USERNAME_LENGTH = 10;
