import { useStyletron } from 'baseui';
import { KIND } from 'baseui/button';
import { CLOSE_SOURCE, Modal, ModalBody, ModalFooter, ModalHeader, ROLE } from 'baseui/modal';
import { Form, Formik, FormikHelpers } from 'formik';
import { toString } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateUserCommand } from '@@api/api.generated';
import { useAdminCreateUserMutation } from '@@api/queries/useUserAdminQueries';
import { useRolesQuery } from '@@api/queries/useUserQueries';
import { CALL_CENTER_LIST, ISO1_LANGUAGE_CODE_TO_V, LANGUAGE_LIST, MAX_USERNAME_LENGTH } from '@@shared/constants';
import { FastEmailInputField } from '@@shared/formFields/EmailInputField';
import { FastInputField } from '@@shared/formFields/InputField';
import { FastSelectField } from '@@shared/formFields/SelectField';
import { toSelectOptions } from '@@shared/functions/general';
import { useSnackbarRef } from '@@shared/hooks/useSnackbarRef';
import { useUser } from '@@shared/hooks/useUser';
import { Banner } from '@@shared/info/Banner';
import { ModalFooterButton } from '@@shared/modals/ModalFooterButton';
import { FormErrors } from '@@shared/types';
import { ApiErrorMessage } from '@@shared/utilityComponents/ApiErrorMessage';
import { CreateUserForm, getCreateUserFormInitialValues } from './userFormFunctions';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CALL_CENTER_OPTIONS = toSelectOptions(CALL_CENTER_LIST);

export const CreateUserModal = (props: Props) => {
  const { isOpen, onClose } = props;

  const [css] = useStyletron();

  const enqueue = useSnackbarRef();

  const tLanguage = useTranslation('translation', { keyPrefix: 'language' }).t;
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tUsers = useTranslation('translation', { keyPrefix: 'users' }).t;

  const [initialValues, setInitialValues] = useState<CreateUserForm>(() => getCreateUserFormInitialValues());

  const { callCenterCountry, languageCodeViking } = useUser();

  const { data: roles } = useRolesQuery();

  const { mutate: createUser, isLoading: createUserIsLoading, error: createUserError } = useAdminCreateUserMutation();

  const defaultRoleId = useMemo(() => {
    return roles ? toString(roles.find((r) => r.name === 'Agent')?.id) : '';
  }, [roles]);

  const roleOptions = (roles ?? []).map((r) => ({ id: r.id, name: r.name }));
  const languageOptions = LANGUAGE_LIST.map((l) => ({ id: ISO1_LANGUAGE_CODE_TO_V[l], name: tLanguage(l) }));

  useEffect(() => {
    setInitialValues(
      getCreateUserFormInitialValues({
        roleId: defaultRoleId,
        callCenterCountryCode: callCenterCountry,
        languageCode: languageCodeViking,
      })
    );
  }, [defaultRoleId, callCenterCountry, languageCodeViking]);

  const handleSubmit = (values: CreateUserForm, helpers: FormikHelpers<CreateUserForm>) => {
    const createCommand: CreateUserCommand = {
      email: values.email,
      visUserId: values.username,
      firstName: values.firstName,
      lastName: values.lastName,
      languageCode: values.languageCode,
      callCenterCountryCode: values.callCenterCountryCode,

      roleId: parseInt(values.roleId),
    };

    createUser(createCommand, {
      onSuccess: () => {
        helpers.setSubmitting(false);
        onClose();
        enqueue({ message: tUsers('newUserCreated') });
      },
    });
  };

  const validate = (values: CreateUserForm) => {
    const errors: FormErrors<CreateUserForm> = {};

    // All fields are required
    Object.entries(values).forEach(([key, value]: [string, string]) => {
      if (toString(value).trim().length === 0) {
        errors[key] = tShared('errorRequired');
      }
    });

    if (values.username !== undefined && values.username.length > MAX_USERNAME_LENGTH) {
      errors.username = tShared('errorMaxLength', { maxChars: MAX_USERNAME_LENGTH, count: MAX_USERNAME_LENGTH });
    }

    return errors;
  };

  const handleModalClose = (closeSource: string | undefined) => {
    if (closeSource === CLOSE_SOURCE.backdrop) return;

    if (createUserIsLoading) return;

    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={(closeResult) => handleModalClose(closeResult.closeSource)}
      role={ROLE.dialog}
      overrides={{
        Dialog: {
          style: {
            width: '40em',
          },
        },
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        enableReinitialize
        validateOnBlur={false}
      >
        {() => (
          <Form noValidate>
            <ModalHeader>{tUsers('addUser')}</ModalHeader>
            <ModalBody>
              {!!createUserError && <Banner text={<ApiErrorMessage error={createUserError} />} kind="negative" />}

              <FastEmailInputField name={nameof('email')} label={tShared('email')} required />
              <FastInputField
                name={nameof('username')}
                label={tUsers('usernameMaxLength', { maxChars: MAX_USERNAME_LENGTH })}
                required
              />
              <FastInputField name={nameof('firstName')} label={tUsers('firstName')} required />
              <FastInputField name={nameof('lastName')} label={tUsers('lastName')} required />
              <FastSelectField
                name={nameof('roleId')}
                label={tUsers('role')}
                options={roleOptions}
                clearable={false}
                required
              />
              <FastSelectField
                name={nameof('callCenterCountryCode')}
                label={tUsers('callCenter')}
                options={CALL_CENTER_OPTIONS}
                labelKey="label"
                clearable={false}
                required
              />
              <FastSelectField
                name={nameof('languageCode')}
                label={tUsers('language')}
                options={languageOptions}
                clearable={false}
                required
              />
            </ModalBody>
            <ModalFooter className={css({ display: 'flex', justifyContent: 'flex-end' })}>
              <ModalFooterButton type="button" kind={KIND.secondary} onClick={() => onClose()}>
                {tShared('cancel')}
              </ModalFooterButton>
              <ModalFooterButton
                type="submit"
                kind={KIND.primary}
                disabled={createUserIsLoading}
                isLoading={createUserIsLoading}
              >
                {tShared('add')}
              </ModalFooterButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export function nameof(fieldName: keyof CreateUserForm): string {
  return fieldName;
}
