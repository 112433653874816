import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CancelCaseCommand,
  CaseDto,
  CaseSearchRequest,
  FixedItCommand,
  ResumeCaseCommand,
  SendCaseToVikingCommand,
} from '../api.generated';
import { useApiClient } from '../apiClientContext';
import { CASE_ACTION_QUERY_KEY, CASE_QUERY_KEY, getInvalidateQueriesMutationOption } from './queryUtils';

/** Queries */

export const useGetCaseQuery = (caseId: number | undefined) => {
  const { caseClient } = useApiClient();

  const queryKey = [CASE_QUERY_KEY, 'GetCase', caseId ?? ''];

  return useQuery(queryKey, async () => (caseId !== undefined ? await caseClient.getByVisCaseId(caseId) : undefined), {
    enabled: !!caseId,
  });
};

export const useSearchCaseQuery = (search: CaseSearchRequest) => {
  const { caseClient } = useApiClient();

  const queryKey = [CASE_QUERY_KEY, 'CaseSearch'];

  return useQuery(queryKey, async () => await caseClient.search(search));
};

export const useGetCasesByRegistrationNumberQuery = (registrationNumber: string | undefined, visCaseId?: number) => {
  const { caseClient } = useApiClient();

  const queryKey = [CASE_QUERY_KEY, 'CasesByRegistrationNumber', registrationNumber, visCaseId ?? ''];

  return useQuery(queryKey, async () => caseClient.getCasesByRegistrationNumber(registrationNumber, visCaseId), {
    enabled: !!registrationNumber,
  });
};

export const useIncidentLocationQuery = (caseId: string | undefined, enabled: boolean) => {
  const { caseClient } = useApiClient();

  const queryKey = [CASE_QUERY_KEY, 'IncidentLocation', caseId];

  return useQuery(queryKey, async () => await caseClient.getIncidentLocationByCaseId(caseId), {
    enabled,
  });
};

export const useHasCompletedAssistance = (visCaseId: number | undefined) => {
  const { caseClient } = useApiClient();

  const queryKey = [CASE_QUERY_KEY, 'HasCompletedAssistance', visCaseId];

  return useQuery(queryKey, async () => await caseClient.hasCompletedAssistance(visCaseId));
};

/** Mutations */

export const useCreateCaseMutation = () => {
  const { caseClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_QUERY_KEY]);

  return useMutation((caseObject: CaseDto) => caseClient.create(caseObject), options);
};

export const useUpdateCaseMutation = () => {
  const { caseClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_QUERY_KEY]);

  return useMutation((caseObject: CaseDto) => caseClient.update(caseObject), options);
};

export const useSendToVikingMutation = () => {
  const { caseClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_QUERY_KEY, CASE_ACTION_QUERY_KEY]);

  return useMutation((command: SendCaseToVikingCommand) => caseClient.sendToViking(command), options);
};

export const useCancelCaseMutation = () => {
  const { caseClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_QUERY_KEY, CASE_ACTION_QUERY_KEY]);

  return useMutation((cancelCase: CancelCaseCommand) => caseClient.cancel(cancelCase), options);
};

export const useResumeCaseMutation = () => {
  const { caseClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_QUERY_KEY, CASE_ACTION_QUERY_KEY]);

  return useMutation((cancelCase: ResumeCaseCommand) => caseClient.resume(cancelCase), options);
};

export const useFixedItMutation = () => {
  const { caseClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_QUERY_KEY, CASE_ACTION_QUERY_KEY]);

  return useMutation((fixedIt: FixedItCommand) => caseClient.fixedIt(fixedIt), options);
};

export const useSearchCaseMutation = () => {
  const { caseClient } = useApiClient();

  return useMutation((search: CaseSearchRequest) => caseClient.search(search));
};
