import { isDate, isNil } from 'lodash-es';

export type SortType = 'Alphabetical' | 'Numerical' | 'Chronological';

export function orderBy<T = Record<string, unknown>>(
  items: T[],
  sortKey: keyof T,
  sortDescending: boolean,
  sortType: SortType,
  sortNilOrEmptyLast?: boolean
): T[] {
  switch (sortType) {
    case 'Chronological':
      return orderChronologically(items, sortKey, sortDescending, sortNilOrEmptyLast);
    case 'Numerical':
      return orderNumerically(items, sortKey, sortDescending, sortNilOrEmptyLast);
    case 'Alphabetical':
    default:
      return orderAlphabetically(items, sortKey, sortDescending, sortNilOrEmptyLast);
  }
}

export function orderChronologically<T = Record<string, unknown>>(
  items: T[],
  sortKey: keyof T,
  sortDescending: boolean,
  sortNilOrEmptyLast?: boolean
): T[] {
  const sorted = [...items].sort((a: T, b: T): number => {
    let valueA = a[sortKey] ? a[sortKey] + '' : '';
    let valueB = b[sortKey] ? b[sortKey] + '' : '';

    let result = 0;

    // Treat null/empty dates as future dates
    if (!valueA && !valueB) {
      result = 0;
    } else if (!valueA && valueB) {
      result = sortDescending && !sortNilOrEmptyLast ? -1 : 1;
    } else if (valueA && !valueB) {
      result = sortDescending && !sortNilOrEmptyLast ? 1 : -1;
    } else {
      const valueADate = new Date(valueA);
      const valueBDate = new Date(valueB);

      const isDateComparison = isDate(valueADate) && isDate(valueBDate);

      // Formate value as ISO string so they can be compared as usual
      if (isDateComparison) {
        valueA = valueADate.toISOString();
        valueB = valueBDate.toISOString();
      }

      result = valueA.localeCompare(valueB) * (sortDescending ? -1 : 1);
    }

    return result;
  });

  return sorted as T[];
}

export function orderNumerically<T = Record<string, unknown>>(
  items: T[],
  sortKey: keyof T,
  sortDescending: boolean,
  sortNilOrEmptyLast?: boolean
): T[] {
  const sorted = [...items].sort((a: T, b: T): number => {
    const valueA = !isNil(a[sortKey]) ? Number(a[sortKey]) : null;
    const valueB = !isNil(b[sortKey]) ? Number(b[sortKey]) : null;

    let result = 0;

    if (valueA === null && valueB === null) {
      result = 0;
    } else if (valueA === null && valueB !== null) {
      result = sortDescending && !sortNilOrEmptyLast ? -1 : 1;
    } else if (valueA !== null && valueB === null) {
      result = sortDescending && !sortNilOrEmptyLast ? 1 : -1;
    } else {
      result = (valueA as number) > (valueB as number) ? 1 : (valueA as number) < (valueB as number) ? -1 : 0;

      if (sortDescending) {
        result *= -1;
      }
    }

    return result;
  });

  return sorted as T[];
}

export function orderAlphabetically<T = Record<string, unknown>>(
  items: T[],
  sortKey: keyof T,
  sortDescending: boolean,
  sortNilOrEmptyLast?: boolean
): T[] {
  const sorted = [...items].sort((a: T, b: T): number => {
    const valueA = a[sortKey] ? a[sortKey] + '' : '';
    const valueB = b[sortKey] ? b[sortKey] + '' : '';

    let result = 0;

    if (!valueA && !valueB) {
      result = 0;
    } else if (!valueA && valueB) {
      result = sortDescending && !sortNilOrEmptyLast ? -1 : 1;
    } else if (valueA && !valueB) {
      result = sortDescending && !sortNilOrEmptyLast ? 1 : -1;
    } else {
      result = valueA.localeCompare(valueB) * (sortDescending ? -1 : 1);
    }

    return result;
  });

  return sorted as T[];
}
