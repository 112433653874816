import { ALIGN, Radio } from 'baseui/radio';
import { useField } from 'formik';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComboboxItem } from '../../api/api.generated';
import { useCustomerWaiting, useKeyPlacementCodesQuery } from '../../api/queries/useCaseMasterDataQueries';
import { FastRadioGroupField as RadioGroupField } from '../../shared/formFields/RadioGroupField';
import { FastSelectField as SelectField } from '../../shared/formFields/SelectField';
import { FastTextareaField as TextareaField } from '../../shared/formFields/TextareaField';
import { Waiting } from '../../shared/types';
import FormGroup from '../../shared/utilityComponents/FormGroup';
import { nameof } from '../caseRegistrationFunctions';

function WaitingFormGroupComp() {
  const tWaiting = useTranslation('translation', { keyPrefix: 'waitingForm' }).t;

  const [field] = useField(nameof('incidentWaitingField'));
  const [, , waitingFieldHelper] = useField(nameof('incidentWaitingInfo'));
  const [, , keyFieldHelper] = useField(nameof('incidentKeyPlacementCode'));
  const [waitingCodesSorted, setWaitingCodeSorted] = useState<ComboboxItem[]>();

  const { value: waiting } = field;

  const { data: waitingCodes } = useCustomerWaiting();
  const { data: keyPlacementCodes } = useKeyPlacementCodesQuery();

  //Remove "not waiting"-value from list shown under "waiting"
  useEffect(() => {
    if (waitingCodes) {
      setWaitingCodeSorted([...waitingCodes.filter((w) => w.id !== 'I')]);
    }
  }, [waitingCodes]);

  function updateWaitingInfo(value: string | number) {
    if (value === Waiting.waiting) {
      waitingFieldHelper.setValue('');
      keyFieldHelper.setValue('');
    } else if (value === Waiting.notWaiting) {
      //Setting value to "not waiting"-code
      waitingFieldHelper.setValue('I');
    }
  }

  return (
    <FormGroup label={tWaiting('waitingInfo')}>
      <RadioGroupField
        name={nameof('incidentWaitingField')}
        label={`${tWaiting('waitingHeader')}*`}
        align={ALIGN.horizontal}
        onChange={(value: string | number) => updateWaitingInfo(value)}
      >
        <Radio value={Waiting.waiting}>{tWaiting('waiting')}</Radio>
        <Radio value={Waiting.notWaiting}>{tWaiting('notWaiting')}</Radio>
      </RadioGroupField>

      {waiting === Waiting.waiting ? (
        <SelectField
          options={waitingCodesSorted}
          label={`${tWaiting('details')}*`}
          name={nameof('incidentWaitingInfo')}
        />
      ) : waiting === Waiting.notWaiting ? (
        <SelectField
          options={keyPlacementCodes}
          label={`${tWaiting('keyPlacement')}*`}
          name={nameof('incidentKeyPlacementCode')}
        />
      ) : null}

      <TextareaField label={tWaiting('waitingInfoDescription')} name="incidentWaitingInfoDescription" maxLength={60} />
    </FormGroup>
  );
}

export const WaitingFormGroup = memo(WaitingFormGroupComp);
