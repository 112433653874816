import { FormikContextType, GenericFieldHTMLAttributes } from 'formik';
import { isEmptyArray, isEmptyObject } from '../functions/general';

type DefaultType = Record<string, unknown>;

export function customShouldComponentUpdate(
  nextProps: DefaultType & GenericFieldHTMLAttributes & { formik: FormikContextType<DefaultType> },
  currProps: DefaultType & GenericFieldHTMLAttributes & { formik: FormikContextType<DefaultType> }
): boolean {
  // Name prop is mandatory on <FastField />
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const nextName = nextProps.name!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currName = currProps.name!;

  const nextValue = (nextProps.formik.values as DefaultType)[nextName];
  const currValue = (currProps.formik.values as DefaultType)[currName];
  const nextError = (nextProps.formik.errors as DefaultType)[nextName];
  const currError = (currProps.formik.errors as DefaultType)[currName];
  const nextTouched = (nextProps.formik.touched as DefaultType)[nextName];
  const currTouched = (currProps.formik.touched as DefaultType)[currName];

  // Default formik checks (sans value checking)
  if (
    nextProps.name !== currProps.name ||
    nextError !== currError ||
    nextTouched !== currTouched ||
    nextProps.disabled !== currProps.disabled || // soon to be deprecated
    nextProps.required !== currProps.required || // soon to be deprecated
    nextProps.formik.isSubmitting !== currProps.formik.isSubmitting
  )
    return true;

  if (areValuesDifferent(nextValue, currValue)) return true;

  if (Object.keys(nextProps).length !== Object.keys(currProps).length) return true;

  const nextDeps = (nextProps.deps as unknown[]) ?? [];
  const currDeps = (currProps.deps as unknown[]) ?? [];

  if (nextDeps.length !== currDeps.length) return true;

  for (let i = 0; i < nextDeps.length; i++) {
    if (areValuesDifferent(nextDeps[i], currDeps[i])) return true;
  }

  return false;
}

const areValuesDifferent = (value1: unknown, value2: unknown): boolean => {
  // If both values are empty arrays or empty objects, consider them the same value
  if ((isEmptyArray(value1) && isEmptyArray(value2)) || (isEmptyObject(value1) && isEmptyObject(value2))) {
    return false;
  }

  return value1 !== value2;
};
