import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { useFormikContext } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { isFieldRequired, nameof } from '../../caseRegistration/caseRegistrationFunctions';
import { CaseRegistrationFormFields } from '../../caseRegistration/caseRegistrationModels';
import { FastDateField as DateField } from '../../shared/formFields/DateField';
import { FastInputField as InputField } from '../../shared/formFields/InputField';
import FormGroup from '../../shared/utilityComponents/FormGroup';

function NewVehicleInfoFormGroupComp() {
  const tNew = useTranslation('translation', { keyPrefix: 'newVehicleInfoForm' }).t;
  const formik = useFormikContext<CaseRegistrationFormFields>();
  const requiredFieldsAgreement = formik.values.agreementInfo?.caseFields;

  return (
    <FormGroup label={tNew('newCarInfo')}>
      <InputField
        label={tNew('originalCarDealership')}
        name={nameof('vehicleOriginalDealerName')}
        //Readonly until we know if this is workshop field or freeText
        readOnly
      />
      <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale200">
        <FlexGridItem>
          <DateField
            label={
              isFieldRequired('vehicleFirstRegistrationDate', requiredFieldsAgreement)
                ? `${tNew('firstReg')}*`
                : tNew('firstReg')
            }
            name={nameof('vehicleFirstRegistrationDate')}
            noFuture
          />
        </FlexGridItem>
      </FlexGrid>
    </FormGroup>
  );
}
export const NewVehicleInfoFormGroup = memo(NewVehicleInfoFormGroupComp);
