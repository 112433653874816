import { Children, memo, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props extends google.maps.InfoWindowOptions {
  children?: ReactNode;
}

// I don't know what to call this
interface PropsWithInternalProps extends Props {
  map: google.maps.Map;
  marker: google.maps.Marker;
  onClose: () => void;
  isOpen: boolean;
}

const MapInfoWindowComp = (props: Props) => {
  const { children, map, marker, onClose, isOpen, ...infoWindowOptions } = props as PropsWithInternalProps;

  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();

  const closeClickListenerRef = useRef<google.maps.MapsEventListener>();

  const containerElement = document.createElement('div');

  useEffect(() => {
    if (!infoWindow) {
      setInfoWindow(new google.maps.InfoWindow({ disableAutoPan: true }));
    } else {
      if (isOpen && children) {
        infoWindow.setContent(containerElement);
        infoWindow.open({ ...map, shouldFocus: false }, marker);
        closeClickListenerRef.current = infoWindow.addListener('closeclick', () => {
          onClose();
        });
      } else {
        infoWindow.close();
      }
    }

    const closeClickListener = closeClickListenerRef.current;

    return () => {
      infoWindow?.close();
      closeClickListener?.remove();
    };
  }, [infoWindow, map, marker, onClose, isOpen, children, containerElement]);

  useEffect(() => {
    if (infoWindow) {
      infoWindow.setOptions(infoWindowOptions);
    }
  }, [infoWindow, infoWindowOptions]);

  return createPortal(Children.only(children), containerElement);
};

export const MapInfoWindow = memo(MapInfoWindowComp);
