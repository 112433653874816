import { KIND } from 'baseui/button';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, ROLE } from 'baseui/modal';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFooterButton } from './ModalFooterButton';

interface Props extends Pick<ModalProps, 'isOpen' | 'overrides'> {
  type: 'alert' | 'confirm' | 'yesNo' | 'custom';
  header: ReactNode;
  body: ReactNode;
  customButtons?: string[];
  onClose: (buttonIndex?: number) => void;
  isLoading?: boolean;
}

export const AlertDialog = (props: Props) => {
  const { type, header, body, isOpen, onClose, overrides, customButtons, isLoading } = props;

  const t = useTranslation('translation').t;

  const buttons =
    type !== 'custom' ? buttonMap[type].map((b) => t(`shared.${b}`)) : (customButtons ?? []).map((b) => t(b));

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} overrides={overrides} role={ROLE.alertdialog}>
      <ModalHeader>{header}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <ButtonArray buttons={buttons} onButtonClick={onClose} isLoading={isLoading} />
      </ModalFooter>
    </Modal>
  );
};

const ButtonArray = ({
  buttons,
  onButtonClick,
  isLoading,
}: {
  buttons: string[];
  onButtonClick: (buttonIndex?: number) => void;
  isLoading?: boolean;
}) => {
  return (
    <>
      {[...buttons].reverse().map((b, i) => {
        const isPrimary = i === buttons.length - 1;

        return (
          <ModalFooterButton
            $wideButton={false}
            key={b}
            type="button"
            disabled={isPrimary && isLoading}
            isLoading={isPrimary && isLoading}
            kind={isPrimary ? KIND.primary : KIND.secondary}
            onClick={() => onButtonClick(buttons.length - 1 - i)}
          >
            {b}
          </ModalFooterButton>
        );
      })}
    </>
  );
};

const buttonMap: Record<string, string[]> = {
  alert: ['ok'],
  confirm: ['ok', 'cancel'],
  yesNo: ['yes', 'no'],
};
