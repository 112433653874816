import { isNil } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { getDateTimeFormatterGroup } from '../functions/dateTimeFormatContainer';
import {
  convertUtcToZonedTime,
  convertZonedTimeToUtc,
  getCurrentDate,
  parseMaybeJsonDate,
  TimePrecisionEnum,
} from '../functions/dateUtils';
import { useTimeZoneContext } from '../TimeZoneContext';
import { useUser } from './useUser';

interface UseZonedDateUtilsHook {
  formatDate: (date?: Date) => string | undefined;
  formatTime: (date?: Date) => string | undefined;
  formatDateTime: (date?: Date) => string | undefined;
  convertUtcToZonedTime: (date: Date) => Date;
  convertZonedTimeToUtc: (date: Date) => Date;
  getCurrentDate: (precision?: TimePrecisionEnum) => Date;
}

export const useZonedDateUtils = (): UseZonedDateUtilsHook => {
  const { languageCode, callCenterTimeZone: userTimeZone } = useUser();

  const { timeZone: contextTimeZone } = useTimeZoneContext();

  const timeZone = useMemo(() => {
    if (contextTimeZone) {
      return contextTimeZone;
    }

    return userTimeZone;
  }, [contextTimeZone, userTimeZone]);

  const [dateFormatter, timeFormatter, dateTimeFormatter] = useMemo(() => {
    const formatterGroup = getDateTimeFormatterGroup(languageCode, timeZone);

    return [formatterGroup.dateFormatter, formatterGroup.timeFormatter, formatterGroup.dateTimeFormatter];
  }, [languageCode, timeZone]);

  const convertUtcToZonedTimeCb = useCallback(
    (date: Date) => {
      return convertUtcToZonedTime(date, timeZone);
    },
    [timeZone]
  );

  const convertZonedTimeToUtcCb = useCallback(
    (date: Date) => {
      return convertZonedTimeToUtc(date, timeZone);
    },
    [timeZone]
  );

  const formatDate = useCallback(
    (date?: Date) => {
      if (isNil(date)) return;

      // This check and parsing correction is out of scope for this function, but it's a temporary fix:tm:
      // before I reconfigure nswag to handle date parsing properly
      if (typeof date === 'string') date = parseMaybeJsonDate(date);

      return dateFormatter.format(date);
    },
    [dateFormatter]
  );

  const formatTime = useCallback(
    (date?: Date) => {
      if (isNil(date)) return;

      // This check and parsing correction is out of scope for this function, but it's a temporary fix:tm:
      // before I reconfigure nswag to handle date parsing properly
      if (typeof date === 'string') date = parseMaybeJsonDate(date);

      return timeFormatter.format(date);
    },
    [timeFormatter]
  );

  const formatDateTime = useCallback(
    (date?: Date) => {
      if (isNil(date)) return;

      // This check and parsing correction is out of scope for this function, but it's a temporary fix:tm:
      // before I reconfigure nswag to handle date parsing properly
      if (typeof date === 'string') date = parseMaybeJsonDate(date);

      return dateTimeFormatter.format(date);
    },
    [dateTimeFormatter]
  );

  const getCurrentDateCb = useCallback(getCurrentDate, []);

  return {
    formatDate,
    formatTime,
    formatDateTime,
    convertUtcToZonedTime: convertUtcToZonedTimeCb,
    convertZonedTimeToUtc: convertZonedTimeToUtcCb,
    getCurrentDate: getCurrentDateCb,
  };
};
