import { FormControl } from 'baseui/form-control';
import { FastField, Field, FieldInputProps, FieldProps, FieldValidator } from 'formik';
import { ChangeEvent, KeyboardEvent, KeyboardEventHandler, useRef } from 'react';
import DebouncedInput, { DebouncedInputProps } from '../inputs/DebouncedInput';
import { FakeBlurEvent, FakeFormikEvent } from '../types';
import { customShouldComponentUpdate } from './fastFormFieldFunctions';

type SafeInputProps = Omit<DebouncedInputProps, 'value' | 'error' | 'onChange' | 'onBlur' | 'onKeyPress'>;

interface CommonInputFieldProps {
  label?: string;
  onChange?: (v: string | undefined) => void;
  onBlur?: () => void;
  onKeyPress?: KeyboardEventHandler;
  upperCase?: boolean;
  caption?: string;
  submitOnEnter?: boolean;
}

interface InputFormControlProps extends CommonInputFieldProps, SafeInputProps {
  fieldProps: FieldProps;
}

export interface InputFieldProps extends CommonInputFieldProps, SafeInputProps {
  name: string;
  validate?: FieldValidator;
}

export interface InputFastFieldProps extends InputFieldProps {
  deps?: unknown[];
}

function InputFormControl(props: InputFormControlProps) {
  const { fieldProps, label, disabled, onChange, onBlur, onKeyPress, caption, submitOnEnter, ...restOfProps } = props;

  const { field, meta } = fieldProps;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const commitOnChange = props.debounceMs !== null && props.debounceMs !== undefined;

  const showError = meta.touched && !!meta.error;

  function smartCommitValue(inputValue: string | undefined, field: FieldInputProps<unknown>): void {
    if (inputValue !== field.value) {
      field.onChange(new FakeFormikEvent(field.name, inputValue));

      if (onChange) onChange(inputValue);
    }
  }

  const labelString = label ? `${label}${props.required ? ' *' : ''}` : undefined;

  return (
    <FormControl label={labelString} disabled={disabled} error={showError && meta.error} caption={caption}>
      <DebouncedInput
        inputRef={inputRef}
        value={field.value}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          if (commitOnChange) {
            const currentInputValue = e.target.value;
            smartCommitValue(currentInputValue, field);
          }
        }}
        onBlur={() => {
          if (!commitOnChange) {
            const currentInputValue = inputRef?.current?.value;

            smartCommitValue(currentInputValue, field);
          }

          setTimeout(() => {
            field.onBlur(new FakeBlurEvent(field.name));
          });

          if (onBlur) onBlur();
        }}
        onKeyPress={(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            !submitOnEnter && e.preventDefault();
            const currentInputValue = inputRef?.current?.value;
            smartCommitValue(currentInputValue, field);
          }

          if (onKeyPress) onKeyPress(e);
        }}
        error={showError}
        clearOnEscape
        autoComplete="remove"
        disabled={disabled}
        {...restOfProps}
      />
    </FormControl>
  );
}

export function FastInputField(props: InputFastFieldProps) {
  const { name, validate, deps, ...restOfProps } = props;

  const fieldDeps = [props.disabled, props.required, props.readOnly, props.placeholder, ...(deps ?? [])];

  return (
    <FastField name={name} validate={validate} shouldUpdate={customShouldComponentUpdate} deps={fieldDeps}>
      {(fieldProps: FieldProps) => <InputFormControl {...restOfProps} fieldProps={fieldProps} />}
    </FastField>
  );
}

export default function InputField(props: InputFieldProps) {
  const { name, validate, ...restOfProps } = props;
  return (
    <Field name={name} validate={validate}>
      {(fieldProps: FieldProps) => <InputFormControl {...restOfProps} fieldProps={fieldProps} />}
    </Field>
  );
}
