import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CaseActionDto,
  CreateCaseActionPaymentGuaranteeCommand,
  CreatePaymentGuaranteeCommand,
} from '../api.generated';
import { useApiClient } from '../apiClientContext';
import { CASE_ACTION_QUERY_KEY, getInvalidateQueriesMutationOption } from './queryUtils';

export const useGetCaseActionsQuery = (caseId: string | undefined) => {
  const { caseActionClient } = useApiClient();

  return useQuery([CASE_ACTION_QUERY_KEY, 'CaseActions', caseId], () =>
    caseActionClient.getCaseActionsByCaseId(caseId)
  );
};

export const useSendNoteToDispatchMutation = () => {
  const { caseActionClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_ACTION_QUERY_KEY]);

  return useMutation((body: CaseActionDto) => caseActionClient.createCaseActionNoteToDispatch(body), options);
};

export const useAdditionalServiceToCaseHandlerMutation = () => {
  const { caseActionClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_ACTION_QUERY_KEY]);

  return useMutation(
    (body: CaseActionDto) => caseActionClient.createCaseActionAdditionalServiceToCaseHandler(body),
    options
  );
};

export const useSendExternalNoteMutation = () => {
  const { caseActionClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_ACTION_QUERY_KEY]);

  return useMutation((body: CaseActionDto) => caseActionClient.createCaseActionExternalNote(body), options);
};

export const useSendNoteToCaseHandlerMutation = () => {
  const { caseActionClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_ACTION_QUERY_KEY]);

  return useMutation((body: CaseActionDto) => caseActionClient.createCaseActionNoteToCaseHandler(body), options);
};

export const useSendDeviationToCaseHandlerMutation = () => {
  const { caseActionClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_ACTION_QUERY_KEY]);

  return useMutation((body: CaseActionDto) => caseActionClient.createCaseActionDeviationToCaseHandler(body), options);
};

export const useSendComplainToCaseHandlerMutation = () => {
  const { caseActionClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_ACTION_QUERY_KEY]);

  return useMutation((body: CaseActionDto) => caseActionClient.createCaseActionComplaintsToCaseHandler(body), options);
};

export const useCreatePaymentGuaranteeMutation = () => {
  const { caseActionClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_ACTION_QUERY_KEY]);

  return useMutation((body: CreatePaymentGuaranteeCommand) => caseActionClient.createPaymentGuarantee(body), options);
};

export const useCreateCaseActionAdditionalServiceWithPaymentGuarantee = () => {
  const { caseActionClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [CASE_ACTION_QUERY_KEY]);

  return useMutation(
    (body: CreateCaseActionPaymentGuaranteeCommand) =>
      caseActionClient.createCaseActionAdditionalServiceWithPaymentGuarantee(body),
    options
  );
};
