import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { AppConfig } from './appConfig';
import * as sourceLocale from './sourceLocale.json';

// https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
// t function was always able to return null by default, but now the type actually reflects that.
// This forces it to return a placeholder or an empty string or something
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export function configureLocalization(appConfig: AppConfig): void {
  i18n
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
      lng: appConfig.defaultLanguage,
      fallbackLng: appConfig.fallbackLanguage,
      supportedLngs: ['source', 'en', 'nb', 'sv', 'da', 'fi'],
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      backend: {
        backends: [
          resourcesToBackend({
            source: {
              translation: sourceLocale,
            },
          }),
          HttpBackend,
        ],
        backendOptions: [
          {},
          {
            loadPath: `${appConfig.localizationCdnUrl}/{{lng}}/{{ns}}.json`,
          },
        ],
      },
      returnNull: false,
    });
}
