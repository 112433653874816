import { useStyletron } from 'baseui';
import { Modal, ModalBody, ModalHeader, ROLE, ModalFooter } from 'baseui/modal';
import { ParagraphMedium } from 'baseui/typography';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../shared/basewebComponentOverrides/Button';

interface MissingFieldsModalProps {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
  setShowRegisterCase: (open: boolean) => void;
  missingFields: string[];
}

function MissingFieldsModalComp(props: MissingFieldsModalProps) {
  const tShared = useTranslation('translation', { keyPrefix: 'caseRegistration' }).t;
  const tMissingfields = useTranslation('translation', { keyPrefix: 'missingFields' }).t;

  const [css] = useStyletron();

  function handleSubmit(register: boolean) {
    props.setIsOpen(false);
    props.setShowRegisterCase(register);
  }

  return (
    <Modal
      onClose={() => handleSubmit(false)}
      closeable
      isOpen={props.isOpen}
      animate
      autoFocus
      role={ROLE.dialog}
      overrides={{
        Dialog: {
          style: {
            width: '40vw',
          },
        },
      }}
    >
      <ModalHeader>{tShared('missingFields')}</ModalHeader>
      <ModalBody>
        <ParagraphMedium>{tShared('missingFieldsPreText')}</ParagraphMedium>
        <ul className={css({})}>
          {props.missingFields.map((field) => {
            return <li key={field}>{tMissingfields(field)}</li>;
          })}
        </ul>

        <ParagraphMedium>{tShared('missingFieldsText')}</ParagraphMedium>
      </ModalBody>
      <ModalFooter className={css({ display: 'flex', justifyContent: 'flex-end' })}>
        <Button
          type="button"
          $wideButton
          className={css({ marginRight: '1em' })}
          kind="secondary"
          onClick={() => handleSubmit(false)}
        >
          {tShared('goBack')}
        </Button>
        <Button type="button" $wideButton onClick={() => handleSubmit(true)}>
          {tShared('continueAnyway')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
export const MissingFieldsModal = memo(MissingFieldsModalComp);
