import { Block } from 'baseui/block';
import { Country } from 'baseui/phone-input';
import { FieldValidator } from 'formik';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdSearch } from 'react-icons/md';
import { NumberLookup } from '../../numberLookup/NumberLookup';
import { Button } from '../../shared/basewebComponentOverrides/Button';
import { isPhoneNumberValid } from '../functions/general';
import InputField, { FastInputField } from './InputField';

export type PhoneNumberFieldValue = {
  country?: Country;
  text?: string;
};

interface FieldProps {
  name: string;
  label: string;
}

interface PhoneNumberFieldProps {
  mapping?: string;
  search?: boolean;
}

function BasePhoneNumberField(props: PhoneNumberFieldProps & { children: ReactNode }) {
  const { search, mapping, children } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Block display="flex" alignItems="end">
        <Block flex={1}>{children}</Block>
        {search ? (
          <Block flex={0} paddingLeft="scale200">
            <Block marginBottom="scale600">
              <Button
                type="button"
                onClick={() => {
                  setIsOpen(true);
                }}
                kind="secondary"
              >
                <MdSearch />
              </Button>
            </Block>
          </Block>
        ) : null}
      </Block>
      <NumberLookup isOpen={isOpen} setIsOpen={setIsOpen} mapping={mapping ?? ''} />
    </>
  );
}

export default function PhoneNumberField(props: PhoneNumberFieldProps & FieldProps) {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const { search, mapping, ...restOfProps } = props;

  const phoneValidator: FieldValidator = (value: string) => {
    if (!value?.trim()) return;

    if (value.length < 4) return;

    if (isPhoneNumberValid(value)) return;

    return tShared('validPhoneNumber') as string;
  };

  return (
    <BasePhoneNumberField search={search} mapping={mapping}>
      <InputField {...restOfProps} validate={phoneValidator} />
    </BasePhoneNumberField>
  );
}

export function FastPhoneNumberField(props: PhoneNumberFieldProps & FieldProps) {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const { search, mapping, ...restOfProps } = props;

  const phoneValidator: FieldValidator = (value: string) => {
    // If it's empty or just a dial code, consider valid to allow saving
    if (!value?.trim() || value.length <= 4) return;

    if (isPhoneNumberValid(value)) return;

    return tShared('validPhoneNumber') as string;
  };

  return (
    <BasePhoneNumberField search={search} mapping={mapping}>
      <FastInputField {...restOfProps} validate={phoneValidator} />
    </BasePhoneNumberField>
  );
}
