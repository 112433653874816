import { SortType } from '../shared/functions/orderFunctions';

export interface CaseSearchResultItem {
  visCaseId?: number;
  registrationNumber?: string;
  incidentDate?: Date;
  incidentReason?: string;
  incidentCityAndCountry?: string;
  makeAndModel?: string;
  owner?: string;
  workshop?: string;
  stateOfCase?: string;
  callCenterCountry?: string;
}

export const SEARCH_RESULT_ITEM_TO_SORT_TYPE_MAP: Record<keyof CaseSearchResultItem | string, SortType> = {
  visCaseId: 'Numerical',
  registrationNumber: 'Alphabetical',
  incidentDate: 'Chronological',
  incidentReason: 'Alphabetical',
  incidentCityAndCountry: 'Alphabetical',
  makeAndModel: 'Alphabetical',
  owner: 'Alphabetical',
  workshop: 'Alphabetical',
  stateOfCase: 'Alphabetical',
};
