import { ButtonGroup } from 'baseui/button-group';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Tag } from 'baseui/tag';
import { has, isDate } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { Button } from '@@shared/basewebComponentOverrides/Button';
import { CaseSearchRequest } from '../api/api.generated';
import { CaseSearchRequestFormFields } from '../caseHistory/sharedFunctions';
import { isRecordObject } from '../shared/functions/general';
import { useZonedDateUtils } from '../shared/hooks/useZonedDateUtils';
import { Filter } from './CaseSearchPage';

interface Props {
  formValues: CaseSearchRequestFormFields;
  isLoading: boolean;
  resetFilters: () => void;
  removeFilter: (filter: Filter) => void;
}

export const CaseSearchFilters = ({ formValues, isLoading, resetFilters, removeFilter }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'searchFilterLabels' });
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;

  const { formatDateTime } = useZonedDateUtils();

  const filters = useMemo(() => {
    return Object.entries(formValues)
      .filter(([, v]) => {
        if (v !== null) {
          if (isDate(v)) {
            return v !== undefined;
          } else if (isRecordObject(v) && has(v, 'name')) {
            return v.name !== undefined;
          } else {
            return v !== undefined && !/^\s*$/.test(v);
          }
        }
      })
      .map((t) => {
        const filter: Filter = { label: t[0], value: t[1], id: t[0] as keyof CaseSearchRequest };
        return filter;
      });
  }, [formValues]);

  const getLabel = (filter: Filter, index: number) => {
    if (isDate(filter.value)) {
      return (
        <Tag kind="neutral" key={`tag-${index}`} onActionClick={() => removeFilter(filter)}>
          {`${t(filter.label)}: ${formatDateTime(filter.value as Date)}`}
        </Tag>
      );
    } else if (typeof filter.value === 'boolean') {
      return (
        <Tag kind="neutral" key={`tag-${index}`} onActionClick={() => removeFilter(filter)}>
          {t(filter.label)}
        </Tag>
      );
    } else if (isRecordObject(filter.value) && has(filter.value, 'name')) {
      return (
        <Tag kind="neutral" key={`tag-${index}`} onActionClick={() => removeFilter(filter)}>
          {`${t(filter.label)}:${filter.value.name}`}
        </Tag>
      );
    } else {
      return (
        <Tag kind="neutral" key={`tag-${index}`} onActionClick={() => removeFilter(filter)}>
          {`${t(filter.label)}:${filter.value}`}
        </Tag>
      );
    }
  };

  return (
    <FlexGrid flexGridColumnCount={2} alignItems={'center'}>
      <FlexGridItem>
        {filters.map((f, i) => {
          return getLabel(f, i);
        })}
      </FlexGridItem>
      <FlexGridItem alignSelf={'flex-end'}>
        <ButtonGroup overrides={{ Root: { style: { justifyContent: 'end' } } }}>
          <Button
            startEnhancer={() => <FaTimes />}
            onClick={resetFilters}
            type="button"
            disabled={filters.length < 1 || isLoading}
          >
            {tShared('reset')}
          </Button>
          <Button
            isSelected={true}
            startEnhancer={() => <FaSearch />}
            type="submit"
            isLoading={isLoading}
            disabled={filters.length < 1}
            overrides={{ BaseButton: { style: { marginLeft: '16px' } } }}
          >
            {tShared('search')}
          </Button>
        </ButtonGroup>
      </FlexGridItem>
    </FlexGrid>
  );
};
