import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { DeleteAlt } from 'baseui/icon';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { Theme } from 'baseui/theme';
import { useFormikContext } from 'formik';
import { CSSProperties, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCopy, FaInfoCircle } from 'react-icons/fa';
import { DekningResultat } from '../api/api.generated';
import { useCountriesQuery } from '../api/queries/useActorQueries';
import { useDriveTrainsQuery, useFuelsQuery } from '../api/queries/useCaseMasterDataQueries';
import { LeasingDrawer } from '../caseRegistration/caseDrawers/LeasingDrawer';
import { CaseRegistrationFormFields } from '../caseRegistration/caseRegistrationModels';
import { Button } from '../shared/basewebComponentOverrides/Button';
import { ensureIsDate, formatDate } from '../shared/functions/dateUtils';
import { isPhoneNumberValid } from '../shared/functions/general';
import InfoRow from '../shared/info/InfoRow';
import { expandPadding } from '../styles/styleUtils';
import { EviVolvoErrors } from './EviVolvoErrors';

interface SelectedRowState {
  source: number | undefined;
  vehicleInfo: number | undefined;
  owner: number | undefined;
  newVehicleInfo: number | undefined;
  serviceInfo: number | undefined;
  insuranceInfo: number | undefined;
}

interface EviResultTableProps {
  isLoading: boolean;
  searchResults: DekningResultat[];
  setIsOpen: (value: boolean) => void;
}

function EviResultTableComp(props: EviResultTableProps) {
  const { isLoading, setIsOpen, searchResults } = props;

  const [css, theme] = useStyletron();

  const [leasingDrawer, setLeasingDrawer] = useState(false);
  const [selectedLeasingState, setSelectedLeasingState] = useState<number>();

  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tEvi = useTranslation('translation', { keyPrefix: 'evi' }).t;
  const tVehicle = useTranslation('translation', { keyPrefix: 'vehicleInfoForm' }).t;
  const tNewVehicle = useTranslation('translation', { keyPrefix: 'newVehicleInfoForm' }).t;
  const tService = useTranslation('translation', { keyPrefix: 'serviceInfoForm' }).t;
  const tInsurance = useTranslation('translation', { keyPrefix: 'insuranceForm' }).t;
  const tLeasing = useTranslation('translation', { keyPrefix: 'leasing' }).t;

  const [selectedRowState, setSelectedRowState] = useState<SelectedRowState>({
    source: undefined,
    vehicleInfo: undefined,
    owner: undefined,
    newVehicleInfo: undefined,
    serviceInfo: undefined,
    insuranceInfo: undefined,
  });

  const [sourceRowHover, setSourceRowHover] = useState<number | undefined>(undefined);

  const { setValues } = useFormikContext<CaseRegistrationFormFields>();
  const { data: fuels = [] } = useFuelsQuery();
  const { data: driveTrain = [] } = useDriveTrainsQuery();
  const { data: countries = [] } = useCountriesQuery();

  useEffect(() => {
    setSelectedRowState({
      source: undefined,
      vehicleInfo: undefined,
      owner: undefined,
      newVehicleInfo: undefined,
      serviceInfo: undefined,
      insuranceInfo: undefined,
    });
  }, [searchResults]);

  function handleRadioClick(column: keyof SelectedRowState, rowNumber: number | undefined) {
    if (column === 'source') {
      if (rowNumber === selectedRowState.source) {
        setSelectedRowState({
          source: undefined,
          vehicleInfo: undefined,
          owner: undefined,
          newVehicleInfo: undefined,
          serviceInfo: undefined,
          insuranceInfo: undefined,
        });
      } else {
        setSelectedRowState({
          source: rowNumber,
          vehicleInfo: rowNumber,
          owner: rowNumber,
          newVehicleInfo: rowNumber,
          serviceInfo: rowNumber,
          insuranceInfo: rowNumber,
        });
      }
    } else {
      if (selectedRowState[column] === rowNumber) {
        setSelectedRowState({ ...selectedRowState, [column]: undefined });
      } else {
        setSelectedRowState({ ...selectedRowState, source: undefined, [column]: rowNumber });
      }
    }
  }

  //Function for updating values to case
  function updateValues() {
    const newValues: Partial<CaseRegistrationFormFields> = {};

    if (selectedRowState.vehicleInfo !== undefined) {
      const selectedVehicleInfo = searchResults[selectedRowState.vehicleInfo];

      if (selectedVehicleInfo.objektNr) newValues.vehicleRegistrationNumber = selectedVehicleInfo.objektNr;

      if (selectedVehicleInfo.merke || selectedVehicleInfo.merkeNavn)
        newValues.vehicleBrand = {
          name: selectedVehicleInfo.merkeNavn,
          id: selectedVehicleInfo.merke,
        };

      if (selectedVehicleInfo.modell || selectedVehicleInfo.modellNavn) {
        newValues.vehicleModel = {
          id: selectedVehicleInfo.modell,
          name: selectedVehicleInfo.modellNavn,
        };
      } else {
        newValues.vehicleModel = undefined;
      }

      if (selectedVehicleInfo.drivstoff) newValues.vehicleFuelType = selectedVehicleInfo.drivstoff;
      if (selectedVehicleInfo.arsModell) newValues.vehicleModelYear = selectedVehicleInfo.arsModell.toString();
      if (selectedVehicleInfo.drivverk) newValues.vehicleDriveTrain = selectedVehicleInfo.drivverk;
      if (selectedVehicleInfo.vekt) newValues.vehicleWeight = selectedVehicleInfo.vekt.toString();
      if (selectedVehicleInfo.tillattTotalVekt)
        newValues.vehicleAllowedWeight = selectedVehicleInfo.tillattTotalVekt.toString();
      if (selectedVehicleInfo.chassisNr) newValues.vehicleChassis = selectedVehicleInfo.chassisNr;

      if (selectedVehicleInfo.sisteServiceTilKm)
        newValues.vehicleLastServiceKmSum = selectedVehicleInfo.sisteServiceTilKm.toString();

      if (selectedVehicleInfo.yrkesTrafikk) newValues.vehicleIsUsedCommercially = selectedVehicleInfo.yrkesTrafikk;

      if (selectedVehicleInfo.kjøretøyType) newValues.vehicleTypeCode = selectedVehicleInfo.kjøretøyType;

      if (selectedVehicleInfo.sisteRegDato)
        newValues.vehicleLastRegistrationDate = ensureIsDate(selectedVehicleInfo.sisteRegDato);
    }

    if (selectedRowState.newVehicleInfo !== undefined) {
      const selectedNewVehicleInfo = searchResults[selectedRowState.newVehicleInfo];

      if (selectedNewVehicleInfo.forsteRegDato)
        newValues.vehicleFirstRegistrationDate = ensureIsDate(selectedNewVehicleInfo.forsteRegDato);

      if (selectedNewVehicleInfo.forhandlerJENavn)
        newValues.vehicleOriginalDealerName = selectedNewVehicleInfo.forhandlerJENavn;
    }

    if (selectedRowState.serviceInfo !== undefined) {
      const selectedServiceInfo = searchResults[selectedRowState.serviceInfo];

      if (selectedServiceInfo.sisteServiceForhandlerNavn)
        newValues.vehicleLastServiceLocationName = selectedServiceInfo.sisteServiceForhandlerNavn;

      if (selectedServiceInfo.sisteServiceGyldigTilKm)
        newValues.vehicleServiceWarrantyMaximumTotalKm = selectedServiceInfo.sisteServiceGyldigTilKm.toString();

      if (selectedServiceInfo.sisteServiceTilDato)
        newValues.vehicleLastServiceDate = ensureIsDate(selectedServiceInfo.sisteServiceTilDato);
      else if (selectedServiceInfo.fraDato)
        newValues.vehicleLastServiceDate = ensureIsDate(selectedServiceInfo.fraDato);

      if (selectedServiceInfo.sisteServiceGyldigTilDato)
        newValues.vehicleServiceWarrantyValidTo = ensureIsDate(selectedServiceInfo.sisteServiceGyldigTilDato);
      else if (selectedServiceInfo.tilDato)
        newValues.vehicleServiceWarrantyValidTo = ensureIsDate(selectedServiceInfo.tilDato);
    }

    if (selectedRowState.owner !== undefined) {
      const selectedOwner = searchResults[selectedRowState.owner];

      if (selectedOwner.eierNavn) newValues.ownerName = selectedOwner.eierNavn;

      if (selectedOwner.eierAdresse) newValues.ownerAddressStreet = selectedOwner.eierAdresse;
      if (selectedOwner.eierTlf) newValues.ownerPhone = selectedOwner.eierTlf;
      if (selectedOwner.eierPostNr) newValues.ownerAddressPostalCode = selectedOwner.eierPostNr;
      if (selectedOwner.eierPostSted) newValues.ownerAddressCity = selectedOwner.eierPostSted;
      if (selectedOwner.eierLand) newValues.ownerAddressCountry = selectedOwner.eierLand;
      if (selectedOwner.eierEpost) newValues.ownerEmail = selectedOwner.eierEpost;
      //Leasingtaker info
      let leasingInfo = '';
      if (selectedOwner.leietakerNavn) leasingInfo += selectedOwner.leietakerNavn + '\n';
      if (selectedOwner.leietakerAdresse) leasingInfo += selectedOwner.leietakerAdresse + '\n';
      if (selectedOwner.leietakerPostNr) leasingInfo += selectedOwner.leietakerPostNr + '\n';
      if (selectedOwner.leietakerPostSted) leasingInfo += selectedOwner.leietakerPostSted + '\n';
      if (selectedOwner.leietakerLand)
        leasingInfo += countries.find((c) => c.id === selectedOwner.leietakerLand)?.name + '\n';
      newValues.leasingTaker = leasingInfo;
    }

    if (selectedRowState.insuranceInfo !== undefined) {
      const selectedInsuranceInfo = searchResults[selectedRowState.insuranceInfo];

      if (selectedInsuranceInfo.jeForsikringselskap || selectedInsuranceInfo.forsikringsselskapNavn)
        newValues.insuranceCompany = {
          id: selectedInsuranceInfo.jeForsikringselskap,
          name: selectedInsuranceInfo.forsikringsselskapNavn,
        };

      if (selectedInsuranceInfo.dekning) newValues.insuranceType = selectedInsuranceInfo.dekning;
      if (selectedInsuranceInfo.egenandel) newValues.insuranceDeductible = selectedInsuranceInfo.egenandel;

      if (selectedInsuranceInfo.refNr) newValues.insuranceReference = selectedInsuranceInfo.refNr;

      if (selectedInsuranceInfo.oppdragDetTekst)
        newValues.insuranceReadOnlyInfo = selectedInsuranceInfo.oppdragDetTekst;
    }

    setValues((v) => ({ ...v, ...newValues }));

    setIsOpen(false);
  }

  //Disable button until row is choosen
  function enableButton() {
    if (selectedRowState.source !== undefined) {
      return false;
    } else if (
      selectedRowState.owner !== undefined ||
      selectedRowState.newVehicleInfo !== undefined ||
      selectedRowState.vehicleInfo !== undefined ||
      selectedRowState.serviceInfo !== undefined ||
      selectedRowState.insuranceInfo !== undefined
    ) {
      return false;
    }
    return true;
  }

  const overrides = {
    Table: {
      style: {
        height: '100%',
      },
    },

    TableHeadCell: {
      style: ({ $theme }: { $theme: Theme }) => ({
        backgroundColor: $theme.colors.backgroundPrimary,
      }),
    },

    TableBodyCell: {
      style: ({ $theme }: { $theme: Theme }) => ({
        borderRight: `1px solid ${$theme.colors.borderOpaque}`,
        ...expandPadding(0),
        ':hover': {
          backgroundColor: $theme.colors.backgroundTertiary,
        },
        height: '100%',
      }),
    },

    TableBodyRow: {
      style: () => ({
        ':hover': {
          backgroundColor: 'unset',
        },
        height: '100%',
        ':last-child': {
          borderBottom: '1px solid red',
        },
      }),
    },
  };

  const cellStyle: CSSProperties = {
    padding: '1em',
    minHeight: '120px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  };

  const getCellStyle = (key: keyof SelectedRowState, index?: number) => ({
    ...cellStyle,
    backgroundColor:
      selectedRowState[key] === index || sourceRowHover === index ? theme.colors.backgroundTertiary : 'transparent',
  });

  return (
    <>
      <Block height="100%" display="flex" flexDirection="column">
        <Block flex={1} position="relative" overflow="auto">
          <Block position="absolute" width="100%">
            <TableBuilder data={searchResults} isLoading={isLoading} overrides={overrides}>
              <TableBuilderColumn header={tEvi('datasource')}>
                {(row: DekningResultat, index?: number) => (
                  <div
                    style={{
                      ...cellStyle,
                      borderLeft: `1px solid ${theme.colors.borderOpaque}`,
                      backgroundColor:
                        selectedRowState.source === index ? theme.colors.backgroundTertiary : 'transparent',
                    }}
                    onClick={() => handleRadioClick('source', index)}
                    onMouseOver={() => setSourceRowHover(index)}
                    onMouseOut={() => setSourceRowHover(undefined)}
                  >
                    {row.kilde}
                  </div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn header={tEvi('vehicleInfo')}>
                {(row: DekningResultat, index?: number) => (
                  <div
                    onClick={() => handleRadioClick('vehicleInfo', index)}
                    style={getCellStyle('vehicleInfo', index)}
                  >
                    <EviVolvoErrors errors={row.feilmeldinger} showForApi="Vehicle" showMessageFromVolvoMessage>
                      {row.objektNr ? <InfoRow label={tEvi('regNr')} info={row.objektNr} /> : null}
                      {row.chassisNr ? <InfoRow label={tEvi('chassisNr')} info={row.chassisNr} /> : null}
                      {row.merkeNavn ? <InfoRow label={tVehicle('brand')} info={row.merkeNavn} /> : null}
                      {row.modellNavn ? <InfoRow label={tVehicle('model')} info={row.modellNavn} /> : null}
                      {row.arsModell ? <InfoRow label={tVehicle('yearModel')} info={row.arsModell} /> : null}
                      {row.drivverk ? (
                        <InfoRow
                          label={tEvi('driveTrain')}
                          info={driveTrain.find((f) => f.id === row.drivverk)?.name}
                        />
                      ) : null}
                      {row.drivstoff ? (
                        <InfoRow label={tVehicle('fuel')} info={fuels.find((f) => f.id === row.drivstoff)?.name} />
                      ) : null}
                      {row.vekt ? <InfoRow label={tEvi('weight')} info={row.vekt} /> : null}
                      {row.tillattTotalVekt ? (
                        <InfoRow label={tEvi('allowedWeight')} info={row.tillattTotalVekt} />
                      ) : null}
                    </EviVolvoErrors>
                  </div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn header={tEvi('ownerInfo')}>
                {(row: DekningResultat, index?: number) => (
                  <div style={getCellStyle('owner', index)} onClick={() => handleRadioClick('owner', index)}>
                    <EviVolvoErrors errors={row.feilmeldinger} showForApi="Consumer">
                      {row.eierNavn ? <InfoRow label={tShared('name')} info={row.eierNavn} /> : null}
                      {row.eierTlf ? <InfoRow label={tEvi('telephoneNr')} info={row.eierTlf} /> : null}
                      {row.eierEpost ? <InfoRow label={tShared('email')} info={row.eierEpost} /> : null}
                      {row.eierAdresse ? <InfoRow label={tShared('address')} info={row.eierAdresse} /> : null}
                      {row.eierPostNr && row.eierPostSted ? (
                        <InfoRow label={tEvi('place')} info={`${row.eierPostNr} ${row.eierPostSted}`} />
                      ) : null}
                      {row.eierLand ? (
                        <InfoRow label={tShared('country')} info={countries.find((c) => c.id === row.eierLand)?.name} />
                      ) : null}
                      {row.leietakerNavn ? (
                        <div style={{ paddingTop: '16px' }}>
                          <Button
                            size="mini"
                            onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => {
                              event.stopPropagation();
                              setLeasingDrawer(true);
                              setSelectedLeasingState(index);
                            }}
                            kind="secondary"
                            type="button"
                            startEnhancer={() => <FaInfoCircle />}
                          >
                            {tLeasing('showLeasingInfo')}
                          </Button>
                        </div>
                      ) : null}
                    </EviVolvoErrors>
                  </div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn header={tNewVehicle('newCarInfo')}>
                {(row: DekningResultat, index?: number) => (
                  <div
                    style={getCellStyle('newVehicleInfo', index)}
                    onClick={() => handleRadioClick('newVehicleInfo', index)}
                  >
                    <EviVolvoErrors errors={row.feilmeldinger} showForApi="Vehicle" showMessageFromVolvoMessage>
                      {row.forhandlerJENavn ? (
                        <InfoRow label={tNewVehicle('originalCarDealership')} info={row.forhandlerJENavn} />
                      ) : null}
                      {row.forsteRegDato ? (
                        <InfoRow
                          label={tEvi('firstRegDate')}
                          info={formatDate(new Date(row.forsteRegDato), {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                          })}
                        />
                      ) : null}
                    </EviVolvoErrors>
                  </div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn header={tService('serviceInformation')}>
                {(row: DekningResultat, index?: number) => (
                  <div
                    style={getCellStyle('serviceInfo', index)}
                    onClick={() => handleRadioClick('serviceInfo', index)}
                  >
                    <EviVolvoErrors errors={row.feilmeldinger} showForApi="SoftProduct">
                      {row.garantiAvtaleNavn ? (
                        <InfoRow label={tService('warranty')} info={row.garantiAvtaleNavn} />
                      ) : null}
                      {row.fraDato ? (
                        <InfoRow
                          label={tShared('fromDate')}
                          info={formatDate(new Date(row.fraDato), {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                          })}
                        />
                      ) : null}
                      {row.tilDato ? (
                        <InfoRow
                          label={tShared('toDate')}
                          info={formatDate(new Date(row.tilDato), {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                          })}
                        />
                      ) : null}
                      {row.sisteServiceForhandlerNavn ? (
                        <InfoRow label={tService('latestServiceWorkshop')} info={row.sisteServiceForhandlerNavn} />
                      ) : null}
                      {row.sisteServiceTilDato ? (
                        <InfoRow
                          label={tEvi('lastServiceDate')}
                          info={formatDate(new Date(row.sisteServiceTilDato), {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                          })}
                        />
                      ) : null}
                      {row.sisteServiceGyldigTilDato ? (
                        <InfoRow
                          label={tEvi('warranty')}
                          info={formatDate(new Date(row.sisteServiceGyldigTilDato), {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                          })}
                        />
                      ) : null}
                      {row.sisteServiceGyldigTilKm ? (
                        <InfoRow label={tEvi('warrantyKm')} info={row.sisteServiceGyldigTilKm} />
                      ) : null}
                    </EviVolvoErrors>
                  </div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn header={tEvi('insuranceInfo')}>
                {(row: DekningResultat, index?: number) => (
                  <div
                    style={getCellStyle('insuranceInfo', index)}
                    onClick={() => handleRadioClick('insuranceInfo', index)}
                  >
                    {row.forsikringsselskapNavn ? (
                      <InfoRow label={tInsurance('insuranceCompany')} info={row.forsikringsselskapNavn} />
                    ) : null}
                    {row.dekning ? <InfoRow label={tInsurance('insuranceType')} info={row.dekning} /> : null}
                    {row.egenandel ? <InfoRow label={tInsurance('excess')} info={row.egenandel} /> : null}
                  </div>
                )}
              </TableBuilderColumn>
            </TableBuilder>
          </Block>
        </Block>
        <Block display="flex" justifyContent="flex-end" marginTop="scale800">
          <Button
            type="button"
            $wideButton
            className={css({ marginRight: '1em' })}
            onClick={() => setIsOpen(false)}
            startEnhancer={() => <DeleteAlt size={18} />}
            kind="secondary"
          >
            {tEvi('cancelAndGoBack')}
          </Button>

          <Button
            type="button"
            $wideButton
            startEnhancer={() => <FaCopy size={18} />}
            disabled={enableButton()}
            onClick={() => updateValues()}
          >
            {tEvi('copyToCase')}
          </Button>
        </Block>
      </Block>
      <LeasingDrawer
        isOpen={leasingDrawer}
        setIsOpen={setLeasingDrawer}
        leasingCompany={
          selectedLeasingState !== undefined
            ? {
                name: searchResults[selectedLeasingState].eierNavn,
                phone: isPhoneNumberValid(searchResults[selectedLeasingState].eierTlf)
                  ? searchResults[selectedLeasingState].eierTlf
                  : undefined,
                address: searchResults[selectedLeasingState].eierAdresse,
                postalCode: searchResults[selectedLeasingState].eierPostNr,
                postalCity: searchResults[selectedLeasingState].eierPostSted,
                country: searchResults[selectedLeasingState].eierLand
                  ? countries.filter((d) => d.id === searchResults[selectedLeasingState].eierLand).map((d) => d.name)[0]
                  : undefined,
              }
            : undefined
        }
        leasingTaker={
          selectedLeasingState !== undefined
            ? {
                name: searchResults[selectedLeasingState].leietakerNavn,
                address: searchResults[selectedLeasingState].leietakerAdresse,
                postalCode: searchResults[selectedLeasingState].leietakerPostNr,
                postalCity: searchResults[selectedLeasingState].leietakerPostSted,
                country: searchResults[selectedLeasingState].leietakerLand
                  ? countries
                      .filter((d) => d.id === searchResults[selectedLeasingState].leietakerLand)
                      .map((d) => d.name)[0]
                  : undefined,
              }
            : undefined
        }
      />
    </>
  );
}

export const EviResultTable = memo(EviResultTableComp);
