import { useMutation, useQuery } from '@tanstack/react-query';
import { SokDekningRequest } from '../api.generated';
import { useApiClient } from '../apiClientContext';

export const useDataSourcesQuery = () => {
  const { externalVehicleInfoClient } = useApiClient();

  return useQuery(['DataSources'], () => externalVehicleInfoClient.getDataSources());
};

export const useDekningsSokMutation = () => {
  const { externalVehicleInfoClient } = useApiClient();

  return useMutation((searchRequest: SokDekningRequest) => externalVehicleInfoClient.dekningsSok(searchRequest));
};
