import { useField } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIncidentCodesQuery, useIncidentDetailCodesQuery } from '../../api/queries/useCaseMasterDataQueries';
import { FastSelectField as SelectField } from '../../shared/formFields/SelectField';
import { FastTextareaField as TextareaField } from '../../shared/formFields/TextareaField';
import FormGroup from '../../shared/utilityComponents/FormGroup';
import { nameof } from '../caseRegistrationFunctions';

function IncidentReasonFormGroupComp() {
  const tIncident = useTranslation('translation', { keyPrefix: 'incidentReasonForm' }).t;

  const [field] = useField(nameof('incidentTypeCode'));
  const [, , incidentDetailCodeHelper] = useField(nameof('incidentDetailTypeCode'));

  const { data: incidentCodes = [] } = useIncidentCodesQuery();
  const { data: incidentDetailCodes = [] } = useIncidentDetailCodesQuery(field.value);

  return (
    <FormGroup label={tIncident('causeOfIncident')}>
      <SelectField
        label={`${tIncident('causeOfIncident')}*`}
        options={incidentCodes}
        name={nameof('incidentTypeCode')}
        onChange={() => incidentDetailCodeHelper.setValue('')}
      />

      {incidentDetailCodes.length > 0 ? (
        <SelectField
          label={`${tIncident('causeOfIncidentDetail')}*`}
          options={incidentDetailCodes}
          name={nameof('incidentDetailTypeCode')}
        />
      ) : null}

      <TextareaField name={nameof('incidentDescription')} label={tIncident('causeOfIncidentComment')} maxLength={255} />
    </FormGroup>
  );
}
export const IncidentReasonFormGroup = memo(IncidentReasonFormGroupComp);
