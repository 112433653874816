import { Panel, StatelessAccordion } from 'baseui/accordion';
import { Cell, Grid } from 'baseui/layout-grid';
import { Key, MutableRefObject, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandDto, CaseSearchRequest, ComboboxItem, ModelDto } from '../api/api.generated';
import { useAgentsQuery, useCountriesQuery, useVolvoWorkshopsQuery } from '../api/queries/useActorQueries';
import { useAgreementsQuery } from '../api/queries/useAgreementQueries';
import {
  useIncidentCodesQuery,
  useInsuranceStatusCodesQuery,
  useLoggTypesQuery,
  useOriginCodesQuery,
  useServicesQuery,
  useStatusesQuery,
  useVehicleBrandsQuery,
  useVehicleModelsQuery,
} from '../api/queries/useCaseMasterDataQueries';
import { CaseSearchRequestFormFields } from '../caseHistory/sharedFunctions';
import { CASE_STATUS_TO_TAG_KEY } from '../shared/constants';
import DateField from '../shared/formFields/DateField';
import InputField from '../shared/formFields/InputField';
import PhoneNumberField from '../shared/formFields/PhoneNumberField';
import SelectField from '../shared/formFields/SelectField';
import TimestampField from '../shared/formFields/TimestampField';

export interface Filter {
  value: string | number | Date | boolean | BrandDto | ModelDto | ComboboxItem;
  label: string;
  id: keyof CaseSearchRequest;
}

interface Props {
  dataRef: MutableRefObject<CaseSearchRequestFormFields>;
  setFieldValueRef: React.MutableRefObject<
    (field: string, value: unknown, shouldValidate?: boolean | undefined) => void
  >;
}

export const CaseSearchForm = (props: Props) => {
  const { dataRef, setFieldValueRef } = props;

  const [expanded, setExpanded] = useState<Key[]>(['P1']);

  const tSearch = useTranslation('translation', { keyPrefix: 'search' }).t;
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tCasereg = useTranslation('translation', { keyPrefix: 'caseRegistration' }).t;

  const brandId = dataRef.current.brand?.id;

  const vehicleBrands = useVehicleBrandsQuery();
  const models = useVehicleModelsQuery(brandId);
  const countries = useCountriesQuery();
  const incidentCodes = useIncidentCodesQuery();
  const agreements = useAgreementsQuery();
  const insurances = useInsuranceStatusCodesQuery();
  const workshops = useVolvoWorkshopsQuery();
  const agents = useAgentsQuery();
  const logtypes = useLoggTypesQuery();
  const services = useServicesQuery();
  const sources = useOriginCodesQuery();
  const statuses = useStatusesQuery();

  const sortedAgents = useMemo(
    () => (agents.data ?? []).sort((a, b) => (a.email ?? '').localeCompare(b.email ?? '')),
    [agents.data]
  );

  const translatedStatuses = useMemo(
    () =>
      (statuses.data ?? []).map((status) => {
        const newItem: ComboboxItem = {
          id: status.id,
          name: tCasereg(CASE_STATUS_TO_TAG_KEY[status.name ?? ''] ?? ''),
        };
        return newItem;
      }),
    [statuses.data, tCasereg]
  );

  return (
    <StatelessAccordion
      overrides={{
        Root: { style: {} },
        Header: { style: { backgroundColor: 'transparent' } },
        Content: { style: { backgroundColor: 'transparent' } },
      }}
      expanded={expanded}
      onChange={({ expanded }) => setExpanded(expanded)}
      renderAll={true}
    >
      <Panel key="P1" title={tSearch('quickSearch')}>
        <InputField submitOnEnter label={tShared('visCaseId')} name="visCaseId" autoFocus />
        <InputField submitOnEnter label={tSearch('registrationNumber')} name="registrationNumber" />
        <InputField submitOnEnter label={tSearch('ownerName')} name="ownerName" />
        <TimestampField label={tSearch('incidentDateTimeFrom')} name="incidentDateTimeFrom" />
        <TimestampField label={tSearch('incidentDateTimeTo')} name="incidentDateTimeTo" />
      </Panel>
      <Panel key="P2" title={tShared('vehicle')}>
        <InputField submitOnEnter label={tSearch('registrationNumber')} name="registrationNumber" />
        <InputField submitOnEnter label={tSearch('vin')} name="vin" />
        <SelectField
          options={vehicleBrands.data}
          label={tSearch('brandCode')}
          name="brand"
          labelKey="name"
          isLoading={vehicleBrands.isLoading}
          error={vehicleBrands.isLoadingError}
          disabled={vehicleBrands.isLoading}
          bindToObject
          onChange={() => setFieldValueRef.current('model', undefined)}
        />
        <SelectField
          options={models.data}
          label={tSearch('modelCode')}
          name="model"
          disabled={!brandId || models.isLoading}
          isLoading={models.isLoading}
          error={models.isLoadingError}
          bindToObject
        />
      </Panel>
      <Panel key="P3" title={tSearch('people')}>
        <InputField submitOnEnter label={tSearch('ownerName')} name="ownerName" />
        <PhoneNumberField label={tSearch('ownerPhoneNumber')} name="ownerPhone" />
        <InputField submitOnEnter label={tSearch('contactPersonName')} name="contactPersonName" />
        <PhoneNumberField label={tSearch('contactPersonPhoneNumber')} name="contactPersonPhone" />
      </Panel>
      <Panel key="P4" title={tSearch('incidentSite')}>
        <InputField submitOnEnter label={tSearch('incidentAdress')} name="incidentAdress" />
        <InputField submitOnEnter label={tSearch('incidentPostalArea')} name="incidentPostalArea" />
        <SelectField
          options={countries.data}
          label={tSearch('incidentCountry')}
          name="incidentCountry"
          labelKey="name"
          isLoading={countries.isLoading}
          error={countries.isLoadingError}
          disabled={countries.isLoading}
          bindToObject
        />
      </Panel>
      <Panel key="P5" title={tSearch('causeOfDamage')}>
        <SelectField
          options={incidentCodes.data}
          label={tSearch('causeOfDamage')}
          name="incidentType"
          labelKey="name"
          isLoading={incidentCodes.isLoading}
          error={incidentCodes.isLoadingError}
          disabled={incidentCodes.isLoading}
          bindToObject
        />
      </Panel>
      <Panel key="P6" title={tSearch('agreementAndInsurance')}>
        <SelectField
          options={agreements.data}
          label={tSearch('agreementCode')}
          name="agreement"
          labelKey="name"
          isLoading={agreements.isLoading}
          error={agreements.isLoadingError}
          disabled={agreements.isLoading}
          bindToObject
        />
        <SelectField
          options={insurances.data}
          label={tSearch('insuranceStatusCode')}
          name="insuranceStatus"
          labelKey="name"
          isLoading={insurances.isLoading}
          error={insurances.isLoadingError}
          disabled={insurances.isLoading}
          bindToObject
        />
        <InputField submitOnEnter label={tSearch('insuranceReferenceNumber')} name="insuranceReferenceNumber" />
      </Panel>
      <Panel key="P7" title={tSearch('transportAndWorkshop')}>
        <SelectField
          options={workshops.data}
          label={tSearch('workshopId')}
          name="workshop"
          labelKey="name"
          isLoading={workshops.isLoading}
          error={workshops.isLoadingError}
          disabled={workshops.isLoading}
          bindToObject
        />
        <InputField submitOnEnter label={tSearch('processingLocationName')} name="processingLocationName" />
      </Panel>
      <Panel key="P8" title={tSearch('caseDetails')}>
        <InputField submitOnEnter label={tShared('visCaseId')} name="visCaseId" />
        <SelectField
          options={translatedStatuses}
          label={tSearch('caseStatusId')}
          name="caseStatus"
          labelKey="name"
          bindToObject
        />
        <SelectField
          options={sources.data}
          label={tSearch('sourceOfCaseInformationCode')}
          name="source"
          labelKey="name"
          isLoading={sources.isLoading}
          error={sources.isLoadingError}
          disabled={sources.isLoading}
          bindToObject
        />
      </Panel>
      <Panel key="P9" title={tSearch('registrationDetails')}>
        <SelectField
          options={sortedAgents}
          label={tSearch('caseAgentId')}
          name="caseAgent"
          labelKey="email"
          valueKey="visUserId"
          isLoading={agents.isLoading}
          error={agents.isLoadingError}
          disabled={agents.isLoading}
          bindToObject
        />
        <TimestampField label={tSearch('caseRegisteredDateTimeFrom')} name="caseRegisteredDateTimeFrom" />
        <TimestampField label={tSearch('caseRegisteredDateTimeTo')} name="caseRegisteredDateTimeTo" />
      </Panel>
      <Panel key="P10" title={tSearch('logDetails')}>
        <Grid gridMargins={0}>
          <Cell span={6}>
            <DateField label={tSearch('logRegisteredDateTimeFrom')} name="logRegisteredDateTimeFrom" />
          </Cell>
          <Cell span={6}>
            <DateField label={tSearch('logRegisteredDateTimeTo')} name="logRegisteredDateTimeTo" />
          </Cell>
        </Grid>
        <SelectField
          options={sortedAgents}
          label={tSearch('logFromAgentId')}
          name="logFromAgent"
          labelKey="email"
          valueKey="visUserId"
          isLoading={agents.isLoading}
          error={agents.isLoadingError}
          disabled={agents.isLoading}
          bindToObject
        />
        <SelectField
          options={logtypes.data}
          label={tSearch('logCode')}
          name="log"
          labelKey="name"
          isLoading={logtypes.isLoading}
          error={logtypes.isLoadingError}
          disabled={logtypes.isLoading}
          bindToObject
        />
        <SelectField
          options={services.data}
          label={tSearch('serviceCode')}
          name="service"
          labelKey="name"
          isLoading={services.isLoading}
          error={services.isLoadingError}
          disabled={services.isLoading}
          bindToObject
        />
        <Grid gridMargins={0}>
          <Cell span={6}>
            <DateField label={tSearch('deadlineDateTimeFrom')} name="deadlineDateTimeFrom" />
          </Cell>
          <Cell span={6}>
            <DateField label={tSearch('deadlineDateTimeTo')} name="deadlineDateTimeTo" />
          </Cell>
        </Grid>
        <Grid gridMargins={0}>
          <Cell span={6}>
            <DateField label={tSearch('completedDateTimeFrom')} name="completedDateTimeFrom" />
          </Cell>
          <Cell span={6}>
            <DateField label={tSearch('completetDateTimeTo')} name="completetDateTimeTo" />
          </Cell>
        </Grid>
        <InputField submitOnEnter label={tSearch('logTransferredToId')} name="logTransferredToId" />
      </Panel>
    </StatelessAccordion>
  );
};
