import { IconTypes, IMarker } from './Map';
import { LocationDto } from '../api/api.generated';
import { toString } from 'lodash-es';

export const mapToMarker = (data: LocationDto[], type: IconTypes): IMarker[] =>
  data.map((workshop) => {
    const marker: IMarker = {
      lat: workshop.coordinates?.latitude ?? 0,
      lng: workshop.coordinates?.longitude ?? 0,
      data: {
        title: toString(workshop.name),
        id: toString(workshop.id),
        address: toString(workshop.address?.address),
        country: toString(workshop.address?.country),
        postCode: toString(workshop.address?.postalCode),
        postArea: toString(workshop.address?.postalPlace),
        type: type,
        phoneNumber: toString(workshop.mainPhoneNumber),
        roleId: workshop.rolleId,
      },
      type: type,
      visible: false,
    };
    return marker;
  });
