export interface AppConfig {
  cacheBustHash: string;
  publicUrl: string;
  apiBaseURL: string;
  defaultLanguage: string;
  fallbackLanguage: string;
  defaultCallCenterCountry: string;
  authConfig: {
    clientId: string;
    authority: string;
    cacheLocation: string;
  };
  apiAuthConfig: string;
  releaseId: string;
  applicationInsightId: string;
  environment: string;
  googleMapsApiKey: string;
  localizationCdnUrl: string;
}

export let appConfig: AppConfig;

export const loadAppConfig = async (): Promise<AppConfig> => {
  let publicUrl: string = import.meta.env.VITE_PUBLIC_URL;
  // if public url is relative, make absolute
  if (publicUrl && publicUrl.startsWith('/')) publicUrl = publicUrl.substring(1, publicUrl.length);

  const cacheBustHash = import.meta.env.VITE_CACHE_BUST_HASH ?? '';

  const file = await fetch(`${publicUrl}/appConfig.${cacheBustHash ? cacheBustHash + '.' : ''}json`);
  const json = await file.json();

  appConfig = json as AppConfig;

  appConfig.publicUrl = publicUrl;
  appConfig.cacheBustHash = cacheBustHash;

  let key: keyof AppConfig;

  // Check for missing config items
  for (key in appConfig) {
    if (appConfig[key] === undefined) {
      console.error(`Could not load "${key}" from config file`);
    }
  }

  return appConfig;
};
