import { Block } from 'baseui/block';
import { KIND } from 'baseui/button';
import { memo } from 'react';
import { MdSearch } from 'react-icons/md';
import { Button } from '../../shared/basewebComponentOverrides/Button';
import { FastInputField as InputField } from '../../shared/formFields/InputField';

interface Props {
  name: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  required?: boolean;
}

function RegNrFieldComp(props: Props) {
  const { onClick, ...restOfProps } = props;

  return (
    <Block display="flex" alignItems="end">
      <Block flex={1}>
        <InputField upperCase {...restOfProps} />
      </Block>
      <Block flex={0} paddingLeft="scale200">
        <Block marginBottom="scale600">
          <Button type="button" kind={KIND.secondary} onClick={onClick}>
            <MdSearch />
          </Button>
        </Block>
      </Block>
    </Block>
  );
}

export const RegNrField = memo(RegNrFieldComp);
