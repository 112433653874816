import { mergeOverrides } from 'baseui';
import { Checkbox, CheckboxProps } from 'baseui/checkbox';
import { FormControl } from 'baseui/form-control';
import { FastField, Field, FieldProps, FieldValidator } from 'formik';
import { CheckboxLabel } from '../basewebComponentOverrides/Typography';
import { customShouldComponentUpdate } from './fastFormFieldFunctions';

type SafeCheckboxProps = Omit<CheckboxProps, 'value' | 'onChange'>;

interface CommonCheckboxFieldProps {
  label: string;
}

interface CheckboxFormControlProps extends CommonCheckboxFieldProps, SafeCheckboxProps {
  fieldProps: FieldProps;
}

export interface CheckboxFieldProps extends CommonCheckboxFieldProps, SafeCheckboxProps {
  name: string;
  validate?: FieldValidator;
}

function CheckboxFormControl(props: CheckboxFormControlProps) {
  const { fieldProps, label, disabled, overrides, ...restOfProps } = props;

  const { field, meta } = fieldProps;

  const showError = meta.touched && !!meta.error;

  return (
    <FormControl disabled={disabled} error={showError && meta.error}>
      <Checkbox
        name={field.name}
        checked={field.value}
        onChange={field.onChange}
        disabled={disabled}
        overrides={mergeOverrides({ Label: CheckboxLabel }, { ...overrides })}
        {...restOfProps}
      >
        {label}
      </Checkbox>
    </FormControl>
  );
}

export function FastCheckboxField(props: CheckboxFieldProps) {
  const { name, ...restOfProps } = props;

  const depProps = { disabled: props.disabled, required: props.required };

  return (
    <FastField name={name} shouldUpdate={customShouldComponentUpdate} {...depProps}>
      {(fieldProps: FieldProps) => <CheckboxFormControl {...restOfProps} fieldProps={fieldProps} />}
    </FastField>
  );
}

export default function CheckboxField(props: CheckboxFieldProps) {
  const { name, ...restOfProps } = props;

  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => <CheckboxFormControl {...restOfProps} fieldProps={fieldProps} />}
    </Field>
  );
}
