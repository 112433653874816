import { toString } from 'lodash-es';
import { UserDto } from '@@api/api.generated';

export interface CreateUserForm {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  roleId: string;
  callCenterCountryCode: string;
  languageCode: string;
}

interface CreateUserFormInitalValuesArgs {
  roleId: string;
  callCenterCountryCode: string;
  languageCode: string;
}

export function getCreateUserFormInitialValues(args?: CreateUserFormInitalValuesArgs): CreateUserForm {
  const initialValues: CreateUserForm = {
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    roleId: args?.roleId ?? '',
    callCenterCountryCode: args?.callCenterCountryCode ?? '',
    languageCode: args?.languageCode ?? '',
  };

  return initialValues;
}

export interface UpdateUserForm {
  email: string;
  username: string;
  roleId: string;
  role: string;
  callCenterCountryCode: string;
  languageCode: string;
}

export function getUpdateUserFormInitialValues(userDto?: UserDto): UpdateUserForm {
  const initialValues: UpdateUserForm = {
    email: userDto?.email ?? '',
    username: userDto?.visUserId ?? '',
    roleId: toString(userDto?.role?.id) ?? '',
    role: toString(userDto?.role?.name),
    callCenterCountryCode: userDto?.callCenterCountryCode ?? '',
    languageCode: userDto?.languageCode ?? '',
  };

  return initialValues;
}
