import { Button, KIND } from 'baseui/button';
import { Checkbox } from 'baseui/checkbox';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Delete, Search } from 'baseui/icon';
import { LabelMedium, LabelSmall } from 'baseui/typography';
import { Form, Formik, FormikProps, useField } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { SokDekningRequest } from '../api/api.generated';
import { CustomErrorMessage } from '../shared/formFields/ErrorMessage';
import InputField from '../shared/formFields/InputField';
import { FormErrors } from '../shared/types';
import { MOTORV_FIN_DB_ID, VOLVO_DB_ID } from './EviModal';

export interface Database {
  name: string;
  selected: boolean;
  id: string;
}
interface SearchFormFields {
  regNr: string;
  chassisNr: string;
}

interface Props {
  onSearch: (request: SokDekningRequest) => void;
  databases: Database[];
  onToggleDatabase: (dbId: string) => void;
  clearResults: () => void;
}

function EviSearchComp(props: Props) {
  const { onSearch, databases, onToggleDatabase, clearResults } = props;

  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tEvi = useTranslation('translation', { keyPrefix: 'evi' }).t;
  const [registrationField] = useField('vehicleRegistrationNumber');

  const values: SearchFormFields = { regNr: registrationField.value, chassisNr: '' };

  function resetForm(formik: FormikProps<SearchFormFields>) {
    formik.setValues({ regNr: '', chassisNr: '' });
    clearResults();
  }

  const validate = (values: SearchFormFields) => {
    const errors: FormErrors<SearchFormFields> = {};

    if (!values.regNr && !values.chassisNr) {
      errors.noInputs = 'RegNr or ChassisNr is required';
    }

    return errors;
  };

  function handleSearch(v: SearchFormFields) {
    const selectedDbIds = databases.filter((db) => db.selected).map((db) => db.id);

    const isPerkele = selectedDbIds.indexOf(MOTORV_FIN_DB_ID) > -1;

    const request: SokDekningRequest = {
      datakilder: selectedDbIds,
      søkeParametere: {
        regNr: v.regNr,
        chassisNr: v.chassisNr,
        kjøretøytypeFinland: isPerkele ? 1 : undefined,
      },
    };

    onSearch(request);
  }

  const noDatabasesSelected = databases.filter((db) => db.selected).length === 0;

  return (
    <>
      <Formik initialValues={values} onSubmit={handleSearch} validate={validate}>
        {(props: FormikProps<SearchFormFields>) => {
          return (
            <Form style={{ height: '100%' }}>
              <FlexGrid height="90%">
                <FlexGridItem paddingBottom={'45px'}>
                  <InputField upperCase label={`${tShared('regNr')}`} name="regNr" debounceMs={0} />

                  <InputField label={tEvi('chassisNr')} name="chassisNr" debounceMs={0} />

                  <CustomErrorMessage name="noInputs" />
                </FlexGridItem>
                <FlexGridItem>
                  <LabelMedium paddingBottom={'13px'}>{tEvi('sources')}</LabelMedium>

                  {databases.length > 0
                    ? databases.map((db) => {
                        return (
                          <Checkbox
                            key={db.id}
                            checked={db.selected}
                            onChange={() => onToggleDatabase(db.id)}
                            overrides={{
                              Checkmark: {
                                style: ({ $checked, $theme }) => ({
                                  backgroundColor: $checked ? '#313131' : $theme.primary,
                                }),
                              },
                              Root:
                                db.id === VOLVO_DB_ID
                                  ? {
                                      style: {
                                        borderBottomWidth: '1px',
                                        borderBottomStyle: 'solid',
                                        borderBottomColor: '#AFAFAF',
                                        marginBottom: '23px',
                                        paddingBottom: '8px',
                                      },
                                    }
                                  : undefined,
                            }}
                          >
                            <LabelSmall paddingTop="scale100" paddingBottom="scale400">
                              {db.name}
                            </LabelSmall>
                          </Checkbox>
                        );
                      })
                    : null}
                </FlexGridItem>
                <FlexGridItem
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  paddingTop="150px"
                >
                  <Button
                    overrides={{
                      BaseButton: {
                        style: {
                          width: '256px',
                          height: '48px',
                          marginBottom: '16px',
                        },
                      },
                    }}
                    startEnhancer={() => <Search size={20} />}
                    type="submit"
                    disabled={!props.isValid || noDatabasesSelected}
                  >
                    {tShared('search')}
                  </Button>

                  <Button
                    onClick={() => {
                      resetForm(props);
                    }}
                    type="button"
                    kind={KIND.secondary}
                    overrides={{
                      BaseButton: {
                        style: {
                          width: '256px',
                          height: '48px',
                        },
                      },
                    }}
                    startEnhancer={() => <Delete size={20} />}
                  >
                    {tShared('reset')}
                  </Button>
                </FlexGridItem>
              </FlexGrid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export const EviSearch = memo(EviSearchComp);
