import { useField } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';
import { Button } from '../../shared/basewebComponentOverrides/Button';
import { FastEmailInputField as EmailInputField } from '../../shared/formFields/EmailInputField';
import { FastInputField as InputField } from '../../shared/formFields/InputField';
import { FastPhoneNumberField as PhoneNumberField } from '../../shared/formFields/PhoneNumberField';
import FormGroup from '../../shared/utilityComponents/FormGroup';
import { nameof } from '../caseRegistrationFunctions';
import { LocationForm } from './LocationForm';

interface OwnerFormGroupProps {
  onClick: () => void;
}
const OwnerFormGroupComp = ({ onClick }: OwnerFormGroupProps) => {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tLeasing = useTranslation('translation', { keyPrefix: 'leasing' }).t;
  const [leasingField] = useField('leasingTaker');

  return (
    <FormGroup label={tShared('owner')}>
      <InputField label={`${tShared('name')} *`} name={nameof('ownerName')} />
      <PhoneNumberField label={`${tShared('telephoneNumber')}*`} name={nameof('ownerPhone')} mapping="owner" />
      <LocationForm mapping="owner" />
      <EmailInputField label={tShared('email')} name={nameof('ownerEmail')} />
      {leasingField.value ? (
        <Button onClick={onClick} kind="secondary" type="button" startEnhancer={() => <FaInfoCircle />}>
          {tLeasing('showLeasingInfo')}
        </Button>
      ) : null}
    </FormGroup>
  );
};

export const OwnerFormGroup = memo(OwnerFormGroupComp);
