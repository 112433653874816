import { useMsal } from '@azure/msal-react';
import React, { createContext, memo, useContext, useState } from 'react';
import { appConfig } from '../config/appConfig';
import {
  ActorClient,
  AgreementClient,
  CaseActionClient,
  CaseClient,
  CommunicationClient,
  ExternalVehicleInfoClient,
  IActorClient,
  IAgreementClient,
  ICaseActionClient,
  ICaseClient,
  ICommunicationClient,
  IExternalVehicleInfoClient,
  IPhoneLookUpClient,
  IRetailerClient,
  IUserAdminClient,
  IUserClient,
  PhoneLookUpClient,
  RetailerClient,
  UserAdminClient,
  UserClient,
} from './api.generated';
import { getApiConfig } from './apiConfig';

export interface ApiClientContextProps {
  caseClient: ICaseClient;
  actorClient: IActorClient;
  agreementClient: IAgreementClient;
  externalVehicleInfoClient: IExternalVehicleInfoClient;
  communicationClient: ICommunicationClient;
  caseActionClient: ICaseActionClient;
  retailerClient: IRetailerClient;
  phoneLookUpClient: IPhoneLookUpClient;
  userClient: IUserClient;
  userAdminClient: IUserAdminClient;
}

const ApiClientContext = createContext<ApiClientContextProps>(undefined as never);
ApiClientContext.displayName = 'ApiClientContext';

export const useApiClient = () => {
  const ctx = useContext(ApiClientContext);

  if (!ctx) {
    throw new Error(`${ApiClientContext.displayName} must be provided before use`);
  }

  return ctx;
};

interface ApiClientProviderProps {
  children: React.ReactNode;
  initialValue?: Partial<ApiClientContextProps>;
}

const ApiClientProviderComponent = ({ children, initialValue }: ApiClientProviderProps) => {
  const { instance } = useMsal();
  const apiConfig = getApiConfig(instance);

  const apiBaseURL = appConfig.apiBaseURL;

  const [caseClient] = useState(() => initialValue?.caseClient ?? new CaseClient(apiConfig, apiBaseURL));
  const [actorClient] = useState(() => initialValue?.actorClient ?? new ActorClient(apiConfig, apiBaseURL));
  const [agreementClient] = useState(() => initialValue?.agreementClient ?? new AgreementClient(apiConfig, apiBaseURL));
  const [externalVehicleInfoClient] = useState(
    () => initialValue?.externalVehicleInfoClient ?? new ExternalVehicleInfoClient(apiConfig, apiBaseURL)
  );
  const [communicationClient] = useState(
    () => initialValue?.communicationClient ?? new CommunicationClient(apiConfig, apiBaseURL)
  );
  const [caseActionClient] = useState(
    () => initialValue?.caseActionClient ?? new CaseActionClient(apiConfig, apiBaseURL)
  );
  const [retailerClient] = useState(() => initialValue?.retailerClient ?? new RetailerClient(apiConfig, apiBaseURL));
  const [phoneLookUpClient] = useState(
    () => initialValue?.phoneLookUpClient ?? new PhoneLookUpClient(apiConfig, apiBaseURL)
  );
  const [userClient] = useState(() => initialValue?.userClient ?? new UserClient(apiConfig, apiBaseURL));
  const [userAdminClient] = useState(() => initialValue?.userAdminClient ?? new UserAdminClient(apiConfig, apiBaseURL));

  return (
    <ApiClientContext.Provider
      value={{
        caseClient,
        actorClient,
        agreementClient,
        externalVehicleInfoClient,
        communicationClient,
        caseActionClient,
        retailerClient,
        phoneLookUpClient,
        userClient,
        userAdminClient,
      }}
    >
      {children}
    </ApiClientContext.Provider>
  );
};

export const ApiClientProvider = memo(ApiClientProviderComponent);
