import { StatefulMenu } from 'baseui/menu';
import { PopoverProps, StatefulPopover } from 'baseui/popover';
import { ReactNode } from 'react';

export interface MenuItem {
  id: string | number;
  label: string | number;
  disabled?: boolean;
}

interface Props extends Omit<PopoverProps, 'content' | 'isOpen'> {
  menuOptions: MenuItem[];
  children: ReactNode;
  onItemSelect: (item: MenuItem) => void;
}

export const PopoverMenu = (props: Props) => {
  const { menuOptions, children, onItemSelect } = props;

  const handleItemSelect = (item: MenuItem) => {
    onItemSelect(item);

    return Promise.resolve();
  };

  return (
    <StatefulPopover
      content={({ close }) => (
        <StatefulMenu items={menuOptions} onItemSelect={(i) => handleItemSelect(i.item).then(close)} />
      )}
    >
      <div>{children}</div>
    </StatefulPopover>
  );
};
