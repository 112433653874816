import { LabelMedium } from 'baseui/typography';
import { ReactNode } from 'react';

interface FormGroupProps {
  label?: string;
  children?: ReactNode;
}
function FormGroup({ label, children }: FormGroupProps) {
  return (
    <>
      <LabelMedium marginTop="32px" marginBottom="12px">
        {label}
      </LabelMedium>
      {children}
    </>
  );
}

export default FormGroup;
