import { Modal, ROLE } from 'baseui/modal';
import { memo } from 'react';
import CaseHistory, { CaseHistoryProps } from '../../caseHistory/CaseHistory';

interface Props {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
}

const LogEntriesModalComp = ({ id, isOpen, setIsOpen }: Props & CaseHistoryProps) => {
  return (
    <Modal
      isOpen={isOpen}
      animate
      autoFocus
      onClose={() => setIsOpen(false)}
      role={ROLE.dialog}
      overrides={{
        Dialog: { style: { width: '95vw', padding: '16px' } },
      }}
    >
      <CaseHistory id={id} showExpandAllButton />
    </Modal>
  );
};

export const LogEntriesModal = memo(LogEntriesModalComp);
