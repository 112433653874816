import { Wrapper } from '@googlemaps/react-wrapper';
import { BaseProvider } from 'baseui';
import { SnackbarProvider } from 'baseui/snackbar';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as StyletronProvider } from 'styletron-react';
import App from './App';
import { ApiClientProvider } from './api/apiClientContext';
import { ReactQueryProvider } from './api/queries/reactQueryProvider';
import AuthProvider, { configureMsal } from './auth/authContext';
import { loadAppConfig } from './config/appConfig';
import AppInsightsContextProvider, { configureApplicationInsights } from './config/appInsights';
import { configureLocalization } from './config/localizationConfig';
import './index.css';
import { SpinnerOverlay } from './shared/basewebComponentOverrides/Spinner';
import { appTheme, styletronEngine } from './styles/appTheme';

(async function init() {
  const appConfig = await loadAppConfig();

  configureLocalization(appConfig);
  configureApplicationInsights(appConfig);
  configureMsal(appConfig);

  createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
      <StyletronProvider value={styletronEngine}>
        <BaseProvider theme={appTheme}>
          <Suspense fallback={<SpinnerOverlay />}>
            <BrowserRouter basename={appConfig.publicUrl}>
              <AppInsightsContextProvider>
                <AuthProvider>
                  <ReactQueryProvider>
                    <ApiClientProvider>
                      <SnackbarProvider>
                        <Wrapper apiKey={appConfig.googleMapsApiKey} libraries={['places']}>
                          <App />
                        </Wrapper>
                      </SnackbarProvider>
                    </ApiClientProvider>
                  </ReactQueryProvider>
                </AuthProvider>
              </AppInsightsContextProvider>
            </BrowserRouter>
          </Suspense>
        </BaseProvider>
      </StyletronProvider>
    </StrictMode>
  );
})();
