import { Children, cloneElement, isValidElement, memo, ReactNode, useEffect, useRef, useState } from 'react';
import { IconTypes } from './Map';
import { Pins } from './pins';
import { Icon } from './types';

export interface MarkerInfoWindowData {
  title: string;
  type: IconTypes;
  id: string;
  address?: string;
  postCode?: string;
  postArea?: string;
  country?: string;
  phoneNumber?: string;
  roleId?: string;
}

interface Props extends google.maps.MarkerOptions {
  type?: IconTypes;
  data?: MarkerInfoWindowData;
  children?: ReactNode;
}

const MarkerComp = ({ children, data, type = IconTypes.INCIDENT, visible, ...options }: Props) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  const [infoWindowVisible, setInfoWindowVisible] = useState(false);

  const markerListeners = useRef<google.maps.MapsEventListener[]>([]);

  useEffect(() => {
    const listeners = markerListeners.current;
    const icon: Icon = {
      url: getIcon(type),
    };

    if (!marker) {
      setMarker(new google.maps.Marker({ icon, zIndex: type === IconTypes.INCIDENT ? 10 : 2 }));
    } else {
      marker.setOptions({ ...options, icon });
      marker.setVisible(!!visible);
      const listener = marker.addListener('click', () => {
        setInfoWindowVisible(true);
      });

      listeners.push(listener);
    }

    return () => {
      if (marker) {
        marker.setMap(null);
        listeners.forEach((e) => e.remove());
        listeners.length = 0;
      }
    };
  }, [marker, visible, data, options, type]);

  function handleClose() {
    setInfoWindowVisible(false);
  }

  useEffect(() => {
    if (!visible && infoWindowVisible) {
      setInfoWindowVisible(false);
    }
  }, [visible, infoWindowVisible]);

  return (
    <>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return cloneElement<any>(child, {
            map: options.map,
            marker,
            onClose: handleClose,
            isOpen: infoWindowVisible,
          });
        }
      })}
    </>
  );
};

const getIcon = (types: IconTypes) => {
  switch (types) {
    case IconTypes.INCIDENT:
      return Pins.incident;
    case IconTypes.CHARGINGSTATION:
      return Pins.chargingstation;
    case IconTypes.CAR_RENTAL:
      return Pins.car_rental;
    case IconTypes.WORKSHOP:
      return Pins.workshop;
    case IconTypes.WORKSHOP_ACTIVE:
      return Pins.workshop_active;
  }
};

export const Marker = memo(MarkerComp);
