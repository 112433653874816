import { FormControl } from 'baseui/form-control';
import { Radio, RadioGroup, RadioGroupProps } from 'baseui/radio';
import { FastField, Field, FieldInputProps, FieldProps, FieldValidator } from 'formik';
import { ChangeEvent, ReactNode } from 'react';
import { FakeFormikEvent } from '../types';
import { customShouldComponentUpdate } from './fastFormFieldFunctions';

type SafeRadioGroupProps = Omit<RadioGroupProps, 'value' | 'onChange'>;

interface CommonRadioGroupFieldProps {
  label: string;
  children?: Array<ReactNode>;
  bindToNumber?: boolean;
  onChange?: (v: string | number) => void;
}

interface RadioGroupFormControlProps extends CommonRadioGroupFieldProps, SafeRadioGroupProps {
  fieldProps: FieldProps;
}

export interface RadioGroupFieldProps extends CommonRadioGroupFieldProps, SafeRadioGroupProps {
  name: string;
  validate?: FieldValidator;
}

export interface RadioGroupFastFieldProps extends RadioGroupFieldProps {
  deps?: unknown[];
}

function RadioGroupFormControl(props: RadioGroupFormControlProps) {
  const { fieldProps, label, disabled, children, bindToNumber, onChange, ...restOfProps } = props;

  const { field, meta } = fieldProps;

  const showError = meta.touched && !!meta.error;

  function smartCommitValue(inputValue: string | number, field: FieldInputProps<unknown>): void {
    if (inputValue !== field.value) {
      field.onChange(new FakeFormikEvent(field.name, inputValue));

      if (onChange) onChange(inputValue);
    }
  }

  return (
    <FormControl label={label} disabled={disabled} error={showError && meta.error}>
      <RadioGroup
        value={field.value?.toString()}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const newValue = e.currentTarget.value;
          const newFieldValue = bindToNumber ? parseInt(newValue) : newValue;

          smartCommitValue(newFieldValue, field);
        }}
        name={field.name}
        {...restOfProps}
      >
        {children}
      </RadioGroup>
    </FormControl>
  );
}

export function FastRadioGroupField(props: RadioGroupFastFieldProps) {
  const { name, validate, deps, ...restOfProps } = props;

  const fieldDeps = [props.disabled, props.required, ...(deps ?? [])];

  return (
    <FastField name={name} validate={validate} shouldUpdate={customShouldComponentUpdate} deps={fieldDeps}>
      {(fieldProps: FieldProps) => <RadioGroupFormControl {...restOfProps} fieldProps={fieldProps} />}
    </FastField>
  );
}

export default function RadioGroupField(props: RadioGroupFieldProps) {
  const { name, validate, ...restOfProps } = props;

  return (
    <Field name={name} validate={validate}>
      {(fieldProps: FieldProps) => <RadioGroupFormControl {...restOfProps} fieldProps={fieldProps} />}
    </Field>
  );
}

export { Radio };
