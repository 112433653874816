import { TextareaProps } from 'baseui/textarea';
import React, { useCallback, useEffect, useState } from 'react';
import useDebouncy from 'use-debouncy/lib/fn';
import { Textarea } from '../basewebComponentOverrides/Textarea';

export interface DebouncedTextareaProps extends TextareaProps {
  debounceMs?: number;
}

const DebouncedTextarea: React.FC<DebouncedTextareaProps> = (props: DebouncedTextareaProps) => {
  const { debounceMs, onChange, value, ...restOfProps } = props;

  const [localValue, setLocalValue] = useState<string | number>('');

  const debouncedHandleChange = useDebouncy((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event);
    }
  }, debounceMs ?? 0);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      event.persist();

      const newValue = event.currentTarget.value;

      setLocalValue(newValue);

      debouncedHandleChange(event);
    },
    [debouncedHandleChange]
  );

  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  return <Textarea value={localValue} onChange={handleChange} {...restOfProps} />;
};

export default DebouncedTextarea;
