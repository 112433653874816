import { Theme } from 'baseui';
import { Button, ExtendedButtonProps } from '@@shared/basewebComponentOverrides/Button';

export const ModalFooterButton = (props: Omit<ExtendedButtonProps, 'overrides'> & Record<string, unknown>) => {
  return (
    <Button
      $wideButton
      {...props}
      overrides={{ BaseButton: { style: ({ $theme }: { $theme: Theme }) => ({ marginLeft: $theme.sizing.scale400 }) } }}
    />
  );
};
