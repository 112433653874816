import { useStyletron } from 'baseui';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { noop } from 'lodash-es';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from '@@shared/utilityComponents/Pagination';
import {
  BrandDto,
  CaseSearchOrderKey,
  CaseSearchRequest,
  ComboboxItem,
  ModelDto,
  OrderDirectionEnum,
} from '../api/api.generated';
import { useSearchCaseMutation } from '../api/queries/useCaseQueries';
import { CaseSearchRequestFormFields, INITIAL_VALUES, unwrap } from '../caseHistory/sharedFunctions';
import { getNonEmptyFields, isEmptyObject } from '../shared/functions/general';
import { CaseSearchFilters } from './CaseSearchFilters';
import { CaseSearchForm } from './CaseSearchForm';
import { CaseSearchResults } from './CaseSearchResults';
import { mapCaseSearchDtoToItem } from './caseSearch.functions';

export interface Filter {
  value: string | number | Date | boolean | BrandDto | ModelDto | ComboboxItem;
  label: string;
  id: keyof CaseSearchRequest;
}

const NUMBER_OF_SEARCH_RESULTS_PER_PAGE = 20;

export const CaseSearchPage = () => {
  const [css, theme] = useStyletron();

  const [orderColumn, setOrderColumn] = useState<CaseSearchOrderKey>(CaseSearchOrderKey.VisCaseId);
  const [orderDirection, setOrderDirection] = useState<OrderDirectionEnum>(OrderDirectionEnum.Descending);

  const tSearch = useTranslation('translation', { keyPrefix: 'search' }).t;

  const dataRef = useRef<CaseSearchRequestFormFields>(INITIAL_VALUES);
  const setFieldValueRef = useRef<FormikHelpers<CaseSearchRequestFormFields>['setFieldValue']>(noop);

  const searchCaseQuery = useSearchCaseMutation();

  const searchResult = searchCaseQuery.data;

  const currentPage = searchResult?.currentPage ?? 1;
  const totalNumberOfResults = searchResult?.totalNumberOfResults ?? 1;
  const caseSearchResults = searchResult?.caseSearchResults;

  const caseSearchItems = useMemo(() => mapCaseSearchDtoToItem(caseSearchResults), [caseSearchResults]);

  const searchCaseQueryMutate = searchCaseQuery.mutate;

  const searchCases = useCallback(
    (
      values: CaseSearchRequestFormFields,
      page: number,
      orderBy: CaseSearchOrderKey,
      orderDirection: OrderDirectionEnum
    ) => {
      const nonEmptyFilterFields = getNonEmptyFields(unwrap(values).filters as Record<string, string>);

      if (isEmptyObject(nonEmptyFilterFields)) return;

      const searchRequest: CaseSearchRequest = {
        filters: {
          ...nonEmptyFilterFields,
        },
        resultPage: page,
        resultQty: NUMBER_OF_SEARCH_RESULTS_PER_PAGE,
        orderBy,
        orderDirection,
      };

      searchCaseQueryMutate(searchRequest);
    },
    [searchCaseQueryMutate]
  );

  const handleOrderColumnChange = (newOrderColumn: CaseSearchOrderKey) => {
    setOrderColumn(newOrderColumn);

    searchCases(dataRef.current, currentPage, newOrderColumn, orderDirection);
  };

  const handleOrderDirectionChange = (newOrderDirection: OrderDirectionEnum) => {
    setOrderDirection(newOrderDirection);

    searchCases(dataRef.current, currentPage, orderColumn, newOrderDirection);
  };

  const handlePageChange = (newPage: number) => {
    searchCases(dataRef.current, newPage, orderColumn, orderDirection);
  };

  const removeFilter = (filter: Filter) => {
    setFieldValueRef.current(filter.id, undefined, false);
  };

  const handleSubmit = (values: CaseSearchRequestFormFields) => {
    searchCases(values, 1, orderColumn, orderDirection);
  };

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
      {(formik: FormikProps<CaseSearchRequestFormFields>) => {
        setFieldValueRef.current = formik.setFieldValue;
        dataRef.current = formik.values;

        return (
          <Form className={css({ height: '100%' })}>
            <div
              className={css({
                display: 'grid',
                alignContent: 'stretch',
                height: '100%',
                minHeight: '0',
                paddingTop: theme.sizing.scale800,
                paddingBottom: theme.sizing.scale800,
                gridTemplateAreas: '"side top" "side main" "side bottom"',
                gridTemplateColumns: '400px minmax(500px, auto)',
                gridTemplateRows: '0fr 1fr 0fr',
                columnGap: '1em',
              })}
            >
              <div
                className={css({
                  gridArea: 'side',
                  overflow: 'auto',
                })}
              >
                <CaseSearchForm dataRef={dataRef} setFieldValueRef={setFieldValueRef} />
              </div>
              <div
                className={css({
                  gridArea: 'top',
                  marginBottom: theme.sizing.scale400,
                })}
              >
                <CaseSearchFilters
                  formValues={formik.values}
                  isLoading={searchCaseQuery.isLoading}
                  resetFilters={formik.resetForm}
                  removeFilter={removeFilter}
                />
              </div>
              <div
                className={css({
                  gridArea: 'main',
                  overflow: 'auto',
                })}
              >
                <CaseSearchResults
                  caseSearchResults={caseSearchItems}
                  isLoading={searchCaseQuery.isLoading}
                  emptyMessage={tSearch('noResults')}
                  orderColumn={orderColumn}
                  onOrderColumnChange={handleOrderColumnChange}
                  orderDirection={orderDirection}
                  onOrderDirectionChange={handleOrderDirectionChange}
                />
              </div>
              <div
                className={css({
                  gridArea: 'bottom',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  marginTop: theme.sizing.scale400,
                })}
              >
                <Pagination
                  currentPage={currentPage}
                  totalCount={totalNumberOfResults}
                  pageSize={NUMBER_OF_SEARCH_RESULTS_PER_PAGE}
                  siblingCount={2}
                  onPageChange={handlePageChange}
                  isLoading={searchCaseQuery.isLoading}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
