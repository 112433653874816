import { Block } from 'baseui/block';
import { Drawer } from 'baseui/drawer';
import { HeadingSmall, LabelLarge, ParagraphSmall } from 'baseui/typography';
import { isString } from 'lodash-es';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface LeasingDrawerProps {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
  leasingCompany?: LeasingCompany;
  leasingTaker?: LeasingTaker | string;
}

export interface LeasingCompany {
  name?: string;
  phone?: string;
  address?: string;
  postalCode?: string;
  postalCity?: string;
  country?: string;
}

export interface LeasingTaker {
  name?: string;
  address?: string;
  postalCode?: string;
  postalCity?: string;
  country?: string;
}

function LeasingDrawerComp(props: LeasingDrawerProps) {
  const tLeasing = useTranslation('translation', { keyPrefix: 'leasing' }).t;

  return (
    <Drawer isOpen={props.isOpen} animate autoFocus onClose={() => props.setIsOpen(false)}>
      <HeadingSmall>{tLeasing('leasingHeader')}</HeadingSmall>
      <LabelLarge>{tLeasing('leasingCompany')}</LabelLarge>
      {props.leasingCompany ? (
        <Block marginTop="8px" marginBottom="16px">
          {props.leasingCompany.name ? <ParagraphSmall margin={0}>{props.leasingCompany.name}</ParagraphSmall> : null}
          {props.leasingCompany.phone ? <ParagraphSmall margin={0}>{props.leasingCompany.phone}</ParagraphSmall> : null}
          {props.leasingCompany.address ? (
            <ParagraphSmall margin={0}>{props.leasingCompany.address}</ParagraphSmall>
          ) : null}

          {props.leasingCompany.postalCode ? (
            <ParagraphSmall margin={0}>{props.leasingCompany.postalCode}</ParagraphSmall>
          ) : null}
          {props.leasingCompany.postalCity ? (
            <ParagraphSmall margin={0}>{props.leasingCompany.postalCity}</ParagraphSmall>
          ) : null}
          {props.leasingCompany.country ? (
            <ParagraphSmall margin={0}>{props.leasingCompany.country}</ParagraphSmall>
          ) : null}
        </Block>
      ) : null}
      <LabelLarge>{tLeasing('leasingTaker')}</LabelLarge>

      {isString(props.leasingTaker) ? (
        <Block marginTop="8px">
          {props.leasingTaker?.split('\n').map((info, index) => {
            return (
              <ParagraphSmall margin={0} key={index}>
                {info}
              </ParagraphSmall>
            );
          })}
        </Block>
      ) : props.leasingTaker ? (
        <Block marginTop="8px">
          {props.leasingTaker.name ? <ParagraphSmall margin={0}>{props.leasingTaker.name}</ParagraphSmall> : null}
          {props.leasingTaker.address ? <ParagraphSmall margin={0}>{props.leasingTaker.address}</ParagraphSmall> : null}
          {props.leasingTaker.postalCode ? (
            <ParagraphSmall margin={0}>{props.leasingTaker.postalCode}</ParagraphSmall>
          ) : null}
          {props.leasingTaker.postalCity ? (
            <ParagraphSmall margin={0}>{props.leasingTaker.postalCity}</ParagraphSmall>
          ) : null}

          {props.leasingTaker.country ? <ParagraphSmall margin={0}>{props.leasingTaker.country}</ParagraphSmall> : null}
        </Block>
      ) : undefined}
    </Drawer>
  );
}

export const LeasingDrawer = memo(LeasingDrawerComp);
