import * as React from 'react';
import { PLACEMENT, Popover, TRIGGER_TYPE } from 'baseui/popover';
import { ButtonProps } from 'baseui/button';
import { Button } from '../basewebComponentOverrides/Button';

export default function PopoverButton(props: ButtonProps & { content: React.ReactNode | (() => React.ReactNode) }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { content, ...buttonProps } = props;
  return (
    <Popover
      isOpen={props.disabled ? props.disabled && isOpen : false}
      triggerType={TRIGGER_TYPE.hover}
      accessibilityType={'tooltip'}
      content={content}
      placement={PLACEMENT.top}
      showArrow
      returnFocus
      autoFocus
      overrides={{
        Body: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.backgroundInversePrimary,
            borderTopLeftRadius: $theme.borders.radius200,
            borderTopRightRadius: $theme.borders.radius200,
            borderBottomRightRadius: $theme.borders.radius200,
            borderBottomLeftRadius: $theme.borders.radius200,
            padding: $theme.sizing.scale600,
          }),
        },
        Inner: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.backgroundInversePrimary,
            color: $theme.colors.white,
          }),
        },
        Arrow: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.backgroundInversePrimary,
          }),
        },
      }}
    >
      <span style={{ position: 'relative' }}>
        <Button {...buttonProps} />
        {props.disabled && (
          <div
            onMouseOver={() => setIsOpen(true)}
            onMouseOut={() => setIsOpen(false)}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
            }}
          />
        )}
      </span>
    </Popover>
  );
}
