import { FILL, Tab, Tabs } from 'baseui/tabs-motion';
import { Key, memo, useState } from 'react';
import { TwoHalvesLayoutProps } from './TwoHalvesLayout';

const TAB_HEIGHT = 52;
const TAB_BORDER_HEIGHT = 5;
const TAB_BAR_HEIGHT = TAB_HEIGHT + TAB_BORDER_HEIGHT;

const TabsLayoutComp = ({ children }: TwoHalvesLayoutProps) => {
  const [activeTab, setActiveTab] = useState<Key>('0');

  return (
    <Tabs
      overrides={{ Root: { style: { height: '100%' } }, TabBorder: { style: { height: `${TAB_BORDER_HEIGHT}px` } } }}
      activeKey={activeTab}
      fill={FILL.fixed}
      onChange={({ activeKey }) => setActiveTab(activeKey)}
    >
      <Tab
        overrides={{
          TabPanel: { style: { height: `calc(100% - ${TAB_BAR_HEIGHT}px)` } },
          Tab: { style: { height: `${TAB_HEIGHT}px` } },
        }}
        title={children[0].props.name}
      >
        {children[0]}
      </Tab>
      <Tab
        overrides={{ TabPanel: { style: { height: `calc(100% - ${TAB_BAR_HEIGHT}px)` } } }}
        title={children[1].props.name}
      >
        {children[1]}
      </Tab>
    </Tabs>
  );
};

export const TabsLayout = memo(TabsLayoutComp);
