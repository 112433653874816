import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdminUpdateUserCommand, CreateUserCommand } from '@@api/api.generated';
import { useApiClient } from '../apiClientContext';
import { getInvalidateQueriesMutationOption, USERS_QUERY_KEY } from './queryUtils';

export const useAdminCreateUserMutation = () => {
  const { userAdminClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [USERS_QUERY_KEY]);

  return useMutation((createCommand: CreateUserCommand) => userAdminClient.createUser(createCommand), options);
};

export const useAdminUpdateUserMutation = () => {
  const { userAdminClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [USERS_QUERY_KEY]);

  return useMutation(
    (args: { userId: string; updateCommand: AdminUpdateUserCommand }) =>
      userAdminClient.updateUser(args.userId, args.updateCommand),
    options
  );
};

export const useAdminDeactivateUserMutation = () => {
  const { userAdminClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [USERS_QUERY_KEY]);

  return useMutation((userId: string) => userAdminClient.deactivateUser(userId), options);
};

export const useAdminActivateUserMutation = () => {
  const { userAdminClient } = useApiClient();
  const options = getInvalidateQueriesMutationOption(useQueryClient(), [USERS_QUERY_KEY]);

  return useMutation((userId: string) => userAdminClient.activateUser(userId), options);
};
