import { useLayoutEffect, useState } from 'react';

export const useContainerQuery = (element: Element | null, minWidth: number, defaultMatch = false): boolean => {
  const [match, setMatch] = useState(defaultMatch);

  useLayoutEffect(() => {
    if (!element) return;

    setMatch(element.clientWidth >= minWidth);

    const widthObserver = new ResizeObserver((entries) => {
      const containerWidth = entries[0].contentRect.width;

      setMatch(containerWidth >= minWidth);
    });

    widthObserver.observe(element);

    return () => {
      widthObserver.unobserve(element);
    };
  }, [minWidth, element]);

  return match;
};
