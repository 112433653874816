import { createContext, memo, ReactNode, useContext, useMemo } from 'react';

export interface TimeZoneContextValue {
  timeZone?: string;
}

const TimeZoneContext = createContext<TimeZoneContextValue>(undefined as never);
TimeZoneContext.displayName = 'TimeZoneContext';

export const useTimeZoneContext = () => {
  const ctx = useContext(TimeZoneContext);

  if (!ctx) {
    throw new Error('TimeZoneContext must be provided before use');
  }

  return ctx;
};

interface DateProviderProps {
  timeZone?: string;
  children: ReactNode;
}

const TimeZoneContextProviderComponent = ({ timeZone, children }: DateProviderProps) => {
  const memoValue = useMemo<TimeZoneContextValue>(() => ({ timeZone }), [timeZone]);

  return <TimeZoneContext.Provider value={memoValue}>{children}</TimeZoneContext.Provider>;
};

export const TimeZoneContextProvider = memo(TimeZoneContextProviderComponent);
