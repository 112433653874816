import { useMsal } from '@azure/msal-react';
import { UseQueryResult } from '@tanstack/react-query';
import { Role, UserDto } from '../../api/api.generated';
import { useUserQuery } from '../../api/queries/useUserQueries';
import { appConfig } from '../../config/appConfig';
import { COUNTRY_ALPHA3_TO_IANA_TIME_ZONE_MAP, V_LANGUAGE_CODE_TO_ISO1 } from '../constants';

interface UseUserHook {
  languageCode: string;
  languageCodeViking: string;
  callCenterCountry: string;
  callCenterTimeZone: string;
  role: Required<Role>;
  userId: string;
}

export const useUser = (noRetry?: boolean): UseUserHook & UseQueryResult<UserDto> => {
  const userQuery = useUserQuery(noRetry);

  const { accounts } = useMsal();

  const userId = userQuery.data?.id ?? accounts[0].localAccountId;

  const languageCode = V_LANGUAGE_CODE_TO_ISO1[userQuery.data?.languageCode ?? ''] ?? appConfig.defaultLanguage;
  const languageCodeViking = userQuery.data?.languageCode ?? '';

  const callCenterCountry = userQuery.data?.callCenterCountryCode ?? appConfig.defaultCallCenterCountry;

  const callCenterTimeZone = COUNTRY_ALPHA3_TO_IANA_TIME_ZONE_MAP[callCenterCountry];

  const userRole = userQuery.data?.role;

  const role: Required<Role> = {
    id: userRole?.id ?? -1,
    name: userRole?.name ?? '',
    description: userRole?.description ?? '',
  };

  return { callCenterCountry, languageCode, languageCodeViking, callCenterTimeZone, role, userId, ...userQuery };
};
