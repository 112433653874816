import { useStyletron } from 'baseui';
import { KIND, Notification } from 'baseui/notification';
import { ReactNode } from 'react';
import { MdCancel, MdInfo, MdOutlineCheckCircle, MdWarning } from 'react-icons/md';
import { expandBorderRadius, expandBorderStyles, expandMargin } from '../../styles/styleUtils';

export interface BannerProps {
  text: ReactNode;
  kind?: typeof KIND[keyof typeof KIND];
  button?: JSX.Element;
  closeable?: boolean;
}

export function Banner({ text, kind, button, closeable }: BannerProps) {
  const [, theme] = useStyletron();

  const icon =
    kind === 'info' ? (
      <MdInfo size={24} />
    ) : kind === 'positive' ? (
      <MdOutlineCheckCircle size={24} />
    ) : kind === 'warning' ? (
      <MdWarning size={24} />
    ) : kind === 'negative' ? (
      <MdCancel size={24} />
    ) : null;

  const borderColor =
    kind === 'info'
      ? theme.colors.borderAccentLight
      : kind === 'positive'
      ? theme.colors.borderPositive
      : kind === 'warning'
      ? theme.colors.borderWarning
      : kind === 'negative'
      ? theme.colors.borderNegative
      : theme.colors.borderAccentLight;

  const [css] = useStyletron();

  return (
    <Notification
      closeable={closeable}
      kind={kind}
      overrides={{
        Body: {
          style: {
            width: 'auto',
            ...expandBorderStyles(`1px solid ${borderColor}`),
            ...expandBorderRadius(0),
            ...expandMargin(['1em', '0']),
          },
        },
        InnerContainer: {
          style: {
            width: '100%',
          },
        },
      }}
    >
      <div className={css({ display: 'flex' })}>
        {icon && <div className={css({ flex: '0 1 auto', paddingRight: '10px' })}>{icon}</div>}
        <div className={css({ flex: '1 1 auto' })}>
          <>{text}</>
        </div>
        {button && <div className={css({ flex: '0 1 auto' })}>{button}</div>}
      </div>
    </Notification>
  );
}
