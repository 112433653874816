import { useStyletron } from 'baseui';
import { LabelMedium } from 'baseui/typography';
import { InitialsAvatar } from '@@shared/utilityComponents/InitialsAvatar';
import { NavItem, PopoverNavMenu } from '@@shared/utilityComponents/PopoverNavMenu';
import { useAppRoutes } from '../Routes';

interface Props {
  name: string;
}

export const UserMenu = ({ name }: Props) => {
  const appRoutes = useAppRoutes();

  const [css, theme] = useStyletron();

  const menuOptions: NavItem[] = appRoutes
    .filter((r) => r.menu === 'user')
    .map((r) => ({ id: r.path, label: r.label, url: r.path, icon: r.icon }));

  return (
    <PopoverNavMenu menuOptions={menuOptions}>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          cursor: 'pointer',
        })}
      >
        <LabelMedium color={[theme.colors.primaryB]}>{name}</LabelMedium>
        <div className={css({ paddingLeft: '1em' })}>
          <InitialsAvatar name={name} size="1.5em" />
        </div>
      </div>
    </PopoverNavMenu>
  );
};
