import { LabelXSmall } from 'baseui/typography';
import { ReactNode, useEffect, useState } from 'react';
import { appConfig } from '../config/appConfig';
import { COUNTRY_ALPHA3_TO_IANA_TIME_ZONE_MAP } from './constants';
import { useUser } from './hooks/useUser';
import { useZonedDateUtils } from './hooks/useZonedDateUtils';

export function DebugOnly(props: { children: ReactNode }) {
  const isDebug = ['local', 'dev', 'qa'].includes(appConfig.environment.toLowerCase());

  if (!isDebug) return null;

  return <>{props.children}</>;
}

const timeFormatter = new Intl.DateTimeFormat(undefined, {
  hour: 'numeric',
  minute: 'numeric',
});

const dateTimeFormatter = new Intl.DateTimeFormat(undefined, {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

export function DateAndLocaleInfo() {
  const { languageCode, callCenterCountry } = useUser();

  const { formatDateTime, formatTime } = useZonedDateUtils();

  const [currentDate, setCurrentDate] = useState(() => new Date());

  const [hover, setHover] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const userTz = COUNTRY_ALPHA3_TO_IANA_TIME_ZONE_MAP[callCenterCountry];
  const userFormatFn = hover ? formatDateTime : formatTime;
  const userFormattedDate = `${userFormatFn(currentDate)}` + (hover ? ` ${userTz}` : '');

  const clientTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const clientFormatter = hover ? dateTimeFormatter : timeFormatter;
  const clientFormattedDate = `${clientFormatter.format(currentDate)}` + (hover ? ` ${clientTz}` : '');

  return (
    <LabelXSmall
      display="inline"
      marginRight="scale400"
      color="primaryB"
      $style={{ whiteSpace: 'nowrap' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      Language {languageCode} | Call center {callCenterCountry} | Call center time {userFormattedDate} | Browser time{' '}
      {clientFormattedDate}
    </LabelXSmall>
  );
}
