import { mergeOverrides, Theme, withStyle } from 'baseui';
import { Button as BaseUIButton, ButtonProps, KIND, StyledBaseButton } from 'baseui/button';
import { expandBorderStyles } from '../../styles/styleUtils';

export interface ExtendedButtonProps extends ButtonProps {
  $wideButton?: boolean;
}

const AppStyledBaseButton = withStyle(
  StyledBaseButton,
  (props: ExtendedButtonProps & { $theme: Theme } & { $borderKind?: (typeof KIND)[keyof typeof KIND] }) => {
    const borderColor = props.$borderKind === KIND.tertiary ? 'transparent' : props.$theme.colors.borderOpaque;

    const isWideButton = !!props.$wideButton;

    return {
      ...expandBorderStyles(`1px solid ${borderColor}`),
      minWidth: isWideButton ? '13.5em' : undefined,
    };
  }
);

export const Button = (props: ExtendedButtonProps & Record<string, unknown>): JSX.Element => {
  const { overrides, ...rest } = props;

  const mergedOverrides = mergeOverrides(
    {
      BaseButton: { component: AppStyledBaseButton, props: { $borderKind: props.kind } },
    },
    { ...overrides }
  );

  return (
    <BaseUIButton {...rest} overrides={mergedOverrides}>
      {props.children}
    </BaseUIButton>
  );
};
