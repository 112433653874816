import { mergeOverrides } from 'baseui/helpers/overrides';
import { Select, SelectOverrides, SelectProps } from 'baseui/select';
import { expandBorderStyles } from '@@styles/styleUtils';

type SelectMiniProps = Omit<SelectProps, 'searchable' | 'clearable'>;

export const SelectMini = (props: SelectMiniProps) => {
  const styleOverrides: SelectOverrides = {
    ControlContainer: { style: { backgroundColor: 'transparent', ...expandBorderStyles('none') } },
  };

  const mergedOverrides = mergeOverrides({ ...styleOverrides }, { ...props.overrides });

  return <Select searchable={false} clearable={false} overrides={mergedOverrides} {...props} />;
};
