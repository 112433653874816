import { useStyletron } from 'baseui';
import { ButtonOverrides, KIND } from 'baseui/button';
import { LabelMedium } from 'baseui/typography';
import { CSSProperties, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAddCircleOutline, MdSearch } from 'react-icons/md';
import { CaseSearchOrderKey, OrderDirectionEnum } from '@@api/api.generated';
import { mapCaseSearchDtoToItem } from '@@caseSearch/caseSearch.functions';
import { CaseSearchResultItem, SEARCH_RESULT_ITEM_TO_SORT_TYPE_MAP } from '@@caseSearch/caseSearch.models';
import { PageTitle } from '@@shared/basewebComponentOverrides/Typography';
import { orderBy } from '@@shared/functions/orderFunctions';
import { useSearchCaseQuery } from '../../api/queries/useCaseQueries';
import { CaseSearchResults } from '../../caseSearch/CaseSearchResults';
import { useUser } from '../../shared/hooks/useUser';
import { Logos } from '../../shared/images/logos';
import { CaseStatusEnum, Country } from '../../shared/types';
import ButtonLink, { ButtonExternalLink } from '../../shared/utilityComponents/ButtonLink';

const buttonOverrides: ButtonOverrides = {
  BaseButton: {
    style: {
      backgroundColor: 'white',
      minWidth: '175px',
      minHeight: '100px',
    },
  },
};

const bigButtonOverrides: ButtonOverrides = {
  BaseButton: {
    style: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '250px',
      minHeight: '150px',
      fontSize: '18px',
      textAlign: 'center',
    },
  },
};

const iconStyles: CSSProperties = {
  display: 'block',
  width: '100%',
  marginBottom: '10px',
};

export const Dashboard = () => {
  const [orderColumn, setOrderColumn] = useState<CaseSearchOrderKey>(CaseSearchOrderKey.IncidentDate);
  const [orderDirection, setOrderDirection] = useState<OrderDirectionEnum>(OrderDirectionEnum.Descending);

  const [css, theme] = useStyletron();

  const tDashboard = useTranslation('translation', { keyPrefix: 'dashboard' }).t;

  const { data, callCenterCountry } = useUser();

  const { data: caseSearchResults, isLoading: activeIsLoading } = useSearchCaseQuery({
    filters: {
      caseAgentId: data?.visUserId,
      caseStatusIds: [CaseStatusEnum.Active, CaseStatusEnum.Transferred],
    },
  });

  const cases = useMemo(() => {
    if (!caseSearchResults?.caseSearchResults) return [];

    const caseSearchItems = mapCaseSearchDtoToItem(caseSearchResults.caseSearchResults);

    const sortNilOrEmptyLast = true;

    const orderedCases = orderBy<CaseSearchResultItem>(
      caseSearchItems,
      orderColumn,
      orderDirection === OrderDirectionEnum.Descending,
      SEARCH_RESULT_ITEM_TO_SORT_TYPE_MAP[orderColumn],
      sortNilOrEmptyLast
    );

    return orderedCases;
  }, [caseSearchResults, orderColumn, orderDirection]);

  const isLoading = activeIsLoading;

  return (
    <div
      className={css({
        display: 'grid',
        height: '100%',
        paddingTop: theme.sizing.scale800,
        paddingBottom: theme.sizing.scale800,
        gridTemplateAreas: '"title title" "main side"',
        gridTemplateColumns: 'minmax(200px, auto) minmax(300px, auto)',
        gridTemplateRows: 'min-content auto',
        columnGap: '1em',
      })}
    >
      <div className={css({ gridArea: 'title' })}>
        <PageTitle>{tDashboard('recentCases')}</PageTitle>
      </div>

      <div
        className={css({
          gridArea: 'main',
          overflow: 'auto',
        })}
      >
        <CaseSearchResults
          caseSearchResults={cases}
          isLoading={isLoading}
          orderColumn={orderColumn}
          orderDirection={orderDirection}
          onOrderColumnChange={setOrderColumn}
          onOrderDirectionChange={setOrderDirection}
          clientSideSorting
        />
      </div>
      <div
        className={css({
          gridArea: 'side',
        })}
      >
        <div className={css({ display: 'flex', flexWrap: 'wrap', gap: '0.5em' })}>
          <ButtonLink type="button" to="/case-search" kind={KIND.secondary} overrides={bigButtonOverrides}>
            <MdSearch size={40} style={iconStyles} />
            {tDashboard('caseSearch')}
          </ButtonLink>
          <ButtonLink type="button" to="/new-case" kind={KIND.primary} overrides={bigButtonOverrides}>
            <MdAddCircleOutline size={40} style={iconStyles} />
            {tDashboard('newCase')}
          </ButtonLink>
        </div>
        <LabelMedium margin={'40px 0 20px'}>{tDashboard('helpfulLinks')}</LabelMedium>
        <div className={css({ display: 'flex', flexWrap: 'wrap', gap: '0.5em' })}>
          {links
            .filter((link) => link.country === callCenterCountry)
            .map((link) => {
              return (
                <ButtonExternalLink
                  key={`link-${link.type}-${link.country}`}
                  type="button"
                  href={link.url}
                  overrides={buttonOverrides}
                  kind={KIND.secondary}
                >
                  <img src={Logos[link.type]} alt={`${link.type} ${link.country} logo`} />
                </ButtonExternalLink>
              );
            })}
        </div>
      </div>
    </div>
  );
};

enum LinkType {
  HERTZ = 'HERTZ',
  EGENCIA = 'EGENCIA',
  TAXI = 'TAXI',
}

const links: Link[] = [
  {
    type: LinkType.HERTZ,
    country: Country.Norway,
    url: 'https://ebook.drivehertz.net/#/start',
  },
  {
    type: LinkType.HERTZ,
    country: Country.Sweden,
    url: 'https://ebook.drivehertz.net/#/start',
  },
  {
    type: LinkType.EGENCIA,
    country: Country.Norway,
    url: 'https://www.egencia.no/auth/v1/login?isRedirected=true',
  },
  {
    type: LinkType.EGENCIA,
    country: Country.Denmark,
    url: 'https://www.egencia.dk/auth/v1/login?isRedirected=true',
  },
  {
    type: LinkType.EGENCIA,
    country: Country.Finland,
    url: 'https://www.egencia.fi/auth/v1/login?isRedirected=true',
  },
  {
    type: LinkType.EGENCIA,
    country: Country.Sweden,
    url: 'https://www.egencia.se/auth/v1/login?isRedirected=true',
  },
  {
    type: LinkType.TAXI,
    country: Country.Norway,
    url: 'https://taxifix.no/',
  },
  {
    type: LinkType.TAXI,
    country: Country.Sweden,
    url: 'https://svea.taxibokning.se/Public/Default.aspx',
  },
  {
    type: LinkType.TAXI,
    country: Country.Denmark,
    url: 'https://book.dantaxi.dk/login',
  },
];

interface Link {
  url: string;
  country: Country;
  type: LinkType;
}
