import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { KIND, SIZE } from 'baseui/button';
import { OnChangeParams } from 'baseui/select';
import { Skeleton } from 'baseui/skeleton';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { toString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { BsThreeDots } from 'react-icons/bs';
import { UserDto } from '@@api/api.generated';
import { useRolesQuery } from '@@api/queries/useUserQueries';
import { Button } from '@@shared/basewebComponentOverrides/Button';
import { CALL_CENTER_LIST, ISO1_LANGUAGE_CODE_TO_V, LANGUAGE_LIST } from '@@shared/constants';
import { toSelectOptions } from '@@shared/functions/general';
import { useUser } from '@@shared/hooks/useUser';
import { SelectMini } from '@@shared/inputs/SelectMini';
import { InitialsAvatar } from '@@shared/utilityComponents/InitialsAvatar';
import { PopoverMenu } from '@@shared/utilityComponents/PopoverMenu';
import { StyledIncognitoLink } from '@@shared/utilityComponents/RouterLink';
import { grays } from '@@styles/appTheme';
import { expandPadding } from '@@styles/styleUtils';
import { PAGE_SIZE } from './UserListPage';

const CALL_CENTER_OPTIONS = toSelectOptions(CALL_CENTER_LIST);

interface Props {
  users: UserDto[];
  dataIsLoading: boolean;
  userUpdateIsLoading: boolean;
  onSort: (columnId: string) => void;
  sortColumn: string;
  sortOrder: 'ASC' | 'DESC';
  onUpdateRole: (userId: string, roleId: number) => void;
  onUpdateCallCenter: (userId: string, callCenter: string) => void;
  onUpdateLanguage: (userId: string, language: string) => void;
  onToggleUserActive: (user: UserDto) => void;
}

export const UsersTable = (props: Props) => {
  const { users, dataIsLoading, userUpdateIsLoading, onSort, sortColumn, sortOrder } = props;
  const { onUpdateRole, onUpdateCallCenter, onUpdateLanguage, onToggleUserActive } = props;

  const { data: currentUser } = useUser();

  const [css, theme] = useStyletron();

  const tLanguage = useTranslation('translation', { keyPrefix: 'language' }).t;
  const tUsers = useTranslation('translation', { keyPrefix: 'users' }).t;

  const { data: roles } = useRolesQuery();

  const roleOptions = (roles ?? []).map((r) => ({ id: r.id, label: r.name }));

  const languageOptions = LANGUAGE_LIST.map((l) => ({ id: ISO1_LANGUAGE_CODE_TO_V[l], label: tLanguage(l) }));

  const getFormattedUsername = function (user: UserDto, fallback?: string) {
    if (!user.firstName || !user.lastName) return fallback ?? '';

    return `${user.firstName} ${user.lastName}`.trim();
  };

  const onRoleChange = (userId: string | undefined) => (params: OnChangeParams) => {
    const selectionId = params.value[0].id;

    if (!userId) return;

    if (selectionId !== undefined) {
      onUpdateRole(userId, Number(selectionId));
    }
  };

  const onCallCenterChange = (userId: string | undefined) => (params: OnChangeParams) => {
    const selectionId = params.value[0].id;

    if (!userId) return;

    if (selectionId !== undefined) {
      onUpdateCallCenter(userId, toString(selectionId));
    }
  };

  const onLanguageChange = (userId: string | undefined) => (params: OnChangeParams) => {
    const selectionId = params.value[0].id;

    if (!userId) return;

    if (selectionId !== undefined) {
      onUpdateLanguage(userId, toString(selectionId));
    }
  };

  const isCurrentUser = (user: UserDto) => {
    if (!user || !currentUser) return false;

    return user.id === currentUser.id;
  };

  const canEditUser = (user: UserDto) => {
    if (!user || !user.active || isCurrentUser(user)) return false;

    return true;
  };

  const loadingSkeleton = (
    <div>
      <Skeleton
        height="100%"
        width="100%"
        animation
        rows={PAGE_SIZE}
        overrides={{
          Row: {
            style: {
              height: '60px',
              marginBottom: '15px',
            },
          },
        }}
      />
    </div>
  );

  const handleItemSelect = (user: UserDto) => (): void => {
    onToggleUserActive(user);
  };

  return (
    <TableBuilder
      data={users || []}
      onSort={onSort}
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      isLoading={dataIsLoading}
      loadingMessage={loadingSkeleton}
      overrides={{
        Root: { style: { height: '100%' } },
        TableBodyRow: {
          style: ({ $row }: { $row: UserDto }) => ({
            backgroundColor: !$row.active ? `${grays.gray200} !important` : undefined,
          }),
        },
      }}
    >
      <TableBuilderColumn
        id="name"
        header={tUsers('name')}
        sortable
        overrides={{ TableBodyCell: { style: { verticalAlign: 'middle', ...expandPadding(0) } } }}
      >
        {(user: UserDto) => (
          <StyledIncognitoLink
            to={`/user/${user.visUserId}`}
            className={css({
              display: 'flex',
              cursor: 'pointer',
              ...expandPadding([
                theme.sizing.scale600,
                theme.sizing.scale1200,
                theme.sizing.scale600,
                theme.sizing.scale600,
              ]),
            })}
          >
            <Block paddingRight="scale400">
              <InitialsAvatar name={getFormattedUsername(user, '?')} />
            </Block>
            <Block display="flex" flexDirection="column" justifyContent="space-between">
              <div>{getFormattedUsername(user)}</div>
              <div>{user.email}</div>
            </Block>
          </StyledIncognitoLink>
        )}
      </TableBuilderColumn>
      <TableBuilderColumn
        id="role.id"
        header={tUsers('role')}
        sortable
        overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }}
      >
        {(user: UserDto) => (
          <div>
            <SelectMini
              disabled={userUpdateIsLoading || !canEditUser(user)}
              options={roleOptions}
              value={toSelectOptions(toString(user.role?.id))}
              onChange={onRoleChange(user.id)}
            />
          </div>
        )}
      </TableBuilderColumn>
      <TableBuilderColumn
        id="callCenterCountryCode"
        header={tUsers('callCenter')}
        sortable
        overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }}
      >
        {(user: UserDto) => (
          <div>
            <SelectMini
              disabled={userUpdateIsLoading || !canEditUser(user)}
              options={CALL_CENTER_OPTIONS}
              value={toSelectOptions(user.callCenterCountryCode)}
              onChange={onCallCenterChange(user.id)}
            />
          </div>
        )}
      </TableBuilderColumn>
      <TableBuilderColumn
        id="languageCode"
        header={tUsers('language')}
        sortable
        overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }}
      >
        {(user: UserDto) => (
          <div>
            <SelectMini
              disabled={userUpdateIsLoading || !canEditUser(user)}
              options={languageOptions}
              value={toSelectOptions(user.languageCode)}
              onChange={onLanguageChange(user.id)}
            />
          </div>
        )}
      </TableBuilderColumn>
      {/*<TableBuilderColumn*/}
      {/*  id="lastActive"*/}
      {/*  header={tUsers('lastActive')}*/}
      {/*  sortable*/}
      {/*  overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }}*/}
      {/*>*/}
      {/*  {(user: UserDto) => (*/}
      {/*    <div>*/}
      {/*      <TextMedium>{formatDateTime(user.lastLogin) ?? tUsers('inactive')}</TextMedium>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</TableBuilderColumn>*/}
      <TableBuilderColumn id="actions" overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }}>
        {(user: UserDto) => (
          <div>
            <PopoverMenu
              menuOptions={[
                {
                  id: 1,
                  label: toString(user.active ? tUsers('deactivateUser') : tUsers('activateUser')),
                  disabled: isCurrentUser(user),
                },
              ]}
              onItemSelect={handleItemSelect(user)}
            >
              <Button type="button" kind={KIND.tertiary} size={SIZE.compact}>
                <BsThreeDots />
              </Button>
            </PopoverMenu>
          </div>
        )}
      </TableBuilderColumn>
    </TableBuilder>
  );
};
