import { TimePicker, TimePickerProps } from 'baseui/timepicker';
import { min } from 'date-fns/esm';
import { isNil } from 'lodash-es';
import { FC } from 'react';
import { useZonedDateUtils } from '../hooks/useZonedDateUtils';

export interface TimePickerExtraProps extends TimePickerProps<Date> {
  useZonedTime?: boolean;
}

const TimePickerExtra: FC<TimePickerExtraProps> = (props: TimePickerExtraProps) => {
  const { value, onChange, useZonedTime, maxTime, ...restOfProps } = props;

  const { convertUtcToZonedTime, convertZonedTimeToUtc } = useZonedDateUtils();

  let convertedDate = value;
  let convertedMaxTime = maxTime;

  if (useZonedTime) {
    if (!isNil(convertedDate)) {
      convertedDate = convertUtcToZonedTime(convertedDate);
    }

    if (!isNil(convertedMaxTime)) {
      convertedMaxTime = convertUtcToZonedTime(convertedMaxTime);
    }
  }

  const handleChange = (date: Date | null) => {
    if (!onChange) return; // No point in converting if there's no handler prop

    if (!useZonedTime) {
      onChange(date);
    }

    let convertedDate = date;

    if (!isNil(date)) {
      if (!isNil(convertedMaxTime)) {
        date = min([date, convertedMaxTime]);
      }

      convertedDate = convertZonedTimeToUtc(date);
    }

    onChange(convertedDate);
  };

  return <TimePicker value={convertedDate} onChange={handleChange} maxTime={convertedMaxTime} {...restOfProps} />;
};

export default TimePickerExtra;
