import { Block } from 'baseui/block';
import { KIND } from 'baseui/button';
import { ButtonGroup } from 'baseui/button-group';
import { Input } from 'baseui/input';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { expandMargin } from '@@styles/styleUtils';
import { Button } from '../basewebComponentOverrides/Button';
import { isNumeric } from '../functions/general';
import { DOTS, usePagination } from '../hooks/usePagination';

export interface PaginationConfig {
  totalCount?: number;
  currentPage?: number;
  pageSize: number;
  siblingCount?: number;
  isLoading?: boolean;
}

export interface PaginationProps extends PaginationConfig {
  onPageChange: (page: number) => void;
}

export const Pagination = ({
  currentPage = 1,
  pageSize = 1,
  totalCount = 1,
  siblingCount = 1,
  onPageChange,
  isLoading,
}: PaginationProps) => {
  const [manualPage, setManualPage] = useState<number | undefined>(currentPage);
  const paginationRange = usePagination({ pageSize, currentPage, totalCount, siblingCount });

  useEffect(() => {
    setManualPage(currentPage);
  }, [currentPage]);

  const { t } = useTranslation('translation', { keyPrefix: 'shared' });

  const onNext = () => {
    const nextPage = manualPage ? manualPage + 1 : 1;
    onPageChange(nextPage);
    setManualPage(nextPage);
  };

  const onPrevious = () => {
    const nextPage = manualPage ? manualPage - 1 : 1;
    onPageChange(nextPage);
    setManualPage(nextPage);
  };

  const goToPage = () => {
    onPageChange(manualPage ? manualPage : 1);
  };

  const globalDisable = isLoading || paginationRange.length <= 1;
  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Block display="flex">
      <Button
        onClick={onPrevious}
        kind={KIND.secondary}
        startEnhancer={() => <FaChevronLeft />}
        disabled={currentPage === 1 || globalDisable}
        type="button"
        overrides={{
          BaseButton: { style: { ...expandMargin(['0', '0.2em']) } },
        }}
      >
        {t('previous')}
      </Button>
      <ButtonGroup overrides={{ Root: { style: { justifyContent: 'center' } } }}>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <Button
                kind={KIND.secondary}
                key={`page-${pageNumber}-${index}`}
                disabled
                type="button"
                overrides={{
                  BaseButton: { style: { ...expandMargin(['0', '0.2em']) } },
                }}
              >
                {DOTS}
              </Button>
            );
          } else {
            return (
              <Button
                onClick={() => onPageChange(pageNumber as number)}
                key={`page-${pageNumber}-${index}`}
                isSelected={pageNumber === currentPage}
                disabled={globalDisable}
                type="button"
                overrides={{
                  BaseButton: { style: { ...expandMargin(['0', '0.2em']) } },
                }}
              >
                {pageNumber}
              </Button>
            );
          }
        })}
      </ButtonGroup>
      <Button
        onClick={onNext}
        kind={KIND.secondary}
        endEnhancer={() => <FaChevronRight />}
        disabled={currentPage === lastPage || globalDisable}
        type="button"
        overrides={{
          BaseButton: { style: { ...expandMargin(['0', '0.2em']) } },
        }}
      >
        {t('next')}
      </Button>
      <Block display="flex">
        <Input
          overrides={{ Root: { style: { width: '4em', ...expandMargin(['0', '0.2em']) } } }}
          value={manualPage}
          onChange={(e) => {
            const input = e.currentTarget.value;
            if (isNumeric(input)) {
              setManualPage(parseInt(input));
            } else {
              setManualPage(undefined);
            }
          }}
          disabled={globalDisable}
        />
        <Button
          onClick={goToPage}
          disabled={globalDisable}
          type="button"
          overrides={{
            BaseButton: { style: { ...expandMargin(['0', '0.2em']) } },
          }}
        >
          {t('choose')}
        </Button>
      </Block>
    </Block>
  );
};
