import { IMAGES } from '../../shared/images';

export const Unauthorized = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>You are not authorized to use this application. </h1>
      <img src={IMAGES.access_denied} alt="access denied" />
    </div>
  );
};
