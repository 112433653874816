import { mergeOverrides, Theme, withStyle } from 'baseui';
import { InputOverrides, StyledRoot } from 'baseui/input';
import { StyledTextarea, Textarea as BaseUITextarea, TextareaProps } from 'baseui/textarea';
import { expandBorderStyles } from '../../styles/styleUtils';

const AppStyledRoot = withStyle(StyledRoot, (props: TextareaProps & { $theme: Theme }) => {
  const customBorder = { ...expandBorderStyles(`2px solid ${props.$theme.colors.inputBorder}`) };

  return props.disabled ? customBorder : {};
});

const AppStyledTextarea = withStyle(StyledTextarea, (props: TextareaProps & { $theme: Theme }) => {
  return {
    backgroundColor: props.readOnly ? props.$theme.colors.inputFillDisabled : undefined,
    maxHeight: '18em',
    minHeight: '6em',
    width: '100vw', // fill all available space up to parent max-width
    resize: 'vertical',
  };
});

export const Textarea = (props: TextareaProps & Record<string, unknown>): JSX.Element => {
  const { overrides, ...rest } = props;

  const inputOverrides: InputOverrides = {
    Root: {
      component: AppStyledRoot,
      props: { disabled: rest.disabled },
    },
    InputContainer: {
      style: {
        maxWidth: '100%',
        width: 'min-content',
      },
    },
    Input: {
      component: AppStyledTextarea,
      props: { readOnly: props.readOnly },
    },
  };

  const mergedOverrides: InputOverrides = mergeOverrides({ ...inputOverrides }, { ...overrides });

  return <BaseUITextarea {...rest} overrides={mergedOverrides} />;
};
