import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { LabelMedium, ParagraphMedium } from 'baseui/typography';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'zustand';
import { useCaseRegistrationStoreContext } from '@@caseRegistration/CaseRegistrationStore';

const AgreementInformationComp = () => {
  const t = useTranslation('translation', { keyPrefix: 'agreementInformation' }).t;
  const [, theme] = useStyletron();

  const store = useCaseRegistrationStoreContext();
  const agreement = useStore(store, (s) => s.agreementInfo);

  return (
    <FlexGrid>
      <FlexGridItem>
        <LabelMedium>{t('importantAgreement')}</LabelMedium>
        <Block
          backgroundColor={theme.colors.backgroundSecondary}
          padding="scale200"
          marginTop="scale500"
          minHeight="64px"
        >
          {agreement?.important?.split('\n').map((paragraph, index) => {
            return <ParagraphMedium key={index}>{paragraph}</ParagraphMedium>;
          })}
        </Block>
      </FlexGridItem>
      <FlexGridItem>
        <LabelMedium paddingTop={'20px'}>{t('agreementDetails')}</LabelMedium>
        <Block
          backgroundColor={theme.colors.backgroundSecondary}
          padding="scale200"
          marginTop="scale500"
          minHeight="64px"
        >
          {agreement?.details?.split('\n').map((paragraph, index) => {
            return <ParagraphMedium key={index}>{paragraph}</ParagraphMedium>;
          })}
        </Block>
      </FlexGridItem>
    </FlexGrid>
  );
};

export const AgreementInformation = memo(AgreementInformationComp);
