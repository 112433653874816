import { Radio } from 'baseui/radio';
import { useSnackbar } from 'baseui/snackbar';
import { useFormikContext } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaLocationArrow } from 'react-icons/fa';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { ApiException } from '../../api/api.generated';
import { useSendGeoLinkSms } from '../../api/queries/useCommunicationClient';
import { FastEmailInputField as EmailInputField } from '../../shared/formFields/EmailInputField';
import { FastInputField as InputField } from '../../shared/formFields/InputField';
import { FastPhoneNumberField as PhoneNumberField } from '../../shared/formFields/PhoneNumberField';
import { FastRadioGroupField as RadioGroupField } from '../../shared/formFields/RadioGroupField';
import { isPhoneNumberValid } from '../../shared/functions/general';
import { ActorsEnum } from '../../shared/types';
import FormGroup from '../../shared/utilityComponents/FormGroup';
import PopoverButton from '../../shared/utilityComponents/PopoverButton';
import { nameof } from '../caseRegistrationFunctions';
import { CaseRegistrationFormFields } from '../caseRegistrationModels';
import { LocationForm } from './LocationForm';

function RequesterFormGroupComp() {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tRequester = useTranslation('translation', { keyPrefix: 'requesterForm' }).t;

  const formik = useFormikContext<CaseRegistrationFormFields>();

  const { visCaseId, caseId } = formik.values;

  const callerPhone = formik.values.callerPhone;
  const ownerPhone = formik.values.ownerPhone;

  const { enqueue } = useSnackbar();

  const sendSms = useSendGeoLinkSms();

  function getRequesterPhoneNumber() {
    if (formik.values.requesterIs === ActorsEnum.caller) return formik.values.callerPhone;
    if (formik.values.requesterIs === ActorsEnum.owner) return formik.values.ownerPhone;
    if (formik.values.requesterIs === ActorsEnum.requester) return formik.values.requesterPhone;
  }

  const requesterPhoneNumber = getRequesterPhoneNumber();

  return (
    <FormGroup label={tShared('requester')}>
      <RadioGroupField
        label={`${tRequester('indicateRequester')}*`}
        name={nameof('requesterIs')}
        deps={[callerPhone, ownerPhone]}
      >
        <Radio value={ActorsEnum.owner} disabled={!isPhoneNumberValid(ownerPhone)}>
          {tRequester('requesterIsOwner')}
        </Radio>
        <Radio value={ActorsEnum.caller} disabled={!isPhoneNumberValid(callerPhone)}>
          {tRequester('requesterIsCaller')}
        </Radio>
        <Radio
          value={ActorsEnum.requester}
          disabled={!(isPhoneNumberValid(callerPhone) || isPhoneNumberValid(ownerPhone))}
        >
          {tRequester('requesterIsOther')}
        </Radio>
      </RadioGroupField>

      {formik.values.requesterIs === ActorsEnum.requester ? (
        <>
          <InputField label={`${tShared('name')}*`} name={nameof('requesterName')} />
          <PhoneNumberField
            label={`${tShared('telephoneNumber')}*`}
            name={nameof('requesterPhone')}
            search
            mapping="requester"
          />
          <LocationForm mapping="requester" />
          <EmailInputField label={tShared('email')} name={nameof('requesterEmail')} />
        </>
      ) : formik.values.requesterIs === ActorsEnum.owner || formik.values.requesterIs === ActorsEnum.caller ? (
        <>
          <InputField
            readOnly
            label={tShared('name')}
            name={formik.values.requesterIs === ActorsEnum.owner ? nameof('ownerName') : nameof('callerName')}
          />
          <InputField
            readOnly
            label={tShared('telephoneNumber')}
            name={formik.values.requesterIs === ActorsEnum.owner ? nameof('ownerPhone') : nameof('callerPhone')}
          />
        </>
      ) : null}
      <PopoverButton
        kind="secondary"
        type="button"
        startEnhancer={() => <FaLocationArrow />}
        disabled={!visCaseId || !isPhoneNumberValid(requesterPhoneNumber)}
        isLoading={sendSms.isLoading}
        onClick={() => {
          if (visCaseId && caseId) {
            sendSms
              .mutateAsync({
                caseId,
                phoneNumber: requesterPhoneNumber,
                visCaseId: parseInt(visCaseId),
                messageText: '', // Isn't needed for GeoLinkSms, but is required in API, so we send empty string always.
              })
              .then(() => {
                enqueue({
                  message: tRequester('geoLinkSent'),
                  startEnhancer: () => <MdCheckCircle size={22} />,
                });
              })
              .catch((e: ApiException) => {
                enqueue({
                  message: `${tShared('error')}: ${e.response}`,
                  startEnhancer: () => <MdError size={22} />,
                });
              });
          }
        }}
        content={tRequester('sendGeolinkRequirements')}
      >
        {tRequester('sendgeolink')}
      </PopoverButton>
    </FormGroup>
  );
}

export const RequesterFormGroup = memo(RequesterFormGroupComp);
