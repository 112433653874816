import { isFinite, isNil, isObject, isPlainObject, isString, toString } from 'lodash-es';
import { Country } from '../types';

export function isNumeric(value: string) {
  return isFinite(parseFloat(value));
}

export function joinToString(arr: (string | undefined)[], separator = ', '): string {
  return arr.filter((i) => !!i).join(separator);
}

export const getDialCodeForCountry = (countryCode?: string): string => {
  return Country.Denmark === countryCode
    ? '+45'
    : Country.Sweden === countryCode
    ? '+46'
    : Country.Finland === countryCode
    ? '+358'
    : '+47';
};

export const isPhoneNumberValid = (phoneNumber?: string): boolean => {
  if (!phoneNumber || !toString(phoneNumber).trim()) return false;

  if (phoneNumber?.length < 4 || phoneNumber?.length > 20) return false;

  // Stars with 00 or +, followed by 2 or 3 digits.
  // First character after optional prefix is a digit.
  // The rest can be either digits, spaces or dashes
  const phoneNumberRegex = /^(?:(?:00|\+)\d{2,3})?\d{1}[\d\-\s]+$/;

  return !!phoneNumber.match(phoneNumberRegex);
};

export const tryGetCountryCodeFromPhoneNumber = (phoneNumber?: string): string | undefined => {
  if (!phoneNumber) return;

  const captureDialCodeRegex = /^(?:(00|\+)((\d{2})\d?))[\d\-\s]+$/;

  const match = phoneNumber.match(captureDialCodeRegex);

  if (!match) return;

  const threeDigitPrefix = match[2];
  const twoDigitPrefix = match[3];

  if (threeDigitPrefix === '358') return Country.Finland;

  switch (twoDigitPrefix) {
    case '45':
      return Country.Denmark;
    case '46':
      return Country.Sweden;
    case '47':
      return Country.Norway;
  }
};

export const isEmptyArray = (value: unknown): boolean => Array.isArray(value) && value.length === 0;

export const isEmptyObject = (obj: unknown): boolean => {
  return isRecordObject(obj) && Object.entries(obj).length === 0;
};

export const isRecordObject = (obj: unknown): obj is Record<string, unknown> => {
  return isObject(obj) && isPlainObject(obj);
};

export const isNilOrEmptyString = (value: unknown, ignoreWhitespace = true): boolean => {
  if (isNil(value)) return true;

  if (!isString(value)) return false;

  const valueAsString = toString(value);

  return ignoreWhitespace ? !valueAsString.trim().length : !valueAsString.length;
};

export const strip = (html: string | undefined): string => {
  if (!html) return '';
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export const getNonEmptyFields = (object: Record<string, string>) => {
  return Object.fromEntries(Object.entries(object).filter(([, v]) => v !== undefined && !/^\s*$/.test(v)));
};

export function toSelectOptions(item: string | undefined): { id: string; label: string }[];
export function toSelectOptions(items: string[] | undefined): { id: string; label: string }[];
export function toSelectOptions(items: string[] | string | undefined): { id: string; label: string }[] {
  if (items === undefined) return [];

  const asArray = Array.isArray(items) ? items : [items];

  return asArray.map((i) => ({ id: i, label: i }));
}

export function equalsIgnoreCase(input1: string, input2: string): boolean {
  return (input1 ?? '').localeCompare(input2 ?? '', undefined, { sensitivity: 'accent' }) === 0;
}
