import { Block } from 'baseui/block';
import { Button, SHAPE } from 'baseui/button';
import { SIZE } from 'baseui/input';
import { Cell, Grid } from 'baseui/layout-grid';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { Key, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUndo } from 'react-icons/fa';
import { MdPictureAsPdf } from 'react-icons/md';
import { useLayoutQuery } from '@@shared/hooks/useLayoutQuery';
import { expandPadding } from '@@styles/styleUtils';
import CaseHistory from '../caseHistory/CaseHistory';
import { MapContainer } from '../map/Map';
import { OverflowTabContent } from '../shared/basewebComponentOverrides/OverflowTabContent';
import { Banner } from '../shared/info/Banner';
import PopoverButton from '../shared/utilityComponents/PopoverButton';
import { CommunicationComponent } from './caseRegTools/CommunicationComponent';
import { ServiceComponent } from './caseRegTools/ServiceComponent';
import { tabOverrides } from './styles';
import { AgreementInformation } from './utilityComponents/AgreementInformation';
import { CaseSummary } from './utilityComponents/CaseSummary';

interface Props {
  caseId: string | undefined;
  earlierCasesCount: number | undefined;
  resumeCaseLoading: boolean;
  resumeCaseDisabled: boolean;
  onResumeCase: () => void;
  onShowHistoryModal: () => void;
  onShowLogEntriesModal: () => void;
}

const CaseRegistrationToolsComp = (props: Props) => {
  const {
    caseId,
    resumeCaseLoading,
    resumeCaseDisabled,
    earlierCasesCount,
    onResumeCase,
    onShowHistoryModal,
    onShowLogEntriesModal,
  } = props;

  const tCaseReg = useTranslation('translation', { keyPrefix: 'caseRegistration' }).t;
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;

  const [activeRightKey, setActiveRightKey] = useState<Key>(0);

  const isBigLayout = useLayoutQuery();

  return (
    <Block
      display="flex"
      flexDirection="column"
      {...expandPadding(isBigLayout ? ['scale600', '0', 'scale600', 'scale600'] : '0px')}
      height={'100%'}
    >
      <Block flex="0">
        <CaseSummary />
      </Block>
      {!!earlierCasesCount && earlierCasesCount > 0 && (
        <Block flex={'0'} marginTop="14px">
          <Banner
            kind="warning"
            text={`${earlierCasesCount} ${tCaseReg('earlierCasesRegistered')}`}
            button={
              <Button
                onClick={() => onShowHistoryModal()}
                size={SIZE.mini}
                shape={SHAPE.pill}
                kind="secondary"
                type="button"
              >
                {tCaseReg('showEarlierCases')}
              </Button>
            }
          />
        </Block>
      )}
      <Block flex="1">
        <Tabs
          activeKey={activeRightKey}
          onChange={({ activeKey }) => setActiveRightKey(activeKey)}
          activateOnFocus
          renderAll
          overrides={{
            Root: { style: { display: 'flex', flexDirection: 'column', width: '100%', height: '100%' } },
          }}
        >
          <Tab title={tCaseReg('agreementInfo')} overrides={tabOverrides}>
            <OverflowTabContent>
              <AgreementInformation />
            </OverflowTabContent>
          </Tab>
          <Tab title={tCaseReg('map')} overrides={tabOverrides}>
            <OverflowTabContent>
              <MapContainer />
            </OverflowTabContent>
          </Tab>
          <Tab title={tCaseReg('caseHistory')} overrides={tabOverrides}>
            <OverflowTabContent>
              <CaseHistory id={caseId} />
            </OverflowTabContent>
          </Tab>
          <Tab title={tCaseReg('communication')} overrides={tabOverrides}>
            <OverflowTabContent>
              <CommunicationComponent caseId={caseId ?? ''} />
            </OverflowTabContent>
          </Tab>
          <Tab title={tCaseReg('services')} overrides={tabOverrides}>
            <OverflowTabContent>
              <ServiceComponent />
            </OverflowTabContent>
          </Tab>
        </Tabs>
      </Block>
      <Block flex="0">
        <Grid gridMargins={[0]} overrides={{ Grid: { style: { display: activeRightKey !== '2' ? 'none' : 'flex' } } }}>
          <Cell span={8}></Cell>
          <Cell span={4}>
            <Block justifyContent="flex-end" display="flex">
              <Button
                type="button"
                onClick={() => onShowLogEntriesModal()}
                startEnhancer={() => <MdPictureAsPdf />}
                kind="secondary"
              >
                {tShared('fullscreen')}
              </Button>
              <PopoverButton
                kind="secondary"
                type="button"
                content={tCaseReg('resumePopover')}
                isLoading={resumeCaseLoading}
                disabled={resumeCaseDisabled}
                onClick={() => onResumeCase()}
                startEnhancer={() => <FaUndo />}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      marginLeft: $theme.sizing.scale200,
                    }),
                  },
                }}
              >
                {tCaseReg('resume')}
              </PopoverButton>
            </Block>
          </Cell>
        </Grid>
      </Block>
    </Block>
  );
};

export const CaseRegistrationTools = memo(CaseRegistrationToolsComp);
