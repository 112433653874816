import { DatepickerProps } from 'baseui/datepicker';
import { min } from 'date-fns/esm';
import { isNil } from 'lodash-es';
import { FC } from 'react';
import { useZonedDateUtils } from '../hooks/useZonedDateUtils';
import DatePickerNoMask from './DatePickerNoMask';

export interface DatePickerExtraProps extends DatepickerProps {
  useZonedTime?: boolean;
}

const DatePickerExtra: FC<DatePickerExtraProps> = (props: DatePickerExtraProps) => {
  const { value, onChange, maxDate, useZonedTime, ...restOfProps } = props;

  const { convertUtcToZonedTime, convertZonedTimeToUtc } = useZonedDateUtils();

  let convertedValue = value;
  let convertedMaxDate = maxDate;

  if (useZonedTime) {
    if (!isNil(convertedValue)) {
      if (Array.isArray(convertedValue)) {
        convertedValue = convertedValue.map((d) => (!isNil(d) ? convertUtcToZonedTime(d) : undefined));
      } else {
        convertedValue = convertUtcToZonedTime(convertedValue);
      }
    }

    if (!isNil(convertedMaxDate)) {
      convertedMaxDate = convertUtcToZonedTime(convertedMaxDate);
    }
  }

  const handleChange = (args: { date: Date | undefined | null | Array<Date | undefined | null> }) => {
    if (!onChange) return; // No point in converting if there's no handler prop

    if (!useZonedTime) {
      onChange(args);
    }

    let date = args.date;
    let convertedDate = date;

    if (!isNil(date)) {
      if (Array.isArray(date)) {
        const lastDateItem = date[date.length - 1];
        if (!isNil(lastDateItem) && !isNil(convertedMaxDate)) {
          date[date.length - 1] = min([lastDateItem, convertedMaxDate]);
        }

        convertedDate = date.map((d) => (!isNil(d) ? convertZonedTimeToUtc(d) : undefined));
      } else if (!isNil(date)) {
        if (!isNil(convertedMaxDate)) {
          date = min([date, convertedMaxDate]);
        }

        convertedDate = convertZonedTimeToUtc(date);
      }
    }

    onChange({ date: convertedDate });
  };

  return (
    <DatePickerNoMask value={convertedValue} onChange={handleChange} maxDate={convertedMaxDate} {...restOfProps} />
  );
};

export default DatePickerExtra;
