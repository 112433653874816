import { KIND, SIZE } from 'baseui/button';
import { LabelSmall } from 'baseui/typography';
import { FormEvent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineInfo } from 'react-icons/md';
import { styled } from 'styletron-react';
import { useStore } from 'zustand';
import { useCaseRegistrationStoreContext } from '@@caseRegistration/CaseRegistrationStore';
import { ActorDetailsDrawer } from '@@core/drawers/ActorDetailsDrawer';
import { useNearbyRentalCarProvidersQuery, useNearbyRetailersQuery } from '../api/queries/useRetailerQueries';
//import { useCaseRegistrationContext } from '../caseRegistration/CaseRegistrationContext';
import { CaseRegistrationFormFields } from '../caseRegistration/caseRegistrationModels';
import { Button } from '../shared/basewebComponentOverrides/Button';
import { isNumeric } from '../shared/functions/general';
import { TransportToType } from '../shared/types';
import { InnerMap } from './InnerMap';
import { MapInfoWindow } from './MapInfoWindow';
import { Marker, MarkerInfoWindowData } from './MapMarker';
import { mapToMarker } from './helperFunctions';
import { LatLng, LatLngLiteral } from './types';

export interface LatLngCoords {
  lat: number;
  lng: number;
}

export enum IconTypes {
  INCIDENT = 'INCIDENT',
  CHARGINGSTATION = 'CHARGINGSTATION',
  WORKSHOP = 'WORKSHOP',
  CAR_RENTAL = 'CAR_RENTAL',
  WORKSHOP_ACTIVE = 'WORKSHOP_ACTIVE',
}

export interface IMarker extends LatLngCoords {
  type: IconTypes;
  visible: boolean;
  data?: MarkerInfoWindowData;
}

export const DEFAULT_MAP_ZOOM = 15;
export const DEFAULT_MAP_ZOOM_NO_HITS = 5;
export const DEFAULT_MAP_COORDS: LatLngCoords = {
  lat: 63,
  lng: 16,
};

const MapContainerComp = () => {
  const store = useCaseRegistrationStoreContext();

  const incidentLat = useStore(store, (s) => s.incidentAddressLatitude);
  const incidentLng = useStore(store, (s) => s.incidentAddressLongitude);
  const workshopAddressStreet = useStore(store, (s) => s.workshopAddressStreet);
  const selectedWorkshopId = useStore(store, (s) => s.workshop?.id);

  const setValues = useStore(store, (s) => s.formikHelpers?.setValues);

  const { t } = useTranslation('translation', { keyPrefix: 'map' });

  let lat = DEFAULT_MAP_COORDS.lat;
  if (incidentLat && isNumeric(incidentLat)) {
    lat = parseFloat(incidentLat);
  }

  let lng = DEFAULT_MAP_COORDS.lng;
  if (incidentLng && isNumeric(incidentLng)) {
    lng = parseFloat(incidentLng);
  }

  const doesIncidentHaveCoords = !(lat === DEFAULT_MAP_COORDS.lat && lng === DEFAULT_MAP_COORDS.lng);

  const [incident, setIncident] = useState<IMarker[]>([]);
  const [carRentals, setCarRentals] = useState<IMarker[]>([]);
  const [workshops, setWorkshops] = useState<IMarker[]>([]);

  const [mapFilters, setMapFilters] = useState<IconTypes[]>([]);
  const [filteredIncident, setFilteredIncident] = useState<IMarker[]>([]);
  const [filteredCarRentals, setFilteredCarRentals] = useState<IMarker[]>([]);
  const [filteredWorkshops, setFilteredWorkshops] = useState<IMarker[]>([]);

  const [showRentalDetailsForId, setShowRentalDetailsForId] = useState<string>();

  const [center, setCenter] = useState<LatLngCoords>({ lat, lng });
  const [zoom, setZoom] = useState(!doesIncidentHaveCoords ? DEFAULT_MAP_ZOOM_NO_HITS : DEFAULT_MAP_ZOOM);
  const [carRentalFilter, setCarRentalFilter] = useState<string | undefined>(undefined);

  const retailers = useNearbyRetailersQuery(center.lat.toString(), center.lng.toString());
  const rentalCars = useNearbyRentalCarProvidersQuery(center.lat.toString(), center.lng.toString());

  useEffect(() => {
    setCenter({ lat, lng });
    if (!doesIncidentHaveCoords) {
      // Incident doesn't have real coords, reset everything
      setIncident([]);
      setZoom(DEFAULT_MAP_ZOOM_NO_HITS);
      setCenter({ lat, lng });
      return;
    }

    setIncident([
      {
        lat: lat,
        lng: lng,
        type: IconTypes.INCIDENT,
        visible: true,
      },
    ]);
    if (mapFilters.length === 0) {
      setMapFilters(carRentalFilter ? [IconTypes.INCIDENT, IconTypes.CAR_RENTAL] : [IconTypes.INCIDENT]);
    }
  }, [lat, lng, carRentalFilter, workshopAddressStreet, doesIncidentHaveCoords, mapFilters.length]);

  useEffect(() => {
    if (retailers.isSuccess && retailers.data) {
      const workshopType = mapToMarker(retailers.data, IconTypes.WORKSHOP).map((m: IMarker) => ({
        ...m,
        type: selectedWorkshopId === m.data?.id ? IconTypes.WORKSHOP_ACTIVE : IconTypes.WORKSHOP,
      }));
      setWorkshops(workshopType);
    }

    if (rentalCars.isSuccess && rentalCars.data) {
      if (!carRentalFilter) {
        setCarRentals(mapToMarker(rentalCars.data, IconTypes.CAR_RENTAL));
      } else {
        setCarRentals(
          mapToMarker(rentalCars.data, IconTypes.CAR_RENTAL)
            .filter((m) => m.data?.roleId === carRentalFilter)
            .map((m) => ({
              ...m,
              visible: m.data?.roleId === carRentalFilter,
            }))
        );
      }
    }
  }, [retailers.isSuccess, retailers.data, selectedWorkshopId, rentalCars.isSuccess, rentalCars.data, carRentalFilter]);

  const updateCaseWorkshop = (markerData: Omit<IMarker, 'type' | 'visible'>) => {
    const updatedState: Partial<CaseRegistrationFormFields> = {
      workshopAddressLongitude: markerData.lng.toString(),
      workshopAddressLatitude: markerData.lat.toString(),
      workshop: { id: markerData.data?.id, name: markerData.data?.title },
      workshopAddressCity: markerData.data?.postArea ?? '',
      workshopAddressCountry: markerData.data?.country ?? '',
      workshopAddressPostalCode: markerData.data?.postCode ?? '',
      workshopAddressStreet: markerData.data?.address ?? '',
      transportTo: TransportToType.workshop,
    };

    setValues && setValues((v) => ({ ...v, ...updatedState }));
  };

  const toggleMapFilter = (types: IconTypes[], event: FormEvent<HTMLInputElement>) => {
    const newFilters: IconTypes[] = [...mapFilters];

    types.forEach((t) => {
      const exists = newFilters.indexOf(t) !== -1;
      if (event.currentTarget.checked) {
        if (!exists) {
          newFilters.push(t);
        }
      } else {
        if (exists) {
          newFilters.splice(newFilters.indexOf(t), 1);
        }
      }
    });

    setMapFilters(newFilters);
  };

  const updateMarkerVisibility = (mapFilters: IconTypes[], markers: IMarker[]) => {
    return markers.map((m) => {
      if (m.type) {
        if (m.type === IconTypes.WORKSHOP_ACTIVE) {
          return {
            ...m,
            visible: mapFilters.includes(IconTypes.WORKSHOP),
          };
        } else {
          return {
            ...m,
            visible: mapFilters.includes(m.type),
          };
        }
      } else {
        return m;
      }
    });
  };

  const onIdle = (m: google.maps.Map) => {
    if (m) {
      setZoom(m.getZoom() ?? DEFAULT_MAP_ZOOM);
    }
  };

  useEffect(() => {
    setFilteredIncident(updateMarkerVisibility(mapFilters, incident));
    setFilteredWorkshops(updateMarkerVisibility(mapFilters, workshops));
    setFilteredCarRentals(updateMarkerVisibility(mapFilters, carRentals));
  }, [mapFilters, workshops, carRentals, incident]);

  const InfoBit = styled('p', {
    margin: '0',
    ':last-of-type': {
      marginBottom: '10px',
    },
  });

  return (
    <>
      <InnerMap
        zoom={zoom}
        setZoom={setZoom}
        center={center}
        onIdle={onIdle}
        toggleMapFilter={toggleMapFilter}
        mapFilters={mapFilters}
        incident={filteredIncident}
        carRentals={filteredCarRentals}
        workshops={filteredWorkshops}
        setCarRentalFilter={setCarRentalFilter}
        incidentLat={lat}
        incidentLng={lng}
      >
        {filteredIncident
          .concat(filteredWorkshops)
          .concat(filteredCarRentals)
          .map((marker, i) => {
            const data = marker.data;

            if (marker.lat && marker.lng && marker.lat !== 0 && marker.lng !== 0) {
              return (
                <Marker
                  key={`mapMarker-${i}`}
                  position={marker}
                  type={marker.type}
                  visible={marker.visible}
                  data={data}
                >
                  {data && (
                    <MapInfoWindow>
                      <>
                        {data.title && <LabelSmall>{data.title}</LabelSmall>}
                        {data.id && (
                          <InfoBit>
                            {t('actorNr')}: {data.id}
                          </InfoBit>
                        )}
                        {data.address && <InfoBit>{data.address}</InfoBit>}
                        {(data.postCode || data.postArea || data.country) && (
                          <InfoBit>{combineFields([data.postCode, data.postArea, data.country])}</InfoBit>
                        )}
                        {data.phoneNumber && <InfoBit>{data.phoneNumber}</InfoBit>}
                        {data.type === IconTypes.WORKSHOP && (
                          <Button
                            type="button"
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            onClick={() => {
                              updateCaseWorkshop({
                                data,
                                lat: getPos(marker, 'lat'),
                                lng: getPos(marker, 'lng'),
                              });
                            }}
                          >
                            {t('choose')}
                          </Button>
                        )}
                        {data.type === IconTypes.CAR_RENTAL && (
                          <Button
                            type="button"
                            kind={KIND.secondary}
                            onClick={() => setShowRentalDetailsForId(data.id)}
                          >
                            <MdOutlineInfo />
                          </Button>
                        )}
                      </>
                    </MapInfoWindow>
                  )}
                </Marker>
              );
            }
          })}
      </InnerMap>
      <ActorDetailsDrawer
        actorId={showRentalDetailsForId}
        isOpen={!!showRentalDetailsForId}
        setIsOpen={(isOpen) => !isOpen && setShowRentalDetailsForId(undefined)}
      />
    </>
  );
};

const combineFields = (fields: (string | undefined)[]): string => {
  return fields.join(' ');
};

const getPos = (pos: LatLng | null | LatLngLiteral | undefined, data: keyof LatLngLiteral): number => {
  if (pos) {
    return typeof pos.lng === 'function' ? (pos as LatLng)[data]() : (pos as LatLngLiteral)[data];
  } else {
    return 0;
  }
};

export const MapContainer = memo(MapContainerComp);
