import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOriginCodesQuery } from '../../api/queries/useCaseMasterDataQueries';
import { EviModal } from '../../evi/EviModal';
import { FastSelectField as SelectField } from '../../shared/formFields/SelectField';
import { FastTimestampField as TimestampField } from '../../shared/formFields/TimestampField';
import FormGroup from '../../shared/utilityComponents/FormGroup';
import { RegNrField } from '../caseFormFields/RegNrField';
import { nameof } from '../caseRegistrationFunctions';

function StartInfoFormGroupComp() {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tInfo = useTranslation('translation', { keyPrefix: 'infoForm' }).t;
  const tIncident = useTranslation('translation', { keyPrefix: 'incidentReasonForm' }).t;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data: originCodes = [] } = useOriginCodesQuery();

  return (
    <FormGroup label={tInfo('startInfo')}>
      <TimestampField label={`${tIncident('incidentTime')}*`} name={nameof('incidentTime')} noFuture />
      <SelectField label={`${tShared('source')}*`} options={originCodes} name={nameof('caseOriginCode')} />

      <RegNrField
        name={nameof('vehicleRegistrationNumber')}
        label={`${tShared('regNr')}*`}
        onClick={() => {
          setIsOpen(true);
        }}
      />
      <EviModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </FormGroup>
  );
}

export const StartInfoFormGroup = memo(StartInfoFormGroupComp);
