import { memo, useEffect, useState } from 'react';
import { useStore } from 'zustand';
import { SpinnerOverlay } from '@@shared/basewebComponentOverrides/Spinner';
import { useGetCaseQuery } from '../api/queries/useCaseQueries';
import { useUser } from '../shared/hooks/useUser';
import { CaseRegistration } from './CaseRegistration';
import { useCaseRegistrationStoreContext } from './CaseRegistrationStore';
import { createInitialValues } from './caseRegistrationFunctions';
import { CaseRegistrationFormFields } from './caseRegistrationModels';

interface Props {
  visCaseId: string | undefined;
}

const CaseRegistrationDataComp = (props: Props) => {
  const { visCaseId } = props;

  const { callCenterCountry } = useUser();

  const store = useCaseRegistrationStoreContext();
  const formikState = useStore(store, (s) => s.formikState);

  const queryResult = useGetCaseQuery(visCaseId ? Number(visCaseId) : undefined);

  const { data: caseDto, isSuccess } = queryResult;

  const [initialValues, setInitialValues] = useState<CaseRegistrationFormFields | undefined>(() => {
    // Initiate values from empty object when creating new case
    if (visCaseId) return undefined;

    return createInitialValues({}, callCenterCountry);
  });

  // Initiate values with fetch case result when updating existing case
  useEffect(() => {
    if (isSuccess && caseDto) {
      setInitialValues(createInitialValues(caseDto, callCenterCountry));
    }
  }, [visCaseId, caseDto, isSuccess, callCenterCountry]);

  // Restore formikState after formikForm dismount
  useEffect(() => {
    if (formikState?.values === undefined) return;

    setInitialValues(formikState?.values);
  }, [visCaseId, formikState]);

  if (!initialValues) return <SpinnerOverlay />;

  return <CaseRegistration queryResult={queryResult} initialValues={initialValues} />;
};

export const CaseRegistrationData = memo(CaseRegistrationDataComp);
