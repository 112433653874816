import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { useFormikContext } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FastDateField as DateField } from '../../shared/formFields/DateField';
import { FastInputField as InputField } from '../../shared/formFields/InputField';
import FormGroup from '../../shared/utilityComponents/FormGroup';
import { isFieldRequired, nameof } from '../caseRegistrationFunctions';
import { CaseRegistrationFormFields } from '../caseRegistrationModels';

function ServiceInformationFormGroupComp() {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tService = useTranslation('translation', { keyPrefix: 'serviceInfoForm' }).t;

  const formik = useFormikContext<CaseRegistrationFormFields>();
  const requiredFieldsAgreement = formik.values.agreementInfo?.caseFields;

  return (
    <FormGroup label={tService('serviceInformation')}>
      <InputField
        label={
          isFieldRequired('vehicleLastServiceLocationName', requiredFieldsAgreement)
            ? `${tService('latestServiceWorkshop')}*`
            : tService('latestServiceWorkshop')
        }
        name={nameof('vehicleLastServiceLocationName')}
        //Readonly until we know if this is workshop field or freeText
        readOnly
      />
      <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale800" flexGridRowGap="scale800" marginBottom="scale800">
        <FlexGridItem>
          <DateField label={tService('dateLatestService')} name={nameof('vehicleLastServiceDate')} noFuture />
        </FlexGridItem>
        <FlexGridItem>
          <DateField
            label={
              isFieldRequired('vehicleServiceWarrantyValidTo', requiredFieldsAgreement)
                ? `${tShared('warrantyExpirationDate')}*`
                : tShared('warrantyExpirationDate')
            }
            name={nameof('vehicleServiceWarrantyValidTo')}
          />
        </FlexGridItem>
      </FlexGrid>

      <InputField label={tService('warrantyMaxKM')} name={nameof('vehicleServiceWarrantyMaximumTotalKm')} />
      <InputField
        label={
          isFieldRequired('vehicleCurrentKmSum', requiredFieldsAgreement)
            ? `${tService('currentKM')}*`
            : tService('currentKM')
        }
        name={nameof('vehicleCurrentKmSum')}
      />
    </FormGroup>
  );
}
export const ServiceInformationFormGroup = memo(ServiceInformationFormGroupComp);
