import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Input } from 'baseui/input';
import { Modal, ModalBody, ROLE } from 'baseui/modal';
import { HeadingMedium, LabelSmall, ParagraphMedium } from 'baseui/typography';
import { useField, useFormikContext } from 'formik';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdSearch } from 'react-icons/md';
import { ApiException } from '../api/api.generated';
import { PhoneLookUpInput, usePhoneLookUpCompany, usePhoneLookUpPerson } from '../api/queries/usePhoneLookUpQueries';
import { CaseRegistrationFormFields } from '../caseRegistration/caseRegistrationModels';
import { Button } from '../shared/basewebComponentOverrides/Button';
import {
  getDialCodeForCountry,
  isPhoneNumberValid,
  tryGetCountryCodeFromPhoneNumber,
} from '../shared/functions/general';
import { useUser } from '../shared/hooks/useUser';

interface NumberLookupProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  mapping: string;
}

interface NumberSearchResult {
  name?: string;
  number?: string;
  address?: string;
  postalCode?: string;
  postalArea?: string;
  countryCode?: string;
  countryName?: string;
}

function NumberLookupComp({ isOpen, setIsOpen, mapping }: NumberLookupProps) {
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tNumber = useTranslation('translation', { keyPrefix: 'numberLookup' }).t;
  const { callCenterCountry } = useUser();
  const phoneNumberPrefix = getDialCodeForCountry(callCenterCountry);
  const formik = useFormikContext<CaseRegistrationFormFields>();

  //Helpers for updating owner info
  const [, , nameHelper] = useField(mapping + 'Name');
  const [, , addressHelper] = useField(mapping + 'AddressStreet');
  const [, , cityHelper] = useField(mapping + 'AddressCity');
  const [, , postalCodeHelper] = useField(mapping + 'AddressPostalCode');
  const [, , countryHelper] = useField(mapping + 'AddressCountry');
  const [, , phoneHelper] = useField(mapping + 'Phone');

  const [searchPhoneNumberInput, setSearchPhoneNumberInput] = useState(
    mapping === 'caller'
      ? formik.values.callerPhone
      : mapping === 'requester'
      ? formik.values.requesterPhone
      : phoneNumberPrefix
  );

  useEffect(() => {
    setSearchPhoneNumberInput(
      mapping === 'caller'
        ? formik.values.callerPhone
        : mapping === 'requester'
        ? formik.values.requesterPhone
        : phoneNumberPrefix
    );
  }, [formik.values.callerPhone, formik.values.requesterPhone, mapping, phoneNumberPrefix]);

  const [result, setResult] = useState<NumberSearchResult>();
  const [noResult, setNoResult] = useState(false);

  const [showInvalidCountryCode, setShowInvalidCountryCode] = useState(false);

  const searchPerson = usePhoneLookUpPerson();
  const searchCompany = usePhoneLookUpCompany();

  const mutateSearchPerson = searchPerson.mutate;
  const mutateSearchCompany = searchCompany.mutate;

  const searchPhoneNumber = useCallback(
    (company?: boolean) => {
      //Split down phone number and send country code

      let countryCode: string | undefined;

      if (isPhoneNumberValid(searchPhoneNumberInput)) {
        countryCode = tryGetCountryCodeFromPhoneNumber(searchPhoneNumberInput);
      }

      if (!countryCode) {
        setNoResult(false);
        setResult(undefined);
        setShowInvalidCountryCode(true);
        return;
      }

      const input: PhoneLookUpInput = {
        countryCode: countryCode,
        phoneNumber: searchPhoneNumberInput,
        callCenterCode: callCenterCountry,
      };

      if (!company) {
        mutateSearchPerson(input);
      } else {
        mutateSearchCompany(input);
      }
    },
    [callCenterCountry, searchPhoneNumberInput, mutateSearchPerson, mutateSearchCompany]
  );

  useEffect(() => {
    if (searchPerson.isSuccess && searchPerson.data.persons && searchPerson?.data?.persons?.length > 0) {
      const name = `${searchPerson.data.persons[0].name?.firstName ?? ''} ${
        searchPerson.data.persons[0].name?.middleName ?? ''
      } ${searchPerson.data.persons[0].name?.lastName ?? ''}`;
      let address;
      if (searchPerson.data.persons[0].addresses && searchPerson.data.persons[0].addresses.length > 0) {
        address = searchPerson.data.persons[0].addresses[0];
      }
      setResult({
        name: name,
        number: searchPhoneNumberInput,
        address: `${address?.streetName ?? ''} ${address?.streetNumber ?? ''}`,
        postalArea: address?.postalArea,
        postalCode: address?.postalCode,
        countryCode: address?.countryCode,
        countryName: address?.countryName,
      });
    } else if (searchPerson.isSuccess) {
      searchPhoneNumber(true);
    } else if (searchPerson.isError) {
      if ((searchPerson.error as ApiException).status === 404) {
        searchPhoneNumber(true);
      }
    }
  }, [
    searchPerson.isSuccess,
    searchPerson.isError,
    searchPerson.error,
    searchPerson.data,
    searchPhoneNumberInput,
    searchPhoneNumber,
  ]);

  useEffect(() => {
    if (searchCompany.isSuccess && searchCompany.data.companies && searchCompany.data.companies.length > 0) {
      let address;
      if (searchCompany.data.companies[0].addresses && searchCompany.data.companies[0].addresses.length > 0) {
        address = searchCompany.data.companies[0].addresses[0];
      }
      setResult({
        name: searchCompany.data.companies[0].name,
        number: searchPhoneNumberInput,
        address: `${address?.streetName ?? ''} ${address?.streetNumber ?? ''}`,
        postalArea: address?.postalArea,
        postalCode: address?.postalCode,
        countryCode: address?.countryCode,
        countryName: address?.countryName,
      });
    } else if (searchCompany.isSuccess) {
      setResult(undefined);
      setNoResult(true);
    } else if (searchCompany.isError) {
      if ((searchCompany.error as ApiException).status === 404) {
        setResult(undefined);
        setNoResult(true);
      }
    }
  }, [searchCompany.isSuccess, searchCompany.isError, searchCompany.error, searchCompany.data, searchPhoneNumberInput]);

  function confirm() {
    nameHelper.setValue(result?.name);
    phoneHelper.setValue(result?.number);
    addressHelper.setValue(result?.address);
    postalCodeHelper.setValue(result?.postalCode);
    cityHelper.setValue(result?.postalArea);
    countryHelper.setValue(result?.countryCode);
    clearValues();
  }

  function clearValues() {
    searchPerson.reset();
    searchCompany.reset();
    setResult(undefined);
    setSearchPhoneNumberInput(phoneNumberPrefix);
    setNoResult(false);
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => clearValues()}
      animate
      autoFocus
      overrides={{
        Dialog: {
          style: {
            width: '35vw',
          },
        },
      }}
      role={ROLE.dialog}
    >
      <ModalBody>
        <HeadingMedium marginBottom="16px">{tNumber('eniroSearch')}</HeadingMedium>
        <FlexGrid flex={1} display="flex">
          <FlexGridItem paddingBottom="23px">
            <LabelSmall>{`${tShared('telephoneNumber')}*`}</LabelSmall>
            <FlexGrid flexGridColumnCount={2} paddingTop="8px" gridColumnGap="8px">
              <FlexGridItem flex={4}>
                <Input
                  value={searchPhoneNumberInput}
                  onChange={(event) => setSearchPhoneNumberInput(event.currentTarget.value)}
                />
              </FlexGridItem>

              <FlexGridItem>
                <Button
                  disabled={searchPhoneNumberInput !== undefined && searchPhoneNumberInput.length < 4}
                  type="button"
                  onClick={() => searchPhoneNumber()}
                  startEnhancer={() => <MdSearch size={20} style={{ marginLeft: '8px' }} />}
                />
              </FlexGridItem>
            </FlexGrid>
          </FlexGridItem>
          {result ? (
            <FlexGridItem>
              <LabelSmall>{`${tNumber('searchResult')}*`}</LabelSmall>
              <ParagraphMedium marginTop="8px">
                {result.name}
                <br />
                {result.number}
                <br />
                {result.address}
                <br />
                {`${result.postalCode} ${result.postalArea}`}
                <br />
                {result.countryName}
              </ParagraphMedium>
            </FlexGridItem>
          ) : noResult ? (
            <FlexGridItem>
              <ParagraphMedium>{tNumber('noResult')}</ParagraphMedium>
            </FlexGridItem>
          ) : showInvalidCountryCode ? (
            <FlexGridItem>
              <ParagraphMedium>{tNumber('errorDialCode')}</ParagraphMedium>
            </FlexGridItem>
          ) : null}

          <FlexGridItem flex={1}>
            <FlexGrid flexGridColumnCount={2}>
              <FlexGridItem flex={1}>
                <Button
                  overrides={{
                    BaseButton: {
                      style: {
                        width: '95%',
                      },
                    },
                  }}
                  onClick={() => clearValues()}
                  type="button"
                  kind="secondary"
                >
                  {tShared('cancel')}
                </Button>
              </FlexGridItem>
              <FlexGridItem flex={1}>
                <Button
                  overrides={{
                    BaseButton: {
                      style: {
                        width: '95%',
                      },
                    },
                  }}
                  type="button"
                  disabled={!result}
                  onClick={() => confirm()}
                >
                  {tNumber('useInfo')}
                </Button>
              </FlexGridItem>
            </FlexGrid>
          </FlexGridItem>
        </FlexGrid>
      </ModalBody>
    </Modal>
  );
}

export const NumberLookup = memo(NumberLookupComp);
