import { FormControl } from 'baseui/form-control';
import { OnChangeParams, Option, Select, SelectProps, Value } from 'baseui/select';
import { FastField, Field, FieldProps, FieldValidator } from 'formik';
import { ChangeEvent, useState } from 'react';
import { MiniSpinner } from '../basewebComponentOverrides/Spinner';
import { isNilOrEmptyString } from '../functions/general';
import { FakeFormikEvent } from '../types';
import { customShouldComponentUpdate } from './fastFormFieldFunctions';

type SafeSelectProps = Omit<SelectProps, 'value'>;

interface CommonSelectFieldProps {
  label: string;
  bindToObject?: boolean;
  newItemValue?: unknown;
}

interface SelectFormControlProps extends CommonSelectFieldProps, SafeSelectProps {
  fieldProps: FieldProps;
}

export interface SelectFieldProps extends CommonSelectFieldProps, SafeSelectProps {
  name: string;
  validate?: FieldValidator;
  disabled?: boolean;
}

export interface SelectFastFieldProps extends SelectFieldProps {
  deps?: unknown[];
}

function SelectFormControl(props: SelectFormControlProps) {
  const {
    fieldProps,
    label,
    bindToObject,
    valueKey,
    labelKey,
    placeholder,
    onClose,
    onChange,
    searchable,
    newItemValue,
    ...restOfSelectProps
  } = props;

  const { disabled, options, creatable } = restOfSelectProps;

  const { field, meta } = fieldProps;

  const [inputValue, setInputValue] = useState('');

  const safeValueKey = valueKey || DEFAULT_VALUE_KEY;
  const safeLabelKey = labelKey || DEFAULT_LABEL_KEY;
  const safeSearchable = searchable ?? DEFAULT_SEARCHABLE;
  const safeNewItemValue = newItemValue ?? DEFAULT_NEW_ITEM_VALUE;
  const safePlaceholder = placeholder ?? DEFAULT_PLACEHOLDER;

  const bindValue: Value = !isNilOrEmptyString(field.value)
    ? bindToObject
      ? [{ ...field.value, [safeLabelKey]: (field.value[safeLabelKey] ?? '').toString() }]
      : [{ [safeValueKey]: field.value, [safeLabelKey]: (field.value ?? '').toString() }]
    : [];

  function selectOrCreateItem() {
    const inputValueIsEmpty = !inputValue?.trim();

    const inputValueIsSelectedItem = inputValueIsEmpty
      ? false
      : inputValue?.toLowerCase() === (bindValue[0]?.[safeLabelKey] as string)?.toLowerCase();

    const inputValueAsOptionsItem = inputValueIsEmpty
      ? undefined
      : (options as Value).find((v) => (v[safeLabelKey] as string)?.toLowerCase().includes(inputValue?.toLowerCase()));

    if (!inputValueIsEmpty && !inputValueIsSelectedItem) {
      if (inputValueAsOptionsItem) {
        const selectedValue = buildFieldValueFromOption(inputValueAsOptionsItem);

        field.onChange(new FakeFormikEvent(field.name, selectedValue));
      } else if (creatable) {
        const newValue = buildFieldValueFromString(inputValue);

        field.onChange(new FakeFormikEvent(field.name, newValue));
      }
    }
  }

  function buildFieldValueFromString(value: string): Option | unknown {
    return bindToObject ? { [safeValueKey]: safeNewItemValue, [safeLabelKey]: value } : value;
  }

  function buildFieldValueFromOption(option: Option | undefined | null): Option | unknown {
    return bindToObject ? option : option?.[safeValueKey] ?? '';
  }

  const showError = meta.touched && !!meta.error;

  const labelString = label ? `${label}${props.required ? ' *' : ''}` : undefined;

  return (
    <FormControl label={labelString} disabled={disabled} error={showError && meta.error}>
      <Select
        onChange={(params: OnChangeParams) => {
          const selectedValue = buildFieldValueFromOption(params.option);

          field.onChange(new FakeFormikEvent(field.name, selectedValue));

          if (onChange) onChange(params);
        }}
        onClose={() => {
          if (safeSearchable) selectOrCreateItem();

          if (onClose) onClose();
        }}
        onInputChange={(e: ChangeEvent<HTMLInputElement>) => setInputValue(e.currentTarget.value)}
        value={bindValue}
        valueKey={safeValueKey}
        labelKey={safeLabelKey}
        searchable={safeSearchable}
        placeholder={safePlaceholder}
        maxDropdownHeight="20em"
        overrides={{ LoadingIndicator: MiniSpinner }}
        {...restOfSelectProps}
      />
    </FormControl>
  );
}

export function FastSelectField(props: SelectFastFieldProps) {
  const { name, validate, deps, disabled, ...restOfProps } = props;

  const fieldDeps = [disabled, props.disabled, props.required, props.options, props.isLoading, ...(deps ?? [])];

  return (
    <FastField name={name} validate={validate} shouldUpdate={customShouldComponentUpdate} deps={fieldDeps}>
      {(fieldProps: FieldProps) => <SelectFormControl {...restOfProps} fieldProps={fieldProps} disabled={disabled} />}
    </FastField>
  );
}

export default function SelectField(props: SelectFieldProps) {
  const { name, validate, disabled, ...restOfProps } = props;

  return (
    <Field name={name} validate={validate}>
      {(fieldProps: FieldProps) => <SelectFormControl {...restOfProps} fieldProps={fieldProps} disabled={disabled} />}
    </Field>
  );
}

const DEFAULT_VALUE_KEY = 'id';
const DEFAULT_LABEL_KEY = 'name';
const DEFAULT_SEARCHABLE = true;
const DEFAULT_NEW_ITEM_VALUE = 0;
const DEFAULT_PLACEHOLDER = '';
