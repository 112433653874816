import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { SHAPE } from 'baseui/button-group';
import { Drawer } from 'baseui/drawer';
import { Menu } from 'baseui/icon';
import { HeadingXSmall, LabelMedium, LabelXSmall } from 'baseui/typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { appConfig } from '../config/appConfig';
import { IRoute, useAppRoutes } from '../Routes';
import { DateAndLocaleInfo, DebugOnly } from '../shared/DateAndLocaleInfoDebug';
import { useUser } from '../shared/hooks/useUser';
import { RouterLink } from '../shared/utilityComponents/RouterLink';
import { UserMenu } from './UserMenu';

export type INavItem = Omit<IRoute, 'component'>;

export const MainNavigation = () => {
  const appRoutes = useAppRoutes();
  const user = useUser().data;

  const [css, theme] = useStyletron();

  const location = useLocation();
  const t = useTranslation('translation', { keyPrefix: 'navigation' }).t;

  const navStyles = css({
    width: '100vw',
    minHeight: '64px',
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr minmax(max-content, min-content)',
    alignItems: 'center',
    backgroundColor: theme.colors.primary,
    color: theme.colors.primaryB,
    padding: '0 40px 0 10px',
    position: 'relative',
  });

  const [isOpen, setIsOpen] = React.useState(false);

  const currentRoute = appRoutes.find((route) => route.path.split('/')[1] === location.pathname.split('/')[1]);

  return (
    <nav className={navStyles}>
      <Drawer isOpen={isOpen} autoFocus onClose={() => setIsOpen(false)} anchor={'left'}>
        <div>
          {appRoutes.map((route) =>
            route.menu === 'main' && route.userHasAccess ? (
              <RouterLink
                to={route.path}
                key={route.path}
                className={css({ marginRight: '20px' })}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <HeadingXSmall margin="0">{t(route.label)}</HeadingXSmall>
              </RouterLink>
            ) : null
          )}
        </div>
      </Drawer>

      <Button
        onClick={() => setIsOpen(!isOpen)}
        shape={SHAPE.square}
        overrides={{
          BaseButton: {
            style: {
              backgroundColor: theme.colors.primary,
              color: theme.colors.primaryB,
              ':hover': { backgroundColor: theme.colors.primary500, color: theme.colors.primaryB },
            },
          },
        }}
      >
        <Menu size={24} />
      </Button>

      <LabelMedium color={[theme.colors.primaryB]} marginTop="0" marginRight="0" marginBottom="0" marginLeft="scale900">
        {currentRoute && t(currentRoute.label)}
      </LabelMedium>

      <UserMenu name={`${user?.firstName} ${user?.lastName}` ?? ''} />

      <Block right="40px" bottom="0.2em" position="absolute">
        <DebugOnly>
          <DateAndLocaleInfo />
        </DebugOnly>
        <LabelXSmall display="inline" color="primaryB" marginLeft="1em">
          {appConfig.releaseId}
        </LabelXSmall>
      </Block>
    </nav>
  );
};
