import incident from './incident.svg';
import chargingstation from './chargingstation.svg';
import home from './home.svg';
import car_rental from './car_rental.svg';
import workshop from './workshop.svg';
import workshop_active from './workshop--active.svg';

export const Pins = {
  incident,
  chargingstation,
  home,
  car_rental,
  workshop,
  workshop_active
};
