import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalHeader, SIZE, ROLE } from 'baseui/modal';
import { ParagraphMedium } from 'baseui/typography';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiException } from '../../api/api.generated';

interface ErrorHandleModalProps {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
  error?: ApiException;
}

function ErrorHandleModalComp(props: ErrorHandleModalProps) {
  const tError = useTranslation('translation', { keyPrefix: 'error' }).t;
  const tCaseReg = useTranslation('translation', { keyPrefix: 'caseRegistration' }).t;

  return (
    <Modal
      onClose={() => props.setIsOpen(false)}
      closeable
      isOpen={props.isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>{tError('error')}</ModalHeader>
      <ModalBody>
        <ParagraphMedium>{tError('errorPreText')}</ParagraphMedium>
        <ParagraphMedium>{props?.error?.response}</ParagraphMedium>
        <ParagraphMedium>{tError('errorPostText')}</ParagraphMedium>
        <Button type="button" onClick={() => props.setIsOpen(false)}>
          {tCaseReg('goBack')}
        </Button>
      </ModalBody>
    </Modal>
  );
}
export const ErrorHandleModal = memo(ErrorHandleModalComp);
