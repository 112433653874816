import { FormikHelpers, FormikState } from 'formik';
import { isEqual } from 'lodash-es';
import { createContext, memo, ReactNode, useContext, useRef } from 'react';
import { create } from 'zustand';
import { CaseRegistrationFormFields } from './caseRegistrationModels';

interface CaseRegistrationState extends Partial<CaseRegistrationFormFields> {
  formikState: FormikState<CaseRegistrationFormFields>;
  formikHelpers: FormikHelpers<CaseRegistrationFormFields>;
  updateValuesAndHelpers: (
    formikState: FormikState<CaseRegistrationFormFields>,
    formikHelpers: FormikHelpers<CaseRegistrationFormFields>
  ) => void;
}

const createCaseRegistrationStore = () =>
  create<Partial<CaseRegistrationState>>()((set) => ({
    updateValuesAndHelpers: (formikState, formikHelpers) => {
      return set((state) => {
        /**  The isEqual check might be an optimization overkill 
           and can safely remove it if it causes more issues than it solves */
        if (!isEqual(state.formikState?.values, formikState.values) || !isEqual(state.formikHelpers, formikHelpers)) {
          return { ...state, formikState: formikState, formikHelpers: formikHelpers, ...formikState.values };
        }

        return state;
      });
    },
  }));

type CaseRegistrationStore = ReturnType<typeof createCaseRegistrationStore>;

const CaseRegistrationStoreContext = createContext<CaseRegistrationStore>(undefined as never);
CaseRegistrationStoreContext.displayName = 'CaseRegistrationStoreContext';

export const useCaseRegistrationStoreContext = () => {
  const ctx = useContext(CaseRegistrationStoreContext);

  if (!ctx) throw new Error(`${CaseRegistrationStoreContext.displayName} must be provided before use`);

  return ctx;
};

const CaseRegistrationStoreContextProviderComponent = ({ children }: { children: ReactNode }) => {
  const caseRegistrationStore = useRef(createCaseRegistrationStore()).current;

  return (
    <CaseRegistrationStoreContext.Provider value={caseRegistrationStore}>
      {children}
    </CaseRegistrationStoreContext.Provider>
  );
};

export const CaseRegistrationStoreContextProvider = memo(CaseRegistrationStoreContextProviderComponent);
