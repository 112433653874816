import { UseQueryResult } from '@tanstack/react-query';
import { DeleteAlt } from 'baseui/icon';
import { useSnackbar } from 'baseui/snackbar';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'zustand';
import { mapCaseSearchDtoToItem } from '@@caseSearch/caseSearch.functions';
import { TwoHalvesLayout, LayoutItem } from '@@core/twoHalvesLayout/TwoHalvesLayout';
import { ApiException, CaseDto, ComboboxItem } from '../api/api.generated';
import { useCountriesQuery } from '../api/queries/useActorQueries';
import {
  useCreateCaseMutation,
  useGetCasesByRegistrationNumberQuery,
  useHasCompletedAssistance,
  useResumeCaseMutation,
  useUpdateCaseMutation,
} from '../api/queries/useCaseQueries';
import CaseHistoryModal from '../caseHistory/CaseHistoryModal';
import { TimeZoneContextProvider } from '../shared/TimeZoneContext';
import { COUNTRY_ALPHA3_TO_IANA_TIME_ZONE_MAP } from '../shared/constants';
import { isPhoneNumberValid } from '../shared/functions/general';
import { useUser } from '../shared/hooks/useUser';
import { Banner } from '../shared/info/Banner';
import { CaseFormStatus, CaseStatusEnum } from '../shared/types';
import { CaseRegistrationForm } from './CaseRegistrationForm';
import { useCaseRegistrationStoreContext } from './CaseRegistrationStore';
import { CaseRegistrationTools } from './CaseRegistrationTools';
import { LeasingDrawer } from './caseDrawers/LeasingDrawer';
import { CancelCaseModal } from './caseModals/CancelCaseModal';
import { ErrorHandleModal } from './caseModals/ErrorHandleModal';
import { FixedItModal } from './caseModals/FixedItModal';
import { LogEntriesModal } from './caseModals/LogEntriesModal';
import { MissingFieldsModal } from './caseModals/MissingFieldsModal';
import { RegisterCaseModal } from './caseModals/RegisterCaseModal';
import { getCaseFormStatus, getUpdatedModel, missingRequiredFields } from './caseRegistrationFunctions';
import { CaseRegistrationFormFields } from './caseRegistrationModels';
import { GeoLinkListener } from './utilityComponents/GeoLinkListener';

interface Props {
  queryResult: UseQueryResult<CaseDto | undefined>;
  initialValues: CaseRegistrationFormFields;
}

const CaseRegistrationComp = (props: Props) => {
  const { queryResult, initialValues } = props;
  const navigate = useNavigate();
  const { enqueue } = useSnackbar();
  const { userId } = useUser();

  const tCancel = useTranslation('translation', { keyPrefix: 'cancelCase' }).t;
  const tFixed = useTranslation('translation', { keyPrefix: 'fixedItCase' }).t;
  const tCaseReg = useTranslation('translation', { keyPrefix: 'caseRegistration' }).t;
  const tError = useTranslation('translation', { keyPrefix: 'error' }).t;
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;

  const [cancelRegistrationModal, setCancelRegistrationModal] = useState(false);
  const [cancelCaseModal, setCancelCaseModal] = useState(false);
  const [fixedItModal, setFixedItModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [leasingDrawer, setLeasingDrawer] = useState(false);
  const [errorToDisplay, setErrorToDisplay] = useState<ApiException>();
  const [showMissingFields, setShowMissingFields] = useState(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [logEntriesModal, setLogEntriesModal] = useState(false);

  const store = useCaseRegistrationStoreContext();
  const vehicleRegistrationNumber = useStore(store, (s) => s.vehicleRegistrationNumber);
  const ownerName = useStore(store, (s) => s.ownerName);
  const ownerPhone = useStore(store, (s) => s.ownerPhone);
  const ownerAddressStreet = useStore(store, (s) => s.ownerAddressStreet);
  const ownerAddressPostalCode = useStore(store, (s) => s.ownerAddressPostalCode);
  const ownerAddressCity = useStore(store, (s) => s.ownerAddressCity);
  const ownerAddressCountry = useStore(store, (s) => s.ownerAddressCountry);
  const leasingTaker = useStore(store, (s) => s.leasingTaker);

  const { data: caseDto, isFetching, isError, error } = queryResult;

  const { data: countries } = useCountriesQuery();

  const createCaseQuery = useCreateCaseMutation();
  const updateCaseQuery = useUpdateCaseMutation();
  const resumeCaseQuery = useResumeCaseMutation();

  const { data: caseSearchResults } = useGetCasesByRegistrationNumberQuery(
    vehicleRegistrationNumber,
    caseDto?.visCaseId
  );

  const caseHistoryItems = useMemo(() => mapCaseSearchDtoToItem(caseSearchResults), [caseSearchResults]);

  const completedAssistance = useHasCompletedAssistance(caseDto?.visCaseId);

  const mode = useMemo(
    () => getCaseFormStatus(caseDto?.statusId, caseDto?.visCaseId, completedAssistance.data),
    [caseDto?.statusId, caseDto?.visCaseId, completedAssistance.data]
  );

  const timeZone = useMemo<string | undefined>(() => {
    if (!caseDto?.callCenterCountry) return undefined;

    return COUNTRY_ALPHA3_TO_IANA_TIME_ZONE_MAP[caseDto.callCenterCountry];
  }, [caseDto]);

  const update = updateCaseQuery.mutate;
  const create = createCaseQuery.mutate;

  const handleSubmit = (values: CaseRegistrationFormFields) => {
    const updatedModel = getUpdatedModel(caseDto, values);

    if (mode === CaseFormStatus.Create) {
      create(updatedModel, {
        onSuccess: (updateModel: CaseDto) => {
          const visCaseId = updateModel.visCaseId;

          /* Something weird started happening after the upgrade to react query 4
            when navigating inside this onSuccess callback, namely that it tries
            to update something in some state of the unmounted component.
            Delaying the navigate call to the next tick of the event loop seems to fix that.
            Rather hacky, but I've looking into this for 2 days now. So whatever, it works. */
          setTimeout(() => {
            navigate('../case/' + visCaseId);
          }, 0);
        },
      });
    } else {
      update(updatedModel, {
        onSuccess: () => {
          enqueue({ message: tCaseReg('caseSaved'), startEnhancer: ({ size }) => <FaCheckCircle size={size} /> });
        },
        onError: () => {
          enqueue({ message: tCaseReg('caseSavedFailed'), startEnhancer: ({ size }) => <DeleteAlt size={size} /> });
        },
      });
    }
  };

  //Error handling useEffect
  useEffect(() => {
    if (updateCaseQuery.isError && (updateCaseQuery.error as ApiException).status === 400) {
      setErrorModal(updateCaseQuery.isError);
      setErrorToDisplay(updateCaseQuery.error as ApiException);
      setCancelRegistrationModal(false);
      setRegisterModal(false);
      setFixedItModal(false);
      setCancelCaseModal(false);
    }
  }, [updateCaseQuery.error, updateCaseQuery.isError]);

  useEffect(() => {
    if (createCaseQuery.isError && (createCaseQuery.error as ApiException).status === 400) {
      setErrorModal(createCaseQuery.isError);
      setErrorToDisplay(createCaseQuery.error as ApiException);
      setCancelRegistrationModal(false);
      setRegisterModal(false);
      setFixedItModal(false);
      setCancelCaseModal(false);
    }
  }, [createCaseQuery.error, createCaseQuery.isError]);

  function handleResume() {
    if (!caseDto) {
      throw 'caseObject is undefined';
    }

    resumeCaseQuery
      .mutateAsync({ caseId: caseDto.id })
      .then(() => {
        enqueue({ message: tCaseReg('resumeCaseSuccess'), startEnhancer: ({ size }) => <FaCheckCircle size={size} /> });
      })
      .catch(() => {
        enqueue({
          message: tCaseReg('resumeCaseFailed'),
          startEnhancer: ({ size }) => <DeleteAlt size={size} />,
        });
      });
  }

  function handleRegister(values: CaseRegistrationFormFields) {
    const missingFieldsTemp = missingRequiredFields(values);
    setMissingFields(missingFieldsTemp);

    if (missingFieldsTemp.length < 1) {
      setRegisterModal(true);
    } else {
      setShowMissingFields(true);
    }
  }

  const formIsLoading =
    isFetching || createCaseQuery.isLoading || updateCaseQuery.isLoading || resumeCaseQuery.isLoading;

  if (isError) {
    return (
      <p>
        En feil har skjedd. Oppgi følgende til IT: {(error as ApiException).status} {(error as ApiException).response}
      </p>
    );
  }

  const hasCreateOrUpdateError = createCaseQuery.isError || updateCaseQuery.isError;
  const createOrUpdateError = createCaseQuery.error ?? updateCaseQuery.error;

  return (
    <TimeZoneContextProvider timeZone={timeZone}>
      {hasCreateOrUpdateError && (createOrUpdateError as ApiException).status !== 400 ? (
        <Banner
          text={`Error ${(createOrUpdateError as ApiException).status}: ${tError('errorMessage')}`}
          kind="negative"
          closeable
        />
      ) : undefined}

      <TwoHalvesLayout>
        <LayoutItem name={tShared('case')}>
          <CaseRegistrationForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            mode={mode}
            isLoading={formIsLoading}
            onCancelClick={() => setCancelRegistrationModal(true)}
            onFixedItClick={() => setFixedItModal(true)}
            onLeasingClick={() => setLeasingDrawer(true)}
            onRegisterClick={handleRegister}
          />
        </LayoutItem>
        <LayoutItem name={tCaseReg('tools')}>
          <CaseRegistrationTools
            caseId={caseDto?.id}
            earlierCasesCount={caseSearchResults?.length}
            onResumeCase={handleResume}
            resumeCaseLoading={resumeCaseQuery.isLoading}
            resumeCaseDisabled={
              formIsLoading ||
              (caseDto?.statusId !== CaseStatusEnum.Cancelled && caseDto?.statusId !== CaseStatusEnum.Closed)
            }
            onShowHistoryModal={() => setHistoryModal(true)}
            onShowLogEntriesModal={() => setLogEntriesModal(true)}
          />
        </LayoutItem>
      </TwoHalvesLayout>

      <CaseHistoryModal isOpen={historyModal} setIsOpen={setHistoryModal} data={caseHistoryItems} />
      <CancelCaseModal
        caseId={caseDto?.id}
        userId={userId}
        setIsOpen={setCancelRegistrationModal}
        isOpen={cancelRegistrationModal}
        successMessage={tCancel('confirmCancelRegistration')}
        failedMessage={tCancel('failedCancelRegistration')}
        title={tCancel('cancelRegistration')}
      />
      <CancelCaseModal
        caseId={caseDto?.id}
        userId={userId}
        setIsOpen={setCancelCaseModal}
        isOpen={cancelCaseModal}
        successMessage={tCancel('confirmCancel')}
        failedMessage={tCancel('failedCancel')}
        title={tCancel('cancelCase')}
      />
      <FixedItModal
        caseId={caseDto?.id}
        userId={userId}
        successPhoneFixMessage={tFixed('phoneFixRegistration')}
        successInfoCallMessage={tFixed('infoCallRegistration')}
        failedInfoCallMessage={tFixed('failedInfoCall')}
        failedPhoneFixMessage={tFixed('failedPhoneFix')}
        isOpen={fixedItModal}
        setIsOpen={setFixedItModal}
      />
      <MissingFieldsModal
        isOpen={showMissingFields}
        setIsOpen={setShowMissingFields}
        setShowRegisterCase={setRegisterModal}
        missingFields={missingFields}
      />
      <RegisterCaseModal setIsOpen={setRegisterModal} isOpen={registerModal} caseId={caseDto?.id} userId={userId} />
      <ErrorHandleModal setIsOpen={setErrorModal} isOpen={errorModal} error={errorToDisplay} />
      <LogEntriesModal id={caseDto?.id} isOpen={logEntriesModal} setIsOpen={setLogEntriesModal} />
      <LeasingDrawer
        isOpen={leasingDrawer}
        setIsOpen={setLeasingDrawer}
        leasingCompany={{
          name: ownerName,
          phone: isPhoneNumberValid(ownerPhone) ? ownerPhone : undefined,
          address: ownerAddressStreet,
          postalCode: ownerAddressPostalCode,
          postalCity: ownerAddressCity,
          country: countries
            ?.filter((d: ComboboxItem) => d.id === ownerAddressCountry)
            .map((d: ComboboxItem) => d.name)[0],
        }}
        leasingTaker={leasingTaker}
      />

      <GeoLinkListener />
    </TimeZoneContextProvider>
  );
};

export const CaseRegistration = memo(CaseRegistrationComp);
