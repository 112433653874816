import { useTranslation } from 'react-i18next';
import { useGetCaseActionsQuery } from '../api/queries/useCaseActionQueries';
import { CaseHistoryTable, HistoryType } from './CaseHistoryTable';

export interface CaseHistoryProps {
  id: string | undefined;
  showExpandAllButton?: boolean;
}

const CaseHistory = (props: CaseHistoryProps) => {
  const history = useGetCaseActionsQuery(props.id);

  const getTypesData = !!(
    (history.data?.services && history.data.services.length > 0) ||
    (history.data?.actions && history.data.actions.length > 0)
  );

  const services = history.data?.services;
  const logs = history.data?.actions;

  const tCaseHistory = useTranslation('translation', { keyPrefix: 'caseHistory' }).t;

  return (
    <>
      <CaseHistoryTable
        heading={tCaseHistory('service')}
        data={services}
        type={HistoryType.services}
        getTypesData={getTypesData}
        showExpandAllButton={props.showExpandAllButton}
      />
      <CaseHistoryTable
        heading={tCaseHistory('log')}
        data={logs}
        type={HistoryType.logs}
        getTypesData={getTypesData}
        showExpandAllButton={props.showExpandAllButton}
      />
    </>
  );
};

CaseHistory.displayName = 'Case history';

export default CaseHistory;
