import { useStyletron } from 'baseui';
import { KIND } from 'baseui/button';
import { LabelLarge, LabelMedium } from 'baseui/typography';
import { Form, Formik, FormikHelpers } from 'formik';
import { toString } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdSave } from 'react-icons/md';
import { UserDto } from '@@api/api.generated';
import { useAdminUpdateUserMutation } from '@@api/queries/useUserAdminQueries';
import { useRolesQuery, useUpdateUserMutation } from '@@api/queries/useUserQueries';
import { Button } from '@@shared/basewebComponentOverrides/Button';
import { TextLarge, TextXLarge } from '@@shared/basewebComponentOverrides/Typography';
import { CALL_CENTER_LIST, ISO1_LANGUAGE_CODE_TO_V, LANGUAGE_LIST } from '@@shared/constants';
import { FastEmailInputField } from '@@shared/formFields/EmailInputField';
import { FastInputField } from '@@shared/formFields/InputField';
import { FastSelectField } from '@@shared/formFields/SelectField';
import { toSelectOptions } from '@@shared/functions/general';
import { useSnackbarRef } from '@@shared/hooks/useSnackbarRef';
import { Banner } from '@@shared/info/Banner';
import { ApiErrorMessage } from '@@shared/utilityComponents/ApiErrorMessage';
import { InitialsAvatar } from '@@shared/utilityComponents/InitialsAvatar';
import { getUpdateUserFormInitialValues, UpdateUserForm } from './userFormFunctions';

const CALL_CENTER_OPTIONS = toSelectOptions(CALL_CENTER_LIST);

interface Props {
  user: UserDto | undefined;
  mode: 'details' | 'profile';
}

export const UserDetails = ({ user, mode }: Props) => {
  const [css, theme] = useStyletron();

  const enqueue = useSnackbarRef();

  const tLanguage = useTranslation('translation', { keyPrefix: 'language' }).t;
  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;
  const tUsers = useTranslation('translation', { keyPrefix: 'users' }).t;

  const [initialValues, setInitialValues] = useState<UpdateUserForm>(() => getUpdateUserFormInitialValues());

  const { data: roles } = useRolesQuery();

  const { mutate: updateUser, isLoading: updateUserIsLoading, error: updateUserError } = useAdminUpdateUserMutation();
  const {
    mutate: updateProfile,
    isLoading: updateProfileIsLoading,
    error: updateProfileError,
  } = useUpdateUserMutation();

  const updateIsLoading = updateUserIsLoading || updateProfileIsLoading;

  const roleOptions = (roles ?? []).map((r) => ({ id: r.id, name: r.name }));
  const languageOptions = LANGUAGE_LIST.map((l) => ({ id: ISO1_LANGUAGE_CODE_TO_V[l], name: tLanguage(l) }));

  useEffect(() => {
    setInitialValues(getUpdateUserFormInitialValues(user));
  }, [user]);

  const handleUpdateUser = (values: UpdateUserForm, helpers: FormikHelpers<UpdateUserForm>) => {
    if (!user?.id) return;

    updateUser(
      {
        userId: user.id,
        updateCommand: {
          email: user.canUpdateEmail ? values.email : undefined,
          roleId: parseInt(values.roleId),
          callCenterCountryCode: values.callCenterCountryCode,
          languageCode: values.languageCode,
        },
      },
      {
        onSuccess: () => {
          enqueue({ message: tUsers('userUpdated') });
          helpers.setSubmitting(false);
        },
      }
    );
  };

  const handleUpdateProfile = (values: UpdateUserForm, helpers: FormikHelpers<UpdateUserForm>) => {
    updateProfile(
      {
        callCenterCountryCode: values.callCenterCountryCode,
        languageCode: values.languageCode,
      },
      {
        onSuccess: () => {
          enqueue({ message: tUsers('profileUpdated') });
          helpers.setSubmitting(false);
          location.reload();
        },
      }
    );
  };

  const requiredValidator = (value: string) => {
    if (toString(value).trim().length === 0) {
      return tShared('errorRequired') as string;
    }
  };

  const handleSubmit = (values: UpdateUserForm, helpers: FormikHelpers<UpdateUserForm>) => {
    if (mode === 'profile') {
      handleUpdateProfile(values, helpers);
    } else {
      handleUpdateUser(values, helpers);
    }
  };

  const getFormattedUsername = function (user: UserDto | undefined, fallback?: string) {
    if (!user || !user.firstName || !user.lastName) return fallback ?? '';

    return `${user.firstName} ${user.lastName}`.trim();
  };

  const updateError = updateUserError || updateProfileError;

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '4em',
        position: 'relative',
      })}
    >
      {!!updateError && (
        <div className={css({ position: 'relative', top: '-2.5em' })}>
          <Banner text={<ApiErrorMessage error={updateError} />} kind="negative" />
        </div>
      )}

      <div
        className={css({
          display: 'grid',
          gridTemplateAreas: '"avatar username" "empy form"',
          gridTemplateColumns: '200px minmax(300px, auto)',
          gridTemplateRows: 'minmax(150px, auto) minmax(500px, auto)',
        })}
      >
        <div
          className={css({
            gridArea: 'avatar',
            justifySelf: 'center',
          })}
        >
          <InitialsAvatar name={getFormattedUsername(user, '?')} size="8em" />
        </div>
        <div
          className={css({
            gridArea: 'username',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5em',
          })}
        >
          <LabelLarge>{user?.role?.name}</LabelLarge>
          <TextXLarge>{getFormattedUsername(user, '-')}</TextXLarge>
          <TextLarge color={theme.colors.mono800}>{user?.email}</TextLarge>
        </div>
        <div
          className={css({
            gridArea: 'form',
          })}
        >
          <LabelMedium marginBottom="2em">{tShared('settings')}</LabelMedium>

          <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
            {() => (
              <Form noValidate>
                {user?.canUpdateEmail && (
                  <FastEmailInputField
                    name={nameof('email')}
                    label={tShared('email')}
                    required
                    validate={requiredValidator}
                  />
                )}

                <FastInputField label={tUsers('username')} name={nameof('username')} readOnly />
                {mode === 'profile' ? (
                  <FastInputField label={tUsers('role')} name={nameof('role')} readOnly />
                ) : (
                  <FastSelectField
                    name={nameof('roleId')}
                    label={tUsers('role')}
                    options={roleOptions}
                    clearable={false}
                    required
                    validate={requiredValidator}
                  />
                )}

                <FastSelectField
                  name={nameof('callCenterCountryCode')}
                  label={tUsers('callCenter')}
                  options={CALL_CENTER_OPTIONS}
                  labelKey="label"
                  clearable={false}
                  required
                  validate={requiredValidator}
                />
                <FastSelectField
                  name={nameof('languageCode')}
                  label={tUsers('language')}
                  options={languageOptions}
                  clearable={false}
                  required
                  validate={requiredValidator}
                />
                <Button type="submit" kind={KIND.primary} disabled={updateIsLoading} isLoading={updateIsLoading}>
                  <MdSave />
                  <span className={css({ paddingLeft: theme.sizing.scale400 })}>{tShared('save')}</span>
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export function nameof(fieldName: keyof UpdateUserForm): string {
  return fieldName;
}
