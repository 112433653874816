import { useQuery } from '@tanstack/react-query';
import { isNumeric } from '../../shared/functions/general';
import { useApiClient } from '../apiClientContext';

export const useCountriesQuery = () => {
  const { actorClient } = useApiClient();

  return useQuery(['Countries'], async () => {
    return await actorClient.getCountries();
  });
};

export const useInsuranceCompaniesQuery = () => {
  const { actorClient } = useApiClient();

  return useQuery(['InsuranceCompanies'], async () => {
    return await actorClient.getInsuranceCompanies();
  });
};

export const useVolvoWorkshopsQuery = () => {
  const { actorClient } = useApiClient();
  return useQuery(['VolvoWorkshops'], async () => {
    return await actorClient.getVolvoWorkshops();
  });
};

export const useAgentsQuery = () => {
  const { actorClient } = useApiClient();
  return useQuery(['Agents'], async () => {
    return await actorClient.getAgents();
  });
};

export const useRentalCarProviders = () => {
  const { actorClient } = useApiClient();
  return useQuery(['RentalCarProviders'], async () => {
    return await actorClient.getRentalCarProviders();
  });
};

export const useRentalCarProviderQuery = (actorId: string | undefined) => {
  const { actorClient } = useApiClient();

  return useQuery(
    ['RentalCarProvider', actorId],
    async () => {
      if (actorId !== undefined && isNumeric(actorId)) {
        return await actorClient.getRentalCarProvider(Number.parseInt(actorId));
      }
    },
    { enabled: actorId !== undefined && isNumeric(actorId) }
  );
};
