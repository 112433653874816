import { QueryClient, UseMutationOptions } from '@tanstack/react-query';

export const CASE_QUERY_KEY = 'Case';
export const CASE_ACTION_QUERY_KEY = 'CaseAction';
export const USERS_QUERY_KEY = 'Users';

// It's probably fine to use unknown here, but if it needs to match
// the data type sent to the mutation can later make this function generic
export function getInvalidateQueriesMutationOption(
  queryClient: QueryClient,
  queryKeys: string[]
): UseMutationOptions<unknown, unknown, unknown> {
  return {
    onSuccess: () => {
      queryKeys.forEach((queryKey) => {
        queryClient.invalidateQueries({ queryKey: [queryKey] });
      });
    },
  };
}
