import { Block } from 'baseui/block';
import { DatepickerProps } from 'baseui/datepicker';
import adapter from 'baseui/datepicker/utils/date-fns-adapter';
import { FormControl } from 'baseui/form-control';
import { TimePickerProps } from 'baseui/timepicker';
import { nb } from 'date-fns/esm/locale';
import { FastField, Field, FieldProps, FieldValidator } from 'formik';
import DatePickerExtra from '../inputs/DatePickerExtra';
import TimePickerExtra from '../inputs/TimePickerExtra';
import { FakeFormikEvent } from '../types';
import { customShouldComponentUpdate } from './fastFormFieldFunctions';

type SafeDatePickerProps = Omit<DatepickerProps, 'value' | 'onChange'>;
type SafeTimePickerProps = Omit<TimePickerProps, 'value' | 'onChange'>;

interface CommonTimestampFieldProps {
  label: string;
  noFuture?: boolean;
  datePickerProps?: SafeDatePickerProps;
  timePickerProps?: SafeTimePickerProps;
}

interface TimestampFormControlProps extends CommonTimestampFieldProps {
  fieldProps: FieldProps;
}

export interface TimestampFieldProps extends CommonTimestampFieldProps {
  name: string;
  validate?: FieldValidator;
}

function TimestampFormControl(props: TimestampFormControlProps) {
  const { fieldProps, label, noFuture, datePickerProps, timePickerProps } = props;

  const { field, meta } = fieldProps;

  const showError = meta.touched && !!meta.error;

  return (
    <FormControl label={label} disabled={datePickerProps?.disabled} error={showError && meta.error}>
      <>
        <Block display="flex">
          <Block flex="1" paddingRight="scale200">
            <DatePickerExtra
              value={field.value}
              onChange={({ date }) => {
                const newValue = Array.isArray(date) ? date[0] : date;
                field.onChange(new FakeFormikEvent(field.name, newValue));
              }}
              useZonedTime
              maxDate={noFuture ? new Date() : undefined}
              formatString="dd.MM.yyyy"
              placeholder="dd.mm.yyyy"
              locale={nb}
              {...datePickerProps}
            />
          </Block>
          <Block flex="0 0 5em">
            <TimePickerExtra
              value={field.value}
              onChange={(date) => field.onChange(new FakeFormikEvent(field.name, date))}
              useZonedTime
              maxTime={noFuture ? new Date() : undefined}
              creatable
              nullable
              format="24"
              adapter={adapter}
              {...timePickerProps}
            />
          </Block>
        </Block>
      </>
    </FormControl>
  );
}

export function FastTimestampField(props: TimestampFieldProps) {
  const { name, validate, ...restOfProps } = props;

  const depProps = { disabled: props.datePickerProps?.disabled, required: props.datePickerProps?.required };

  return (
    <FastField name={name} validate={validate} shouldUpdate={customShouldComponentUpdate} {...depProps}>
      {(fieldProps: FieldProps) => <TimestampFormControl {...restOfProps} fieldProps={fieldProps} />}
    </FastField>
  );
}

export default function TimestampField(props: TimestampFieldProps) {
  const { name, validate, ...restOfProps } = props;

  return (
    <Field name={name} validate={validate}>
      {(fieldProps: FieldProps) => <TimestampFormControl {...restOfProps} fieldProps={fieldProps} />}
    </Field>
  );
}
