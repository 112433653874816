import { BrandDto, CaseSearchRequest, CaseSearchRequestFilters, ComboboxItem, ModelDto } from '../api/api.generated';

//Mapping out every value that has name and id
export interface CaseSearchRequestFormFields
  extends Omit<
    CaseSearchRequestFilters,
    | 'brandCode'
    | 'modelCode'
    | 'incidentTypeCode'
    | 'insuranceStatusCode'
    | 'agreementCode'
    | 'incidentCountryCode'
    | 'workshopId'
    | 'caseStatusId'
    | 'sourceOfCaseInformationCode'
    | 'logFromAgentId'
    | 'caseAgentId'
    | 'logCode'
    | 'serviceCode'
  > {
  resultPage?: number;
  resultQty?: number;
  languageCode?: string;
  brand?: BrandDto;
  model?: ModelDto;
  incidentType?: ComboboxItem;
  insuranceStatus?: ComboboxItem;
  agreement?: ComboboxItem;
  incidentCountry?: ComboboxItem;
  workshop?: ComboboxItem;
  caseStatus?: ComboboxItem;
  source?: ComboboxItem;
  logFromAgent?: UserAgent;
  caseAgent?: UserAgent;
  log?: ComboboxItem;
  service?: ComboboxItem;
}

export interface UserAgent {
  id?: string;
  visUserId?: string;
  name?: string;
  callCenterCountryCode?: string;
  active?: boolean;
  callCenterId?: string;
  canUpdateEmail?: boolean;
  company?: string;
  email?: string;
  firstName?: string;
  languageCode?: string;
  lastLogin?: string;
  lastName?: string;
  role?: string;
}

export const unwrap = (values: CaseSearchRequestFormFields): CaseSearchRequest => {
  const newVal: CaseSearchRequest = {
    resultPage: values.resultPage,
    resultQty: values.resultQty,
    filters: {
      agreementCode: values.agreement?.id,
      brandCode: values.brand?.id,
      caseAgentId: values.caseAgent?.visUserId,
      caseId: values.caseId,
      caseRegisteredDateTimeFrom: values.caseRegisteredDateTimeFrom,
      caseRegisteredDateTimeTo: values.caseRegisteredDateTimeTo,
      caseStatusIds: values.caseStatus?.id ? [parseInt(values.caseStatus?.id)] : undefined,
      completedDateTimeFrom: values.completedDateTimeFrom,
      completetDateTimeTo: values.completetDateTimeTo,
      contactPersonName: values.contactPersonName,
      deadlineDateTimeFrom: values.deadlineDateTimeFrom,
      deadlineDateTimeTo: values.deadlineDateTimeTo,
      incidentAdress: values.incidentAdress,
      incidentCountryCode: values.incidentCountry?.id,
      incidentDateTimeFrom: values.incidentDateTimeFrom,
      incidentDateTimeTo: values.incidentDateTimeTo,
      incidentPostalArea: values.incidentPostalArea,
      incidentTypeCode: values.incidentType?.id,
      insuranceReferenceNumber: values.insuranceReferenceNumber,
      insuranceStatusCode: values.insuranceStatus?.id,

      logCode: values.log?.id,
      logFromAgentId: values.logFromAgent?.visUserId,
      logFromCallCenterCode: values.logFromCallCenterCode,
      logRegisteredDateTimeFrom: values.logRegisteredDateTimeFrom,
      logRegisteredDateTimeTo: values.logRegisteredDateTimeTo,
      logTransferredToId: values.logTransferredToId,
      modelCode: values.model?.id,
      ownerName: values.ownerName,
      processingLocationName: values.processingLocationName,
      registrationNumber: values.registrationNumber,

      serviceCode: values.service?.id,
      sourceOfCaseInformationCode: values.source?.id,
      vin: values.vin,
      visCaseId: values.visCaseId,
      workshopId: values.workshop?.id ? parseInt(values.workshop?.id) : undefined,
      ownerPhoneNumber: values.ownerPhoneNumber,
      contactPersonPhoneNumber: values.contactPersonPhoneNumberCountryCode,
    },
  };
  return newVal;
};

export const INITIAL_VALUES: CaseSearchRequestFormFields = {
  agreement: undefined,
  brand: undefined,
  caseAgent: undefined,
  caseId: undefined,
  caseRegisteredDateTimeFrom: undefined,
  caseRegisteredDateTimeTo: undefined,
  caseStatus: undefined,
  completedDateTimeFrom: undefined,
  completetDateTimeTo: undefined,
  contactPersonName: undefined,
  deadlineDateTimeFrom: undefined,
  deadlineDateTimeTo: undefined,
  incidentAdress: undefined,
  incidentCountry: undefined,
  incidentDateTimeFrom: undefined,
  incidentDateTimeTo: undefined,
  incidentPostalArea: undefined,
  incidentType: undefined,
  insuranceReferenceNumber: undefined,
  insuranceStatus: undefined,
  languageCode: undefined,
  log: undefined,
  logFromAgent: undefined,
  logFromCallCenterCode: undefined,
  logRegisteredDateTimeFrom: undefined,
  logRegisteredDateTimeTo: undefined,
  logTransferredToId: undefined,
  model: undefined,
  ownerName: undefined,
  processingLocationName: undefined,
  registrationNumber: undefined,
  resultPage: undefined,
  resultQty: undefined,
  service: undefined,
  source: undefined,
  vin: undefined,
  visCaseId: undefined,
  workshop: undefined,
  contactPersonPhoneNumber: undefined,
  ownerPhoneNumber: undefined,
};
