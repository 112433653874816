import { Modal, ModalBody, ModalHeader, ROLE } from 'baseui/modal';
import { HeadingMedium } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useStore } from 'zustand';
import { useCaseRegistrationStoreContext } from '@@caseRegistration/CaseRegistrationStore';
import { CaseSearchResultItem } from '@@caseSearch/caseSearch.models';
import { CaseSearchResults } from '../caseSearch/CaseSearchResults';

interface CaseHistoryModalProps {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
  data?: CaseSearchResultItem[];
}

function CaseHistoryModal(props: CaseHistoryModalProps) {
  const tCase = useTranslation('translation', { keyPrefix: 'caseHistoryResult' }).t;

  const store = useCaseRegistrationStoreContext();
  const vehicleRegistrationNumber = useStore(store, (s) => s.vehicleRegistrationNumber);

  return (
    <Modal
      isOpen={props.isOpen}
      animate
      autoFocus
      overrides={{
        Dialog: {
          style: {
            width: '80%',
          },
        },
      }}
      role={ROLE.dialog}
      onClose={() => props.setIsOpen(false)}
    >
      <ModalHeader>
        <HeadingMedium>
          {tCase('registeredCaseForRegNr')}
          {vehicleRegistrationNumber}
        </HeadingMedium>
      </ModalHeader>

      <ModalBody>
        <CaseSearchResults caseSearchResults={props.data} />
      </ModalBody>
    </Modal>
  );
}

export default CaseHistoryModal;
