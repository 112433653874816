import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FastTextareaField as TextareaField } from '../../shared/formFields/TextareaField';
import FormGroup from '../../shared/utilityComponents/FormGroup';
import { nameof } from '../caseRegistrationFunctions';
import { LocationForm } from './LocationForm';

function IncidentAddressFormGroupComp() {
  const tIncident = useTranslation('translation', { keyPrefix: 'incidentAddressForm' }).t;

  return (
    <FormGroup label={tIncident('carLocation')}>
      <LocationForm mapping="incident" allowSearch />
      <TextareaField label={tIncident('locationComment')} name={nameof('incidentAddressComment')} maxLength={255} />
    </FormGroup>
  );
}
export const IncidentAddressFormGroup = memo(IncidentAddressFormGroupComp);
