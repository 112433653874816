import { TabOverrides } from 'baseui/tabs-motion';

export const tabOverrides: TabOverrides = {
  TabPanel: {
    style: {
      overflowX: 'hidden',
      position: 'relative',
    },
  },
  Tab: {
    style: { fontWeight: '700' },
  },
};
