import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { StyledBodyCell, StyledHeadCell, StyledTable } from 'baseui/table-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineInfo } from 'react-icons/md';
import { RentalCarServiceCode } from '@@caseRegistration/constants';
import { ActorDetailsDrawer } from '@@core/drawers/ActorDetailsDrawer';
import { useZonedDateUtils } from '../shared/hooks/useZonedDateUtils';
import InfoBlock from '../shared/utilityComponents/InfoBlock';
import { ExtendedCaseAction } from './types';

interface Props {
  service: ExtendedCaseAction;
  backgroundColor: string;
}

const HistoryService = ({ service, backgroundColor }: Props) => {
  const [css] = useStyletron();

  const { t } = useTranslation('translation', { keyPrefix: 'caseHistory' });

  const { formatTime } = useZonedDateUtils();
  const [showRentalDetailsForId, setShowRentalDetailsForId] = useState<string>();

  const showConveyed =
    service.distributedToName || (service.visLoggServiceCode === RentalCarServiceCode && service.distributedToId);

  const showExecutionDetails =
    service.customerContactedDateTime ||
    service.enRouteDateTime ||
    service.arrivalDateTime ||
    service.loadedDateTime ||
    service.completedDateTime;

  const showScheduledExecution = service.estaDriverDateTime || service.etaDriverDateTime;

  const spacer = css({
    marginTop: '15px',
  });

  if (!showExecutionDetails && !showScheduledExecution && !showConveyed) {
    return null;
  }

  return (
    <div
      className={css({
        gridColumn: 'span 9',
        padding: '10px',
        backgroundColor: backgroundColor,
      })}
    >
      <StyledTable
        role="grid"
        $gridTemplateColumns={`${showConveyed ? '1fr' : ''} ${showScheduledExecution ? '1fr' : ''} ${
          showExecutionDetails ? '2fr' : ''
        }`}
      >
        <div role="row" className={css({ display: 'contents' })}>
          {showConveyed ? <StyledHeadCell>{t('conveyed')}</StyledHeadCell> : null}
          {showScheduledExecution ? <StyledHeadCell>{t('scheduledExecution')}</StyledHeadCell> : null}
          {showExecutionDetails ? <StyledHeadCell>{t('executionDetails')}</StyledHeadCell> : null}
        </div>
        <div role="row" className={css({ display: 'contents' })}>
          {showConveyed ? (
            <StyledBodyCell>
              <FlexGrid flexGridColumnCount={1}>
                <FlexGridItem>
                  <InfoBlock heading={t('conveyedTo')} paragraphs={[service.distributedToName]} />
                  <Block display={'flex'} paddingTop={'8px'}>
                    {service.visLoggServiceCode === RentalCarServiceCode ? (
                      <>
                        <InfoBlock heading={t('actorNr')} paragraphs={[service.distributedToId?.toString()]} />

                        <Block display={'flex'} alignItems={'center'} paddingLeft={'8px'}>
                          <Button
                            type="button"
                            kind={KIND.tertiary}
                            onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => {
                              e.stopPropagation();
                              setShowRentalDetailsForId(service.distributedToId?.toString());
                            }}
                          >
                            <MdOutlineInfo />
                          </Button>
                        </Block>
                      </>
                    ) : null}
                  </Block>
                </FlexGridItem>
              </FlexGrid>
            </StyledBodyCell>
          ) : null}
          {showScheduledExecution ? (
            <StyledBodyCell>
              <FlexGrid flexGridColumnCount={1}>
                <FlexGridItem>
                  <InfoBlock
                    heading={t('etaDriver')}
                    paragraphs={[
                      service.estaDriverDateTime
                        ? formatTime(service.estaDriverDateTime)
                        : service.etaDriverDateTime
                        ? formatTime(service.etaDriverDateTime)
                        : '',
                    ]}
                  />
                </FlexGridItem>
              </FlexGrid>
            </StyledBodyCell>
          ) : null}
          {showExecutionDetails ? (
            <StyledBodyCell>
              <FlexGrid flexGridColumnCount={3}>
                <FlexGridItem>
                  <InfoBlock
                    heading={t('customerContacted')}
                    paragraphs={[
                      service.customerContactedDateTime ? formatTime(service.customerContactedDateTime) : '',
                    ]}
                  />
                  <InfoBlock
                    heading={t('departure')}
                    paragraphs={[service.enRouteDateTime ? formatTime(service.enRouteDateTime) : '']}
                    className={spacer}
                  />
                </FlexGridItem>
                <FlexGridItem>
                  <InfoBlock
                    heading={t('arrival')}
                    paragraphs={[service.arrivalDateTime ? formatTime(service.arrivalDateTime) : '']}
                  />
                  <InfoBlock
                    heading={t('uploaded')}
                    paragraphs={[service.loadedDateTime ? formatTime(service.loadedDateTime) : '']}
                    className={spacer}
                  />
                </FlexGridItem>
                <FlexGridItem>
                  <InfoBlock
                    heading={t('completed')}
                    paragraphs={[service.completedDateTime ? formatTime(service.completedDateTime) : '']}
                  />
                  {/*TODO: Implement this when it's returned from API*/}
                  <InfoBlock heading={t('outcome')} paragraphs={[]} className={spacer} />
                </FlexGridItem>
              </FlexGrid>
            </StyledBodyCell>
          ) : null}
        </div>
      </StyledTable>
      <ActorDetailsDrawer
        actorId={showRentalDetailsForId}
        isOpen={!!showRentalDetailsForId}
        setIsOpen={(isOpen) => !isOpen && setShowRentalDetailsForId(undefined)}
      />
    </div>
  );
};

export default HistoryService;
