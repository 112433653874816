import { KIND, Tag, VARIANT } from 'baseui/tag';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CASE_FORM_STATUS_TO_TAG_KEY } from '../../shared/constants';
import { CaseFormStatus } from '../../shared/types';

interface CaseStatusTagProps {
  mode: string;
}

const CaseStatusTagComp = ({ mode }: CaseStatusTagProps) => {
  const tCaseReg = useTranslation('translation', { keyPrefix: 'caseRegistration' }).t;

  const text = tCaseReg(CASE_FORM_STATUS_TO_TAG_KEY[mode] ?? CASE_FORM_STATUS_TO_TAG_KEY['_']);

  const colorSetting =
    mode === CaseFormStatus.Sent
      ? '#FFFFFF'
      : mode === CaseFormStatus.Closed || mode === CaseFormStatus.Cancelled
      ? '#757575'
      : '#000000';

  return (
    <Tag
      closeable={false}
      color={
        mode === CaseFormStatus.Sent
          ? '#757575'
          : mode === CaseFormStatus.Closed || mode === CaseFormStatus.Cancelled
          ? '#FFFFFF'
          : '#FFFFFF'
      }
      kind={mode === CaseFormStatus.Create || mode === CaseFormStatus.Update ? KIND.warning : KIND.custom}
      variant={
        mode === CaseFormStatus.Create || mode === CaseFormStatus.Update || mode === CaseFormStatus.Sent
          ? VARIANT.solid
          : VARIANT.outlined
      }
      overrides={{
        Root: {
          style: {
            float: 'right',
            color: colorSetting,
            borderTopColor: colorSetting,
            borderRightColor: colorSetting,
            borderBottomColor: colorSetting,
            borderLeftColor: colorSetting,
          },
        },
      }}
    >
      {text}
    </Tag>
  );
};

export const CaseStatusTag = memo(CaseStatusTagComp);
